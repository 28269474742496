import { NgModule } from '@angular/core'
import { BusinessVerificationComponent } from './business-verification/business-verification.component'
import { SignupComponent } from './business-verification/signup/signup.component'

import { UnableToVerifyBusinessComponent } from './unable-to-verify-business/unable-to-verify-business.component'
import { PreApprovalComponent } from './pre-approval/pre-approval.component'
import { FundedComponent } from './funded/funded.component'
import { LoanAgreementComponent } from './loan-agreement/loan-agreement.component'
import { SharedModule } from '../shared/shared.module'
import { DocCheckoutRoutingModule } from './doc-checkout-routing.module'
import { PaymentScheduleComponent } from './loan-details/payment-schedule/payment-schedule.component'
import { IntroductionComponent } from './introduction/introduction.component'
import { DocCheckoutComponent } from './doc-checkout.component'
import { LoanDetailsComponent } from './loan-details/loan-details.component'
import { IdentityVerificationComponent } from './identity-verification/identity-verification.component'
import { NotVerifiedComponent } from './identity-not-verified/identity-not-verified.component'
import { ReviewSubmitComponent } from './review-submit/review-submit.component'
import { TimePipe } from '../shared/pipes/time.pipe'
import { DocuSignCallBackComponent } from './docu-sign-call-back/docu-sign-call-back.component'
import { VerifyEmailComponent } from './verify-email/verify-email.component'
import { EmailVerificationComponent } from './email-verification/email-verification.component'
import { DeclinedToSignComponent } from './declined-to-sign/declined-to-sign.component'
import { FinishLaterComponent } from './finish-later/finish-later.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { VerificationLinkExpiredComponent } from './email-verification/verification-link-expired/verification-link-expired.component'
import { DisabledComponent } from './disabled/disabled.component'
import { IneligibleComponent } from './ineligible/ineligible.component'
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { RetryVerificationQuestionsComponent } from './retry-verification-questions/retry-verification-questions.component'

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null

@NgModule({
  imports: [SharedModule, DocCheckoutRoutingModule, NgxMaskModule.forRoot()],
  declarations: [
    TimePipe,
    // SafePipe,
    DocCheckoutComponent,
    BusinessVerificationComponent,
    SignupComponent,
    UnableToVerifyBusinessComponent,
    PreApprovalComponent,
    FundedComponent,
    LoanAgreementComponent,
    IntroductionComponent,
    LoanDetailsComponent,
    IdentityVerificationComponent,
    NotVerifiedComponent,
    ReviewSubmitComponent,
    PaymentScheduleComponent,
    DocuSignCallBackComponent,
    VerifyEmailComponent,
    EmailVerificationComponent,
    DeclinedToSignComponent,
    FinishLaterComponent,
    PageNotFoundComponent,
    VerificationLinkExpiredComponent,
    DisabledComponent,
    IneligibleComponent,
    RetryVerificationQuestionsComponent,
  ],
  exports: [],
})
export class DocCheckoutModule {}
