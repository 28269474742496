import { AbstractControl } from '@angular/forms'
// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export function haveUpperLowerCase(control: AbstractControl) {
  if (
    control.value &&
    control.value.length !== 0 &&
    !(control.value.match(/[a-z]/) && control.value.match(/[A-Z]/))
  ) {
    return { haveUpperLowerCase: true }
  }
  return null
}
