import { AbstractControl } from '@angular/forms'
import isValid from 'date-fns/isValid'
import { parseISO } from 'date-fns'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'

export function InValidateStartDate(control: AbstractControl) {
  const pattern = /^\d+$/
  const today: Date = new Date()
  const thisYear = getYear(today)
  const thisMonth = getMonth(today)

  if (control.value && control.value.length !== 0) {
    const cleaned = control.value.split('/')
    const dValue = cleaned

    if (+dValue[0] < 1 || +dValue[0] > 12) {
      return { InValidateStartDate: true }
    }

    if (+dValue[1] && +dValue[1].length < 4) {
      return { InValidateStartDate: true }
    }

    if (dValue[2] || !pattern.test(dValue[0]) || !pattern.test(dValue[1])) {
      return { InValidateStartDate: true }
    }

    // future year
    if (dValue[1] > thisYear) {
      return { InValidateStartDate: true }
    }
    // future month
    if (+dValue[1] === thisYear && +dValue[0] > thisMonth + 1) {
      return { InValidateStartDate: true }
    }
  }
  return null
}
