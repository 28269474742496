import { Component, OnInit } from '@angular/core'
import { ErrorService } from '../../core/services/generic-error/error.service'
@Component({
  selector: 'app-disabled',
  templateUrl: './disabled.component.html',
  styleUrls: ['./disabled.component.scss'],
})
export class DisabledComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.storeError(this.errorService.LOAN_VOIDED)
  }
}
