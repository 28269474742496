<div class="content-container">
  <div class="auth-page-container">
    <div class="info-page-wrap">
      <div></div>
      <div>
        <app-logo></app-logo>

        <form [formGroup]="forgotForm" (keydown)="onSubmit($event)">
          <div class="password-rectangle">
            <div class="pre-title text-align-center auth-title">
              Forgot Password
            </div>
            <div class="auth-info text-align-center margin-auto">
              Enter the email associated with your account and we’ll send you a
              reset link to create a new password.
            </div>

            <div class="creds-wrap">
              <div class="input-text em">
                <div class="label-text">Email</div>
                <div class="email-change">
                  <input
                    formControlName="email"
                    #email
                    class="input-text container"
                    type="email"
                  />
                </div>

                <div
                  *ngIf="f.email.dirty || f.email.touched"
                  class="alert alert-danger err-text"
                >
                  <div
                    *ngIf="f.email.errors?.required"
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span class="alert alert-danger help-text">
                      <span>Email is required.</span>
                    </span>
                  </div>

                  <div
                    *ngIf="f.email.errors?.email || f.email.errors?.pattern"
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span class="alert alert-danger help-text">
                      <span>Enter a valid email.</span>
                    </span>
                  </div>
                </div>

                <div class="btn-container">
                  <button (click)="reSet()" #submit class="btn-background">
                    SEND RESET LINK
                  </button>
                </div>

                <div
                  class="btn-container info signin text-align-center"
                  (click)="signin()"
                >
                  <img src="assets/imgs/link-arrow-left-icon.svg" />
                  <a class="info">Back To Sign In</a>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
