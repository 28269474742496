import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'
import { Subscription } from 'rxjs'

import * as fakeData from '../../assets/fixtures/calcNums.json'
import { StepService } from '../core/services/steps/step.service'
import { LoanService } from '../core/services/loan/loan.service'
import * as INTF from '../core/models/interfaces'
import { AuthService } from '../core/services/auth/auth.service'
import { ErrorService } from '../core/services/generic-error/error.service'
import { environment } from '../../environments/environment'
import { getAccessToken, handleError } from '../core'

@Component({
  selector: 'app-doc-checkout',
  templateUrl: './doc-checkout.component.html',
  styleUrls: ['./doc-checkout.component.scss'],
})
export class DocCheckoutComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  calcNums: INTF.Loan
  loanStatus: INTF.DocCheckoutStep | null = null
  stepStatus: INTF.DocCheckoutStep | null = null
  loanUuid: string
  userName: string
  userClaims: any
  getTokenManager: any
  loanIntroductionStep: boolean
  identityVerificationIdentityStep: boolean
  loanDetailsStep: boolean
  businessReferenceStep: boolean
  loanAgreementStep: boolean
  isAuthenticated: boolean
  isShowSidebar: boolean

  // subs
  loanStatusSubscription: Subscription
  getloanSubscription: Subscription
  currentStepSubscription: Subscription

  // defaulted values
  accessToken: string | null = null
  useServer: boolean
  error: INTF.BackendError | null

  loanStep = {
    loanIntroductionStep: false,
    identityVerificationQuestionsStep: false,
    loanDetailsStep: false,
    loanAgreementStep: false,
    businessReferenceStep: false,
    retryVerificationQuestions: false,
  }

  constructor(
    public oktaAuth: OktaAuthService,
    private loanService: LoanService,
    private stepService: StepService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {
    this.useServer = environment.useServer
  }

  async ngOnInit() {
    console.log('DocCheckoutComponent ngOnInit--------')

    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.loanService.loanUuidSource.next(this.loanUuid)

    if (this.useServer) {
      const mfAccessToken = getAccessToken()
      // nuula or not nuula
      if (mfAccessToken && mfAccessToken.isValid) {
        console.log('mfAccessToken.isValid--------', mfAccessToken)

        this.isAuthenticated = true
        if (this.isAuthenticated) {
          // this is temporary solution
          this.userClaims = mfAccessToken // we need to call a backend endpoint for getting userprofile
        }
      } else {
        this.isAuthenticated = false
      }
    } else {
      this.isAuthenticated = fakeData.auth.isAuthenticated
    }

    this.loanStatusSubscription = this.loanService.currentStatus.subscribe(
      (res) => {
        this.loanStatus = res
        this.showSidebar()

        if (this.stepStatus <= 0) {
          this.stepStatus = res
        }
        this.checkLoanStatus(this.loanStatus)
      },
    )
    // step update coming from sidebar links
    this.currentStepSubscription = this.stepService.currentStep.subscribe(
      (data) => {
        this.stepStatus = data
        this.checkLoanStatus(this.stepStatus)
      },
    )

    this.getLoan()
  }

  async logout() {
    this.authService.logout()
  }

  showSidebar() {
    this.isShowSidebar = INTF.DOC_CHECKOUT_SIDEBAR_VISIBLE_STEPS.includes(
      this.loanStatus,
    )
      ? true
      : false
  }

  getLoan() {
    this.getloanSubscription = this.loanService
      .getLoan$(this.loanUuid)
      .subscribe(
        (res) => {
          const step = INTF.stepFromString(
            res.docCheckoutStatus,
          ) as INTF.DocCheckoutStep
          if (step !== INTF.DocCheckoutStep.preApprovalConfirmation) {
            this.calcNums = res
            this.calcNums.guarantors.forEach((element) => {
              element.user.fullName =
                element.user.firstName + ' ' + element.user.lastName
            })
          }

          this.checkLoanStatus(step)
          this.loanService.loanDataSource.next(res)
          const route = this.stepService.stepToRoute(step, this.loanUuid)

          this.router.navigate([route])
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  // VERY IMPORTANT TO USE THIS HOOK
  ngAfterViewChecked() {
    this.showSidebar()

    // Run change detection explicitly after the change
    this.cdr.detectChanges()
  }

  cleanLoanStep() {
    for (const step in this.loanStep) {
      if (this.loanStep.hasOwnProperty(step)) {
        this.loanStep[step] = false
      }
    }
  }

  checkLoanStatus(step: INTF.DocCheckoutStep) {
    if (!step) {
      return
    }

    if (INTF.DOC_CHECKOUT_PROCESS_STEPS.indexOf(step) >= 0) {
      this.cleanLoanStep()
      switch (step) {
        case INTF.DocCheckoutStep.loanIntroduction:
          this.loanStep.loanIntroductionStep = true
          break
        case INTF.DocCheckoutStep.identityVerificationQuestions:
          this.loanStep.identityVerificationQuestionsStep = true
          break
        case INTF.DocCheckoutStep.retryVerificationQuestions:
          this.loanStep.retryVerificationQuestions = true
          break
        case INTF.DocCheckoutStep.loanDetails:
          this.loanStep.loanDetailsStep = true
          break
        case INTF.DocCheckoutStep.loanAgreement:
          this.loanStep.loanAgreementStep = true
          break
        case INTF.DocCheckoutStep.businessReference:
          this.loanStep.businessReferenceStep = true
          break
      }
    }
  }

  signin() {
    this.router.navigate([`login`])
  }

  ngOnDestroy() {
    if (this.loanStatusSubscription) {
      this.loanStatusSubscription.unsubscribe()
      this.loanStatusSubscription = null
    }
    if (this.getloanSubscription) {
      this.getloanSubscription.unsubscribe()
      this.getloanSubscription = null
    }

    if (this.currentStepSubscription) {
      this.currentStepSubscription.unsubscribe()
      this.currentStepSubscription = null
    }
  }
}
