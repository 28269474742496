import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { distinctUntilChanged } from 'rxjs/operators'
import { Subscription } from 'rxjs'

import { ModalService } from '../../core/services/modal/modal.service'
import * as INTF from '../../core/models/interfaces'
import {
  CreateAccountPayload,
  CreateAccountResponse,
  DirectAppStep,
  DirectAppUrl,
  DOLLAR_INPUT_PLACEHOLDER,
  EMAIL_FORM_REGEX,
  getTokenObjectFromValue,
  handleError,
  MFAccessToken,
} from '../../core'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import {
  hasNum,
  haveUpperLowerCase,
  ValidateDesiredAmt,
  ValidateMonthlySalesAmt,
  ValidatePassword,
  ValidatePhone,
} from '../../shared/validators'
import Utils from '../../shared/utils/utils'
import { AuthService } from '../../core/services/auth/auth.service'

@Component({
  selector: 'app-direct-loan-details',
  templateUrl: './direct-loan-details.component.html',
  styleUrls: ['./direct-loan-details.component.scss'],
})
export class DirectLoanDetailsComponent implements OnInit, OnDestroy {
  accessToken: null | MFAccessToken
  @Input() set setAccessToken(value: MFAccessToken) {
    this.accessToken = value
  }

  createFromLead: boolean
  @Input() set setCreateFromLead(value: boolean) {
    this.createFromLead = value
  }
  @Input() disableEdit: boolean

  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
    if (this.isNuulaUser) {
      this.email = this.accessToken?.email ? this.accessToken.email : ''
      if (this.f && this.f.email_address) {
        this.f.email_address.setValue(this.email)
      }
    }
  }

  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    const newAppInfo = // has value but it is a new applicationInfo, value comes from direct component
      value && Object.keys(value).length !== 0 && !this.applicationInfo
    this.applicationInfo = value
    if (newAppInfo) {
      // init the form from existing applicationInfo, without query params, from refreshing page
      this.formInit()
      this.valueChange()
    }
    if (value && Object.keys(value).length > 0) {
      this.isFormDisabled = true
    }
  }
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    const newMetadata =
      value && Object.keys(value).length !== 0 && !this.metadata
    this.metadata = value

    if (newMetadata && value && value.qparams) {
      this.email = value.qparams?.email || null
      this.companyName = value.qparams?.companyName || null
      this.phoneNumber = value.qparams?.phoneNumber || null
      this.lastName = value.qparams?.lastName || null
      this.firstName = value.qparams?.firstName || null

      // init the form from query params

      this.formInit()
      this.valueChange()
    }
  }
  respId: string
  showAccountAlreadyExists: boolean

  phoneNumber: string
  companyName: string
  email: string
  firstName: string
  lastName: string
  data: any
  amountPlaceholderText = DOLLAR_INPUT_PLACEHOLDER
  formattedDesiredLoanAmt = ''
  formattedMonthlyAverageSalesAmt = ''
  @ViewChild('acknowledgedConsent') acknowledgedConsent: ElementRef
  @ViewChild('password') password: ElementRef
  @ViewChild('desiredLoanAmt', { static: false }) desiredLoanAmt: ElementRef

  createAccountForm: FormGroup
  createAccountFormSubscription: Subscription
  createAccountFormValueChangSubscription: Subscription
  directAppRespIdSubscription: Subscription
  startAppSubscription: Subscription
  cleanedDesiredLoanAmount: number
  cleanedMonthlyAverageSales: number

  clicked: boolean
  accountInfo: INTF.AccountInfo
  directAppStatus: DirectAppStep
  isFormDisabled = false
  new: any
  newphoneNo = ''
  timeInBusinessList = [
    '0-2 Months',
    '3-11 Months',
    '1 to < 2 Years',
    '2 to < 3 Years',
    '3 to < 5 Years',
    '5+ Years',
  ]
  showError = false

  constructor(
    private authService: AuthService,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private directAppService: DirectAppService,
    private directAppStepService: DirectAppStepService,
    private errorService: ErrorService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.formInit()
    this.valueChange()
  }

  blurMonthlyAverageSales(element) {
    Utils.formatCurrencyField(element)
  }

  blurDesiredAmt(element) {
    Utils.formatCurrencyField(element)
  }

  amountKeydown(e) {
    Utils.AmountKeydown(e)
  }

  formatBusinessPhone(element) {
    this.newphoneNo = Utils.formatPhoneNo(element.target.value)

    this.createAccountForm.patchValue(
      {
        phoneNumber: this.newphoneNo,
      },
      { emitEvent: false },
    )
  }

  getDefaultEmailAddress() {
    let defaultEmail = ''
    if (this.accessToken?.email) {
      defaultEmail = this.accessToken.email
    } else {
      defaultEmail = this.applicationInfo?.accountInfo?.email
        ? this.applicationInfo?.accountInfo?.email
        : this.email
    }
    return defaultEmail
  }

  getEmailAddress() {
    return this.email
  }

  getIsFormDisabled() {
    return this.isFormDisabled && !this.createFromLead
  }

  getHideLoanAmount() {
    return Boolean(
      this.createFromLead &&
        this.applicationInfo?.accountInfo?.desiredLoanAmount,
    )
  }
  getHideLoanPurpose() {
    return Boolean(
      this.createFromLead && this.applicationInfo?.accountInfo?.loanPurpose,
    )
  }
  getHideTimeInBusiness() {
    return Boolean(
      this.createFromLead && this.applicationInfo?.accountInfo?.timeInBusiness,
    )
  }
  getHideAverageSales() {
    return Boolean(
      this.createFromLead &&
        this.applicationInfo?.accountInfo?.monthlyAverageSales,
    )
  }
  valueChange() {
    this.createAccountFormValueChangSubscription =
      this.createAccountForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value) => {})
  }

  togglePassword() {
    this.password.nativeElement.type =
      this.password.nativeElement.type === 'text' ? 'password' : 'text'
  }

  formInit() {
    this.directAppService.currentdirectAppQParams.subscribe((val) => {
      if (val) {
        this.email = val.email
        this.companyName = val.companyName
        this.phoneNumber = val.phoneNumber
        this.firstName = val.firstName
        this.lastName = val.lastName
      }
    })
    this.directAppRespIdSubscription =
      this.directAppService.currentDirectAppRespId.subscribe((val) => {
        if (val) {
          this.respId = val
        }
      })
    const defaultFirstName = this.applicationInfo?.accountInfo?.firstName
      ? this.applicationInfo?.accountInfo?.firstName
      : this.firstName
    const defaultLastName = this.applicationInfo?.accountInfo?.lastName
      ? this.applicationInfo?.accountInfo?.lastName
      : this.lastName
    const defaultCompanyName = this.applicationInfo?.accountInfo?.companyName
      ? this.applicationInfo?.accountInfo?.companyName
      : this.companyName
    const defaultPhoneNumber = this.applicationInfo?.accountInfo?.phoneNumber
      ? this.applicationInfo?.accountInfo?.phoneNumber
      : this.phoneNumber
    const defaultEmail = this.getDefaultEmailAddress()

    this.createAccountForm = this.formBuilder.group({
      firstName: [
        defaultFirstName,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      lastName: [
        defaultLastName,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      companyName: [
        defaultCompanyName,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      phoneNumber: [
        defaultPhoneNumber,
        {
          validators: [Validators.required, ValidatePhone],
          updateOn: 'blur',
        },
      ],

      desiredLoanAmount: [
        this.applicationInfo?.accountInfo?.desiredLoanAmount,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9.,]+$/),
            ValidateDesiredAmt,
          ],
          updateOn: 'blur',
        },
      ],

      loanPurpose: [
        this.applicationInfo?.accountInfo?.loanPurpose,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      timeInBusiness: [
        this.applicationInfo?.accountInfo?.timeInBusiness,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      monthlyAverageSales: [
        this.applicationInfo?.accountInfo?.monthlyAverageSales,
        {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9.,]+$/),
            ValidateMonthlySalesAmt,
          ],
          updateOn: 'blur',
        },
      ],
      email: [
        defaultEmail,
        {
          validators: [
            Validators.required,
            Validators.pattern(EMAIL_FORM_REGEX),
            Validators.email,
            Validators.minLength(6),
            Validators.maxLength(100),
          ],
          updateOn: 'blur',
        },
      ],
      password: [
        this.applicationInfo?.accountInfo?.password,
        {
          validators: [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(72),
            ValidatePassword,
            hasNum,
            haveUpperLowerCase,
          ],
          // updateOn: 'blur',
        },
      ],
      privacyPolicyAndeConsent: [false, [Validators.required]],
    })

    this.clicked = false

    this.newphoneNo = Utils.formatPhoneNo(this.f.phoneNumber.value)
    this.createAccountForm.patchValue(
      {
        phoneNumber: this.newphoneNo,
      },
      { emitEvent: false },
    )
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.createAccountForm.controls
  }

  removePasswordFromFormForNuula() {
    if (this.isNuulaUser) {
      this.createAccountForm.removeControl('password')
    }
    this.createAccountForm.updateValueAndValidity()
  }
  getSubmitButtonText() {
    return this.isFormDisabled && !this.createFromLead
      ? 'RESUME APPLICATION'
      : 'START APPLICATION'
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.handleSubmit()
    }
  }

  handleSubmit() {
    this.showError = true

    if (!this.isFormDisabled || this.createFromLead) {
      this.createAccount()
    } else {
      const nextStep = DirectAppStep.BUSINESS_BASIC_INFO
      const data = { packetId: this.packetId, newStatus: nextStep }
      this.directAppService.setStatus$(data).subscribe(
        (res) => {
          this.directAppStepService.updatedDirectAppStep(nextStep)
        },
        (err) => {
          if (err.error && err.error.toString().toLowerCase() === 'forbidden') {
            this.router.navigate([`/auth/login`])
          } else {
            handleError(err, this.errorService, this.router)
          }
        },
      )
    }
  }

  createAccount() {
    this.removePasswordFromFormForNuula()
    if (
      this.createAccountForm.valid &&
      this.createAccountForm.value.privacyPolicyAndeConsent === true
    ) {
      ;['desiredLoanAmount', 'monthlyAverageSales'].forEach((formControl) => {
        Utils.handleFormCurrencyInput(this.createAccountForm, formControl)
      })

      this.accountInfo = {
        ...this.createAccountForm.value,
      } // has the email we need for directapp backend

      const data: CreateAccountPayload = {
        accountInfo: this.accountInfo,
      }
      if (this.packetId) {
        data.packetId = this.packetId
      }
      if (this.respId) {
        data.respId = this.respId
      }

      const handleResponseSuccess = (res: CreateAccountResponse) => {
        this.clicked = false

        const rawToken = res.access_token
        const newToken = getTokenObjectFromValue(rawToken)
        this.authService.loadToken(newToken)
        window.location.replace(`${DirectAppUrl.BUSINESS_BASIC_INFO}`)
      }
      const handleResponseError = (err: any) => {
        if (err?.error?.error?.payload?.errorCode === 'AccountAlreadyExists') {
          this.showAccountAlreadyExists = true
          this.modalService.updatedModalState(true)
          this.modalService.updatedModalType(
            this.modalService.ACCOUNT_ALREADY_EXISTS,
          )
        } else {
          if (err.error && err.error.toString().toLowerCase() === 'forbidden') {
            this.router.navigate([`/auth/login`])
          }
          handleError(err, this.errorService, this.router)
        }
      }
      if (
        this.createAccountForm.valid &&
        this.createAccountForm.value.privacyPolicyAndeConsent === true
      ) {
        if (this.createFromLead) {
          console.log('create-from-lead flow')
          this.startAppSubscription = this.directAppService
            .postCreateAccountFromLead$(data)
            .subscribe(
              (res) => {
                handleResponseSuccess(res as CreateAccountResponse)
              },
              (err) => {
                handleResponseError(err)
              },
            )
        } else {
          this.startAppSubscription = this.directAppService
            .createAccount$(data, this.isNuulaUser)
            .subscribe(
              (res) => {
                handleResponseSuccess(res as CreateAccountResponse)
              },
              (err) => {
                handleResponseError(err)
              },
            )
        }
      }
    }
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  ngOnDestroy() {
    if (this.createAccountFormSubscription) {
      this.createAccountFormSubscription.unsubscribe()
    }

    if (this.createAccountFormValueChangSubscription) {
      this.createAccountFormValueChangSubscription.unsubscribe()
    }

    if (this.startAppSubscription) {
      this.startAppSubscription.unsubscribe()
    }

    if (this.directAppRespIdSubscription) {
      this.directAppRespIdSubscription.unsubscribe()
    }
  }
}
