import { Component, OnInit } from '@angular/core'

import { Router } from '@angular/router'
import {
  DirectAppUrl,
  getTokenObjectFromValue,
  TokenExchangeResult,
} from '../../../core'
import { AuthService } from '../../../core/services/auth/auth.service'
import { MicroAuthService } from '../../../core/services/micro-auth'

@Component({
  selector: 'app-nuula-landing',
  templateUrl: './nuula-landing.component.html',
  styleUrls: ['./nuula-landing.component.scss'],
})
export class NuulaLandingComponent implements OnInit {
  navigating = false
  openSections = false
  token = ''
  tokenError = false
  tokenVerified = false
  constructor(
    private authService: AuthService,
    private microAuthService: MicroAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    const urlParts = this.router.url.split('/')
    const token = urlParts[urlParts.length - 1]
    window.location.replace(`/ui/direct-app/nuula-landing/${token}`)

    this.authService.exchangeOneTimeUseTokenFromUrl()

    this.authService.currentTokenVerified.subscribe((val) => {
      this.tokenVerified = val
    })
    this.authService.currentTokenError.subscribe((val) => {
      this.tokenError = val
    })
  }

  isSpinnerVisible() {
    return !this.tokenVerified && !this.tokenError
  }

  toggleRestrictedIndustries() {
    this.openSections = !this.openSections
  }

  applyNow() {
    // Use this.navigating to avoid a double click submission
    if (!this.navigating) {
      this.navigating = true
      window.location.replace(`${DirectAppUrl.CREATE_ACCOUNT}`)
    }
  }
}
