import { AbstractControl } from '@angular/forms'
import Utils from '../../shared/utils/utils'

export function ValidateNumber(min: number, max: number) {
  return (control: AbstractControl) => {
    const num = Number(Utils.format2Decimals(control.value))
    if (num && (num > max || num < min)) {
      return {
        ValidateNumber: true,
      }
    }
    return null
  }
}
