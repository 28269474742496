import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms'
import { distinctUntilChanged } from 'rxjs/operators'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { DirectAppReviewSubmitService } from '../../core/services/direct-app-review-submit/direct-app-review-submit.service'
import {
  DirectAppStep,
  DOLLAR_INPUT_PLACEHOLDER,
  getDirectAppUrlFromStep,
  handleError,
  isValidDateKeydown,
  SupportedDateFormat,
  validateDateString,
} from '../../core'
import * as INTF from '../../core/models/interfaces'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { ModalService } from '../../core/services/modal/modal.service'
import Utils from '../../shared/utils/utils'
import { ValidateNumber } from '../../shared/validators/numberRange.validator'
import { InValidateStartDate } from '../../shared/validators/startDate.validator'

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit, OnDestroy {
  packetId: string

  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    const newAppInfo = value && !this.applicationInfo
    this.applicationInfo = value
    if (newAppInfo) {
      this.formInit()
    }
  }
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
  }
  amountPlaceholderText: string = DOLLAR_INPUT_PLACEHOLDER
  backspace = false
  clicked: boolean
  collapseLender = []
  comingFromReviewSubmit = false
  comingFromReviewSubmitSubscription: Subscription
  continue: boolean
  debtInfo: INTF.DebtInfo[] = []
  deleteID: number
  debtsForm: FormGroup
  directAppStatus: DirectAppStep
  disableAddBtn = false
  formattedOriginal = []
  formattedOriginalLoanAmt: any
  formattedPaymentAmt: any
  formattedRemainingBalance: any
  newDate = []
  showAddLender = true
  showError = false
  updateLender = false
  disableEdit = false
  constructor(
    private modalService: ModalService,
    private directAppStepService: DirectAppStepService,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private formBuilder: FormBuilder,
    private router: Router,
    private directAppService: DirectAppService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/debt-info`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })
    this.directAppService.currentDeleteItem.subscribe((val: boolean) => {
      if (val) {
        this.deleteLenderBtn()
      }
    })
    this.comingFromReviewSubmitSubscription =
      this.directAppReviewSubmitService.currentComingFromReviewSubmit.subscribe(
        (val) => {
          this.comingFromReviewSubmit = val
        },
      )
  }

  getDateFormatPlaceholder() {
    return SupportedDateFormat.MM_YYYY
  }

  handleDateInput(e: InputEvent, i: number) {
    const target = e.target as HTMLInputElement
    const value = target.value ? target.value.toString() : ''

    const newValue = validateDateString(
      value,
      SupportedDateFormat.MM_YYYY,
      this.backspace,
    )
    this.newDate[i] = newValue

    if (newValue !== value) {
      target.value = newValue
    }
    this.lenders.at(i).patchValue(
      {
        loanOriginationDate: target.value,
      },
      { emitEvent: false },
    )
  }

  handleNumberKeydown(e: KeyboardEvent) {
    if (!isValidDateKeydown(e)) {
      e.preventDefault()
    }
    if (e.key === 'Backspace') {
      this.backspace = true
    } else {
      this.backspace = false
    }
  }

  blurCurrency(event) {
    Utils.formatCurrencyField(event)
  }

  formInit() {
    this.debtsForm = new FormGroup({
      lenders: this.formBuilder.array([]),
    })
    if (!this.applicationInfo.debtInfo) {
      this.debtInfo = []
    } else {
      this.debtInfo = this.applicationInfo.debtInfo
    }
    this.addLender()
    this.clicked = false
  }

  showDelete(i) {
    this.deleteID = i

    if (this.collapseLender.every(this.check)) {
      this.modalService.updatedModalState(true)
      this.modalService.updatedModalType(this.modalService.DELETE)
      this.modalService.updatedItemTypeState('lender')
      this.modalService.updatedModalIDState(i)
    }
  }

  deleteLenderBtn() {
    if (this.collapseLender.every(this.check)) {
      this.deleteLender()
      this.directAppService.deleteItemSource.next(false)
    }
  }

  deleteLender() {
    const i = this.deleteID

    if (!this.deleteID && this.deleteID !== 0) {
      this.debtInfo.slice(0, -1)
      this.lenders?.removeAt(-1)
      this.collapseLender.splice(-1)
    } else {
      this.lenders?.removeAt(i)
      this.debtInfo.splice(i, 1)
      this.collapseLender.splice(i, 1)
    }

    if (i === null) {
      return
    }

    this.checkAddBtn()
    this.modalService.updatedModalState(false)
  }

  cancelLender(i: number) {
    if (!this.updateLender) {
      this.deleteLender()
    } else {
      const lender = this.debtInfo[i]
      if (lender) {
        this.lenders.at(i).patchValue(
          {
            lengthOfLoan: lender.lengthOfLoan,
            loanOriginationDate: lender.loanOriginationDate,
            originalLoanAmount: lender.originalLoanAmount,
            nameOfLender: lender.nameOfLender,
            paymentAmount: lender.paymentAmount,
            remainingBalance: lender.remainingBalance,
            paymentFrequency: lender.paymentFrequency,
          },
          { emitEvent: false },
        )
        this.collapseLender[i] = true
      }
    }
    this.checkAddBtn()
  }

  addLender() {
    if (this.debtInfo) {
      for (const [index, lender] of this.debtInfo.entries()) {
        this.addLenderWithFields(lender, true)
        this.showError = false

        this.formattedOriginal[index] = String(
          lender.originalLoanAmount,
        ).replace(/[^0-9\.]+/g, '')
      }
    }
    this.checkAddBtn()
    this.updateLender = false
    this.showError = false
  }

  amountKeydown(e) {
    Utils.AmountKeydown(e)
  }

  addLenderWithFields(lenders, collapseLenderValue) {
    const lengthOfLoan = lenders?.lengthOfLoan || ''
    const loanOriginationDate = lenders?.loanOriginationDate || ''
    const nameOfLender = lenders?.nameOfLender || ''
    const originalLoanAmount = lenders?.originalLoanAmount || ''
    const paymentAmount = lenders?.paymentAmount || ''
    const remainingBalance = lenders?.remainingBalance || ''
    const paymentFrequency = lenders?.paymentFrequency || ''

    this.lenders?.push(
      new FormGroup({
        lengthOfLoan: new FormControl(lengthOfLoan, {
          validators: [Validators.required, ValidateNumber(1, 999)],
          updateOn: 'blur',
        }),
        loanOriginationDate: new FormControl(loanOriginationDate, {
          validators: [Validators.required, InValidateStartDate],
          updateOn: 'blur',
        }),
        nameOfLender: new FormControl(nameOfLender, [Validators.required]),
        originalLoanAmount: new FormControl(originalLoanAmount, {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9.,]+$/),
            ValidateNumber(1, 999999999),
          ],
          updateOn: 'blur',
        }),
        paymentAmount: new FormControl(paymentAmount, {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9.,]+$/),
            ValidateNumber(1, 999999999),
          ],
          updateOn: 'blur',
        }),
        remainingBalance: new FormControl(remainingBalance, {
          validators: [
            Validators.required,
            Validators.pattern(/^[0-9.,]+$/),
            ValidateNumber(1, 999999999),
          ],
          updateOn: 'blur',
        }),
        paymentFrequency: new FormControl(
          paymentFrequency,
          Validators.required,
        ),
      }),
    )
    this.collapseLender?.push(collapseLenderValue)
    this.checkAddBtn()
    this.showError = false
  }

  addLenderBtn() {
    if (
      (this.collapseLender.length > 0 &&
        this.debtsForm.value.lenders.length >= 2) ||
      !this.collapseLender.every(this.check)
    ) {
      return
    } else {
      this.addLenderWithFields(null, false)
    }
  }

  saveLendersInfo(i: number) {
    this.showError = true

    if (this.debtsForm.valid) {
      this.debtsForm
        .get('lenders')
        ['controls'].forEach((lender, index: number) => {
          ;[
            'originalLoanAmount',
            'paymentAmount',
            'remainingBalance',
            'lengthOfLoan',
          ].forEach((formControl) => {
            Utils.handleFormCurrencyInput(lender, formControl)
          })
        })
    }

    if (this.debtsForm.valid) {
      this.formattedOriginal[i] = String(
        this.debtsForm.value.lenders[i].originalLoanAmount,
      ).replace(/[^0-9\.]+/g, '')

      this.collapseLender[i] = this.debtsForm.valid ? true : false

      this.debtInfo = this.debtsForm.value.lenders

      this.checkAddBtn()
    } else {
      this.collapseLender[i] = false
    }
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.submitDebtInfo$()
    }
  }

  get lenders() {
    if (this.debtsForm) {
      return this.debtsForm.get('lenders') as FormArray
    }
  }

  goBack() {
    this.directAppStepService.updatedDirectAppStep(DirectAppStep.OWNER_INFO)
  }

  editLender(i: number) {
    this.newDate[i] = this.lenders.at(i).value.loanOriginationDate
    if (this.collapseLender.every(this.check)) {
      this.collapseLender[i] = false
      this.updateLender = true

      setTimeout(() => {
        const btn = document.getElementById(
          'save-lender' + i,
        ) as HTMLInputElement
        btn.textContent = 'UPDATE'

        const originalLoanAmountEl = document.getElementById(
          'original-loan' + i,
        ) as HTMLInputElement
        const remainingBalanceEl = document.getElementById(
          'remaining-balance' + i,
        ) as HTMLInputElement
        const paymentAmtEl = document.getElementById(
          'payment-amount' + i,
        ) as HTMLElement

        const originalLoan = Utils.formatCurrencyValue(originalLoanAmountEl)
        const remainingBalance = Utils.formatCurrencyValue(remainingBalanceEl)
        const paymentAmt = Utils.formatCurrencyValue(paymentAmtEl)

        this.formattedOriginalLoanAmt = originalLoan
        this.formattedRemainingBalance = remainingBalance
        this.formattedPaymentAmt = paymentAmt
      }, 0)
    }

    this.checkAddBtn()
    this.continue = false
  }

  checkContinue() {
    if (
      this.debtsForm?.invalid ||
      // this.collapseLender.length === 0 ||
      this.collapseLender.length !== this.lenders['controls'].length
    ) {
      this.continue = false
    } else {
      this.continue = this.collapseLender.every(this.check) ? true : false
    }
  }
  check(val) {
    return val === true
  }

  checkAddBtn() {
    this.checkContinue()

    this.showAddLender = this.lenders['controls'].length >= 2 ? false : true
  }

  submitDebtInfo$() {
    this.showError = true

    if (this.debtsForm.valid) {
      const nextStep = this.comingFromReviewSubmit
        ? DirectAppStep.REVIEW_SUBMIT
        : DirectAppStep.DOCUMENT

      const data = {
        packetId: this.packetId,
        debtInfo: this.debtsForm.value.lenders,
        nextStep,
      }

      this.directAppReviewSubmitService.setComingFromReviewSubmit(false)
      if (this.debtsForm.valid) {
        this.directAppService.submitDebtInfo$(data).subscribe(
          (res) => {
            this.clicked = false
            if (nextStep === DirectAppStep.REVIEW_SUBMIT) {
              window.location.replace(`/ui/direct-app/review-submit`)
            } else {
              window.location.replace(`/ui/direct-app/document-summary`)
            }
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
      }
    }
  }

  ngOnDestroy() {
    if (this.comingFromReviewSubmitSubscription) {
      this.comingFromReviewSubmitSubscription.unsubscribe()
    }
  }
}
