export const isValidDateKeydown: (event: KeyboardEvent) => boolean = (
  event,
) => {
  if (
    event.key &&
    event.key.length === 1 &&
    event.key.replace(/[^0-9]+/g, '').length !== 1
  ) {
    return false
  }
  return true
}
