import { AbstractControl } from '@angular/forms'
export function ValidateMonthlySalesAmt(control: AbstractControl) {
  if (control.value) {
    const cleaned = +String(control.value).replace(/[^0-9\.]+/g, '')
    if (cleaned <= 0) {
      return { ValidateMonthlySalesAmt: true }
    }
  }
  return null
}
