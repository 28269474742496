import { Component, OnInit } from '@angular/core'
import { ErrorService } from '../../core/services/generic-error/error.service'

@Component({
  selector: 'app-identity-not-verified',
  templateUrl: './identity-not-verified.component.html',
  styleUrls: ['./identity-not-verified.component.scss'],
})
export class NotVerifiedComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit() {
    this.errorService.storeError(this.errorService.ID_NOT_VERIFIED)
  }
}
