import { BrowserModule } from '@angular/platform-browser'
import { NgModule, ErrorHandler } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { Router } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GoogleTagManagerModule } from 'angular-google-tag-manager'

import { OktaAuthModule } from '@okta/okta-angular'
import { OKTA_CONFIG } from '@okta/okta-angular'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/angular'
import { ReportingObserver as ReportingObserverIntegration } from '@sentry/integrations'
import { RewriteFrames } from '@sentry/integrations'
import { ExtraErrorData } from '@sentry/integrations'
import { CaptureConsole } from '@sentry/integrations'
import { Debug } from '@sentry/integrations'
import SentryRRWeb from '@sentry/rrweb'

import { AppComponent } from './app.component'
import { AuthModule } from './core/auth/auth.module'
import { oktaConfig } from './core/models/okta_config'
import { AuthInterceptor } from './core/services/interceptors/auth-interceptor'
import { DocCheckoutModule } from './doc-checkout/doc-checkout.module'
import { HomeModule } from './home/home.module'
import { SharedModule } from './shared/shared.module'
import { HomeComponent } from './home/home.component'
import { OktaAuthGuard } from './core/services/guard/app.guard'
import { environment } from '../environments/environment'
import { DirectModule } from './direct/direct.module'

Sentry.init({
  /*
  interface Options: https://github.com/getsentry/sentry-javascript/blob/b7baedd9/packages/types/src/options.ts#L92

  Specifies whether this SDK should activate and send events to Sentry.
  Disabling the SDK reduces all overhead from instrumentation, collecting
  breadcrumbs and capturing events. Defaults to true.
  */

  // enabled: false ? environment.environment === 'local*' : true,
  // enabled: true,
  /*
  The DSN tells the SDK where to send the events.If this value is not provided,
  the SDK will try to read it from the SENTRY_DSN environment variable.
  If that variable also does not exist, the SDK will just not send any events.
  */
  dsn: 'https://f2fb10cc69484fa9816dcc09e68378ff@o423005.ingest.sentry.io/5352551',

  /* The current environment of your application (e.g. "production"). */
  environment: environment.environment,

  // This variable controls the total amount of breadcrumbs that should be captured. This defaults to 100.
  maxBreadcrumbs: 50,

  /*
  Configures the sample rate for error events, in the range of 0.0 to 1.0.
  The default is 1.0 which means that 100% of error events are sent.
  If set to 0.1 only 10% of error events will be sent. Events are picked randomly.
  */
  sampleRate: 1.0,

  /*
  A list of strings or regex patterns that match error URLs that should not be sent to Sentry.
  */

  /*
  A legacy alias for a list of strings or regex patterns that match error URLs which should exclusively be sent to Sentry.
  */
  allowUrls: ['mulliganfunding', 'mulligancloud'],

  // It's generally not recommended to turn it on in production, though turning debug mode on will not cause any safety concerns.
  debug: false,

  /*Attaches stacktraces to pure capture message / log integrations
  stack traces are automatically attached to all messages logged.
  */
  attachStacktrace: true,

  /* Releases are global per organization; prefix them with something project-specific for easy differentiation.
    release: "my-project-name@2.3.12", // https://docs.npmjs.com/misc/scripts#packagejson-vars
  */
  integrations: [
    /* Registers and configures the Tracing integration,
    which automatically instruments your application to monitor its
    performance, including custom Angular routing instrumentation
    */
    new Integrations.BrowserTracing({
      tracingOrigins: [
        /*
        One important thing to note is that the tracingOrigins option matches against
        the whole request URL, not just the domain. Using stricter regex to match certain
        parts of the URL can help make sure that requests do not unnecessarily have the sentry-trace header attached.
        */
        'localhost',
        'https://customer.stg.mulligancloud.com/*',
        'https://account.mulliganfunding.com/*',
        'https://customer.prod.mulligancloud.com/*',
        'https://app.mulliganfunding.com/*',
        'https://customer.dev.mulligancloud.com/*',
        'https://account.dev.mulliganfunding.com/*',
        'https://app.dev.mulliganfunding.com/*',
      ],
      shouldCreateSpanForRequest: (url) => {
        // Do not create spans for outgoing requests to a `/health/` endpoint
        return !url.match(/\/health\/?$/)
      },
      routingInstrumentation: Sentry.routingInstrumentation,
    }),

    // Pluggable Integrations
    /*
    This integration allows you to ignore specific errors based on the type, message, or URLs in a given exception.
    It ignores errors that start with Script error or Javascript error: Script error by default.
    To configure this integration, use ignoreErrors, denyUrls, and allowUrls SDK options directly.
    Keep in mind that denyURL and allowURL work only for captured exceptions, not raw message events.
    Filter errors from these releases/IP addresses;
    Filter out known web crawlers;
    Filter out known errors from legacy browsers/be caused by browser extensions;
    */
    // new Sentry.Integrations.InboundFilters(),

    new ReportingObserverIntegration(),
    // https://www.npmjs.com/package/sentry-testkit
    // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/rrweb/
    new SentryRRWeb({
      // default is empty
      // checkoutEveryNth: 100,
      // default is 5 minutes
      // checkoutEveryNms: 15 * 60 * 1000,
      // on by default
      maskAllInputs: false,
    }),

    /*
    This integration allows the SDK to provide original functions and method names, even when our error or breadcrumbs handlers wrap them.
    */
    new Sentry.Integrations.FunctionToString(),

    /*
    This integration wraps native time and events APIs (setTimeout, setInterval, requestAnimationFrame,
    addEventListener/removeEventListener) in try/catch blocks to handle async exceptions.
    */
    // new Sentry.Integrations.TryCatch(),

    /*
    This integration allows you to configure linked errors.
    They’ll be recursively read up to a specified limit and lookup will be performed by a specific key.
    By default, the Sentry SDK sets the limit to five and the key used is cause.
    */
    new Sentry.Integrations.LinkedErrors({
      key: 'cause',
      limit: 5,
    }),

    // This integration attaches global handlers to capture uncaught exceptions and unhandled rejections.
    new Sentry.Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: true,
    }),

    // This integration wraps native APIs to capture breadcrumbs. By default, the Sentry SDK wraps all APIs.
    new Sentry.Integrations.Breadcrumbs({
      console: true ? environment.environment === 'local*' : false,
      dom: true ? environment.environment === 'local*' : false,
      sentry: true,
      xhr: true,
    }),

    /*
    This integration allows you to apply a transformation to each frame of the stack trace.
    In the streamlined scenario, it can be used to change the name of the file frame it originates from,
    or it can be fed with an iterated function to apply any arbitrary transformation.
    */

    // new RewriteFrames(),

    // This integration extracts all non-native attributes from the Error object and attaches them to the event as the extra data.
    // limit of how deep the object serializer should go. Anything deeper than limit will
    // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
    // a primitive value. Defaults to 3.
    new ExtraErrorData({ depth: 3 }),

    new Debug({
      // trigger DevTools debugger instead of using console.log
      debugger: false,

      // stringify event before passing it to console.log
      // stringify: true
    }),

    /*
    This integration captures all Console API calls and redirects them to Sentry
    using the captureMessage call. It then retriggers to preserve default native behavior.
    */
    new CaptureConsole({
      // array of methods that should be captured
      levels: ['info', 'warn', 'error', 'debug', 'assert'],
    }),
  ],
  /*
  Set tracesSampleRate to 1.0 to capture 100%
  of transactions for performance monitoring.
  We recommend adjusting this value in production
  */
  tracesSampleRate: 1.0,

  /*
  A callback invoked during event submission, allowing to optionally modify
  the event before it is sent to Sentry.

  Note that you must return a valid event from this callback. If you do not
  wish to modify the event, simply return it at the end.
  Returning null will case the event to be dropped.

  @param event The error or message event generated by the SDK.
  @param hint May contain additional information about the original exception.
  @returns A new event that will be sent | null.

  Because beforeSend and beforeBreadcrumb are async, user can fetch some data
  return a promise, or whatever he wants
  */
  beforeSend(event, hint) {
    const error: any = hint.originalException
    if (error && error.message && error.message.match(/Testing/i)) {
      event.fingerprint = ['Testing']
    }
    return event
  },

  /* Method called for every captured breadcrumb

  level / input
  For breadcrumbs created from console log interceptions.
  This holds the original console log level and the original input data to the log function.

  response / input
  For breadcrumbs created from HTTP requests.
  This holds the response object (from the fetch API) and the input parameters to the fetch function.

  request / response / event
  For breadcrumbs created from HTTP requests.
  This holds the request and response object (from the node HTTP API) as well as the node event (response or error).

  xhr
  For breadcrumbs created from HTTP requests done via the legacy XMLHttpRequest API. This holds the original xhr object.
  */
  beforeBreadcrumb(breadcrumb, hint) {
    // We ignore our own logger
    if (breadcrumb.message.startsWith('Sentry Logger')) {
      return null
    }
    if (breadcrumb.category !== 'ui.click') {
      return null
    }

    // If we have a `ui.click` type of breadcrumb, eg. clicking on a button we defined in index.html
    // We will extract a `data-label` attribute from it and use it as a part of the message
    if (breadcrumb.category === 'ui.click') {
      const data = hint.event.target.dataset.sectionvalue

      if (data) {
        breadcrumb.message = `User clicked on a button with "${data}"`
      }
    }

    return breadcrumb
  },
})

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    DocCheckoutModule,
    DirectModule,
    AuthModule,
    OktaAuthModule,
    HomeModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-MW9SD9J',
    }),
  ],
  providers: [
    OktaAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: OKTA_CONFIG, useValue: oktaConfig },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      // @sentry/angular exports a function to instantiate an ErrorHandler provider that
      // will automatically send JavaScript errors captured by the Angular's error handler.
      // https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts
      useValue: Sentry.createErrorHandler({
        showDialog: true,
        logErrors: true,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
