<div class="margin-auto rectangle expiring">

  <div class="session-expiring margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto">Do you need more time?</p>
  </div>

  <div class="session-info margin-auto text-align-center">
    <div class="content-group"></div>Your session is about to expire. For your security, you will be signed out in
    <strong *ngIf="timeLeftSec">{{timeoutMin}} minute {{timeoutSec}} seconds.</strong>
  </div>


  <div class="btn-container text-align-center">
    <button (click)="continueSession()" #continue class="btn-background continue">CONTINUE SESSION</button>
  </div>

  <div class="ok margin-auto" id="signout-out" (click)="logout()">Sign Out Now</div>
</div>