import {
  Component,
  Renderer2,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription, timer } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

import { BannersStatesService } from '../../core/services/banners/banners-states.service'
import { LoanService } from '../../core/services/loan/loan.service'
import * as INTF from '../../core/models/interfaces'
import { StepService } from '../../core/services/steps/step.service'
import { CurrentUserService } from '../../core/services/current-user/current-user.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import Utils from '../../shared/utils/utils'
import { InValidateDOB } from '../../shared/validators/DOB.validator'
import { handleError } from '../../core'

enum ShowMarkupStates {
  loading = 0,
  businessVerifyForm = 1,
  signupForm = 2,
}
interface FormValues {
  taxId: string
  ssn: string
  dob: string
}

@Component({
  selector: 'app-business-verification',
  templateUrl: './business-verification.component.html',
  styleUrls: ['./business-verification.component.scss'],
})
export class BusinessVerificationComponent implements OnInit, OnDestroy {
  bizVerificationForm: FormGroup
  bizVerificationFormValueChangSubscription: Subscription
  bizVerificationSubscription: Subscription
  getloanStatusSubscription: Subscription
  timeoutSubscription: Subscription

  showBizVerificationForm: ShowMarkupStates
  userEmail: null | string = null
  data: INTF.BizVeriInputs
  notMatch: boolean
  showWarning: boolean
  errorMessage: string
  newtaxId: string
  id: string
  loanUuid: string
  loanStatus: INTF.DocCheckoutStep
  error: INTF.BackendError | null
  clicked: boolean
  showError = false
  showSsnError = false
  dobMask = '00/00/0000'
  isoDate: string

  @ViewChild('taxId') taxId: ElementRef
  @ViewChild('dob') dob: ElementRef
  @ViewChild('ssn') ssn: ElementRef

  constructor(
    public bannersStatesService: BannersStatesService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private loanService: LoanService,
    private stepService: StepService,
    private currentUserService: CurrentUserService,
    private errorService: ErrorService,
    private renderer: Renderer2,
  ) {
    this.showBizVerificationForm = ShowMarkupStates.loading
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')

    this.getLoanStatus()

    this.initForm()

    this.valueChange()

    const turnOffLoaderTimer = timer(10 * 1000) // 10 seconds should be enough, yeah?
    this.timeoutSubscription = turnOffLoaderTimer.subscribe(() => {
      // if we're still on the loading state, then we should probably admit defeat
      if (this.showBizVerificationForm === ShowMarkupStates.loading) {
        this.router.navigate([`doc-checkout/unknownerror`])
      }
    })
  }

  initForm(): FormGroup {
    this.clicked = false
    this.bizVerificationForm = this.formBuilder.group({
      taxId: [
        '',
        {
          validators: [
            Validators.required,
            Validators.pattern(/^\d{2}-?\d{7}$/),
          ],
          updateOn: 'blur',
        },
      ],
      dob: [
        '',
        {
          validators: [Validators.required, InValidateDOB],
          updateOn: 'blur',
        },
      ],
      ssn: [
        '',
        {
          validators: [Validators.required, Validators.pattern(/^\d{4}$/)],
          updateOn: 'blur',
        },
      ],
    })
    return this.bizVerificationForm
  }

  getLoanStatus() {
    this.getloanStatusSubscription = this.loanService
      .getLoanStatus$(this.loanUuid)
      .subscribe(
        (res) => {
          this.loanStatus = res.updatedStep
          if (
            this.loanStatus ===
            INTF.DocCheckoutStep.businessVerificationAndCreateAcct
          ) {
            this.showBizVerificationForm = ShowMarkupStates.businessVerifyForm
          } else {
            // redirect to actual step
            let route = this.stepService.stepToRoute(
              this.loanStatus,
              this.loanUuid,
            )
            if (!route) {
              route = this.stepService.stepToRoute(
                INTF.DocCheckoutStep.unknownStep,
                this.loanUuid,
              )
            }
            this.router.navigate([route])
          }
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.bizVerificationForm.controls
  }

  valueChange() {
    this.bizVerificationFormValueChangSubscription =
      this.bizVerificationForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(this.formValuesChanged.bind(this))
  }

  formValuesChanged(value: FormValues) {
    if (value) {
      const cleanedSSN = Utils.cleanDigit(value.ssn).substring(0, 4)
      this.renderer.setProperty(this.ssn.nativeElement, 'value', cleanedSSN)

      if (value.taxId) {
        const cleaned = Utils.cleanDigit(value.taxId).substring(0, 11)
        const cleanedtaxID = Utils.formatTaxID(cleaned)
        this.renderer.setProperty(
          this.taxId.nativeElement,
          'value',
          cleanedtaxID,
        )
      }

      if (value.dob) {
        const cleanedDate = Utils.formatDate8Digit(value.dob)
        this.bizVerificationForm.patchValue(
          { dob: cleanedDate },
          { emitEvent: false },
        )
      }
    }
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.onSubmitVerify()
    }
  }

  validateSsn() {
    this.showSsnError = true
  }

  onSubmitVerify() {
    this.showError = true
    if (this.bizVerificationForm.valid && !this.clicked) {
      this.clicked = true
      this.data = this.bizVerificationForm.getRawValue()

      this.bizVerificationSubscription = this.loanService
        .onSubmitBizVerify(this.loanUuid, this.data)
        .subscribe(
          (bizData) => {
            if (bizData) {
              this.showError = false
              this.userEmail = bizData.email
              this.currentUserService.updatedCurrentUser(this.userEmail)
              this.loanStatus = INTF.stepFromString(
                bizData.docCheckoutStatus as INTF.stepStrings,
              )
              if (bizData.magicLinkLogin) {
                window.location.replace('/ui/dashboard/magic-link-login')
              }
              if (bizData.userExists) {
                this.bannersStatesService.storeBannerState(
                  this.bannersStatesService.EXISTING_ACCOUNT,
                )
                this.router.navigate([`/auth/login`])
              } else {
                this.showBizVerificationForm = ShowMarkupStates.signupForm
              }
            }
          },
          (err) => {
            console.log(err)

            if (err.error.status === 0 || err.error.type === 'error') {
              this.errorService.storeError(
                this.errorService.SERVER_ERROR as INTF.ErrType,
              )
              this.router.navigate([`doc-checkout/unknownerror`])
            } else if (
              err?.error?.error?.errorCode ===
              INTF.ErrorCode.BUSINESS_VERIFICATION_MAX_ATTEMPTS_REACHED
            ) {
              this.errorService.storeError(err.error.error)
              // reach max failed verification attempts, redirect to unableToVerifyBusiness page
              this.router.navigate([
                `doc-checkout/loan/${this.loanUuid}/unable-to-verify-business`,
              ])
            } else if (
              err?.error?.error?.errorCode ===
              INTF.ErrorCode.BUSINESS_VERIFICATION_INVALID_DATA // 2 more attempts
            ) {
              this.showWarning = true
              this.errorMessage =
                'The information entered does not match our systems. You have two more attempts.'
            } else if (
              err?.error?.error?.errorCode ===
              INTF.ErrorCode.BUSINESS_VERIFICATION_ONE_ATTEMPT_LEFT // 1 more attempt
            ) {
              this.showWarning = true
              this.errorMessage =
                'The information entered does not match our systems. You have one more attempt.'
            } else {
              this.router.navigate([
                `doc-checkout/loan/testId/unable-to-verify-business`,
              ])
            }

            this.bizVerificationForm.setErrors({ invalid: true }) // reset form state
            this.bizVerificationForm.reset()
            this.clicked = false
          },
        )
    }
  }

  close() {
    this.showWarning = false
  }

  ngOnDestroy() {
    if (this.bizVerificationSubscription) {
      this.bizVerificationSubscription.unsubscribe()
      this.bizVerificationSubscription = null
    }

    if (this.bizVerificationFormValueChangSubscription) {
      this.bizVerificationFormValueChangSubscription.unsubscribe()
      this.bizVerificationFormValueChangSubscription = null
    }

    if (this.getloanStatusSubscription) {
      this.getloanStatusSubscription.unsubscribe()
      this.getloanStatusSubscription = null
    }

    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe()
      this.timeoutSubscription = null
    }
  }
}
