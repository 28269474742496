import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
@Component({
  selector: 'app-owner-credit-auth',
  templateUrl: './owner-credit-auth.component.html',
  styleUrls: ['./owner-credit-auth.component.scss'],
})
export class DirectAppOwnerCreditAuthComponent implements OnInit {
  constructor(private router: Router) {}
  ngOnInit(): void {
    setTimeout(() => {
      const urlParts = this.router.url.split('/')
      const token = urlParts[urlParts.length - 1]
      window.location.replace(`/ui/direct-app/owner-credit-auth/${token}`)
    }, 0)
  }
}
