import { checkIsTimePassed } from './check-is-time-passed'

export const checkIsTokenExpired: (token: string) => boolean = (token) => {
  const parts = token.split('.')
  if (parts.length === 3) {
    try {
      const body = JSON.parse(window.atob(parts[1]))
      if (!checkIsTimePassed(body['exp'])) {
        return false
      }
    } catch (error) {
      console.warn(
        'Error checking if token is expired:',
        error,
        '\n- expiring the token - i.e. returing true',
      )
    }
  }
  // Invalid token - return true
  return true
}
