import { throwError } from 'rxjs'
import { DirectAppStep } from '../enums'
import { DirectAppStepString } from '../models/interfaces'

export const getDirectAppStepFromString = (
  step: DirectAppStepString | string,
): DirectAppStep => {
  switch (step) {
    case 'additionalInfo':
    case 'additional-info':
    case 'ADDITIONAL_INFO':
      return DirectAppStep.ADDITIONAL_INFO
    case 'app-submitted':
    case 'APP_SUBMITTED':
      return DirectAppStep.APP_SUBMITTED
    case 'business-address-info':
    case 'businessAddressInfo':
    case 'BUSINESS_ADDRESS_INFO':
      return DirectAppStep.BUSINESS_ADDRESS_INFO
    case 'business-basic-info':
    case 'businessBasicInfo':
    case 'BUSINESS_BASIC_INFO':
      return DirectAppStep.BUSINESS_BASIC_INFO
    case 'business-contact-info':
    case 'businessContactInfo':
    case 'BUSINESS_CONTACT_INFO':
      return DirectAppStep.BUSINESS_CONTACT_INFO
    case 'create-account':
    case 'createAcct':
    case 'CREATE_ACCOUNT':
      return DirectAppStep.CREATE_ACCOUNT
    case 'create-from-lead':
      return DirectAppStep.CREATE_FROM_LEAD
    case 'credit-auth-success':
      return DirectAppStep.CREDIT_AUTH_SUCCESS
    case 'disabled':
    case 'DISABLED':
      return DirectAppStep.DISABLED
    case 'document':
    case 'DOCUMENT':
      return DirectAppStep.DOCUMENT
    case 'owner-info':
    case 'ownerInfo':
    case 'OWNER_INFO':
      return DirectAppStep.OWNER_INFO
    case 'owner-credit-auth':
      return DirectAppStep.OWNER_CREDIT_AUTH
    case 'review-submit':
    case 'reviewSubmit':
    case 'REVIEW_SUBMIT':
      return DirectAppStep.REVIEW_SUBMIT
    case 'unknown':
    case 'UNKNOWN':
      return DirectAppStep.UNKNOWN
    default:
      console.warn(`unknown direct-app-step from string: ${step}`)
      break
  }
  if (!DirectAppStep[step]) {
    throwError('Unknown direct app step ' + step)
  }
  return DirectAppStep[step]
}
