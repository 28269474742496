<div
  [ngClass]="{
    'header-logo-container tablet': !isNuulaUser,
    'nuula-header-logo-container nuula-tablet': isNuulaUser
  }"
>
  <div class="tablet-header-logo-container">
    <img
      *ngIf="!isNuulaUser"
      class="header-logo"
      src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
    />
    <img
      *ngIf="isNuulaUser"
      class="nuula-header-logo cursor-pointer"
      src="assets/imgs/mf-main-logo.svg"
    />
    <div *ngIf="isNuulaUser" class="nuula-line"></div>
  </div>
</div>

<div class="auth-page-container">
  <div class="info-page-wrap">
    <div></div>
    <div>
      <!-- always show company logo when desktop width -->
      <div class="logo-container desktop">
        <img
          class="header-logo cursor-pointer"
          src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
        />
      </div>

      <!-- genError:::{{ genError | json }} -->
      <div class="rectangle margin-auto">
        <div class="{{ error?.img }} added-icon"></div>
        <div class="text-align-center">
          <p class="pre-title margin-auto" [innerHTML]="error?.title"></p>
        </div>

        <div class="err-rectangle">
          <div
            class="review margin-auto text-align-center"
            [innerHTML]="error?.message"
          ></div>
        </div>

        <div
          class="text-align-center margin-auto"
          *ngIf="error?.btn && !isNuulaUser"
        >
          <div *ngIf="showgoHome" class="btn-container">
            <button (click)="goHome()" class="btn-background">
              {{ error?.btn }}
            </button>
          </div>

          <div *ngIf="showgSignIn" class="btn-container">
            <button (click)="goSignIn()" class="btn-background">
              {{ error?.btn }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
