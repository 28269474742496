import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

import { DashboardHeaderComponent } from './dashboard/dashboard-header/dashboard-header.component'
import { DashboardAccountDetailComponent } from './dashboard/dashboard-account/dashboard-account.component'
import { DashboardLoanComponent } from './dashboard/dashboard-loan/dashboard-loan.component'
import { SuccessContactComponent } from './success-contact/success-contact.component'
import { SharedModule } from '../shared/shared.module'
import { DashboardAllAccountsComponent } from './dashboard/dashboard-all-accounts/dashboard-all-accounts.component'
@NgModule({
  declarations: [
    DashboardAllAccountsComponent,
    DashboardHeaderComponent,
    DashboardAccountDetailComponent,
    DashboardLoanComponent,
    SuccessContactComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [
    SuccessContactComponent,
    DashboardLoanComponent,
    DashboardAllAccountsComponent,
    DashboardHeaderComponent,
    DashboardAccountDetailComponent,
  ],
})
export class HomeModule {}
