import { Component, OnDestroy, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/core/services/auth/auth.service'

import { ErrorService } from '../../core/services/generic-error/error.service'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  isNuulaUserSubscription: Subscription
  isNuulaUser: boolean
  constructor(
    private errorService: ErrorService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.isNuulaUserSubscription =
      this.authService.currentIsNuulaUser.subscribe((val) => {
        this.isNuulaUser = val
      })

    window.scrollTo(0, 0)

    this.errorService.storeError(this.errorService.NOT_FOUND)
  }

  ngOnDestroy() {
    if (this.isNuulaUserSubscription) {
      this.isNuulaUserSubscription.unsubscribe()
    }
  }
}
