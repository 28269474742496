<!-- inside of right-box class -->
<div class="step-content">
  <div class="direct-app-page-title">
    About {{ applicationInfo?.accountInfo?.companyName }}
  </div>
  <div class="direct-app-page-info">
    Please provide some information about your business to find the best
    financial solution.
  </div>

  <div class="direct-app-page-sub-title">Basic Information</div>
  <div class="direct-app-page-info info-mobile">
    Please provide some information about your business to find the best
    financial solution.
  </div>

  <div class="infoForm">
    <form
      *ngIf="basicInfoForm"
      [formGroup]="basicInfoForm"
      (keydown)="onSubmit($event)"
    >
      <div class="direct-app-form">
        <div class="input-text em">
          <div class="label-text">Business Legal Name (Must Be Exact)</div>
          <div class="content-input">
            <input
              autocomplete="organization"
              formControlName="businessLegalName"
              #businessLegalName
              class="input-text container"
              type="text"
            />
          </div>
          <div
            *ngIf="
              showError ||
              f.businessLegalName.dirty ||
              f.businessLegalName.touched
            "
          >
            <div
              *ngIf="f.businessLegalName.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text">
                Business legal name is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Doing Business As (Optional)</div>
          <div class="content-input">
            <input
              formControlName="doingBusinessAs"
              #doingBusinessAs
              class="input-text container"
              type="text"
            />
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Corporate Structure</div>
          <div class="content-input">
            <select
              formControlName="corporateStructure"
              #corporateStructure
              id="corporateStructure"
              class="input-text container"
              type="text"
            >
              <option hidden [ngValue]="null" selected>Select an Option</option>
              <option
                value="{{ corporateStructure }}"
                *ngFor="let corporateStructure of corporateStructures"
              >
                {{ corporateStructure }}
              </option>
            </select>
          </div>
          <div
            *ngIf="
              showError ||
              f.corporateStructure.dirty ||
              f.corporateStructure.touched
            "
          >
            <div
              *ngIf="f.corporateStructure.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Corporate structure is required.
              </span>
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text" #taxIDSSN>Business Tax ID</div>
          <input
            inputmode="numeric"
            placeholder="{{ getTaxIdPlaceholder() }}"
            formControlName="taxID"
            value="{{ newtaxID }}"
            maxlength="{{ getTaxIdMaxLength() }}"
            #taxID
            id="taxID"
            type="text"
            class="container input-text"
            (keydown)="handleNumberKeydown($event)"
            (input)="handleSSNInput($event)"
          />
          <button
            (click)="toggleNewPassword()"
            id="toggleNewPassword"
            class="password"
          ></button>
          <div class="taxID-note">For a Sole Proprietorship, provide SSN.</div>

          <div
            *ngIf="
              showError ||
              (f.taxID.invalid && (f.taxID.dirty || f.taxID.touched))
            "
            class="alert alert-danger"
          >
            <span class="alert alert-danger" *ngIf="f.taxID.errors?.required">
              Business tax ID is required.
            </span>
            <span class="alert alert-danger" *ngIf="f.taxID.errors?.pattern">
              Enter a valid business tax ID.
            </span>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">State of Incorporation</div>
          <div class="content-input">
            <select
              formControlName="stateOfIncorporation"
              #stateOfIncorporation
              id="stateOfIncorporation"
              class="input-text container"
              type="text"
            >
              <option hidden [ngValue]="null" selected>Select a State</option>
              <option value="{{ state }}" *ngFor="let state of states">
                {{ state }}
              </option>
            </select>
          </div>

          <div
            *ngIf="
              showError ||
              f.stateOfIncorporation.dirty ||
              f.stateOfIncorporation.touched
            "
            class="alert alert-danger err-text"
          >
            <div
              *ngIf="f.stateOfIncorporation.errors?.required"
              class="red-text"
            >
              <span class="alert alert-danger help-text">
                State of incorporation is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Business Start Date</div>
          <div class="content-input">
            <input
              inputmode="numeric"
              value="{{ newBusinessStartDate }}"
              placeholder="{{ getDateFormatPlaceholder() }}"
              formControlName="businessStartDate"
              #businessStartDate
              class="input-text container"
              type="text"
              (keydown)="handleNumberKeydown($event)"
              (input)="handleDateInput($event)"
            />
          </div>
          <div
            *ngIf="
              showError ||
              f.businessStartDate.dirty ||
              f.businessStartDate.touched
            "
            class="alert alert-danger err-text"
          >
            <div *ngIf="f.businessStartDate.errors?.required" class="red-text">
              <span class="alert alert-danger help-text">
                Business start date is required.
              </span>
            </div>
            <div
              *ngIf="
                f.businessStartDate.errors?.pattern ||
                f.businessStartDate.errors?.InValidateStartDate
              "
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                ><span>Enter a valid business start date.</span></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container start" *ngIf="!disableEdit">
        <button (click)="submitBasicInfo()" #submit class="btn-background">
          CONTINUE
        </button>
      </div>
    </form>
  </div>
</div>
