import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'
import { DirectAppReviewSubmitService } from 'src/app/core/services/direct-app-review-submit/direct-app-review-submit.service'
import {
  DirectAppStep,
  getDirectAppUrlFromStep,
  handleError,
  isValidDateKeydown,
  SSN_OR_TAX_ID_REGEX,
  SupportedDateFormat,
  SupportedTaxIdFormat,
  US_STATES,
  validateDateString,
  validateSSNString,
} from '../../core'
import * as INTF from '../../core/models/interfaces'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { InValidateStartDate } from '../../shared/validators/startDate.validator'

@Component({
  selector: 'app-business-basic-info',
  templateUrl: './business-basic-info.component.html',
  styleUrls: ['./business-basic-info.component.scss'],
})
export class BusinessBasicInfoComponent implements OnInit, OnDestroy {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    const newAppInfo = value && !this.applicationInfo
    this.applicationInfo = value
    if (newAppInfo) {
      this.formInit()
      this.valueChange()
    }
  }
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
  }
  disableEdit = false

  newBusinessStartDate: string

  @ViewChild('taxID') taxID: ElementRef

  backspace = false
  basicInfoForm: FormGroup
  basicInfoFormValueChangSubscription: Subscription
  comingFromReviewSubmit = false
  comingFromReviewSubmitSubscription: Subscription
  startAppSubscription: Subscription
  clicked: boolean
  basicInfo: INTF.BasicInfo
  directAppStatus: DirectAppStep
  showError = false
  newtaxID: string
  // tslint:disable-next-line: no-string-literal
  corporateStructure: SupportedTaxIdFormat =
    SupportedTaxIdFormat.SOLE_PROPRIETOR
  states = Object.keys(US_STATES)
  corporateStructures = [
    'Sole Proprietorship',
    'Partnership',
    'Corporation',
    'S Corporation',
    'Limited Liability Company (LLC)',
  ]

  @ViewChild('taxIDSSN') taxIDSSN: ElementRef
  @ViewChild('businessStartDate') businessStartDate: ElementRef

  constructor(
    private directAppService: DirectAppService,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/basic-info`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })
    this.comingFromReviewSubmitSubscription =
      this.directAppReviewSubmitService.currentComingFromReviewSubmit.subscribe(
        (val) => {
          this.comingFromReviewSubmit = val
        },
      )
  }

  getDateFormatPlaceholder() {
    return SupportedDateFormat.MM_YYYY
  }

  getTaxIdPlaceholder() {
    switch (this.corporateStructure) {
      case SupportedTaxIdFormat.CORPORATION:
      case SupportedTaxIdFormat.LIMITED_LIABILITY_CORPORATION:
      case SupportedTaxIdFormat.PARTNERSHIP:
      case SupportedTaxIdFormat.S_CORPORATION:
        return '## - #######'
      case SupportedTaxIdFormat.SOLE_PROPRIETOR:
      default:
        return '### - ## - ####'
    }
  }

  getTaxIdMaxLength() {
    switch (this.corporateStructure) {
      case SupportedTaxIdFormat.CORPORATION:
      case SupportedTaxIdFormat.LIMITED_LIABILITY_CORPORATION:
      case SupportedTaxIdFormat.PARTNERSHIP:
      case SupportedTaxIdFormat.S_CORPORATION:
        return 10
      case SupportedTaxIdFormat.SOLE_PROPRIETOR:
      default:
        return 11
    }
  }
  handleDateInput(e: InputEvent) {
    const target = e.target as HTMLInputElement
    const value = target.value ? target.value.toString() : ''

    const newValue = validateDateString(
      value,
      SupportedDateFormat.MM_YYYY,
      this.backspace,
    )
    this.newBusinessStartDate = newValue
    if (newValue !== value) {
      target.value = newValue
    }
  }

  handleSSNInput(e: InputEvent) {
    const target = e.target as HTMLInputElement
    const value = target.value ? target.value.toString() : ''

    const newValue = validateSSNString(
      value,
      this.corporateStructure,
      this.backspace,
    )
    this.newtaxID = newValue

    if (newValue !== value) {
      target.value = newValue
    }
  }

  handleNumberKeydown(e: KeyboardEvent) {
    if (!isValidDateKeydown(e)) {
      e.preventDefault()
      return
    }
    if (e.key === 'Backspace') {
      this.backspace = true
    } else {
      this.backspace = false
    }
  }
  formInit() {
    this.basicInfoForm = this.formBuilder.group({
      businessLegalName: [
        this.applicationInfo?.basicInfo?.businessLegalName,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      doingBusinessAs: [this.applicationInfo?.basicInfo?.doingBusinessAs],
      corporateStructure: [
        this.applicationInfo?.basicInfo?.corporateStructure,
        [Validators.required],
      ],
      taxID: [
        this.applicationInfo?.basicInfo?.taxID,
        {
          validators: [
            Validators.required,
            Validators.pattern(SSN_OR_TAX_ID_REGEX),
          ],
          updateOn: 'blur',
        },
      ],
      stateOfIncorporation: [
        this.applicationInfo?.basicInfo?.stateOfIncorporation,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      businessStartDate: [
        this.applicationInfo?.basicInfo?.businessStartDate,
        {
          validators: [Validators.required, InValidateStartDate],
          updateOn: 'blur',
        },
      ],
    })
    this.clicked = false
  }

  valueChange() {
    this.basicInfoFormValueChangSubscription = this.basicInfoForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(this.formValuesChanged.bind(this))
  }

  toggleNewPassword() {
    this.taxID.nativeElement.type =
      this.taxID.nativeElement.type === 'text' ? 'password' : 'text'
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.basicInfoForm.controls
  }

  formValuesChanged(value) {
    if (value.businessStartDate && this.newBusinessStartDate) {
      this.basicInfoForm.patchValue(
        { businessStartDate: this.newBusinessStartDate },
        { emitEvent: false },
      )
    }

    if (value.corporateStructure) {
      switch (value.corporateStructure) {
        case 'Corporation':
          this.corporateStructure = SupportedTaxIdFormat.CORPORATION
          break
        case 'Limited Liability Company (LLC)':
          this.corporateStructure =
            SupportedTaxIdFormat.LIMITED_LIABILITY_CORPORATION
          break
        case 'Partnership':
          this.corporateStructure = SupportedTaxIdFormat.PARTNERSHIP
          break
        case 'S Corporation':
          this.corporateStructure = SupportedTaxIdFormat.S_CORPORATION
          break
        default:
        case 'Sole Proprietorship':
          this.corporateStructure = SupportedTaxIdFormat.SOLE_PROPRIETOR
          break
      }
      if (value.taxID) {
        this.newtaxID = validateSSNString(
          value.taxID.toString(),
          this.corporateStructure,
          this.backspace,
        )
        if (this.newtaxID !== value.taxID) {
          this.basicInfoForm.patchValue(
            { taxID: this.newtaxID },
            { emitEvent: false },
          )
          this.basicInfoForm.controls.taxID.updateValueAndValidity()
        }
      }
    }
    if (value.taxID) {
      this.newtaxID = validateSSNString(
        value.taxID.toString(),
        this.corporateStructure,
        this.backspace,
      )
      if (this.newtaxID !== value.taxID) {
        this.basicInfoForm.patchValue(
          { taxID: this.newtaxID },
          { emitEvent: false },
        )
        this.basicInfoForm.controls.taxID.updateValueAndValidity()
      }
    }
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.submitBasicInfo()
    }
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  submitBasicInfo() {
    this.showError = true

    this.basicInfo = {
      ...this.basicInfoForm.value,
    }
    if (!this.basicInfo.doingBusinessAs) {
      this.basicInfo.doingBusinessAs = ''
    }
    if (this.basicInfoForm.valid) {
      const nextStep = this.comingFromReviewSubmit
        ? DirectAppStep.REVIEW_SUBMIT
        : DirectAppStep.BUSINESS_CONTACT_INFO

      const data = {
        packetId: this.packetId,
        basicInfo: this.basicInfo,
        nextStep,
      }

      this.directAppReviewSubmitService.setComingFromReviewSubmit(false)

      this.startAppSubscription = this.directAppService
        .submitBasicInfo$(data)
        .subscribe(
          (res) => {
            this.clicked = false
            if (nextStep === DirectAppStep.REVIEW_SUBMIT) {
              window.location.replace(`/ui/direct-app/review-submit`)
            } else {
              window.location.replace(`/ui/direct-app/contact-info`)
            }
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    }
  }

  ngOnDestroy() {
    if (this.basicInfoFormValueChangSubscription) {
      this.basicInfoFormValueChangSubscription.unsubscribe()
    }

    if (this.comingFromReviewSubmitSubscription) {
      this.comingFromReviewSubmitSubscription.unsubscribe()
    }

    if (this.startAppSubscription) {
      this.startAppSubscription.unsubscribe()
    }
  }
}
