import { Component, OnInit, OnDestroy } from '@angular/core'

import * as INTF from '../../core/models/interfaces'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-owner-credit-auth-sidebar',
  templateUrl: './owner-credit-auth-sidebar.component.html',
  styleUrls: ['./owner-credit-auth-sidebar.component.scss'],
})
export class DirectAppOwnerCreditAuthSidebarComponent
  implements OnInit, OnDestroy
{
  tokenVerified = false
  ownerInfo: undefined | INTF.OwnerDetail = undefined
  companyName = ''
  creditAuthorized = false
  directAppOwnerDetailSubscription: Subscription
  constructor(private directAppService: DirectAppService) {}

  ngOnInit(): void {
    // The verify token response will set the creditResponse - we need to subscribe to it
    this.directAppOwnerDetailSubscription =
      this.directAppService.currentDirectAppOwnerCreditAuth.subscribe(
        async (creditResponse) => {
          if (Boolean(creditResponse)) {
            if (creditResponse.status === 'success') {
              this.tokenVerified = true
              this.companyName = creditResponse.companyName
              this.ownerInfo = creditResponse.owner
              this.creditAuthorized = creditResponse.creditAuthorized
            }
          }
        },
      )
  }

  getGreeting() {
    return `Hi ${this.ownerInfo ? this.ownerInfo.ownerFirstName : ''},`
  }

  getAuthStatement() {
    const companyString = this.companyName ? this.companyName : ''
    return this.creditAuthorized
      ? `Authorization received for ${companyString}`
      : `Authorize your application for ${companyString}.`
  }
  ngOnDestroy() {
    if (this.directAppOwnerDetailSubscription) {
      this.directAppOwnerDetailSubscription.unsubscribe()
    }
  }
}
