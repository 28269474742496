<div class="content-container">
  <div class="auth-page-container">
    <div class="auth-page-wrap">
      <app-logo></app-logo>

      <form [formGroup]="resetForm" (keydown)="onSubmit($event)">
        <div class="password-rectangle">
          <div class="auth-title text-align-center">Reset Your Password</div>
          <div class="auth-info text-align-center margin-auto">
            Enter the new password you would like to use for your account.
          </div>

          <div class="creds-wrap">
            <div class="err-info-container expired" *ngIf="showWarning">
              <div class="expired-icon"></div>
              <div class="warning-info fr">
                {{ errorMessage }} 
                <div *ngIf="backToResetLink">
                  <a href="/auth/forgot-password">Generate a new reset email</a>
                </div>
              </div>
              <button (click)="close()" class="close"></button>
            </div>
            <div class="input-text">
              <div class="label-text">New Password</div>
              <div class="new-password">
                <input
                  (focus)="focusPassword()"
                  (blur)="blurPassword()"
                  formControlName="newPassword"
                  autocomplete="off"
                  type="password"
                  id="newPassword"
                  #newPassword
                  class="input-text container"
                />
                <button
                  (click)="toggleNewPassword()"
                  id="toggleNewPassword"
                  class="password"
                ></button>
              </div>

              <span *ngIf="f.newPassword.valid">
                <div class="green-text">
                  <img class="check-symbol" />
                  <span class="green help-text">Password strength: Good</span>
                </div>
              </span>

              <div
                *ngIf="showError || blurPasswordError || focusPasswordErr"
                class="alert alert-danger err-text"
              >
                <div
                  *ngIf="f.newPassword.invalid"
                  id="invalid-err"
                  class="alert alert-danger err-text"
                >
                  <!-- if error -->
                  <span class="alert alert-danger help-text">
                    <div
                      class="red-text"
                      *ngIf="
                        f.newPassword.invalid &&
                        f.newPassword.value &&
                        blurPasswordError
                      "
                    >
                      Password requirements have not been met.
                    </div>

                    <div
                      *ngIf="f.newPassword.errors"
                      [ngClass]="{
                        'red-text': blurPasswordError || showError,
                        'grey-text': focusPasswordErr
                      }"
                    >
                      <img class="symbol" />
                      <span id="weak">Password strength: Weak</span>
                    </div>

                    <div
                      [ngClass]="{
                        'green-text':
                          !f.newPassword.errors?.minlength &&
                          f.newPassword.value !== '',
                        'red-text': blurPasswordError || showError,
                        'grey-text':
                          focusPasswordErr &&
                          !(
                            !f.newPassword.errors?.minlength &&
                            f.newPassword.value !== ''
                          )
                      }"
                    >
                      <img class="symbol" />
                      <span id="toofewchar">Use at least 8 characters</span>
                    </div>

                    <div
                      [ngClass]="{
                        'green-text':
                          !f.newPassword.errors?.haveUpperLowerCase &&
                          f.newPassword.value !== '',
                        'red-text': blurPasswordError || showError,
                        'grey-text':
                          focusPasswordErr &&
                          !(
                            !f.newPassword.errors?.haveUpperLowerCase &&
                            f.newPassword.value !== ''
                          )
                      }"
                    >
                      <img class="symbol" />
                      <span id="upperlower"
                        >Use upper and lower case letters</span
                      >
                    </div>

                    <div
                      [ngClass]="{
                        'green-text':
                          !f.newPassword.errors?.hasNum &&
                          f.newPassword.value !== '',
                        'red-text': blurPasswordError || showError,
                        'grey-text':
                          focusPasswordErr &&
                          !(
                            !f.newPassword.errors?.hasNum &&
                            f.newPassword.value !== ''
                          )
                      }"
                    >
                      <img class="symbol" />
                      <span id="numbersymbol">Use a number</span>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div class="input-text confirmPassword">
              <div class="label-text">Confirm New Password</div>
              <div class="new-password">
                <input
                  formControlName="confirmPassword"
                  autocomplete="off"
                  type="password"
                  #confirmPassword
                  class="input-text container"
                  id="confirmPassword"
                />
                <button
                  (click)="toggleConfirmPassword()"
                  id="toggleConfirmPassword"
                  class="password"
                ></button>
              </div>

              <div *ngIf="f.confirmPassword.dirty || f.confirmPassword.touched">
                <div *ngIf="f.confirmPassword.valid">
                  <div class="green-text">
                    <img class="symbol" />
                    <span class="help-text">Password strength: Good</span>
                  </div>
                </div>
              </div>

              <div
                *ngIf="
                  showError ||
                  f.confirmPassword.dirty ||
                  f.confirmPassword.touched
                "
                class="alert alert-danger err-text"
              >
                <div
                  *ngIf="f.confirmPassword.errors?.required"
                  [ngClass]="
                    f.confirmPassword.invalid ? 'red-text' : 'green-text'
                  "
                  class="alert alert-danger err-text"
                >
                  <img class="symbol" />
                  <span>Confirm Password is required.</span>
                </div>

                <div
                  *ngIf="!match() && f.confirmPassword.value.length !== 0"
                  [ngClass]="match() ? 'green-text' : 'red-text'"
                  class="alert alert-danger err-text"
                >
                  <img class="symbol" />
                  <span>Passwords do not match.</span>
                </div>
              </div>

              <div class="btn-container">
                <button
                  (click)="clicked || resetPassword()"
                  #submit
                  type="submit"
                  id="submit-reset"
                  class="btn-background"
                >
                  RESET PASSWORD
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <app-footer></app-footer>
</div>
