import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'

import { ErrorService } from '../../../core/services/generic-error/error.service'

import { BannersStatesService } from '../../../core/services/banners/banners-states.service'
import { handleError } from '../../../core'
import { LoanService } from '../../../core/services/loan/loan.service'
import { AuthService } from 'src/app/core/services/auth/auth.service'

@Component({
  selector: 'app-verification-link-expired',
  templateUrl: './verification-link-expired.component.html',
  styleUrls: ['./verification-link-expired.component.scss'],
})
export class VerificationLinkExpiredComponent implements OnInit, OnDestroy {
  resent: boolean
  showInfo: boolean
  isNuulaUser: boolean
  isNuulaUserSubscription: Subscription
  resendEmailSubscription: Subscription

  loanUuid: string
  @Input() email: string

  constructor(
    private loanService: LoanService,
    private bannersStatesService: BannersStatesService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.resent = false

    this.isNuulaUserSubscription =
      this.authService.currentIsNuulaUser.subscribe((val) => {
        this.isNuulaUser = val
      })
  }

  close() {
    this.resent = false
  }

  resendEmail() {
    const data = { email: this.email }

    this.resendEmailSubscription = this.loanService.resendEmail(data).subscribe(
      () => {
        this.bannersStatesService.storeBannerState(
          this.bannersStatesService.EMAIL_RESENT,
        )
        this.bannersStatesService.updatedBannerState(
          this.bannersStatesService.EMAIL_RESENT,
        )
        this.resent = true
      },
      (err) => {
        handleError(err, this.errorService, this.router)
      },
    )
  }

  ngOnDestroy() {
    if (this.isNuulaUserSubscription) {
      this.isNuulaUserSubscription.unsubscribe()
    }
  }
}
