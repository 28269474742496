import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../../../core/services/auth/auth.service'

import { Subscription } from 'rxjs'

import {
  BYPASS_ONE_TIME_USE_TOKEN,
  GetPacketResponse,
  handleError,
  MFAccessToken,
} from '../../../core'
import { ErrorService } from '../../../core/services/generic-error/error.service'
import { DirectAppService } from 'src/app/core/services/direct/direct-app.service'
import Utils from '../../../shared/utils/utils'
import { ApplicationInfo } from '../../../core/models/interfaces'

@Component({
  selector: 'app-nuula-app-details',
  templateUrl: './nuula-app-details.component.html',
  styleUrls: ['./nuula-app-details.component.scss'],
})
export class NuulaAppDetailsComponent implements OnDestroy, OnInit {
  accessToken: null | MFAccessToken
  navigating = false
  openSections = false
  tokenError = false
  tokenVerified = false
  NuulalineNo = '866-712-6189'
  directAppPacketData: ApplicationInfo
  directAppStage: string

  currentAccessTokenSubscription: Subscription
  currentTokenErrorSubscription: Subscription
  currentTokenVerifiedSubscription: Subscription

  constructor(
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
    private directAppService: DirectAppService,
  ) {}
  tileIcon = null
  tileTitle = null
  tileBody = null
  tileButton = null
  getDirectAppStatusSubscription: Subscription

  ngOnInit(): void {
    const urlParts = this.router.url.split('/')
    const token = urlParts[urlParts.length - 1]
    if (token !== BYPASS_ONE_TIME_USE_TOKEN) {
      this.authService.exchangeOneTimeUseTokenFromUrl(token)
    } else {
      this.currentAccessTokenSubscription =
        this.authService.currentMfAccessToken.subscribe((val) => {
          if (val) {
            this.accessToken = val
            this.getDirectAppPacket()
          }
        })
    }

    this.currentTokenVerifiedSubscription =
      this.authService.currentTokenVerified.subscribe((val) => {
        this.tokenVerified = val
        if (val) {
          this.getDirectAppPacket()
        }
      })
    this.currentTokenErrorSubscription =
      this.authService.currentTokenError.subscribe((val) => {
        this.tokenError = val
      })
  }

  isReapplyVisible() {
    return this.directAppStage === 'Declined / Expired'
  }
  isSpinnerVisible() {
    return !this.tokenVerified && !this.tokenError && !this.accessToken
  }
  formatMonthlyAverageSales() {
    return Utils.formatNumberToCurrency(
      this.directAppPacketData?.accountInfo?.monthlyAverageSales,
    )
  }
  formatDesiredLoanAmount() {
    return Utils.formatNumberToCurrency(
      this.directAppPacketData?.accountInfo?.desiredLoanAmount,
    )
  }
  getDirectAppPacket() {
    this.getDirectAppStatusSubscription = this.directAppService
      .getDirectAppPacket$()
      .subscribe(
        (res) => {
          const response = res as GetPacketResponse
          this.directAppPacketData = response.packet.applicationInfo
          this.directAppStage = Utils.getDirectAppStage(res.packet)
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  ngOnDestroy() {
    if (this.currentAccessTokenSubscription) {
      this.currentAccessTokenSubscription.unsubscribe()
    }
    if (this.currentTokenErrorSubscription) {
      this.currentTokenErrorSubscription.unsubscribe()
    }
    if (this.currentTokenVerifiedSubscription) {
      this.currentTokenVerifiedSubscription.unsubscribe()
    }
  }
}
