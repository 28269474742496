<!-- inside of right-box class -->
<div class="step-content">
  <div class="direct-app-page-title">Ownership Information</div>
  <div class="direct-app-page-info">
    Let us know what individuals are involved in your business.
  </div>

  <div class="direct-app-page-sub-title">Owners</div>
  <div class="direct-app-page-info owner">
    List at least one owner below. The first owner listed will be the primary
    contact for the application.
  </div>

  <div class="ownership-percent" id="ownership-percentage">
    <div>
      <img class="add-icon" src="/assets/imgs/information-circle.svg" />
    </div>
    <div>
      To proceed, <strong>at least 51% ownership stake</strong> needs to be
      listed in the application.
    </div>
  </div>

  <div class="infoForm">
    <div class="owners">
      <form [formGroup]="ownersForm" class="owner-form" *ngIf="ownersForm">
        <div formArrayName="owners">
          <div
            class="owner-row"
            *ngFor="let item of owners.controls; let i = index"
          >
            <div
              class="banners"
              *ngIf="collapseOwner.length !== 0 && collapseOwner[i] === true"
            >
              <div class="item-banner">
                <div class="item-icon">
                  <img src="assets/imgs/owners-illustration.svg" />
                </div>
                <div class="item-header">
                  OWNER {{ i + 1 }}<br />
                  <span class="item-banner-detail"
                    >{{ owners.value[i].ownerFirstName }}
                    {{ owners.value[i].ownerLastName }}
                    {{ owners.value[i].ownershipPercentage }}% Owner</span
                  >
                </div>

                <div
                  class="item-delete"
                  *ngIf="!disableEdit"
                  (click)="showDelete(i)"
                >
                  <span
                    ><img
                      class="add-icon"
                      src="/assets/imgs/red-trashcan-icon.svg"
                  /></span>
                  <span>Delete</span>
                </div>

                <div
                  class="item-edit"
                  *ngIf="!disableEdit"
                  (click)="editOwner(i)"
                >
                  <span
                    ><img
                      class="add-icon"
                      src="/assets/imgs/icon-pencil.svg" /></span
                  ><span>Edit</span>
                </div>
              </div>

              <div class="mobile-item-banner-detail">
                <div class="name">
                  {{ owners.value[i].ownerFirstName }}
                  {{ owners.value[i].ownerLastName }}
                </div>
                <div>{{ owners.value[i].ownershipPercentage }}% Owner</div>
              </div>
            </div>

            <div
              [formGroupName]="i"
              class="item-details"
              *ngIf="collapseOwner.length === 0 || collapseOwner[i] !== true"
            >
              <div class="owner-title">
                <div class="item-num owner-num">
                  Owner {{ i + 1 }} <span *ngIf="i === 0"> (Primary)</span>
                </div>
                <div
                  class="checkbox"
                  *ngIf="
                    i === 0 ||
                    (applicationInfo?.ownerInfo?.isPrimaryOwner && i === 0)
                  "
                >
                  <div class="question">
                    Are you the primary owner for this business?
                  </div>
                  <div class="radio-group">
                    <div>
                      <input
                        (click)="showPrimaryOwner(true)"
                        id="primaryOwner"
                        class=""
                        type="radio"
                        name="primaryOwner"
                        value="true"
                      />
                      <label class="primary-owner" for="primaryOwner"
                        >Yes
                      </label>
                    </div>
                    <div>
                      <input
                        (click)="showPrimaryOwner(false)"
                        id="notPrimaryOwner"
                        class=""
                        type="radio"
                        name="primaryOwner"
                        value="false"
                      />
                      <label class="primary-owner" for="notPrimaryOwner"
                        >No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="direct-app-form">
                <div class="field">
                  <div class="label-text">First Name</div>
                  <div class="content-input">
                    <input
                      *ngIf="!isPrimaryOwner || i !== 0"
                      formControlName="ownerFirstName"
                      class="input-text container"
                      type="text"
                    />
                    <span
                      class="disabledText"
                      *ngIf="isPrimaryOwner && i === 0"
                    >
                      {{ applicationInfo.accountInfo.firstName }}</span
                    >
                  </div>
                  <div
                    *ngIf="
                      (!isPrimaryOwner || i !== 0) &&
                      (showError ||
                        item['controls'].ownerFirstName.dirty ||
                        item['controls'].ownerFirstName.touched)
                    "
                  >
                    <div
                      *ngIf="item['controls'].ownerFirstName.errors?.required"
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >First name is required.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="label-text">Last name</div>
                  <div class="content-input">
                    <input
                      *ngIf="!isPrimaryOwner || i !== 0"
                      formControlName="ownerLastName"
                      #ownerLastName
                      class="input-text container"
                      type="text"
                    />
                    <span
                      class="disabledText"
                      *ngIf="isPrimaryOwner && i === 0"
                    >
                      {{ applicationInfo.accountInfo.lastName }}</span
                    >
                  </div>

                  <div
                    *ngIf="
                      (!isPrimaryOwner || i !== 0) &&
                      (showError ||
                        item['controls'].ownerLastName.dirty ||
                        item['controls'].ownerLastName.touched)
                    "
                  >
                    <div
                      *ngIf="item['controls'].ownerLastName.errors?.required"
                      class="red-text alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >Last name is required.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="field" *ngIf="isPrimaryOwner && i === 0">
                  <div class="label-text">Date of Birth</div>
                  <div class="content-input">
                    <input
                      type="text"
                      inputmode="numeric"
                      placeholder="{{ getDateFormatPlaceholder() }}"
                      id="dob{{ i }}"
                      formControlName="ownerDOB"
                      class="input-text container"
                      (keydown)="handleNumberKeydown($event)"
                      (input)="handleDateInput($event)"
                    />
                  </div>

                  <div
                    *ngIf="
                      showError ||
                      item['controls'].ownerDOB.dirty ||
                      item['controls'].ownerDOB.touched
                    "
                  >
                    <div class="red-text alert alert-danger err-text">
                      <span *ngIf="item['controls'].ownerDOB.errors?.required"
                        >Date of Birth is required.</span
                      >

                      <span
                        *ngIf="item['controls'].ownerDOB.errors?.InValidateDOB"
                      >
                        Enter a valid date of birth.
                      </span>
                    </div>
                  </div>
                </div>

                <div class="field" *ngIf="isPrimaryOwner && i === 0">
                  <div class="label-text">Social Security Number</div>
                  <div class="content-input">
                    <input
                      value="{{ newOwnerSSN[i] }}"
                      (keydown)="handleNumberKeydown($event)"
                      (input)="handleSSNInput($event, i)"
                      placeholder="###-##-####"
                      formControlName="ownerSSN"
                      id="owner-ssn{{ i }}"
                      class="input-text container"
                      type="password"
                    />
                    <button
                      (click)="toggleOwnerSSN(i)"
                      id="toggleNewPassword"
                      class="password"
                    ></button>
                  </div>
                  <div
                    *ngIf="
                      showError ||
                      item['controls'].ownerSSN.dirty ||
                      item['controls'].ownerSSN.touched
                    "
                  >
                    <div class="red-text alert alert-danger err-text">
                      <span
                        *ngIf="item['controls'].ownerSSN.errors?.required"
                        class="alert alert-danger help-text"
                        >Social security number is required.</span
                      >
                      <span
                        *ngIf="item['controls'].ownerSSN.errors?.pattern"
                        class="alert alert-danger"
                      >
                        Enter a valid social security number.
                      </span>
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="label-text">Mobile Phone Number</div>
                  <div class="content-input">
                    <input
                      placeholder="(###) ###-####"
                      formControlName="ownerMobilePhone"
                      #ownerMobilePhone
                      class="input-text container"
                      type="text"
                      value="{{ newphoneNo[i] }}"
                      (keyup)="formatOwnerPhone($event, i)"
                      (keydown)="amountKeydown($event)"
                      maxlength="14"
                    />
                  </div>
                  <div
                    *ngIf="
                      showError ||
                      item['controls'].ownerMobilePhone.dirty ||
                      item['controls'].ownerMobilePhone.touched
                    "
                  >
                    <div
                      *ngIf="item['controls'].ownerMobilePhone.errors?.required"
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >Mobile phone number is required.</span
                      >
                    </div>
                    <div
                      *ngIf="
                        item['controls'].ownerMobilePhone.errors?.ValidatePhone
                      "
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >Enter a valid mobile phone number.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="label-text">Email</div>
                  <div class="content-input">
                    <input
                      *ngIf="!isPrimaryOwner || i !== 0"
                      formControlName="ownerEmail"
                      #ownerEmail
                      class="input-text container"
                      type="text"
                    />
                    <span
                      class="disabledText"
                      *ngIf="isPrimaryOwner && i === 0"
                    >
                      {{ applicationInfo.accountInfo.email }}</span
                    >
                  </div>
                  <div
                    *ngIf="
                      (!isPrimaryOwner || i !== 0) &&
                      (showError ||
                        item['controls'].ownerEmail.dirty ||
                        item['controls'].ownerEmail.touched)
                    "
                  >
                    <div class="red-text alert alert-danger err-text">
                      <span *ngIf="item['controls'].ownerEmail.errors?.required"
                        >Email is required.</span
                      >
                      <span *ngIf="item['controls'].ownerEmail.errors?.pattern"
                        >Enter a valid email.</span
                      >
                      <span
                        *ngIf="
                          item['controls'].ownerEmail.errors
                            ?.ValidateUniqueEmail
                        "
                        >Email address already in use, please enter a different
                        one.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="label-text">Title/Position (Optional)</div>
                  <div class="content-input">
                    <input
                      formControlName="ownerTitle"
                      #ownerTitle
                      class="input-text container"
                      type="text"
                    />
                  </div>
                  <div
                    *ngIf="
                      showError ||
                      item['controls'].ownerTitle.dirty ||
                      item['controls'].ownerTitle.touched
                    "
                  >
                    <div
                      *ngIf="item['controls'].ownerTitle.errors?.required"
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >Title/Position is required.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="field">
                  <div class="label-text">Ownership Percentage</div>
                  <div class="content-input input-icon">
                    <input
                      formControlName="ownershipPercentage"
                      maxlength="3"
                      id="owerPercentage{{ i }}"
                      class="input-text container"
                      type="text"
                    />
                    <i>%</i>
                  </div>
                  <div
                    *ngIf="
                      showError ||
                      item['controls'].ownershipPercentage.dirty ||
                      item['controls'].ownershipPercentage.touched
                    "
                  >
                    <div
                      *ngIf="
                        item['controls'].ownershipPercentage.errors?.required
                      "
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >Ownership percentage is required.</span
                      >
                    </div>

                    <div
                      *ngIf="item['controls'].ownershipPercentage.errors?.min"
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text"
                        >Enter a valid percentage.</span
                      >
                    </div>
                    <div
                      *ngIf="totalPercentage > 100"
                      class="red-text"
                      class="alert alert-danger err-text"
                    >
                      <span class="alert alert-danger help-text">
                        Total ownership cannot exceed 100%.
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isPrimaryOwner && i === 0">
                <div class="item-subtitle">
                  Owner {{ i + 1 }} Address Information
                </div>
                <div class="direct-app-form">
                  <div class="field">
                    <div class="label-text">Street Address</div>
                    <div class="content-input">
                      <input
                        formControlName="ownerStreetAddress"
                        #ownerStreetAddress
                        class="input-text container"
                        type="text"
                      />
                    </div>
                    <div
                      *ngIf="
                        showError ||
                        item['controls'].ownerStreetAddress.dirty ||
                        item['controls'].ownerStreetAddress.touched
                      "
                    >
                      <div
                        *ngIf="
                          item['controls'].ownerStreetAddress.errors?.required
                        "
                        class="red-text"
                        class="alert alert-danger err-text"
                      >
                        <span class="alert alert-danger help-text"
                          >Street address is required.</span
                        >
                      </div>
                      <div
                        *ngIf="
                          item['controls'].ownerStreetAddress.errors
                            ?.InValidateAddress
                        "
                        class="red-text"
                        class="alert alert-danger err-text"
                      >
                        <span class="alert alert-danger help-text">
                          Address cannot be a P.O. Box.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="label-text">City</div>
                    <div class="content-input">
                      <input
                        formControlName="ownerCity"
                        #ownerCity
                        class="input-text container"
                        type="text"
                      />
                    </div>
                    <div
                      *ngIf="
                        showError ||
                        item['controls'].ownerCity.dirty ||
                        item['controls'].ownerCity.touched
                      "
                    >
                      <div
                        *ngIf="item['controls'].ownerCity.errors?.required"
                        class="red-text"
                        class="alert alert-danger err-text"
                      >
                        <span class="alert alert-danger help-text"
                          >City is required.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="label-text">State</div>
                    <div class="content-input">
                      <select
                        formControlName="ownerState"
                        id="ownerState{{ 0 }}"
                        class="input-text states container"
                        type="text"
                      >
                        <option hidden [ngValue]="null" selected>
                          Select a State
                        </option>
                        <option
                          value="{{ state }}"
                          *ngFor="let state of states"
                        >
                          {{ state }}
                        </option>
                      </select>
                    </div>
                    <div
                      *ngIf="
                        showError ||
                        item['controls'].ownerState.dirty ||
                        item['controls'].ownerState.touched
                      "
                    >
                      <div
                        *ngIf="item['controls'].ownerState.errors?.required"
                        class="red-text"
                        class="alert alert-danger err-text"
                      >
                        <span class="alert alert-danger help-text"
                          >State is required.</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="label-text">Zip Code</div>
                    <div class="content-input">
                      <input
                        maxlength="5"
                        formControlName="ownerZipcode"
                        class="input-text container"
                        type="text"
                        value="{{ newOwnerZip[i] }}"
                      />
                    </div>
                    <div
                      *ngIf="
                        showError ||
                        item['controls'].ownerZipcode.dirty ||
                        item['controls'].ownerZipcode.touched
                      "
                    >
                      <div class="red-text" class="alert alert-danger err-text">
                        <span
                          *ngIf="item['controls'].ownerZipcode.errors?.required"
                          class="alert alert-danger help-text"
                          >Zip code is required.</span
                        >
                        <span
                          *ngIf="
                            item['controls'].ownerZipcode.errors?.maxlength ||
                            item['controls'].ownerZipcode.errors?.minlength
                          "
                          class="alert alert-danger"
                        >
                          Enter a valid zip code.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="btn-container end">
                <button
                  (click)="cancelOwner(i)"
                  class="add-item cancel"
                  id="cancel-owner{{ i }}"
                >
                  Cancel
                </button>
                <button
                  (click)="saveOwnerInfo(i)"
                  class="btn-background add"
                  id="save-owner{{ i }}"
                >
                  ADD
                </button>
                <button (click)="cancelOwner(i)" class="add-item cancel-mobile">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="showAddOwner && totalPercentage <= 100 && !disableEdit">
    <button (click)="addMoreOwner()" class="add-item">
      <img class="add-icon plus" src="/assets/imgs/add-icon.svg" />
      Add Owner
    </button>
  </div>

  <div class="btn-group">
    <div class="goback-group tablet-back">
      <div class="goback" (click)="goBack()">
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="goback" #back>Back </a>
      </div>
    </div>

    <div class="btn-container end">
      <button
        *ngIf="!disableEdit"
        [disabled]="!continue || clicked"
        [ngClass]="continue ? 'continue-btn-background' : 'btn-disable'"
        (click)="submitOwnerInfo()"
        #submit
        class="btn-background"
      >
        CONTINUE
      </button>
    </div>

    <div class="goback-group">
      <div class="gobackmobile" (click)="goBack()">
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="gobackmobile" #back>Back </a>
      </div>
    </div>
  </div>
</div>
