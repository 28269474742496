import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  AfterViewInit,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'

import { format, parseISO } from 'date-fns'
import { Subscription } from 'rxjs'

import * as INTF from '../../../../core/models/interfaces'
import { ModalService } from '../../../../core/services/modal/modal.service'
import { StepService } from '../../../../core/services/steps/step.service'
import { ErrorService } from '../../../../core/services/generic-error/error.service'
import { LoanService } from '../../../../core/services/loan/loan.service'
import { handleError } from '../../../../core'
@Component({
  selector: 'app-change-payment-due-day',
  templateUrl: './change-payment-due-day.component.html',
  styleUrls: ['./change-payment-due-day.component.scss'],
})
export class ChangePaymentDueDayComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() calcNums: INTF.Loan
  @Input() userClaims: any
  @Input() loanUuid: string

  updateFirstPaymentDaySubscription: Subscription | undefined
  clicked = false
  loanStatus: INTF.DocCheckoutStep | null = null
  firstPaymentDateForm: FormGroup
  private firstPaymentDateFormValueChangSubscription: Subscription | undefined
  requestedStartDate: any
  selectedDate: string
  defaultFirstPaymentDate: any
  dateString: string

  constructor(
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private loanService: LoanService,
    private stepService: StepService,
    private errorService: ErrorService,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.loanService.currentFirstPaymentDay.subscribe((data) => {
      if (data) {
        this.selectedDate = format(parseISO(data), 'EEEE MMMM dd, yyyy')
        this.dateString = data
      } else {
        this.selectedDate = format(
          parseISO(this.calcNums.requestedStartDate),
          'EEEE MMMM dd, yyyy',
        )
        this.dateString = this.calcNums.requestedStartDate
      }
    })

    this.defaultFirstPaymentDate = this.calcNums.paymentDayOptionList[0].date

    this.loanService.currentStatus.subscribe((res) => {
      this.loanStatus = res
    })

    this.calcNums.paymentDayOptionList.forEach((x) => {
      x.formatedDate = format(parseISO(x.date), 'MMMM dd, yyyy')
    })

    this.forminit()
    this.getrequestedStartDate()
  }

  getIndex() {
    this.calcNums.paymentDayOptionList.forEach((value, index) => {
      if (value.date === this.dateString) {
        this.checkPaymentDay(index) // show icon to selected payment day
      }
    })
  }

  // ngAfterViewInit() is called after the view is initially rendered.
  ngAfterViewInit() {
    this.getIndex()
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.changeFirstPaymentDay()
    }
  }

  get f() {
    return this.firstPaymentDateForm.controls
  }

  forminit() {
    this.firstPaymentDateForm = this.fb.group({
      firstPaymentDay: [this.defaultFirstPaymentDate, Validators.required],
    })
  }

  checkPaymentDay(i: number) {
    const nums = [0, 1, 2]
    const diff = nums.filter((x) => x !== i)
    const selectedDayIcon = 'first-dayIcon' + i
    const selectedDayBox = 'first-day-box' + i
    const selectedEveryDay = 'first-everyday' + i

    diff.forEach((x) => {
      document.getElementById('first-dayIcon' + x).classList.remove('checked')
      document
        .getElementById('first-day-box' + x)
        .classList.remove('selected-Day-Box')
      document
        .getElementById('first-everyday' + x)
        .classList.remove('selected-every-day')
    })
    document.getElementById(selectedDayIcon).classList.add('checked')
    document.getElementById(selectedDayBox).classList.add('selected-Day-Box')
    document
      .getElementById(selectedEveryDay)
      .classList.add('selected-every-day')
  }

  close() {
    this.modalService.updatedModalState(false)
  }

  changeFirstPaymentDay() {
    if (this.firstPaymentDateForm.valid) {
      this.getrequestedStartDate()
      const data = { requestedStartDate: this.requestedStartDate }

      this.updateFirstPaymentDaySubscription = this.loanService
        .updateFirstPaymentDay$(this.loanUuid, data)
        .subscribe(
          (res: INTF.LoanStatus) => {
            this.clicked = false
            this.loanStatus = res.updatedStep

            this.loanService.FirstPaymentDaySource.next(this.requestedStartDate)

            this.loanService.updatedStatus$(this.loanStatus)
            this.stepService.updatedStep(this.loanStatus, this.loanUuid)

            // close this modal
            this.modalService.updatedModalType(null)
            this.modalService.updatedModalState(false)
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    }
  }

  getrequestedStartDate() {
    this.requestedStartDate =
      this.firstPaymentDateForm.getRawValue().firstPaymentDay
  }

  ngOnDestroy() {
    if (this.firstPaymentDateFormValueChangSubscription) {
      this.firstPaymentDateFormValueChangSubscription.unsubscribe()
      this.firstPaymentDateFormValueChangSubscription = null
    }

    if (this.updateFirstPaymentDaySubscription) {
      this.updateFirstPaymentDaySubscription.unsubscribe()
      this.updateFirstPaymentDaySubscription = null
    }
  }
}
