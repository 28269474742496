import { SupportedTaxIdFormat } from '../enums'

export const validateSSNString: (
  ssn: string,
  format: SupportedTaxIdFormat,
  backspace?: boolean,
) => string = (ssn, format, backspace) => {
  let updatedSSN = ssn.replace(new RegExp('-', 'gi'), '')
  switch (format) {
    case SupportedTaxIdFormat.CORPORATION:
    case SupportedTaxIdFormat.LIMITED_LIABILITY_CORPORATION:
    case SupportedTaxIdFormat.PARTNERSHIP:
    case SupportedTaxIdFormat.S_CORPORATION:
      if (updatedSSN.length >= 2) {
        updatedSSN = `${updatedSSN.substring(0, 2)}-${updatedSSN.substring(2)}`
      }
      break
    case SupportedTaxIdFormat.SOCIAL_SECURITY_NUMBER:
    case SupportedTaxIdFormat.SOLE_PROPRIETOR:
    default:
      if (updatedSSN.length >= 3) {
        updatedSSN = `${updatedSSN.substring(0, 3)}-${updatedSSN.substring(3)}`
      }
      if (updatedSSN.length >= 6) {
        updatedSSN = `${updatedSSN.substring(0, 6)}-${updatedSSN.substr(6, 4)}`
      }
      break
  }
  if (backspace && updatedSSN[updatedSSN.length - 1] === '-') {
    updatedSSN = updatedSSN.slice(0, -1)
  }
  return updatedSSN
}
