<div id="myModal" class="modal" *ngIf="!emitTokenRenewed" (click)="close()">
  <!-- Modal content -->
  <div class="modal-content" #modalcontent (click)="nothing($event)">
    <div class="yellow-bar"></div>
    <div class="modal-close" *ngIf="!showTimeoutMdl" (click)="close()"></div>
    <app-before-continue *ngIf="showBeforeContinue"> </app-before-continue>
    <app-change-payment-due-day
      [loanUuid]="loanUuid"
      [calcNums]="calcNums"
      *ngIf="showChangePaymentDay"
    >
    </app-change-payment-due-day>
    <app-disbursement
      [calcNums]="calcNums"
      *ngIf="showDisbursementMdl"
    ></app-disbursement>
    <app-expiration *ngIf="showTimeoutMdl"></app-expiration>
    <app-num-of-payment *ngIf="showPaymentMdl"></app-num-of-payment>
    <app-ucc *ngIf="showUCCMdl"></app-ucc>
    <app-account-already-exists
      *ngIf="showAccountAlreadyExistsMdl"
    ></app-account-already-exists>
    <app-delete [modalItemType]="modalItemType" *ngIf="showDelete"></app-delete>
    <app-email-verification-needed
      *ngIf="showEmailVerificationNeeded"
    ></app-email-verification-needed>
    <app-connection-error *ngIf="showConnectionError"></app-connection-error>
  </div>
</div>
