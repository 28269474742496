export enum DirectAppStep {
  ADDITIONAL_INFO = 'additional-info',
  APP_SUBMITTED = 'app-submitted',
  BUSINESS_ADDRESS_INFO = 'business-address-info',
  BUSINESS_BASIC_INFO = 'business-basic-info',
  BUSINESS_CONTACT_INFO = 'business-contact-info',
  CREATE_ACCOUNT = 'create-account',
  CREATE_FROM_LEAD = 'create-from-lead',
  CREDIT_AUTH_SUCCESS = 'credit-auth-success',
  DISABLED = 'disabled',
  DOCUMENT = 'document',
  EMAIL_VERIFICATION = 'email-verification',
  NUULA_LANDING = 'nuula-landing',
  OWNER_CREDIT_AUTH = 'owner-credit-auth',
  OWNER_INFO = 'owner-info',
  REVIEW_SUBMIT = 'review-submit',
  UNKNOWN = 'unknown',
}
