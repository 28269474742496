<!-- The sidebar -->
<div class="sidebar">
  <div></div>
  <div>
    <a (click)="introduction()" id="introduction" disabled="disabledIntroduction" [ngClass]="{
    'current-on':loanStep.loanIntroductionStep,
    'checked':doneStep.doneIntroduction,
    'uncheck':!doneStep.doneIntroduction && !loanStep.loanIntroductionStep}">
      Introduction
    </a>

    <a (click)="verification()" id="verification" [ngClass]="{
    'current-on':loanStep.identityVerificationIdentityStep,
    'checked':doneStep.doneVerification,
    'uncheck':!doneStep.doneVerification && !loanStep.identityVerificationIdentityStep}">
      Verification
    </a>

    <a (click)="loanDetails()" id="details" [ngClass]="{
    'current-on':loanStep.loanDetailsStep,
    'checked':doneStep.doneLoanDetails,
    'uncheck':!doneStep.doneLoanDetails && !loanStep.loanDetailsStep}">
      Loan Details
    </a>

    <a (click)="loanAgreement()" id="agreement" [ngClass]="{
    'current-on':loanStep.loanAgreementStep,
    'checked':doneStep.doneLoanAgreement,
    'uncheck':!doneStep.doneLoanAgreement && !loanStep.loanAgreementStep}">
      Loan Agreement
    </a>

    <a (click)="businessReference()" id="review" [ngClass]="{
    'current-on':loanStep.businessReferenceStep,
    'checked':doneStep.doneReviewSubmit,
    'uncheck':!doneStep.doneReviewSubmit && !loanStep.businessReferenceStep}">
      Review & Submit
    </a>
  </div>
  <div></div>
</div>