import { Injectable } from '@angular/core'
import {
  HttpErrorResponse,
  HttpClient,
  HttpResponse,
} from '@angular/common/http'

import { Observable, throwError, of } from 'rxjs'
import { catchError, retry } from 'rxjs/operators'

export interface Config {
  serverUrl: string
  textfile: string
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  configUrl = 'assets/config.json'

  constructor(private http: HttpClient) {}

  // can not be private because we need to test it
  // for sentry see following:
  // https://docs.sentry.io/platforms/javascript/angular/?_ga=2.103591302.762584819.1595270154-968122351.1595270154
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error("Client-side error occured:", error.error.message);
      throwError(error)
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Error occured, returned code ${error.status}, ` +
          `body was: ${JSON.stringify(error.error)}`,
      )
    }
    // return an observable with a user-facing error message
    // return throwError("Something bad happened; please try again later.");
    return throwError(error)
  }
}
