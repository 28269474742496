<div class="app-submitted-container">
  <div class="spacer"></div>
  <div class="app-submitted-card">
    <div class="spinner-wrapper" *ngIf="getSpinner()">
      <img class="spinner-icon" src="assets/imgs/uploading-icon.svg" />
    </div>
    <div class="spinner-wrapper" *ngIf="tokenError">
      <img
        class="static-icon"
        src="assets/imgs/error-generic-illustration.svg"
      />
    </div>
    <div class="card-title" *ngIf="getSpinner()">Signing In</div>
    <div class="card-title" *ngIf="tokenError">Something Went Wrong</div>
    <div class="card-text" *ngIf="tokenError">
      We're sorry, the page you are trying to reach is not available. Please try again later. If the problem persists, please contact us at 
      <span class="strong">(855) 326-3564</span>.
    </div>
    <div class="card-signin-container" *ngIf="tokenError">
      <div class="card-signin-title">Trying to sign in?</div>
      <div class="card-signin-text">
        If you are having trouble accessing your application or loan, you can sign in using the button below.
      </div>
      <div class="btn-container">
        <button (click)="signin()" class="btn-background">
          SIGN IN
        </button>
      </div>
    </div>
  </div>
  <div class="spacer"></div>
</div>
