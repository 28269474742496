import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './login/login.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { LinkSentComponent } from './link-sent/link-sent.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { LoginCallbackComponent } from './login-callback/login-callback.component'

const authRoutes: Routes = [
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'link-sent',
    component: LinkSentComponent,
  },
  {
    path: 'password-reset/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'callback',
    component: LoginCallbackComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
