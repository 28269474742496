import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import { BannersStatesService } from '../../core/services/banners/banners-states.service'
import { CurrentUserService } from '../../core/services/current-user/current-user.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { handleError } from '../../core'
import { LoanService } from '../../core/services/loan/loan.service'

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  @Input() calcNums: INTF.Loan
  token: string
  resent: boolean
  showInfo: boolean
  sendEmailSubscription: Subscription
  error: INTF.BackendError | null
  currentUserEmail: string | null
  resendEmailSubscription: Subscription

  constructor(
    private loanService: LoanService,
    private bannersStatesService: BannersStatesService,
    private router: Router,
    private currentUserService: CurrentUserService,
    private errorService: ErrorService,
  ) {
    this.error = null
    this.currentUserEmail = null
    this.currentUserService.currentUser.subscribe((data) => {
      this.currentUserEmail = data
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.resent = false

    // this need to have a new backend change to give out one time user token to not use localstorage

    if (!this.currentUserEmail) {
      this.currentUserEmail = localStorage.getItem('email')

      if (!this.currentUserEmail) {
        this.error = {
          status: 'Unknown user',
          error: {
            message:
              "We're having a problem retrieving your account. Please locate the verification email we sent you previously.",
            title: 'Unknown User Account',
          },
        }
      }
    }
  }

  close() {
    this.resent = false
  }

  resendEmail() {
    // this need to have a new backend change to give out one time user token to not use localstorage

    const data = { email: this.currentUserEmail }

    this.resendEmailSubscription = this.loanService.resendEmail(data).subscribe(
      () => {
        this.bannersStatesService.storeBannerState(
          this.bannersStatesService.EMAIL_RESENT,
        )

        this.resent = true
      },
      (err) => {
        console.log('resendEmail HTTP Error', err)

        if (
          err?.error?.error?.errorCode === INTF.ErrorCode.OKTA_EMAIL_VERIFIED
        ) {
          this.bannersStatesService.storeBannerState(
            this.bannersStatesService.ALREADY_VERIFIED,
          )
          this.router.navigate([`/auth/login`])
        } else {
          handleError(err, this.errorService, this.router)
        }
      },
    )
  }

  ngOnDestroy() {
    if (this.resendEmailSubscription) {
      this.resendEmailSubscription.unsubscribe()
      this.resendEmailSubscription = null
    }
  }
}
