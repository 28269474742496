<div class="auth-page-container">
  <div class="info-page-wrap">
    <div></div>
    <div>
      <app-logo></app-logo>

      <div class="margin-auto rectangle">
        <div class="pre-approval-sent-illustration info-icon added-icon margin-auto"></div>
        <div class="text-align-center">
          <p class="pre-title margin-auto">You're all set!</p>
        </div>

        <div class="rectangle">
          <div class="review margin-auto">We will review the information submitted and send you a confirmation email to
            <strong class="user-email">{{ userClaims?.email }}</strong> once you’ve been approved for disbursement.
          </div>
        </div>

        <div class="btn-container text-align-center">
          <button (click)="goDashboard()" #submit type="submit" class="btn-background">GO TO DASHBOARD
          </button>

        </div>
      </div>

      <div class="contact-group margin-auto">
        <div class="question margin-auto">Have Questions?</div>
        <div class="contact margin-auto">
          <p>
            Call us at <strong>(888) 705-3765</strong> or send an email to</p>
          <p>
            <strong class="user-email">docs@mulliganfunding.com</strong> for assistance.</p>
        </div>
      </div>
    </div>
  </div>
</div>