import { Component, OnInit, OnDestroy, Input } from '@angular/core'

import { Router } from '@angular/router'

import { AuthService } from '../../core/services/auth/auth.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { DirectAppUrl, getAccessToken, invalidateAccessToken } from '../../core'

@Component({
  selector: 'app-direct-app-create-from-lead',
  templateUrl: './create-from-lead.component.html',
  styleUrls: ['./create-from-lead.component.scss'],
})
export class DirectAppCreateFromLeadComponent implements OnInit, OnDestroy {
  token = ''

  tokenError = false
  constructor(
    private authService: AuthService,
    private directAppService: DirectAppService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // If there is a token, invalidate it
    const accessToken = getAccessToken()
    if (accessToken) {
      const invalidToken = {
        ...accessToken,
        isValid: false,
      }
      invalidateAccessToken()
      this.authService.mfAccessToken.next(invalidToken)
    }

    // redirect to direct-app-react /ui/direct-app/[TOKEN]
    const urlParts = this.router.url.split('/')
    this.token = urlParts[urlParts.length - 1]
    window.location.replace(`/ui/direct-app/create-from-lead/${this.token}`)
  }

  formInit() {}

  getSpinner() {
    return !this.tokenError
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  ngOnDestroy() {}
}
