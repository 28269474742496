<div class="sidebar-container">
  <div class="sidebar-data" *ngIf="tokenVerified">
    <div class="sidebar-greeting">{{ getGreeting() }}</div>
    <div class="sidebar-auth-statement">{{ getAuthStatement() }}</div>
    <div class="sidebar-separator"></div>
    <div class="sidebar-fine-print" *ngIf="!creditAuthorized">
      Enter the following information to authorize your company’s application.
      Your credit score will not be impacted by submitting this authorization.
    </div>
  </div>
</div>
