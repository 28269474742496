<app-direct-header
  [setDirectAppStep]="directAppStep"
  [setIsNuulaUser]="isNuulaUser"
  *ngIf="showFullWidthHeader()"
></app-direct-header>
<app-generic-error
  [genError]="genError"
  [setIsNuulaUser]="isNuulaUser"
  *ngIf="showGenErr"
></app-generic-error>
<app-server-error [genError]="genError" *ngIf="showSeverErr"></app-server-error>
<div *ngIf="!genError">
  <app-direct-loan-details
    [setAccessToken]="accessToken"
    [setApplicationInfo]="applicationInfo"
    [setCreateFromLead]="createFromLead"
    [setIsNuulaUser]="isNuulaUser"
    [setMetadata]="metadata"
    [setPacketId]="packetId"
    *ngIf="directAppStep === 'create-account'"
  >
  </app-direct-loan-details>

  <div
    *ngIf="
      directAppStep !== 'create-account' ||
      showEmailVerificationPage() ||
      showOwnerCreditAuthPage() ||
      showCreditAuthSuccessPage() ||
      showCreateFromLeadPage()
    "
  >
    <div
      class="sidebar-content-container"
      [ngClass]="{
        'nuula-details-container': isNuulaUser,
        'details-container': !isNuulaUser
      }"
      *ngIf="!showFullWidthHeader()"
    >
      <div
        class="fullname"
        [ngClass]="{
          'header-logo-container tablet': !isNuulaUser,
          'nuula-header-logo-container nuula-tablet': isNuulaUser
        }"
      >
        <div class="tablet-header-logo-container">
          <img
            *ngIf="!isNuulaUser"
            class="header-logo"
            src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
          />
          <img
            *ngIf="isNuulaUser"
            class="nuula-header-logo"
            src="assets/imgs/mf-main-logo.svg"
          />
          <div *ngIf="isNuulaUser" class="nuula-line"></div>
        </div>
        <div *ngIf="!isNuulaUser">
          <a class="desktop-phone" href="tel:(855) 326-3564"
            ><img class="phone" src="assets/imgs/phone-icon-orange.svg" /><span
              >(855) 326-3564</span
            ></a
          >
        </div>
      </div>

      <div class="left" *ngIf="!showFullWidthHeader()">
        <div class="left-box">
          <div class="header-logo-container">
            <img
              class="header-logo"
              src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
            />
          </div>

          <div>
            <div class="sidebar-container">
              <app-direct-sidebar
                [setDirectAppStep]="directAppStep"
                [setPacketId]="packetId"
                [setMetadata]="metadata"
                *ngIf="!showOwnerCreditAuthPage()"
              >
              </app-direct-sidebar>
              <app-owner-credit-auth-sidebar
                *ngIf="showOwnerCreditAuthPage()"
              ></app-owner-credit-auth-sidebar>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content progress-bar"
        *ngIf="
          !isNuulaUser &&
          !showFullWidthHeader() &&
          directAppStep !== 'owner-credit-auth' &&
          directAppStep !== 'app-submitted'
        "
      >
        <div class="progress-info">
          <div class="step-name">{{ stepName }}</div>
          <div class="step-num">{{ stepNum }} of {{ totalSteps }}</div>
        </div>
        <div>
          <progress
            id="directAppProgress"
            #directAppProgress
            max="1"
          ></progress>
        </div>
      </div>
      <div class="right" *ngIf="!showFullWidthHeader()">
        <div class="right-box">
          <div
            class="header-logo-container fullname desktop"
            *ngIf="!isNuulaUser"
          >
            <a href="tel:(855) 326-3564"
              ><img
                class="phone"
                src="assets/imgs/phone-icon-orange.svg"
              />(855) 326-3564</a
            >
          </div>

          <div class="content-container">
            <app-business-basic-info
              [setPacketId]="packetId"
              [setApplicationInfo]="applicationInfo"
              [setMetadata]="metadata"
              *ngIf="directAppStep === 'business-basic-info'"
            >
            </app-business-basic-info>
            <app-business-contact-info
              [setPacketId]="packetId"
              [setApplicationInfo]="applicationInfo"
              [setMetadata]="metadata"
              *ngIf="directAppStep === 'business-contact-info'"
            >
            </app-business-contact-info>
            <app-business-address-info
              [setPacketId]="packetId"
              [setApplicationInfo]="applicationInfo"
              [setMetadata]="metadata"
              *ngIf="directAppStep === 'business-address-info'"
            >
            </app-business-address-info>

            <app-owner-info
              [setPacketId]="packetId"
              [setApplicationInfo]="applicationInfo"
              [setMetadata]="metadata"
              *ngIf="directAppStep === 'owner-info'"
            >
            </app-owner-info>
            <app-additional-info
              [setPacketId]="packetId"
              [setApplicationInfo]="applicationInfo"
              [setMetadata]="metadata"
              *ngIf="directAppStep === 'additional-info'"
            ></app-additional-info>
            <app-documents
              [setPacketId]="packetId"
              [setApplicationInfo]="applicationInfo"
              [setMetadata]="metadata"
              *ngIf="directAppStep === 'document'"
            >
            </app-documents>
            <app-direct-review-submit
              [setIsNuulaUser]="isNuulaUser"
              [packetId]="packetId"
              [applicationInfo]="applicationInfo"
              [metadata]="metadata"
              [openSections]="openSections"
              *ngIf="showReviewSubmitPage()"
            ></app-direct-review-submit>
            <app-owner-credit-auth
              *ngIf="showOwnerCreditAuthPage()"
            ></app-owner-credit-auth>
          </div>
        </div>
      </div>
    </div>
    <app-submitted
      [setPacketId]="packetId"
      [setApplicationInfo]="applicationInfo"
      [setIsNuulaUser]="isNuulaUser"
      *ngIf="showAppSubmittedPage()"
    ></app-submitted>
    <app-direct-app-email-verification
      *ngIf="showEmailVerificationPage()"
    ></app-direct-app-email-verification>
    <app-direct-app-create-from-lead
      *ngIf="showCreateFromLeadPage()"
    ></app-direct-app-create-from-lead>
    <app-owner-credit-auth-success
      *ngIf="showCreditAuthSuccessPage()"
    ></app-owner-credit-auth-success>
    <app-footer [setIsNuulaUser]="isNuulaUser"></app-footer>
  </div>
</div>
