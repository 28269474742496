import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { PageNotFoundComponent } from './doc-checkout/page-not-found/page-not-found.component'
import { HomeComponent } from './home/home.component'
import { SuccessContactComponent } from './home/success-contact/success-contact.component'
import { AboutComponent } from './about/about.component'

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'next-step-preference',
    component: SuccessContactComponent,
  },
  {
    path: 'app',
    loadChildren: () =>
      import('./direct/direct.module').then((m) => m.DirectModule),
  },
  {
    path: 'doc-checkout',
    loadChildren: () =>
      import('./doc-checkout/doc-checkout.module').then(
        (m) => m.DocCheckoutModule,
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '**', component: PageNotFoundComponent },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
