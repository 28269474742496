import { DirectAppStep, DirectAppUrl } from '../enums'

export const getDirectAppUrlFromStep: (step: DirectAppStep) => string = (
  step,
) => {
  switch (step) {
    case DirectAppStep.ADDITIONAL_INFO:
      return DirectAppUrl.ADDITIONAL_INFO
    case DirectAppStep.APP_SUBMITTED:
      return DirectAppUrl.APP_SUBMITTED
    case DirectAppStep.BUSINESS_ADDRESS_INFO:
      return DirectAppUrl.BUSINESS_ADDRESS_INFO
    case DirectAppStep.BUSINESS_BASIC_INFO:
      return DirectAppUrl.BUSINESS_BASIC_INFO
    case DirectAppStep.BUSINESS_CONTACT_INFO:
      return DirectAppUrl.BUSINESS_CONTACT_INFO
    case DirectAppStep.CREATE_ACCOUNT:
      return DirectAppUrl.CREATE_ACCOUNT
    case DirectAppStep.CREATE_FROM_LEAD:
      return DirectAppUrl.CREATE_FROM_LEAD
    case DirectAppStep.CREDIT_AUTH_SUCCESS:
      return DirectAppUrl.CREDIT_AUTH_SUCCESS
    case DirectAppStep.DOCUMENT:
      return DirectAppUrl.DOCUMENT
    case DirectAppStep.EMAIL_VERIFICATION:
      return DirectAppUrl.EMAIL_VERIFICATION
    case DirectAppStep.OWNER_CREDIT_AUTH:
      return DirectAppUrl.OWNER_CREDIT_AUTH
    case DirectAppStep.OWNER_INFO:
      return DirectAppUrl.OWNER_INFO
    case DirectAppStep.REVIEW_SUBMIT:
      return DirectAppUrl.REVIEW_SUBMIT
  }
}
