import { ErrorService } from '../services/generic-error/error.service'
import * as INTF from '../models/interfaces'
import { Router, ActivatedRoute } from '@angular/router'

export const handleError = (
  err: any,
  errorService: ErrorService,
  router: Router,
) => {
  // First log the error
  console.warn('HTTP Error', err)
  // console.warn('err.error.statusCode', err.error.statusCode)
  if (
    err.error &&
    err.error?.status &&
    err?.error?.status?.toString().toLowerCase() === 'forbidden'
    // todo when we handle expired token
    // || err?.error?.statusCode === 401
  ) {
    router.navigate([`/auth/login`])
  }
  if (err.error && (err.error.status === 0 || err.error.type === 'error')) {
    console.log('500--', err)

    errorService.storeError(errorService.SERVER_ERROR as INTF.ErrType)
  } else {
    console.log('400---', err)

    errorService.storeError(err.error)
  }
}
