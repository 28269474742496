import {
  Component,
  OnDestroy,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'
import { format, parseISO } from 'date-fns'
import { Subscription } from 'rxjs'

import { ErrorService } from '../../core/services/generic-error/error.service'
import { ModalService } from '../../core/services/modal/modal.service'
import { StepService } from '../../core/services/steps/step.service'
import { LoanService } from '../../core/services/loan/loan.service'
import * as INTF from '../../core/models/interfaces'
import { handleError } from '../../core'

@Component({
  selector: 'app-loan-details',
  templateUrl: './loan-details.component.html',
  styleUrls: ['./loan-details.component.scss'],
})
export class LoanDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  checked: false
  clicked: boolean
  isAcknowledged: boolean
  showItem: boolean
  paymentDateForm: FormGroup
  selectedDate: string
  selectedDay: string
  formattedDay: string
  requestedStartDate: any
  error: INTF.BackendError | null

  private paymentDateFormValueChangSubscription: Subscription | undefined

  submitLoanDetailsSubscription: Subscription | undefined
  currentFirstPaymentDaySub: Subscription | undefined
  paymentdayArr: string[]
  paymentScheduleList: INTF.PaymentScheduleItem[]

  @ViewChild('viewPaymentSchedule') viewPaymentSchedule: ElementRef
  @ViewChild('howiscal') howiscal: ElementRef
  @ViewChild('arrow') arrow: ElementRef
  @ViewChild('acknowledged') acknowledged: ElementRef

  @Input() calcNums: INTF.Loan
  @Input() userClaims: any

  loanUuid: string
  loanStatus: INTF.DocCheckoutStep
  givenName: string
  dateString: string
  defaultFirstPaymentDate: any

  constructor(
    private errorService: ErrorService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private stepService: StepService,
    private loanService: LoanService,
    private router: Router,
    private route: ActivatedRoute,
    public oktaAuth: OktaAuthService,
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0)
    this.defaultFirstPaymentDate = this.calcNums.paymentDayOptionList[0].date

    this.currentFirstPaymentDaySub =
      this.loanService.currentFirstPaymentDay.subscribe((data) => {
        if (data) {
          this.selectedDate = format(parseISO(data), 'EEEE MMMM dd, yyyy')
          this.calcNums.requestedStartDate = data
        } else {
          if (!this.calcNums.requestedStartDate) {
            this.calcNums.requestedStartDate = this.defaultFirstPaymentDate
          }

          this.selectedDate = format(
            parseISO(this.calcNums.requestedStartDate),
            'EEEE MMMM dd, yyyy',
          )
        }
      })

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')

    this.isAcknowledged = false
    this.showItem = false

    this.paymentFreqIsWeekly()

    this.calcNums.paymentDayOptionList.forEach((x) => {
      x.formatedDate = format(parseISO(x.date), 'MMMM dd, yyyy')
    })

    this.forminit()
    this.valueChange()
    this.getrequestedStartDate()
  }

  paymentFreqIsWeekly() {
    if (this.calcNums.paymentFreq) {
      return this.calcNums.paymentFreq.toLowerCase() === 'weekly'
    }
  }

  getIndex() {
    this.calcNums.paymentDayOptionList.forEach((value, index) => {
      if (value.date === this.calcNums.requestedStartDate) {
        this.checkDay(index) // show icon to selected payment day
      }
    })
  }

  // ngAfterViewInit() is called after the view is initially rendered.
  ngAfterViewInit() {
    this.getIndex()
  }

  forminit() {
    this.paymentDateForm = this.fb.group({
      requestedStartDate: [
        this.calcNums.requestedStartDate,
        Validators.required,
      ],
    })
  }

  toggleShowItem() {
    this.viewPaymentSchedule.nativeElement.innerText = this.showItem
      ? 'View Payment Schedule'
      : 'Hide Payment Schedule'
    this.showItem = !this.showItem
  }

  toggleAcknowledged() {
    this.isAcknowledged = !this.isAcknowledged
  }

  showDisbursement() {
    this.modalService.updatedModalState(true)
    this.modalService.updatedModalType(this.modalService.DISBURSEMENT)
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.submitLoanDetails()
    }
  }

  getrequestedStartDate() {
    this.requestedStartDate =
      this.paymentDateForm.getRawValue().requestedStartDate
  }

  submitLoanDetails() {
    if (this.paymentDateForm.valid && this.isAcknowledged) {
      this.getrequestedStartDate()
      const data = { requestedStartDate: this.requestedStartDate }

      this.submitLoanDetailsSubscription = this.loanService
        .submitLoanDetails$(this.loanUuid, data)
        .subscribe(
          (res: INTF.LoanStatus) => {
            this.clicked = false
            this.loanStatus = res.updatedStep
            this.loanService.updatedStatus$(this.loanStatus)
            this.stepService.updatedStep(this.loanStatus, this.loanUuid)
            this.loanService.FirstPaymentDaySource.next(this.requestedStartDate)
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    }
  }

  get f() {
    return this.paymentDateForm.controls
  }

  checkDay(i: number) {
    if (this.paymentFreqIsWeekly()) {
      const nums = [0, 1, 2]
      const diff = nums.filter((x) => x !== i)
      const selectedDayIcon = 'dayIcon' + i
      const selectedDayBox = 'day-box' + i
      const selectedEveryDay = 'every' + i

      diff.forEach((x) => {
        document.getElementById('dayIcon' + x).classList.remove('checked')
        document
          .getElementById('day-box' + x)
          .classList.remove('selected-Day-Box')
        document
          .getElementById('every' + x)
          .classList.remove('selected-every-day')
      })
      document.getElementById(selectedDayIcon).classList.add('checked')
      document.getElementById(selectedDayBox).classList.add('selected-Day-Box')
      document
        .getElementById(selectedEveryDay)
        .classList.add('selected-every-day')
    }
  }

  showUCC() {
    this.modalService.updatedModalState(true)
    this.modalService.updatedModalType(this.modalService.UCC)
  }

  showNumOfPaymentInfo() {
    this.modalService.updatedModalState(true)
    this.modalService.updatedModalType(this.modalService.PAYMENT)
  }

  valueChange() {
    this.paymentDateFormValueChangSubscription =
      this.paymentDateForm.valueChanges.subscribe((value) => {
        this.selectedDate = format(
          parseISO(value.requestedStartDate),
          'EEEE MMMM dd, yyyy',
        )
        this.getrequestedStartDate()
        this.showItem = false
        this.viewPaymentSchedule.nativeElement.innerText =
          'View Payment Schedule'
      })
  }

  ngOnDestroy() {
    if (this.paymentDateFormValueChangSubscription) {
      this.paymentDateFormValueChangSubscription.unsubscribe()
      this.paymentDateFormValueChangSubscription = null
    }

    if (this.submitLoanDetailsSubscription) {
      this.submitLoanDetailsSubscription.unsubscribe()
      this.submitLoanDetailsSubscription = null
    }

    if (this.currentFirstPaymentDaySub) {
      this.currentFirstPaymentDaySub.unsubscribe()
      this.currentFirstPaymentDaySub = null
    }
  }
}
