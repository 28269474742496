import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { distinctUntilChanged } from 'rxjs/operators'
import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import {
  DirectAppStep,
  EMAIL_FORM_REGEX,
  getDirectAppUrlFromStep,
  handleError,
} from '../../core'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import Utils from '../../shared/utils/utils'
import { DirectAppReviewSubmitService } from 'src/app/core/services/direct-app-review-submit/direct-app-review-submit.service'
import { ValidatePhone } from '../../shared/validators'

@Component({
  selector: 'app-business-contact-info',
  templateUrl: './business-contact-info.component.html',
  styleUrls: ['./business-contact-info.component.scss'],
})
export class BusinessContactInfoComponent implements OnInit, OnDestroy {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  disableEdit = false

  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    const newAppInfo = value && !this.applicationInfo
    this.applicationInfo = value
    if (newAppInfo) {
      this.formInit()
      this.valueChange()
    }
  }
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
  }

  comingFromReviewSubmit = false
  comingFromReviewSubmitSubscription: Subscription
  contactInfo: INTF.ContactInfo
  contactInfoForm: FormGroup
  contactInfoFormSubscription: Subscription
  contactInfoFormValueChangSubscription: Subscription

  clicked: boolean
  directAppStatus: DirectAppStep
  showError = false
  newphoneNo = ''

  @ViewChild('businessPhone') businessPhone: ElementRef

  constructor(
    private directAppStepService: DirectAppStepService,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private formBuilder: FormBuilder,
    private router: Router,
    private directAppService: DirectAppService,
    private errorService: ErrorService,
  ) {}
  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/business-info`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })
    this.comingFromReviewSubmitSubscription =
      this.directAppReviewSubmitService.currentComingFromReviewSubmit.subscribe(
        (val) => {
          this.comingFromReviewSubmit = val
        },
      )
  }

  goBack() {
    this.directAppStepService.updatedDirectAppStep(
      DirectAppStep.BUSINESS_BASIC_INFO,
    )
  }
  formatBusinessPhone(element) {
    this.newphoneNo = Utils.formatPhoneNo(element.target.value)

    this.contactInfoForm.patchValue(
      {
        businessPhone: this.newphoneNo,
      },
      { emitEvent: false },
    )
  }
  amountKeydown(e) {
    Utils.AmountKeydown(e)
  }
  formInit() {
    this.contactInfoForm = this.formBuilder.group({
      businessPhone: [
        this.applicationInfo?.contactInfo?.businessPhone,
        {
          validators: [Validators.required, ValidatePhone],
          updateOn: 'blur',
        },
      ],
      businessEmail: [
        this.applicationInfo?.contactInfo?.businessEmail,
        {
          validators: [
            Validators.required,
            Validators.email,
            Validators.pattern(EMAIL_FORM_REGEX),
          ],
          updateOn: 'blur',
        },
      ],
      businessWebsite: [this.applicationInfo?.contactInfo?.businessWebsite],
    })
    this.clicked = false
  }

  valueChange() {
    this.contactInfoFormValueChangSubscription =
      this.contactInfoForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(this.formValuesChanged.bind(this))
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.contactInfoForm.controls
  }

  formValuesChanged(value) {}

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.submitContactInfo()
    }
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  submitContactInfo() {
    this.showError = true
    this.contactInfo = {
      ...this.contactInfoForm.value,
    }
    if (this.contactInfoForm.valid) {
      const nextStep = this.comingFromReviewSubmit
        ? DirectAppStep.REVIEW_SUBMIT
        : DirectAppStep.BUSINESS_ADDRESS_INFO

      this.directAppReviewSubmitService.setComingFromReviewSubmit(false)
      const data = {
        packetId: this.packetId,
        contactInfo: this.contactInfo,
        nextStep,
      }
      if (!this.contactInfo.businessWebsite) {
        this.contactInfo.businessWebsite = ''
      }
      this.contactInfoFormSubscription = this.directAppService
        .submitContactInfo$(data)
        .subscribe(
          (res) => {
            this.clicked = false
            if (nextStep === DirectAppStep.REVIEW_SUBMIT) {
              window.location.replace(`/ui/direct-app/review-submit`)
            } else {
              window.location.replace(`/ui/direct-app/address-info`)
            }
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    }
  }

  ngOnDestroy() {
    if (this.comingFromReviewSubmitSubscription) {
      this.comingFromReviewSubmitSubscription.unsubscribe()
    }

    if (this.contactInfoFormSubscription) {
      this.contactInfoFormSubscription.unsubscribe()
    }

    if (this.contactInfoFormValueChangSubscription) {
      this.contactInfoFormValueChangSubscription.unsubscribe()
    }
  }
}
