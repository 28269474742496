<div class="modal-body">
  <div class="warning-wrapper">
    <img
      class="warning-icon"
      src="assets/imgs/error-generic-illustration.svg"
    />
  </div>
  <div class="modal-title">Unable to Connect</div>
  <div class="modal-text">
    We are unable to connect to your account at this time. You can try to
    connect again or choose to upload your company’s bank statements.
  </div>
  <div class="close-text" (click)="close()">Close</div>
</div>
