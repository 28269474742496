<div class="content-container">
  <div class="auth-page-container">
    <div class="info-page-wrap">
      <div></div>
      <div>
        <app-logo></app-logo>

        <div class="margin-auto rectangle">
          <div
            class="link-sent-illustration added-icon info-icon margin-auto"
          ></div>
          <div class="text-align-center">
            <p class="pre-title margin-auto">Request Received</p>
          </div>

          <div class="err-rectangle">
            <div class="review margin-auto">
              If an account related to <b>{{ getResetEmail() }}</b> exists, we've sent you an
              email with a link to reset your password. If you do not receive 
              an email within 10 minutes, check your spam folder or
              contact us at (855) 326-3564.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
