<div class="rectangle">
  <div class="page-container loan-detail bgw">

    <div class="page-title">Identity Verification</div>
    <div class="intro">
      In order to verify your identity and keep your account secure, we would like you to answer the
      following questions.

    </div>

    <div *ngIf="incompletedKBA && questions">
      <div class="timeout">

        <img src="/assets/imgs/info-icon.svg" *ngIf="ExpTimer" class="info-icon">
        <div class="timeout-msg">
          You have <strong>{{ExpTimer |
            async }}
          </strong>to submit
          your
          answers for
          verification.</div>
      </div>
      <form [formGroup]="questionForm" class="questions-container">

        <div class="question-set" formArrayName='questionAnswer'>
          <div class="question-group" *ngFor="let item of questionAnswer.controls; let i = index;">

            <div class="question">{{questions[i].id}}. {{questions[i].question}}</div>

            <div class="answer" [formGroupName]="i">
              <div *ngFor="let answer of questions[i]?.answers let j=index" [attr.data-index]="j">
                <input type="radio" value="{{answer.id}}" id="group{{i}}-answer{{j}}" formControlName="answer">
                <label class="answer-label" for="group{{i}}-answer{{j}}">{{answer.answer}}</label>
              </div>
            </div>

            <div *ngIf="questionAnswer.value[i].answer==='' && showMsg" class="alert alert-danger err-text">
              <span class="alert alert-danger help-text">
                <span>Select an answer for this question.</span>
              </span>
            </div>
          </div>

        </div>
      </form>

      <div class="btn-container text-align-center">
        <button (click)="scoreQuestion()" #submit class="btn-background verify">
          VERIFY IDENTITY</button>
      </div>
    </div>


    <div class="completed" *ngIf="completedKBA">
      <div class="oval"></div>
      <div class="signed">Your identity has already been verified.</div>
      <div class="btn-container">
        <button (click)="continue()" #submit class="btn-background">
          CONTINUE</button>
      </div>
    </div>
  </div>
</div>