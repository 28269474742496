import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
  }
  currentYear: number
  constructor() {}

  ngOnInit(): void {
    this.calCurrentYear()
  }
  calCurrentYear() {
    const date = new Date()
    this.currentYear = date.getFullYear()
  }
}
