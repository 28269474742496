import { AbstractControl } from '@angular/forms'
export function ValidateDesiredAmt(control: AbstractControl) {
  if (control.value) {
    const cleaned = +String(control.value).replace(/[^0-9\.]+/g, '')
    if (cleaned < 5000 || cleaned > 2000000) {
      return { ValidateDesiredAmt: true }
    }
  }
  return null
}
