import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-link-sent',
  templateUrl: './link-sent.component.html',
  styleUrls: ['./link-sent.component.scss'],
})
export class LinkSentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  getResetEmail = () => {
    return sessionStorage.getItem('resetEmail')
  }
}
