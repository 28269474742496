import * as INTF from '../../app/core/models/interfaces'

export const fakeSendRid: INTF.DirectAppGetPacketResp = {
  status: 'success',
  packet: {
    applicationInfo: {},
    id: '0016b8c250e60fff79419fe',
    metadata: {
      timestamps: [],
      boxFolderId: '144992774558',
      created: '2021-09-03T21:21:28.188874+00:00',
      data_version: 1,
      directAppStep: 'create-account',
      emailVerified: false,
      file_uploads: [],
      id: '0016dba7b3869f390b22940',
      maxStepReachedInt: 10,
      packet_payload_version: '3',
      plaidInteractions: false,
      qparams: {
        companyName: null,
        email: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        username: null,
      },
      updated: '2021-09-03T21:21:28.188892+00:00',
      username: null,
      respId: 'c70102c76fc8e064',
      version: 'pr3613-17cb3e24f1eaa7782a36d85081d6202fe91cda1d',
    },
    _attachments: 'attachments/',
    _etag: '"3600c57f-0000-0700-0000-613291d80000"',
    _rid: 'k+ttAP0mQK2iCgAAAAAAAA==',
    _self: 'dbs/k+ttAA==/colls/k+ttAP0mQK0=/docs/k+ttAP0mQK2iCgAAAAAAAA==/',
    _ts: 1630704088,
  },
}
