import { DirectAppStep } from '../enums'

export function getDirectAppStepFromUrl(url: string) {
  if (url.includes('address-info')) {
    return DirectAppStep.BUSINESS_ADDRESS_INFO
  }
  if (url.includes('application-review')) {
    return DirectAppStep.REVIEW_SUBMIT
  }
  if (url.includes('application-submitted')) {
    return DirectAppStep.APP_SUBMITTED
  }
  if (url.includes('bank-upload-preference')) {
    return DirectAppStep.DOCUMENT
  }
  if (url.includes('basic-info')) {
    return DirectAppStep.BUSINESS_BASIC_INFO
  }
  if (url.includes('contact-info')) {
    return DirectAppStep.BUSINESS_CONTACT_INFO
  }
  if (url.includes('create-from-lead')) {
    return DirectAppStep.CREATE_FROM_LEAD
  }
  if (url.includes('credit-auth-success')) {
    return DirectAppStep.CREDIT_AUTH_SUCCESS
  }
  if (url.includes('debt-info')) {
    return DirectAppStep.ADDITIONAL_INFO
  }
  if (url.includes('email-verification')) {
    return DirectAppStep.EMAIL_VERIFICATION
  }
  if (url.includes('owner-credit-auth')) {
    return DirectAppStep.OWNER_CREDIT_AUTH
  }
  if (url.includes('owner-info')) {
    return DirectAppStep.OWNER_INFO
  }
  if (url.includes('start-application')) {
    return DirectAppStep.CREATE_ACCOUNT
  }
  if (url.includes('upload-statements')) {
    return DirectAppStep.DOCUMENT
  }
  return false
}
