<div class="step-content">
  <div class="spinner-container" >
    <div class="spinner-icon-wrapper">
      <img class="spinner-icon" src="assets/imgs/uploading-icon.svg" />
    </div>
  </div>
  <div>
  <div class="direct-app-page-title">Personal Information</div>
    <form>
      <div class="direct-app-form">
        <div class="field">
          <div class="label-text">First Name</div>
          <div class="content-input read-only">
            <input
              value="name"
              formControlName="firstName"
              class="input-text container"
              type="text"
            />
          </div>
        </div>
        <div class="field">
          <div class="label-text">Last Name</div>
          <div class="content-input read-only">
            <input
              value="last name"
              formControlName="lastName"
              class="input-text container"
              type="text"
            />
          </div>
        </div>
        <div class="field">
          <div class="label-text">Email</div>
          <div class="content-input read-only">
            <input
              value="email"
              formControlName="email"
              class="input-text container"
              type="text"
            />
          </div>
        </div>
        <div class="field">
          <div class="label-text">Date of Birth</div>
          <div class="read-only">
            <input
              autocomplete="bday"
              type="text"
              inputmode="numeric"
              placeholder="01/02/2022"
              formControlName="dateOfBirth"
              class="input-text container"
            />
          </div>
          <div>
            <div class="red-text alert alert-danger err-text">
              <span>
                Date of Birth is required.
              </span>
              <span>
                Enter a valid date of birth.
              </span>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="label-text">Social Security Number</div>
          <div class="read-only">
            <input
              placeholder="###-##-####"
              formControlName="socialSecurityNumber"
              class="input-text container"
              type="password"
            />
            <button class="password"></button>
          </div>
          <div>
            <div class="red-text alert alert-danger err-text">
              <span
                class="alert alert-danger help-text"
                >Social security number is required.</span
              >
              <span
                class="alert alert-danger"
              >
                Enter a valid social security number.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="direct-app-page-title address-info">Address Information</div>
      <div class="direct-app-form">
        <div class="field">
          <div class="label-text">Street Address</div>
          <div class="read-only">
            <input
              autocomplete="address-line1"
              formControlName="streetAddress"
              class="input-text container"
              type="text"
            />
          </div>
          <div>
            <div
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Street address is required.</span
              >
            </div>
            <div
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text">
                Address cannot be a P.O. Box.</span
              >
            </div>
          </div>
        </div>
        <div class="field">
          <div class="label-text">City</div>
          <div class="read-only">
            <input
              autocomplete="address-level2"
              formControlName="city"
              class="input-text container"
              type="text"
            />
          </div>
          <div>
            <div
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >City is required.</span
              >
            </div>
          </div>
        </div>
        <div class="field">
          <div class="label-text">State</div>
          <div class="read-only">
            <select
              autocomplete="address-level1"
              formControlName="state"
              class="input-text states container"
              type="text"
            >
              <option hidden [ngValue]="null" selected>Select a State</option>
              <option value="state">
                state
              </option>
            </select>
          </div>
          <div>
            <div
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >State is required.</span
              >
            </div>
          </div>
        </div>
        <div class="field">
          <div class="label-text">Zip Code</div>
          <div class="read-only">
            <input
              autocomplete="postal-code"
              maxlength="5"
              formControlName="zipCode"
              class="input-text container"
              type="text"
            />
          </div>
          <div>
            <div class="red-text" class="alert alert-danger err-text">
              <span
                class="alert alert-danger help-text"
                >Zip code is required.</span
              >
              <span
                class="alert alert-danger"
              >
                Enter a valid zip code.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="checkbox acknowledgement">
        <input
          formControlName="privacyPolicyAndConsent"
          #privacyPolicyAndeConsent
          id="privacyPolicyAndConsent"
          class="acknowledged"
          #acknowledgedConsent
          type="checkbox"
          name="privacyPolicyAndeConsent"
        />
        <label class="acknowledged-disclosures" for="privacyPolicyAndeConsent"
          >I agree to Mulligan Funding’s
          <a
            target="_blank"
            href="https://www.mulliganfunding.com/application-terms-conditions/"
            >Application Agreement</a
          >,
          <a
            target="_blank"
            href="https://www.mulliganfunding.com/privacy-policy/"
            >Privacy Policy</a
          >, and
          <a
            target="_blank"
            href="https://www.mulliganfunding.com/application-terms-conditions/#section5"
            >E-Sign Consent.</a
          ></label
        >
        <div></div>

        <div class="agreement alert alert-danger err-text">
          <div class="red-text">
            <span class="alert alert-danger help-text">
              You must agree to the above to continue.
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="btn-group">
      <div class="btn-container end">
        <button #submit class="btn-background">
          SUBMIT AUTHORIZATION
        </button>
      </div>
    </div>
  </div>
</div>
