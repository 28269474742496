import { Injectable } from '@angular/core'

import { Subject } from 'rxjs'

import * as INTF from '../../models/interfaces'
import { ModalType } from '../../enums'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  public readonly CHANGEPAYMENTDAY = ModalType.CHANGEPAYMENTDAY
  public readonly CONNECTION_ERROR = ModalType.CONNECTION_ERROR
  public readonly DISBURSEMENT = ModalType.DISBURSEMENT
  public readonly PAYMENT = ModalType.PAYMENT
  public readonly PLAID = ModalType.PLAID
  public readonly TIMEOUT = ModalType.TIMEOUT
  public readonly UCC = ModalType.UCC
  public readonly DELETE = ModalType.DELETE
  public readonly BEFORE_CONTINUE = ModalType.BEFORE_CONTINUE
  public readonly ACCOUNT_ALREADY_EXISTS = ModalType.ACCOUNT_ALREADY_EXISTS

  modalSource = new Subject<ModalType>()
  currentModal = this.modalSource.asObservable()

  modalIDSource = new Subject<number>()
  currentModalID = this.modalIDSource.asObservable()

  modalItemTypeSource = new Subject<INTF.DeleteItemType>()
  currentModalItemType = this.modalItemTypeSource.asObservable()

  showModal = new Subject<boolean>()
  showPlaidModal = new Subject<boolean>()
  currentShowModal = this.showModal.asObservable()
  currentShowPlaidModal = this.showPlaidModal.asObservable()

  constructor() {}

  updatedModalType(data: ModalType) {
    this.modalSource.next(data)
  }

  updatedModalState(val: boolean) {
    this.showModal.next(val)
  }

  updatedPlaidModalState(val: boolean) {
    this.showPlaidModal.next(val)
  }

  updatedItemTypeState(val: INTF.DeleteItemType) {
    this.modalItemTypeSource.next(val)
  }

  updatedModalIDState(val: number) {
    this.modalIDSource.next(val)
  }
}
