<div class="header-container">
    <div class="logo-container">
        <img class="header-logo cursor-pointer desktop-logo" src="assets/imgs/MF_Horizontal_RGB.png">
        <img class="header-logo cursor-pointer mobile-logo" src="assets/imgs/MF_HorizontalStacked_RGB.png">
    </div>

    <div class="phone-container fullname"><img class="phone" src="assets/imgs/phone-icon-orange.svg">
        <span>(855) 326-3564</span>
    </div>
    <div class="user">
        <div class="fullname" (click)="toggleSignOut()">{{fullname}}</div>
        <!-- <div class="allaccounts">All Accounts</div> -->
    </div>

    <div *ngIf="auth" [ngClass]="{ 'chevron-down': !showSignoutDropdown, 'chevron-up': showSignoutDropdown }"
        id="signout-dropdown-btn" (click)="toggleSignOut()"></div>

    <div class="mobile-menu" *ngIf="auth" id="mobile-signout-dropdown-btn" (click)="toggleMobileSignOut()"></div>

    <div id="signOut-overlay" *ngIf="showSignoutDropdown && auth" (click)="toggleSignOut()">

        <div id="show-signOut-dropdown" (click)="nothing($event)">
            <ul id="header-dropdown" class="header-dropdown">
                <!-- <span *ngFor="let account of dashboardData?.accounts">
                    <li><a [routerLink]="['doc-checkout/loan',account.loans[0].uuid]">{{account.nameOfBusiness}}</a>
                    </li>
                </span>
                <div class="line"></div>
                <li class=" "><a>Profile Settings</a></li>
                <li class=" "><a>Support</a></li> -->
                <li class="signout-link "><a id="signout" (click)="logout()">SIGN OUT</a></li>
            </ul>
        </div>
    </div>

    <div class="signout-mobile-modal" *ngIf="showSignoutMobileDropdown" (click)="close()">
        <div class="signout-modal-content" id="mobile-signOut-dropdown" (click)="nothing($event)">
            <div class="user-line">
                <div class="mobile-fullname">{{fullname}}</div>
                <button (click)="close()" class="close"></button>
            </div>

            <div class="mobile-signout"><a id="" (click)="logout()">SIGN OUT</a></div>

        </div>
    </div>
</div>