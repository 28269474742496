import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

import * as INTF from '../../../core/models/interfaces'
import { BannersStatesService } from '../../../core/services/banners/banners-states.service'
import { ValidatePassword } from '../../../shared/validators/password.validator'
import { hasNum } from '../../../shared/validators/hasNum.validator'
import { haveUpperLowerCase } from '../../../shared/validators/haveUpperLowerCase.validator'
import { AuthService } from '../../../core/services/auth/auth.service'
import { ErrorService } from '../../services/generic-error/error.service'
import { handleError } from '../../utils'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  resetForm: FormGroup
  resetFormSubscription: Subscription
  resetFormValueChangSubscription: Subscription
  error: INTF.BackendError | null
  token: string
  errorMessage: string
  backToResetLink = false
  showWarning = false
  clicked = false
  showError = false
  blurPasswordError = false
  focusPasswordErr = false

  @ViewChild('newPassword') newPassword: ElementRef
  @ViewChild('confirmPassword') confirmPassword: ElementRef

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bannersStatesService: BannersStatesService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token')

    this.initForm()

    this.valueChange()
  }

  initForm(): FormGroup {
    this.resetForm = this.formBuilder.group({
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(72),
          ValidatePassword,
          hasNum,
          haveUpperLowerCase,
        ],
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(72),
          ValidatePassword,
          hasNum,
          haveUpperLowerCase,
        ],
      ],
    })

    return this.resetForm
  }

  valueChange() {
    this.resetFormValueChangSubscription = this.resetForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.match()
      })
  }

  blurPassword() {
    this.blurPasswordError = true
    this.focusPasswordErr = false
  }

  focusPassword() {
    this.focusPasswordErr = true
    this.blurPasswordError = false
  }

  close() {
    this.showWarning = false
  }

  valueEmpty(control: AbstractControl) {
    return control.value.length === 0
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetForm.controls
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  match() {
    return (
      this.confirmPassword.nativeElement.value ===
      this.newPassword.nativeElement.value
    )
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.resetPassword()
    }
  }

  resetPassword() {
    this.errorMessage = '';
    this.backToResetLink = false
    this.showWarning = false

    if (this.resetForm.valid && this.match() && !this.clicked) {
      this.clicked = true

      const data = {
        password: this.f.newPassword.value,
        token: this.token,
      }

      this.resetFormSubscription = this.authService
        .resetPassword(data)
        .subscribe(
          () => {
            this.showError = false
            this.bannersStatesService.storeBannerState(
              this.bannersStatesService.ACCOUNT_UPDATED,
            )
            this.router.navigate([`auth/login`])
          },
          (err) => {
            
            console.log('reset-password error response: ', {err})

            // error, check for password used too recently
            let errorMessage = ''
            const errorCauses = err?.error?.payload?.errorCauses
            const errorSummary = Array.isArray(errorCauses) && errorCauses.length > 0 && errorCauses[0].errorSummary
            if (errorSummary) { errorMessage = errorSummary }
            
            if (!errorMessage) {
              // check for other message
              const newErrorMsg = err?.error?.new_error?.message
              if (newErrorMsg === 'Missing or expired request') {
                errorMessage = 'The reset password email link has expired'
                this.backToResetLink = true
              } else {
                errorMessage = newErrorMsg || 'Password Reset Failed'
                this.backToResetLink = true
              }
            }

            if (errorMessage) {
              this.showWarning = true;
              this.errorMessage = errorMessage
              this.showError = false
              this.clicked = false
              this.blurPasswordError = false
            }

          },
        )
    }
  }

  toggleNewPassword() {
    this.newPassword.nativeElement.type =
      this.newPassword.nativeElement.type === 'text' ? 'password' : 'text'
  }

  toggleConfirmPassword() {
    this.confirmPassword.nativeElement.type =
      this.confirmPassword.nativeElement.type === 'text' ? 'password' : 'text'
  }

  ngOnDestroy() {
    if (this.resetFormSubscription) {
      this.resetFormSubscription.unsubscribe()
      this.resetFormSubscription = null
    }

    if (this.resetFormValueChangSubscription) {
      this.resetFormValueChangSubscription.unsubscribe()
      this.resetFormValueChangSubscription = null
    }
  }
}
