import { Router } from '@angular/router'
import { OktaConfig } from '@okta/okta-angular/src/okta/models/okta.config'

export const OKTA_CLIENT_ID = '0oak3vziw7Ltl4hZD1d7'
export const OKTA_BASE_URL = 'https://mulliganfundingapp.oktapreview.com'
export const OKTA_LOGIN_REDIRECT_URI = `/auth/callback`
export const OKTA_LOGOUT_REDIRECT_URI = `/auth/login`

export const OKTA_ISSUER = `${OKTA_BASE_URL}/oauth2/aus2io5fy0v9YHDRw1d7`

export function onAuthRequired(oktaAuth, injector) {
  // Use injector to access any service available within your application
  const router = injector.get(Router)
  // Redirect the user to your custom login page
  router.navigate([`/auth/login`])
}

export const oktaConfig: OktaConfig = {
  // see: https://github.com/okta/okta-oidc-js/blob/master/packages/okta-angular/src/okta/models/okta.config.ts
  // default authorization server for OIDC
  onAuthRequired,
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + OKTA_LOGIN_REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin + OKTA_LOGOUT_REDIRECT_URI,
  pkce: true,
  restoreOriginalUri: undefined, // this allows external redirect,
  tokenManager: {
    storage: 'localStorage',
    autoRenew: false,
  },
  scopes: ['openid', 'profile', 'email'],
}
