<!-- inside of right-box class -->
<div class="step-content">
  <div class="direct-app-page-title">
    About {{ applicationInfo?.accountInfo?.companyName }}
  </div>
  <div class="direct-app-page-info">
    Let us know how we can reach your business.
  </div>

  <div class="direct-app-page-sub-title">Contact Information</div>
  <div class="direct-app-page-info info-mobile">
    Let us know how we can reach your business.
  </div>

  <div class="infoForm">
    <form
      *ngIf="contactInfoForm"
      [formGroup]="contactInfoForm"
      (keydown)="onSubmit($event)"
    >
      <div class="direct-app-form">
        <div class="input-text em">
          <div class="label-text">Business Phone Number</div>
          <div class="content-input">
            <input
              formControlName="businessPhone"
              type="text"
              name="phoneNumber"
              class="input-text container"
              placeholder="(###) ###-####"
              value="{{ newphoneNo }}"
              (keydown)="amountKeydown($event)"
              (keyup)="formatBusinessPhone($event)"
              maxlength="14"
            />
          </div>
          <div
            *ngIf="
              showError || f.businessPhone.dirty || f.businessPhone.touched
            "
          >
            <div
              *ngIf="f.businessPhone.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text">
                Business phone number is required.</span
              >
            </div>
            <div
              *ngIf="f.businessPhone.errors?.ValidatePhone"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Enter a valid business phone number.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Business Email</div>
          <div class="content-input">
            <input
              formControlName="businessEmail"
              #businessEmail
              class="input-text container"
              type="text"
            />
          </div>
          <div
            *ngIf="
              showError || f.businessEmail.dirty || f.businessEmail.touched
            "
          >
            <div
              *ngIf="f.businessEmail.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Business email is required.</span
              >
            </div>
          </div>

          <div
            *ngIf="
              f.businessEmail.errors?.pattern || f.businessEmail.errors?.email
            "
            class="red-text alert alert-danger err-text"
          >
            <span>Enter a valid business email.</span>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Business Website (Optional)</div>
          <div class="content-input">
            <input
              formControlName="businessWebsite"
              #businessWebsite
              class="input-text container"
              type="text"
            />
          </div>
          <div
            *ngIf="
              showError || f.businessWebsite.dirty || f.businessWebsite.touched
            "
          >
            <div
              *ngIf="f.businessWebsite.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text">
                Business website is required.</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="btn-group">
        <div class="goback-group tablet-back">
          <div class="goback" (click)="goBack()">
            <img src="assets/imgs/link-arrow-left-icon.svg" />
            <a class="goback" #back>Back </a>
          </div>
        </div>

        <div class="btn-container end" *ngIf="!disableEdit">
          <button (click)="submitContactInfo()" #submit class="btn-background">
            CONTINUE
          </button>
        </div>

        <div class="goback-group">
          <div class="gobackmobile" (click)="goBack()">
            <img src="assets/imgs/link-arrow-left-icon.svg" />
            <a class="gobackmobile" #back>Back </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
