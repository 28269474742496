import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core'
import { ModalType } from '../../../core/enums'
import { ModalService } from '../../../core/services/modal/modal.service'
import * as INTF from '../../../core/models/interfaces'
import { AuthService } from '../../../core/services/auth/auth.service'
import { LoanService } from '../../../core/services/loan/loan.service'
import { DirectAppService } from 'src/app/core/services/direct/direct-app.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  showBeforeContinue = false
  showChangePaymentDay = false
  showConnectionError = false
  showDisbursementMdl = false
  showEmailVerificationNeeded = false
  showPaymentMdl = false
  showTimeoutMdl = false
  showUCCMdl = false
  showDelete = false
  showAccountAlreadyExistsMdl = false
  emitTokenRenewed: boolean
  calcNums: INTF.Loan
  loanUuid: string
  modalID: number
  packetId: string
  directAppPacketIdSubscription: Subscription

  @Input() modalMsg: string
  @Input() modalItemType: INTF.DeleteItemType
  @ViewChild('modalcontent') modalcontent: ElementRef

  constructor(
    private directAppService: DirectAppService,
    public authService: AuthService,
    private loanService: LoanService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.loanService.currentLoanData.subscribe((data) => {
      this.calcNums = data
    })
    this.loanService.currentLoanUuid.subscribe((data) => {
      this.loanUuid = data
    })
    this.authService.emitTokenRenewedObs.subscribe((data: boolean) => {
      this.emitTokenRenewed = data
    })

    this.checkModalType(this.modalMsg)
  }

  checkModalType(type) {
    switch (type) {
      case ModalType.BEFORE_CONTINUE:
        this.showBeforeContinue = true
        break
      case ModalType.CHANGEPAYMENTDAY:
        this.showChangePaymentDay = true
        break
      case ModalType.CONNECTION_ERROR:
        this.showConnectionError = true
        break
      case ModalType.DELETE:
        this.showDelete = true
        break
      case ModalType.DISBURSEMENT:
        this.showDisbursementMdl = true
        break
      case ModalType.EMAIL_VERIFICATION_NEEDED:
        this.showEmailVerificationNeeded = true
        break
      case ModalType.PAYMENT:
        this.showPaymentMdl = true
        break
      case ModalType.TIMEOUT:
        this.showTimeoutMdl = true
        break
      case ModalType.UCC:
        this.showUCCMdl = true
        break
      case ModalType.ACCOUNT_ALREADY_EXISTS:
        this.showAccountAlreadyExistsMdl = true
        break
      default:
        console.warn(`Unknown modal type: ${type}`)
        break
    }
  }

  close() {
    if (this.modalMsg !== this.modalService.TIMEOUT) {
      this.modalService.updatedModalState(false)
    }
  }

  nothing(event) {
    event.stopPropagation()
    // event.preventDefault();
  }
}
