import { Component, Input, OnInit } from '@angular/core'

import * as INTF from '../../../../core/models/interfaces'
import { ModalService } from '../../../../core/services/modal/modal.service'

@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.scss'],
})
export class DisbursementComponent implements OnInit {
  @Input() calcNums: INTF.Loan
  @Input() userClaims: any
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  close() {
    this.modalService.updatedModalState(false)
  }
}
