import { Component, OnInit, OnDestroy } from '@angular/core'
import { filter } from 'rxjs/operators'
import { Router, NavigationEnd } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'
import { Subscription } from 'rxjs'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { tap } from 'rxjs/operators'

import { ModalService } from './core/services/modal/modal.service'
import { AuthService } from './core/services/auth/auth.service'
import { environment } from './../environments/environment'
import * as fakeData from '../assets/fixtures/calcNums.json'
import * as constant from './shared/constants'
import { DirectAppStepService } from './core/services/direct-app-steps/direct-app-steps.service'
import * as INTF from './core/models/interfaces'
import {
  checkIsTimePassed,
  DirectAppUrl,
  getAccessToken,
  invalidateAccessToken,
  MFAccessToken,
} from './core'
import {} from './core/models'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'customer-portal-frontend'
  useServer = false
  public isAuthenticated = false
  accessToken: MFAccessToken | null
  currentAccessTokenSubscription: Subscription
  currentTokenTimeLeftSubscription: Subscription
  currentModalSubscription: Subscription
  currentModalItemTypeSubscription: Subscription
  secondCounterSub: Subscription
  modalMsg: string
  showModal: boolean
  modalItemType: INTF.DeleteItemType
  url: string
  isNuulaUserSubscription: Subscription
  isNuulaUser = false

  constructor(
    private directAppStepService: DirectAppStepService,
    private authService: AuthService,
    public oktaAuth: OktaAuthService,
    private modalService: ModalService,
    public router: Router,
    private gtmService: GoogleTagManagerService,
  ) {
    this.useServer = environment.useServer
    this.isNuulaUserSubscription =
      this.authService.currentIsNuulaUser.subscribe((val) => {
        this.isNuulaUser = val
      })
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe((isAuthenticated: boolean) => {
      this.isAuthenticated = isAuthenticated
      if (!isAuthenticated) {
        // dont show any modal if unanthenitcated
        this.modalService.updatedModalType(null)
        this.modalService.updatedModalState(false)
      }
    })
    this.currentAccessTokenSubscription =
      this.authService.currentMfAccessToken.subscribe((val) => {
        this.accessToken = val
      })

    const token = getAccessToken()
    if (token) {
      this.authService.loadToken(token)
    }
  }

  doesUrlNeedAuth() {
    // Email verification page and Owner credit auth pages don't need auth
    return (
      this.url.indexOf(DirectAppUrl.EMAIL_VERIFICATION) < 0 &&
      this.url.indexOf(DirectAppUrl.OWNER_CREDIT_AUTH) < 0 &&
      this.url.indexOf(DirectAppUrl.CREATE_FROM_LEAD) < 0 &&
      this.url.indexOf(DirectAppUrl.CREDIT_AUTH_SUCCESS) < 0 &&
      this.url.indexOf(DirectAppUrl.CREATE_ACCOUNT) < 0
    )
  }

  async afterUrlLoaded() {
    if (this.doesUrlNeedAuth()) {
      if (this.useServer) {
        if (
          this.accessToken &&
          this.accessToken.isValid &&
          checkIsTimePassed(this.accessToken.exp)
        ) {
          console.log('AccessToken is expired')
          invalidateAccessToken()
          this.modalService.updatedModalState(false)
        }
      } else {
        this.isAuthenticated = fakeData.auth.isAuthenticated
      }

      this.currentTokenTimeLeftSubscription =
        this.authService.currentTokenTimeLeft
          .pipe(
            tap((tokenTimeLeft) => {
              // Uncomment this line to debut the token timer
              // console.log('tokenTimeLeft: ', tokenTimeLeft)
            }),

            filter(
              (tokenTimeLeft) =>
                tokenTimeLeft &&
                tokenTimeLeft > 0 &&
                tokenTimeLeft < constant.constants.TIMEOUT_CAP,
            ),
          )
          .subscribe((e) => {
            this.modalService.updatedModalState(true)
            this.modalService.updatedModalType(this.modalService.TIMEOUT)
          })

      this.currentModalSubscription = this.modalService.currentModal.subscribe(
        (data) => {
          this.modalMsg = data
        },
      )

      this.currentModalSubscription =
        this.modalService.currentShowModal.subscribe((val) => {
          this.showModal = val
        })

      this.currentModalItemTypeSubscription =
        this.modalService.currentModalItemType.subscribe((val) => {
          this.modalItemType = val
        })
    } else {
      this.modalService.updatedModalState(false)
    }
  }

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: event.url,
        }
        this.url = event.url
        this.gtmService.pushTag(gtmTag)
        this.directAppStepService.directAppRouteToStep(event.url)
        this.afterUrlLoaded()
      }
    })
  }

  ngOnDestroy() {
    this.authService.clearInterval()

    if (this.currentTokenTimeLeftSubscription) {
      this.currentTokenTimeLeftSubscription.unsubscribe()
    }

    if (this.isNuulaUserSubscription) {
      this.isNuulaUserSubscription.unsubscribe()
    }

    if (this.secondCounterSub) {
      this.secondCounterSub.unsubscribe()
    }
  }
}
