import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'

import { Subscription } from 'rxjs'
import { OktaAuthService } from '@okta/okta-angular'
import * as OktaSignIn from '@okta/okta-signin-widget'

import { environment } from '../../../../environments/environment'
import { BannersStatesService } from '../../../core/services/banners/banners-states.service'
import Utils from '../../../shared/utils/utils'
import { OKTA_BASE_URL, oktaConfig } from '../../../core/models/okta_config'
import * as fakeData from '../../../../assets/fixtures/calcNums.json'
import * as INTF from '../../../core/models/interfaces'
import { getAccessToken, invalidateAccessToken } from '../../utils'

const DEFAULT_ORIGINAL_URI = window.location.origin
@Component({
  selector: 'app-secure',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public isAuthenticated = false
  loanUuid: string

  bannerState: INTF.BannerStateType
  error: string
  showInfo: boolean
  showBeenVerified = false
  showAccountUpdated = false
  showEmailResent = false
  showAccountCreated = false
  showExistingAccount = false
  showSessionExpired = false
  showRenewTokenFailed = false
  showAlreadyVerified = false
  useServer: boolean
  currentYear: number
  bannerStateSub: Subscription
  widget: any

  constructor(
    public oktaAuth: OktaAuthService,
    private router: Router,
    public bannersStatesService: BannersStatesService,
  ) {
    this.useServer = environment.useServer

    // https://github.com/okta/samples-js-angular/blob/master/
    // ...custom-login/src/app/login/login.component.ts#L26
    this.widget = new OktaSignIn({
      // username: 'azhang@mulliganfunding.com', // you can pre-fill the username...
      brandName: 'Mulligan Funding',
      //  logo: '/assets/imgs/MF_HorizontalStacked_RGB_whitetext.png',
      language: 'en',
      logo: 'assets/imgs/MF_HorizontalStacked_RGB.png',
      baseUrl: OKTA_BASE_URL,
      clientId: oktaConfig.clientId,
      redirectUri: oktaConfig.redirectUri,
      pkce: true,
      authParams: {
        pkce: oktaConfig.pkce,
        issuer: oktaConfig.issuer,
        scopes: oktaConfig.scopes,
        responseMode: 'query',
        responseType: ['id_token', 'token'],
      },
      // Changes to widget functionality
      features: {
        showPasswordToggleOnSignInPage: true,
        registration: false, // Enable self-service registration flow
        rememberMe: false, // Setting to false will remove the checkbox to save username
        // multiOptionalFactorEnroll: true,  // Allow users to enroll in multiple optional factors before finishing the authentication flow.
        // selfServiceUnlock: false,          // Will enable unlock in addition to forgotten password
        // smsRecovery: true,                // Enable SMS-based account recovery
        // callRecovery: true,               // Enable voice call-based account recovery
      },
      i18n: {
        // Overrides default text when using English.
        // Override other languages by adding additional sections.
        en: {
          'primaryauth.title': 'Sign In', // Changes the sign in text
          'primaryauth.submit': 'SIGN IN', // Changes the sign in button

          // error
          'error.password.required': 'Password is required.',
          'error.username.required': 'Email is required.',
          'errors.E0000004':
            'The email or password you entered does not match our records. Please try again.',
          'error.expired.session':
            'Your session has expired due to inactivity. Please sign in below.',
          'error.auth.lockedOut':
            'For your security, your account has been locked. Please contact us at (###) ###-####.',
          'error.E0000119':
            'For your security, your account has been locked. Please contact us at (###) ###-####.',

          // More e.g. [primaryauth.username.placeholder,  primaryauth.password.placeholder, needhelp, etc.].
          // Full list here: https://github.com/okta/okta-signin-widget/blob/master/packages/%40okta/i18n/src/properties/login.properties
        },
      },
      helpLinks: {
        // help: 'https://www.mulliganfunding.com/contact',
        forgotPassword: `${window.location.origin}/auth/forgot-password`,
        // custom: [
        // {
        //   text: 'Who is Mulligan Funding?',
        //   href: 'https://www.mulliganfunding.com/about-us/',
        //   target: '_blank'
        // },
        // {
        //   text: 'Homepage',
        //   href: 'https://www.mulliganfunding.com/',
        //   target: '_blank'
        // }
        // ]
      },
    })
    // Show the widget when prompted, otherwise remove it from the DOM.
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        switch (event.url) {
          case '/auth/login':
            break
          case '/auth/callback':
            break
          default:
            if (this.widget) {
              this.widget.remove()
            }
            break
        }
      }
    })
  }

  async ngOnInit() {
    invalidateAccessToken()
    localStorage.removeItem('okta-token-storage')
    localStorage.removeItem('okta-cache-storage')

    // https://github.com/okta/samples-js-angular/blob/master/custom-login/src/app/login/login.component.ts
    this.widget
      .showSignInToGetTokens({
        el: '#okta-signin-container',
        scopes: oktaConfig.scopes,
      })
      .then((tokens) => {
        this.oktaAuth.setOriginalUri('/ui/dashboard/')

        // https://github.com/okta/okta-auth-js/blob/33a5fdaebf218082d168cd539eb0c0fadbac529b/lib/OktaAuth.ts#L611
        this.oktaAuth.handleLoginRedirect(tokens)

        if (this.widget) {
          // Remove the widget
          this.widget.remove()
        }
      })
      .catch((err) => {
        // Typically due to misconfiguration
        throw err
      })

    // this order matters now because we are using Subject
    this.bannerStateSub =
      this.bannersStatesService.currentBannerState.subscribe((data) => {
        this.bannerDataUpdated(data)
      })

    if (this.useServer) {
      this.isAuthenticated = await this.oktaAuth.isAuthenticated()
    } else {
      this.isAuthenticated = fakeData.auth.isAuthenticated
    }

    this.bannersStatesService.checkIfStoredBannerState(true)
    this.calCurrentYear()
  }

  calCurrentYear() {
    const date = new Date()
    this.currentYear = date.getFullYear()
  }

  bannerDataUpdated(data) {
    switch (data) {
      case this.bannersStatesService.ACCOUNT_CREATED:
        this.showAccountCreated = true
        break
      case this.bannersStatesService.ACCOUNT_UPDATED:
        this.showAccountUpdated = true
        break
      case this.bannersStatesService.BEEN_VERIFIED:
        this.showBeenVerified = true
        break
      case this.bannersStatesService.EMAIL_RESENT:
        this.showEmailResent = true
        break
      case this.bannersStatesService.EXISTING_ACCOUNT:
        this.showExistingAccount = true
        break
      case this.bannersStatesService.ALREADY_VERIFIED:
        this.showAlreadyVerified = true
        break
      case this.bannersStatesService.RENEW_TOKEN_FAILED:
        this.showRenewTokenFailed = true
        break
      case this.bannersStatesService.SESSION_EXP:
        this.showSessionExpired = true
        break
    }
  }

  readStory() {
    Utils.ReadStory()
  }

  applyALoan() {
    Utils.RequestaQuote()
  }

  close() {
    this.showAccountCreated = false
    this.showAccountUpdated = false
    this.showBeenVerified = false
    this.showEmailResent = false
    this.showExistingAccount = false
  }

  forgotPW() {
    this.router.navigate([`auth/loan/${this.loanUuid}/forgot-password`])
  }

  goToMagicLinkLogin() {
    window.location.replace('/ui/dashboard/magic-link-login')
  }
  ngOnDestroy() {
    if (this.bannerStateSub && this.bannerStateSub.unsubscribe) {
      this.bannerStateSub.unsubscribe()
    }
    // if (!!this.widget) {
    //   this.widget.remove();
    // }
    console.log('this.widget', this.widget)
  }
}
