import { Component, OnInit, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'
import { OktaAuthService } from '@okta/okta-angular'

import { AuthService } from '../../core/services/auth/auth.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import * as INTF from '../../core/models/interfaces'
import { LoanService } from '../../core/services/loan/loan.service'

@Component({
  selector: 'app-retry-verification-questions',
  templateUrl: './retry-verification-questions.component.html',
  styleUrls: ['./retry-verification-questions.component.scss'],
})
export class RetryVerificationQuestionsComponent implements OnInit {
  loanUuid: string
  getloanSubscription: Subscription
  getQuestionSubscription: Subscription
  step: any

  constructor(
    private route: ActivatedRoute,
    private loanService: LoanService,
    private router: Router,
    private errorService: ErrorService,
    public oktaAuth: OktaAuthService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.loanService.loanUuidSource.next(this.loanUuid)
  }

  goRetryID() {
    this.router.navigate([`doc-checkout/loan/${this.loanUuid}`])
  }
}
