import { Injectable } from '@angular/core'

import { BehaviorSubject } from 'rxjs'
import { ReviewSubmitOpenSections } from '../../models'

@Injectable({
  providedIn: 'root',
})
export class DirectAppReviewSubmitService {
  constructor() {}

  comingFromReviewSubmitSource = new BehaviorSubject<boolean>(false)
  currentComingFromReviewSubmit =
    this.comingFromReviewSubmitSource.asObservable()

  openSectionsSource = new BehaviorSubject<ReviewSubmitOpenSections>({
    businessInfo: false,
    ownerInfo: false,
    debtInfo: false,
    docInfo: false,
  })

  currentOpenSections = this.openSectionsSource.asObservable()

  setComingFromReviewSubmit(value: boolean) {
    this.comingFromReviewSubmitSource.next(value)
  }
  toggleBusinessInfo$() {
    this.openSectionsSource.next({
      ...this.openSectionsSource.value,
      businessInfo: !this.openSectionsSource.value.businessInfo,
    })
  }
  toggleOwnerInfo$() {
    this.openSectionsSource.next({
      ...this.openSectionsSource.value,
      ownerInfo: !this.openSectionsSource.value.ownerInfo,
    })
  }
  toggleDebtInfo$() {
    this.openSectionsSource.next({
      ...this.openSectionsSource.value,
      debtInfo: !this.openSectionsSource.value.debtInfo,
    })
  }
  toggleDocInfo$() {
    this.openSectionsSource.next({
      ...this.openSectionsSource.value,
      docInfo: !this.openSectionsSource.value.docInfo,
    })
  }
}
