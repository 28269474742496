import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'

import { distinctUntilChanged } from 'rxjs/operators'
import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import {
  DirectAppStep,
  getDirectAppUrlFromStep,
  handleError,
  US_STATES,
} from '../../core'
import { InValidateAddress } from '../../shared/validators/address.validator'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import Utils from '../../shared/utils/utils'
import { DirectAppReviewSubmitService } from 'src/app/core/services/direct-app-review-submit/direct-app-review-submit.service'

@Component({
  selector: 'app-business-address-info',
  templateUrl: './business-address-info.component.html',
  styleUrls: ['./business-address-info.component.scss'],
})
export class BusinessAddressInfoComponent implements OnInit, OnDestroy {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  disableEdit = false

  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    const newAppInfo = value && !this.applicationInfo
    this.applicationInfo = value
    if (newAppInfo) {
      this.physicalAddressInfoFormInit()
      this.physicalAddressInfoFormValueChange()

      setTimeout(() => {
        if (this.checkShowMailingAddress()) {
          this.mailingAddress.nativeElement.checked = false
        } else {
          this.mailingAddress.nativeElement.checked = true
          this.mailingFormInit()
        }
      }, 0) // have to wait for the form
    }
  }

  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
  }

  physicalAddressInfo: INTF.PhysicalAddressInfo
  mailingAddressInfo: INTF.MailingAddressInfo
  mailingAddressInfoForm: FormGroup
  physicalAddressInfoForm: FormGroup

  getDirectAppStatusSubscription: Subscription
  mailingAddressInfoFormValueChangSubscription: Subscription
  addressInfoFormSubscription: Subscription
  physicalAddressInfoFormValueChangSubscription: Subscription

  clicked: boolean
  comingFromReviewSubmit = false
  comingFromReviewSumbitSubscription: Subscription
  directAppStatus: DirectAppStep
  showError = false
  states = Object.keys(US_STATES)

  @ViewChild('mailingAddress') mailingAddress: ElementRef
  cleanedPhysicalZip = ''
  cleanedMailingZip = ''
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private directAppService: DirectAppService,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private errorService: ErrorService,
    private directAppStepService: DirectAppStepService,
  ) {}

  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/address-info`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })
    setTimeout(() => {
      if (this.mailingAddress) {
        if (this.checkShowMailingAddress()) {
          this.mailingAddress.nativeElement.checked = false
        } else {
          this.mailingAddress.nativeElement.checked = true
          this.mailingFormInit()
        }
      }
    }, 0)
    this.comingFromReviewSumbitSubscription =
      this.directAppReviewSubmitService.currentComingFromReviewSubmit.subscribe(
        (val) => {
          this.comingFromReviewSubmit = val
        },
      )
  }

  checkShowMailingAddress() {
    if (this.applicationInfo?.addressInfo?.mailingAddressInfo) {
      if (
        this.applicationInfo.addressInfo?.mailingAddressInfo.mailingCity ===
          this.applicationInfo.addressInfo?.physicalAddressInfo.city &&
        this.applicationInfo.addressInfo?.mailingAddressInfo.mailingState ===
          this.applicationInfo.addressInfo?.physicalAddressInfo.state &&
        this.applicationInfo.addressInfo?.mailingAddressInfo.mailingStreet ===
          this.applicationInfo.addressInfo?.physicalAddressInfo.street &&
        this.applicationInfo.addressInfo?.mailingAddressInfo.mailingZip ===
          this.applicationInfo.addressInfo?.physicalAddressInfo.zip
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  }

  toggleMailingAddress() {
    if (this.mailingAddress.nativeElement.checked) {
      this.mailingFormInit()
    } else {
      this.mailingAddressInfoForm = null
    }
    this.showError = false
  }

  physicalAddressInfoFormInit() {
    this.physicalAddressInfoForm = this.formBuilder.group({
      street: [
        this.applicationInfo?.addressInfo?.physicalAddressInfo?.street,
        {
          validators: [Validators.required, InValidateAddress],
          updateOn: 'blur',
        },
      ],
      city: [
        this.applicationInfo?.addressInfo?.physicalAddressInfo?.city,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      state: [
        this.applicationInfo?.addressInfo?.physicalAddressInfo?.state,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      zip: [
        this.applicationInfo?.addressInfo?.physicalAddressInfo?.zip,
        {
          validators: [
            Validators.required,
            Validators.maxLength(5),
            Validators.minLength(5),
          ],
          updateOn: 'blur',
        },
      ],
    })

    this.clicked = false
  }

  mailingFormInit() {
    const mailingStreet = this.checkShowMailingAddress()
      ? ''
      : this.applicationInfo?.addressInfo?.mailingAddressInfo?.mailingStreet
    const mailingCity = this.checkShowMailingAddress()
      ? ''
      : this.applicationInfo?.addressInfo?.mailingAddressInfo?.mailingCity
    const mailingState = this.checkShowMailingAddress()
      ? ''
      : this.applicationInfo?.addressInfo?.mailingAddressInfo?.mailingState
    const mailingZip = this.checkShowMailingAddress()
      ? ''
      : this.applicationInfo?.addressInfo?.mailingAddressInfo?.mailingZip

    this.mailingAddressInfoForm = this.formBuilder.group({
      mailingStreet: [
        mailingStreet,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      mailingCity: [
        mailingCity,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      mailingState: [
        mailingState,
        {
          validators: [Validators.required],
          updateOn: 'blur',
        },
      ],
      mailingZip: [
        mailingZip,
        {
          validators: [
            Validators.required,
            Validators.maxLength(5),
            Validators.minLength(5),
          ],
          updateOn: 'blur',
        },
      ],
    })
    this.showError = false
    this.mailingAddressInfoFormValueChange()
  }

  mailingAddressInfoFormValueChange() {
    this.mailingAddressInfoFormValueChangSubscription =
      this.mailingAddressInfoForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(this.mailingFormValuesChanged.bind(this))
  }

  physicalAddressInfoFormValueChange() {
    this.physicalAddressInfoFormValueChangSubscription =
      this.physicalAddressInfoForm.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(this.physicalAddressFormValuesChanged.bind(this))
  }

  // convenience getter for easy access to form fields
  get pf() {
    return this.physicalAddressInfoForm.controls
  }

  get mf() {
    return this.mailingAddressInfoForm.controls
  }

  mailingFormValuesChanged(value) {
    if (value.mailingZip) {
      this.cleanedMailingZip = Utils.formatZipcode(value.mailingZip)

      //  important to have the emitEvent set to false
      this.mailingAddressInfoForm.patchValue(
        {
          mailingZip: this.cleanedMailingZip,
        },
        { emitEvent: false },
      )
    }
  }

  physicalAddressFormValuesChanged(value) {
    if (value.zip) {
      this.cleanedPhysicalZip = Utils.formatZipcode(value.zip)

      //  important to have the emitEvent set to false
      this.physicalAddressInfoForm.patchValue(
        {
          zip: this.cleanedPhysicalZip,
        },
        { emitEvent: false },
      )
    }
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.submitAddressInfo()
    }
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  goBack() {
    this.directAppStepService.updatedDirectAppStep(
      DirectAppStep.BUSINESS_CONTACT_INFO,
    )
  }

  submitAddressInfo() {
    this.showError = true

    if (this.physicalAddressInfoForm && this.physicalAddressInfoForm.valid) {
      this.physicalAddressInfo = {
        ...this.physicalAddressInfoForm.value,
      }
      const nextStep = this.comingFromReviewSubmit
        ? DirectAppStep.REVIEW_SUBMIT
        : DirectAppStep.OWNER_INFO

      const data = {
        packetId: this.packetId,
        addressInfo: {
          physicalAddressInfo: this.physicalAddressInfo,
        },
        nextStep,
      }

      this.directAppReviewSubmitService.setComingFromReviewSubmit(false)
      if (this.mailingAddressInfoForm && this.mailingAddressInfoForm.valid) {
        this.mailingAddressInfo = {
          ...this.mailingAddressInfoForm.value,
        }
        data.addressInfo['mailingAddressInfo'] = this.mailingAddressInfo
      }

      if (
        (this.mailingAddressInfoForm && this.mailingAddressInfoForm.valid) ||
        !this.mailingAddressInfoForm
      ) {
        this.addressInfoFormSubscription = this.directAppService
          .submitAddressinfo$(data)
          .subscribe(
            (res) => {
              this.clicked = false
              if (nextStep === DirectAppStep.REVIEW_SUBMIT) {
                window.location.replace(`/ui/direct-app/review-submit`)
              } else {
                window.location.replace(`/ui/direct-app/owner-info`)
              }
            },
            (err) => {
              handleError(err, this.errorService, this.router)
            },
          )
      }
    }
  }

  ngOnDestroy() {
    if (this.comingFromReviewSumbitSubscription) {
      this.comingFromReviewSumbitSubscription.unsubscribe()
    }

    if (this.physicalAddressInfoFormValueChangSubscription) {
      this.physicalAddressInfoFormValueChangSubscription.unsubscribe()
    }

    if (this.getDirectAppStatusSubscription) {
      this.getDirectAppStatusSubscription.unsubscribe()
    }

    if (this.addressInfoFormSubscription) {
      this.addressInfoFormSubscription.unsubscribe()
    }

    if (this.mailingAddressInfoFormValueChangSubscription) {
      this.mailingAddressInfoFormValueChangSubscription.unsubscribe()
    }
  }
}
