<div class="nuula-tile-container">
  <div
    [ngClass]="{
      'tile-container': true,
      'full-screen': isSpinnerVisible() || tokenError
    }"
  >
    <div class="logo-container title-logo-with-line">
      <div>
        <img class="main-logo" src="assets/imgs/mf-main-logo.svg" />
      </div>
    </div>
    <div class="spinner-wrapper" *ngIf="isSpinnerVisible()">
      <img class="spinner-icon" src="assets/imgs/uploading-icon.svg" />
    </div>
    <div class="error-wrapper" *ngIf="tokenError">
      <img
        class="static-icon"
        src="assets/imgs/error-generic-illustration.svg"
      />
    </div>
    <div
      class="tile-text-container"
      *ngIf="directAppPacketData && !tokenError && !isSpinnerVisible()"
    >
      <div class="details">Loan Application Details</div>

      <div class="details-rows">
        <div>
          <div class="details-row-title">Company Name</div>
          <div class="details-row-value">
            {{ directAppPacketData?.accountInfo?.companyName }}
          </div>
          <div class="details-row-line"></div>
          <div>
            <div class="details-row-title">Time in Business</div>
            <div class="details-row-value">
              {{ directAppPacketData?.accountInfo?.timeInBusiness }}
            </div>
            <div class="details-row-line"></div>

            <div>
              <div class="details-row-title">Average Monthly Sales</div>
              <div class="details-row-value">
                ${{ formatMonthlyAverageSales() }}
              </div>

              <div class="details-row-line"></div>
            </div>
            <div>
              <div class="details-row-title">Desired Loan Amount</div>
              <div class="details-row-value">
                ${{ formatDesiredLoanAmount() }}
              </div>

              <div class="details-row-line"></div>
            </div>
            <div>
              <div class="details-row-title">Loan Purpose</div>
              <div class="details-row-value">
                {{ directAppPacketData?.accountInfo?.loanPurpose }}
              </div>

              <div class="details-row-line"></div>
            </div>

            <div>
              <div class="details-row-title">Application Status</div>
              <div class="details-row-value">{{ directAppStage }}</div>

              <div class="details-row-line"></div>
            </div>
            <div class="reapply-rectangle" *ngIf="isReapplyVisible()">
              <div><img src="assets/imgs/icon-Alert-bell.svg" /></div>
              <div>
                You will be eligible to reapply for a new loan after 30 days
                from your last submission date.
              </div>
            </div>
            <div class="details-row-line" *ngIf="isReapplyVisible()"></div>
            <div>
              <a href="tel:{{ NuulalineNo }}">
                <button class="nuula-btn">Call loan representative</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
