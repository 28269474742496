import { MFAccessToken } from '../models'
import { checkIsTimePassed } from './check-is-time-passed'

export const getTokenObjectFromValue: (
  token: string,
) => MFAccessToken | null = (token) => {
  const parts = token.split('.')
  if (parts.length !== 3) {
    console.warn('getTokenObjectFrom - malformed token')
    return null
  }
  try {
    const body = JSON.parse(window.atob(parts[1]))
    const exp = body['exp']
    return {
      email: body['email'],
      exp,
      externalIdList: body['external_ids'] ? body['external_ids'] : [],
      iat: body['iat'],
      id: body['jti'],
      isValid: !checkIsTimePassed(exp),
      issuer: body['iss'],
      scope: body['scope'],
      userId: body['sub'],
      value: token,
    } as MFAccessToken
  } catch (error) {
    console.warn('getTokenObjectFromValue - error parsing token body')
    return null
  }
}
