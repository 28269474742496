<div class="content-container">
  <div class="auth-page-container">
    <div
      [ngClass]="{
        'auth-page-wrap': showBizVerificationForm === 1,
        'signup-page-wrap': showBizVerificationForm === 2
      }"
    >
      <app-logo></app-logo>
      <div *ngIf="showBizVerificationForm === 0">
        <app-loading-spinner></app-loading-spinner>
      </div>

      <div [hidden]="showBizVerificationForm !== 1">
        <form [formGroup]="bizVerificationForm" (keydown)="onSubmit($event)">
          <div id="biz-rec" class="rectangle">
            <div class="auth-title text-align-center">
              Business Verification
            </div>
            <div class="auth-info text-align-center margin-auto">
              For your business’ security, please verify the information below
              to continue.
            </div>

            <div class="creds-wrap">
              <div class="err-info-container expired" *ngIf="showWarning">
                <div class="expired-icon"></div>
                <div class="warning-info fr">
                  {{ errorMessage }}
                </div>
                <button (click)="close()" class="close"></button>
              </div>
              <div class="creds">
                <div class="input-text-disabled bv">
                  <div class="label-text">Business Tax ID</div>
                  <input
                    inputmode="numeric"
                    placeholder="## - ########"
                    formControlName="taxId"
                    value="{{ newtaxId }}"
                    maxlength="9"
                    #taxId
                    id="taxId"
                    type="text"
                    class="container input-text"
                  />

                  <div
                    *ngIf="
                      showError ||
                      (f.taxId.invalid && (f.taxId.dirty || f.taxId.touched))
                    "
                    class="alert alert-danger"
                  >
                    <span
                      class="alert alert-danger"
                      *ngIf="f.taxId.errors?.required"
                    >
                      Business tax ID is required.
                    </span>
                    <span
                      class="alert alert-danger"
                      *ngIf="
                        f.taxId.errors?.minlength ||
                        f.taxId.errors?.maxlength ||
                        f.taxId.errors?.pattern
                      "
                    >
                      Enter a valid business tax ID.
                    </span>
                  </div>
                </div>

                <div class="input-text-default bv">
                  <div class="label-text">Owner's Date of Birth</div>
                  <input
                    type="text"
                    inputmode="numeric"
                    formControlName="dob"
                    #dob
                    id="dob"
                    class="container input-text"
                    [mask]="dobMask"
                    placeholder="mm/dd/yyyy"
                  />
                  <div
                    *ngIf="
                      showError ||
                      (f.dob.invalid && (f.dob.dirty || f.dob.touched))
                    "
                    class="alert alert-danger"
                  >
                    <span
                      class="alert alert-danger"
                      *ngIf="f.dob.errors?.required"
                    >
                      Owner's date of birth is required.
                    </span>
                    <span
                      class="alert alert-danger"
                      *ngIf="f.dob.errors?.InValidateDOB"
                    >
                      Enter a valid date of birth.
                    </span>
                  </div>
                </div>

                <div class="input-text-default bv">
                  <div class="label-text">Last 4 of Social Security Number</div>

                  <input
                    inputmode="numeric"
                    (blur)="validateSsn()"
                    placeholder="####"
                    name="ssn"
                    maxlength="4"
                    type="text"
                    formControlName="ssn"
                    #ssn
                    id="ssn"
                    class="container input-text"
                  />
                  <div *ngIf="showError || showSsnError">
                    <div
                      *ngIf="
                        showError ||
                        (f.ssn.invalid && (f.ssn.dirty || f.ssn.touched))
                      "
                      class="alert alert-danger"
                    >
                      <span
                        id="ssn-warning"
                        class="alert alert-danger"
                        *ngIf="f.ssn.errors"
                      >
                        Last 4 digits of social security number are required.
                      </span>
                    </div>
                  </div>
                </div>

                <div class="btn-container">
                  <button
                    (click)="onSubmitVerify()"
                    #submit
                    class="btn-background"
                  >
                    VERIFY INFORMATION
                  </button>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="showBizVerificationForm === 2">
        <app-signup
          [bizVerifyFormInputs]="data"
          [userEmail]="userEmail"
          [loanStatus]="loanStatus"
        ></app-signup>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
