<!-- inside of right-box class -->
<div class="step-content">
  <div class="direct-app-page-title">Additional Information</div>
  <div class="direct-app-page-info">
    Complete any additional information that may apply to your business so we
    can identify what loan options you are most likely to qualify for.
  </div>

  <div class="direct-app-page-sub-title">Outstanding Debts</div>
  <div class="direct-app-page-info lender">
    Add any outstanding loans, advances, or lines of credit with daily or weekly
    payments.
  </div>

  <div class="infoForm">
    <form
      [formGroup]="debtsForm"
      *ngIf="debtsForm"
      (keydown)="onSubmit($event)"
    >
      <div formArrayName="lenders">
        <div
          class="lender-row"
          *ngFor="let item of lenders.controls; let i = index"
        >
          <div
            class="banners"
            *ngIf="collapseLender.length !== 0 && collapseLender[i] === true"
          >
            <div class="item-banner">
              <div class="item-icon">
                <img src="assets/imgs/lender-illustration.svg" />
              </div>
              <div class="item-header">
                LENDER {{ i + 1 }}<br />
                <span class="item-banner-detail"
                  >{{ item['controls'].nameOfLender.value }} -
                  {{ formattedOriginal[i] | currency }}</span
                >
              </div>

              <div
                class="item-delete"
                *ngIf="!disableEdit"
                (click)="showDelete(i)"
              >
                <span
                  ><img
                    class="add-icon"
                    src="/assets/imgs/red-trashcan-icon.svg"
                /></span>
                <span>Delete</span>
              </div>

              <div
                class="item-edit"
                *ngIf="!disableEdit"
                (click)="editLender(i)"
              >
                <span
                  ><img
                    class="add-icon"
                    src="/assets/imgs/icon-pencil.svg" /></span
                ><span>Edit</span>
              </div>
            </div>
            <div class="mobile-item-banner-detail">
              <div class="name">{{ item['controls'].nameOfLender.value }}</div>
              <div>
                {{ formattedOriginal[i] | currency }}
              </div>
            </div>
          </div>
          <div
            [formGroupName]="i"
            class="item-details"
            *ngIf="collapseLender.length === 0 || collapseLender[i] !== true"
          >
            <div class="item-title">
              <div class="item-num">Lender {{ i + 1 }}</div>
            </div>
            <div class="direct-app-form">
              <div class="field">
                <div class="label-text">Name of Lender</div>
                <div class="content-input">
                  <input
                    formControlName="nameOfLender"
                    class="input-text container"
                    type="text"
                  />
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].nameOfLender.dirty ||
                    item['controls'].nameOfLender.touched
                  "
                >
                  <div
                    *ngIf="item['controls'].nameOfLender.errors?.required"
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span class="alert alert-danger help-text"
                      >Name Of lender is required.</span
                    >
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="label-text">Loan Origination Date</div>
                <div class="content-input">
                  <input
                    placeholder="{{ getDateFormatPlaceholder() }}"
                    formControlName="loanOriginationDate"
                    class="input-text container"
                    type="text"
                    (keydown)="handleNumberKeydown($event)"
                    (input)="handleDateInput($event, i)"
                  />
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].loanOriginationDate.dirty ||
                    item['controls'].loanOriginationDate.touched
                  "
                >
                  <div
                    *ngIf="
                      item['controls'].loanOriginationDate.errors?.required
                    "
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span class="alert alert-danger help-text"
                      >Loan origination date is required.</span
                    >
                  </div>
                  <div
                    *ngIf="
                      item['controls'].loanOriginationDate.errors
                        ?.InValidateStartDate
                    "
                    class="red-text"
                  >
                    <span class="err-text alert alert-danger help-text"
                      >Enter a valid loan origination date.</span
                    >
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="label-text">Original Loan Amount</div>
                <div class="input-icon">
                  <input
                    id="original-loan{{ i }}"
                    (blur)="blurCurrency($event)"
                    (keydown)="amountKeydown($event)"
                    formControlName="originalLoanAmount"
                    class="input-text container"
                    type="text"
                    value="{{ formattedOriginalLoanAmt }}"
                    placeholder="{{ amountPlaceholderText }}"
                  />
                  <i>$</i>
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].originalLoanAmount.dirty ||
                    item['controls'].originalLoanAmount.touched
                  "
                >
                  <div
                    *ngIf="
                      item['controls'].originalLoanAmount.errors || showError
                    "
                    class="red-text"
                  >
                    <span
                      class="alert alert-danger help-text"
                      *ngIf="
                        item['controls'].originalLoanAmount.errors?.required
                      "
                      >Original loan amount is required.</span
                    >
                  </div>

                  <div
                    *ngIf="
                      item['controls'].originalLoanAmount.errors?.ValidateNumber
                    "
                    class="red-text"
                  >
                    <span class="err-text alert alert-danger help-text"
                      >Enter a valid original loanAmount.</span
                    >
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="label-text">Remaining Balance</div>
                <div class="input-icon">
                  <input
                    value="{{ formattedRemainingBalance }}"
                    id="remaining-balance{{ i }}"
                    (blur)="blurCurrency($event)"
                    (keydown)="amountKeydown($event)"
                    formControlName="remainingBalance"
                    class="input-text container"
                    type="text"
                    placeholder="{{ amountPlaceholderText }}"
                  /><i>$</i>
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].remainingBalance.dirty ||
                    item['controls'].remainingBalance.touched
                  "
                >
                  <div
                    *ngIf="
                      item['controls'].remainingBalance.errors || showError
                    "
                    class="red-text"
                  >
                    <span
                      *ngIf="item['controls'].remainingBalance.errors?.required"
                      class="alert alert-danger help-text"
                    >
                      Remaining balance is required.</span
                    >
                  </div>
                  <div
                    *ngIf="
                      item['controls'].remainingBalance.errors?.ValidateNumber
                    "
                    class="red-text"
                  >
                    <span class="err-text alert alert-danger help-text"
                      >Enter a valid remaining balance.</span
                    >
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="label-text">Length of Loan (Months)</div>
                <div class="content-input">
                  <input
                    (keydown)="amountKeydown($event)"
                    formControlName="lengthOfLoan"
                    class="input-text container"
                    type="text"
                  />
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].lengthOfLoan.dirty ||
                    item['controls'].lengthOfLoan.touched
                  "
                >
                  <div
                    *ngIf="item['controls'].lengthOfLoan.errors?.required"
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span class="alert alert-danger help-text"
                      >Length of loan is required.</span
                    >
                  </div>

                  <div
                    *ngIf="item['controls'].lengthOfLoan.errors?.ValidateNumber"
                    class="red-text"
                  >
                    <span class="err-text alert alert-danger help-text"
                      >Enter a valid length of loan.</span
                    >
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="label-text">Payment Amount</div>
                <div class="input-icon">
                  <input
                    id="payment-amount{{ i }}"
                    value="{{ formattedPaymentAmt }}"
                    (blur)="blurCurrency($event)"
                    (keydown)="amountKeydown($event)"
                    formControlName="paymentAmount"
                    class="input-text container"
                    type="text"
                    placeholder="{{ amountPlaceholderText }}"
                  /><i>$</i>
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].paymentAmount.dirty ||
                    item['controls'].paymentAmount.touched
                  "
                >
                  <div
                    *ngIf="item['controls'].paymentAmount.errors || showError"
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span
                      *ngIf="item['controls'].paymentAmount.errors?.required"
                      class="alert alert-danger help-text"
                      >Payment amount is required.</span
                    >
                  </div>

                  <div
                    *ngIf="item['controls'].paymentAmount.errors || showError"
                    class="red-text"
                  >
                    <span
                      *ngIf="
                        item['controls'].paymentAmount.errors?.ValidateNumber
                      "
                      class="err-text alert alert-danger help-text"
                      >Enter a valid payment amount.</span
                    >
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="label-text">Payment Frequency</div>
                <div class="content-input">
                  <input
                    type="radio"
                    name="paymentFrequency"
                    id="Daily"
                    value="Daily"
                    formControlName="paymentFrequency"
                  />
                  <label class="daily" for="Daily">Daily </label>
                  <input
                    type="radio"
                    name="paymentFrequency"
                    id="Weekly"
                    value="Weekly"
                    formControlName="paymentFrequency"
                  />
                  <label class="weekly" for="Weekly">Weekly</label>
                </div>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].paymentFrequency.dirty ||
                    item['controls'].paymentFrequency.touched
                  "
                >
                  <div
                    *ngIf="item['controls'].paymentFrequency.errors?.required"
                    class="red-text"
                    class="alert alert-danger err-text"
                  >
                    <span class="alert alert-danger help-text"
                      >Payment frequency is required.</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="btn-container end">
              <button
                (click)="cancelLender(i)"
                class="add-item cancel"
                id="cancel-lender{{ i }}"
              >
                Cancel
              </button>

              <button
                (click)="saveLendersInfo(i)"
                class="btn-background add"
                id="save-lender{{ i }}"
              >
                ADD
              </button>

              <button (click)="cancelLender(i)" class="add-item cancel-mobile">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="showAddLender && !disableEdit" class="add-lender">
    <button (click)="addLenderBtn()" class="add-item">
      <img class="add-icon plus" src="/assets/imgs/add-icon.svg" />
      Add Daily or Weekly Debt
    </button>
  </div>

  <div class="btn-group">
    <div class="goback-group">
      <div class="goback" (click)="goBack()">
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="goback" #back>Back </a>
      </div>
    </div>

    <div class="btn-container end">
      <button
        *ngIf="!disableEdit"
        [disabled]="!continue || clicked"
        [ngClass]="continue ? 'continue-btn-background' : 'btn-disable'"
        (click)="submitDebtInfo$()"
        #submit
        class="btn-background"
      >
        CONTINUE
      </button>
    </div>

    <div class="goback-group">
      <div class="gobackmobile" (click)="goBack()">
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="gobackmobile" #back>Back </a>
      </div>
    </div>
  </div>
</div>
