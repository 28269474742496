import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'

import { AuthService } from '../../core/services/auth/auth.service'
import { BannersStatesService } from '../../core/services/banners/banners-states.service'
import * as INTF from '../../core/models/interfaces'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { handleError } from '../../core'
import { LoanService } from '../../core/services/loan/loan.service'

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnInit {
  verifyEmailSubscription: Subscription
  loanUuid: string
  token: string
  error: INTF.BackendError | null
  email: string
  showExpired = false

  constructor(
    private loanService: LoanService,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
    private bannersStatesService: BannersStatesService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email']
    })
  }

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')

    this.token = this.route.snapshot.paramMap.get('token')
    this.verifyEmail()
  }

  verifyEmail() {
    this.verifyEmailSubscription = this.loanService
      .verifyEmail(this.loanUuid, this.token)
      .subscribe(
        (response) => {
          if (response) {
            console.log('verifyEmail response', response)

            console.log('verified Email!!!')
            this.router.navigate([`/auth/login`])
            this.bannersStatesService.storeBannerState(
              this.bannersStatesService.BEEN_VERIFIED,
            )
          }
        },
        (err) => {
          console.log('HTTP Error', err)

          if (
            err?.error?.error?.errorCode === INTF.ErrorCode.OKTA_EMAIL_VERIFIED
          ) {
            this.bannersStatesService.storeBannerState(
              this.bannersStatesService.ALREADY_VERIFIED,
            )
            this.router.navigate([`/auth/login`])
          } else if (
            err?.error?.error?.errorCode ===
            INTF.ErrorCode.EMAIL_VERIFICATION_FAILED
          ) {
            // need email from error body
            this.errorService.storeError(err.error.error)
            this.showExpired = true
          } else {
            handleError(err, this.errorService, this.router)
          }
        },
      )
  }
}
