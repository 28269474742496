<div class="content-container">
  <div class="auth-page-container">
    <div class="info-page-wrap">
      <div></div>
      <div>
        <app-logo></app-logo>

        <div class="rectangle margin-auto">
          <div class="added-icon email-icon margin-auto"></div>
          <div class="text-align-center">
            <p class="pre-title margin-auto">Verification Link Expired</p>
          </div>
          <div class="rectangle margin-auto">
            <div class="review margin-auto text-align-center">
              This verification link has expired. To receive a new email
              verification link, click the button below.
            </div>

            <div
              *ngIf="resent"
              class="resent-container password-updated margin-auto"
            >
              <div class="updated"></div>
              <div class="signin-info fr">
                Success! We sent you a new verification link.
              </div>
              <button (click)="close()" class="close"></button>
            </div>
            <div class="btn-container text-align-center">
              <button
                (click)="resendEmail()"
                [disabled]=""
                class="btn-background sign-in-btn"
              >
                SEND NEW LINK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer [setIsNuulaUser]="isNuulaUser"></app-footer>
</div>
