import { SupportedDateFormat } from '../enums'
export const validateDateString: (
  date: string,
  format?: SupportedDateFormat,
  backspace?: boolean,
) => string = (date, format, backspace) => {
  let updatedDate = date.replace(new RegExp('/', 'gi'), '')
  if (updatedDate.length >= 2) {
    updatedDate = `${updatedDate.substring(0, 2)}/${updatedDate.substring(2)}`
  }
  if (updatedDate.length >= 5 && format === SupportedDateFormat.MM_DD_YYYY) {
    updatedDate = `${updatedDate.substring(0, 5)}/${updatedDate.substring(5)}`
  }
  if (backspace && updatedDate[updatedDate.length - 1] === '/') {
    updatedDate = updatedDate.slice(0, -1)
  }
  switch (format) {
    case SupportedDateFormat.MM_YYYY:
      if (updatedDate.length > 7) {
        updatedDate = updatedDate.substr(0, 7)
      }
      break
    case SupportedDateFormat.MM_DD_YYYY:
    default:
      if (updatedDate.length > 10) {
        updatedDate = updatedDate.substr(0, 10)
      }
      break
  }
  return updatedDate
}
