import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'
import { AuthService } from '../../core/services/auth/auth.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import {
  DirectAppUrl,
  getAccessToken,
  QueryParam,
  removeAccessToken,
} from '../../core'

@Component({
  selector: 'app-success-contact',
  templateUrl: './success-contact.component.html',
  styleUrls: ['./success-contact.component.scss'],
})
export class SuccessContactComponent implements OnInit, OnDestroy {
  name: string
  currentYear: number
  contactForm: FormGroup
  clicked = false
  rid = null
  email: string
  firstName: string
  lastName: string
  companyName: string
  data: any
  phoneNumber: string
  newUrlParams: string

  sendRidSubscription: Subscription

  constructor(
    private directAppService: DirectAppService,
    private errorService: ErrorService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // example url: http://localhost:4200/next-step-preference?rid=123X&email=someEmail@example.com&name=John
    this.route.queryParams.subscribe((params) => {
      this.rid = params[QueryParam.RID] || null
      this.directAppService.directAppRespIdSource.next(this.rid)
      this.email = params[QueryParam.EMAIL_ADDRESS] || null
      this.firstName = params[QueryParam.FIRST_NAME] || null
      this.lastName = params[QueryParam.LAST_NAME] || null
      this.companyName = params[QueryParam.COMPANY_NAME] || null
      this.phoneNumber = params[QueryParam.PHONE_NUMBER] || null

      // const next = 'next-step-preference';
      const currentUrl = this.router.url
      this.newUrlParams = currentUrl.replace(/^.+next-step-preference/, '')
    })
  }

  ngOnInit(): void {
    this.directAppService.directAppQParamsSource.next({
      email: this.email,
      companyName: this.companyName,
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber,
    })
    this.calCurrentYear()
    const token = getAccessToken()
    if (token) {
      removeAccessToken()
      this.authService.mfAccessToken.next(null)
    }
    this.forminit()
  }

  calCurrentYear() {
    const date = new Date()
    this.currentYear = date.getFullYear()
  }

  forminit() {
    this.contactForm = this.fb.group({
      select: ['', Validators.required],
    })
  }

  get f() {
    return this.contactForm.controls
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.continue()
    }
  }

  // ["firstName", "lastName", "companyName", "phoneNumber", "email"]

  // https://account.dev.mulliganfunding.com/next-step-preference?
  // rlp=
  // &rid=212aa789
  // &tfa_3=goodemail@goodemail.com
  // &tfa_63=Web
  // &tfa_1=abby
  // &tfa_2=Zhang
  // &tfa_5=real%20company
  // &tfa_4=619-517-2169
  // &tfa_71=619-517-2169
  // &pi_list_email=goodemail@goodemail.com
  // &tfa_79=

  continue() {
    if (this.contactForm.valid && !this.clicked) {
      this.clicked = true
      if (this.f.select.value === 'speakToConcultant') {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();

        window.location.href =
          `https://calendly.com/mulligan-funding-loan-advisors/mulligan-funding-discovery-call?month=${year}-${month}`
      } else {
        window.location.replace(
          `${DirectAppUrl.CREATE_ACCOUNT}${this.newUrlParams}`,
        )
      }
    }
  }

  ngOnDestroy() {
    if (this.sendRidSubscription) {
      this.sendRidSubscription.unsubscribe()
      this.sendRidSubscription = null
    }
  }
}
