<!-- <app-dashboard-header [auth]=false></app-dashboard-header> -->
<div class="header-container">
  <div class="logo-container">
    <img class="header-logo cursor-pointer desktop-logo" src="assets/imgs/MF_Horizontal_RGB.png">
    <img class="header-logo cursor-pointer mobile-logo" src="assets/imgs/MF_HorizontalStacked_RGB.png">
  </div>

  <div class="phone-container fullname"><a href="tel:855-326-3564"><img class="phone"
        src="assets/imgs/phone-icon-orange.svg">
      <span>(855) 326-3564</span></a>
  </div>
</div>




<div class="welcome">
  <div class="wel-title" *ngIf="name">Welcome, {{name}}!</div>

  <div class="wel-title" *ngIf="!name">Welcome!</div>
  <div class="next">What would you like to do next?</div>
</div>

<div class="apply">
  <form [formGroup]="contactForm" (keydown)="onSubmit($event)">
    <div class=box-group>
      <div class="apply-for-funding">
        <label for="applyForFunding" id="apply-for-funding">
          <div [ngClass]="f.select.value==='speakToConcultant' ||f.select.value==='' ? 'coins-default'
            :'coins-active'"> </div>

          <div class="btn-txt">APPLY FOR FUNDING</div>
          <input type="radio" name="select" id="applyForFunding" value="applyForFunding" formControlName="select">
        </label>
      </div>

      <div class="speak">
        <label for="speakToConcultant" id="speak">
          <div [ngClass]="f.select.value==='applyForFunding' ||f.select.value==='' ? 'consultants-default'
          :'consultants-active'"> </div>

          <div class="btn-txt">SPEAK TO A LOAN ADVISOR</div>
          <input type="radio" name="select" id="speakToConcultant" value="speakToConcultant" formControlName="select">
        </label>
      </div>
    </div>
  </form>

  <div class="btn-container" (click)="continue()" #submit type="submit" id="submit-reset">
    <div [ngClass]="this.contactForm.valid  ? 'btn-background'
    :'default-btn'">CONTINUE</div>
  </div>

  <div class="assist">
    <img class="assistance" src="/assets/imgs/img-lady.png">
    <div class="">
      <div class="ass-txt">
        If you need immediate assistance,
        <br>please
        call us at <a href="tel:855-326-3564">855-326-3564.</a>
      </div>
    </div>
  </div>
  <footer id="footer">
    <div class="line"></div>
    <div class="desktop-footer-txt">Mulligan Funding,
      LLC | 4715 Viewridge Avenue,
      Suite 100 | San Diego,
      CA 92123 <br>&copy;
      {{currentYear}}
      Mulligan Funding. All Rights Reserved.</div>
    <div class="mobile-footer-txt">
      {{currentYear}}
      Mulligan Funding. All Rights Reserved.</div>
  </footer>