import { Component } from '@angular/core'
import * as packageInfo from '../../../package.json'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
})
export class AboutComponent {
  version: string = packageInfo.version
  name: string = packageInfo.name
}
