<div class="rectangle">
    <div class="page-title">Loan Agreement</div>


    <div *ngIf="docusignUrl" class="intro">
        Review and sign your loan agreement by clicking the button below. You will be redirected to DocuSign® to sign
        your agreement electronically.
    </div>

    <div *ngIf="incompleteDocusign">

        <div class="btn-container text-align-center">
            <button *ngIf="docusignUrl" (click)="docUsign()" #submit class="btn-background">SIGN
                AGREEMENT</button>
        </div>

        <div class="docusign-icon">
            <div></div>
            <div class="docusign"><img src="assets/imgs/mulliganfunding-docusign.png"></div>
            <div></div>
        </div>
    </div>


    <div *ngIf="showSpinner">
        <app-loading-spinner></app-loading-spinner>
    </div>

    <div class="completed" *ngIf="completedDocusign">
        <div class="oval"></div>
        <div class="signed">You have signed your loan agreement. Please click “Continue” to proceed to the next step.
        </div>
        <div class="btn-container">
            <button (click)="continue()" #submit class="btn-background">
                CONTINUE</button>
        </div>
    </div>
</div>