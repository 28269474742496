import { Component, OnInit, OnDestroy } from '@angular/core'

import { Router } from '@angular/router'
import * as INTF from '../../core/models/interfaces'
import { MFAccessToken } from '../../core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-direct-app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class DirectAppEmailVerificationComponent implements OnInit, OnDestroy {
  accessToken: MFAccessToken | null
  applicationInfo: INTF.ApplicationInfo | null
  metadata: INTF.Metadata | undefined
  currentAccessTokenSubscription: Subscription
  directAppApplicationInfoSubscription: Subscription
  directAppMetadataSubscription: Subscription
  emailVerified = false
  token = ''
  verifiedError = false
  constructor(private router: Router) {}

  ngOnInit(): void {
    const urlParts = this.router.url.split('/')
    this.token = urlParts[urlParts.length - 1]

    window.location.replace(`/ui/direct-app/email-verification/${this.token}`)
  }

  ngOnDestroy() {
    if (this.currentAccessTokenSubscription) {
      this.currentAccessTokenSubscription.unsubscribe()
    }
    if (this.directAppApplicationInfoSubscription) {
      this.directAppApplicationInfoSubscription.unsubscribe()
    }
    if (this.directAppMetadataSubscription) {
      this.directAppMetadataSubscription.unsubscribe()
    }
  }
}
