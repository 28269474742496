import { DirectAppStep } from '../enums'
import { Metadata } from '../models'
export const getNextStepFromMetadata: (
  currentStep: DirectAppStep,
  metadata: Metadata,
) => DirectAppStep = (currentStep, metadata) => {
  if (!metadata) {
    return currentStep === DirectAppStep.UNKNOWN
      ? DirectAppStep.CREATE_ACCOUNT
      : DirectAppStep.UNKNOWN
  }
  if (!metadata.basicInfoUpdated) {
    if (
      currentStep === DirectAppStep.UNKNOWN &&
      metadata.directAppStep === DirectAppStep.CREATE_ACCOUNT
    ) {
      return DirectAppStep.CREATE_ACCOUNT
    }
    return currentStep !== DirectAppStep.BUSINESS_BASIC_INFO
      ? DirectAppStep.BUSINESS_BASIC_INFO
      : DirectAppStep.BUSINESS_CONTACT_INFO
  }
  if (!metadata.contactInfoUpdated) {
    return currentStep !== DirectAppStep.BUSINESS_CONTACT_INFO
      ? DirectAppStep.BUSINESS_CONTACT_INFO
      : DirectAppStep.BUSINESS_ADDRESS_INFO
  }
  if (!metadata.addressInfoUpdated) {
    return currentStep !== DirectAppStep.BUSINESS_ADDRESS_INFO
      ? DirectAppStep.BUSINESS_ADDRESS_INFO
      : DirectAppStep.OWNER_INFO
  }
  if (!metadata.ownerInfoUpdated) {
    return currentStep !== DirectAppStep.OWNER_INFO
      ? DirectAppStep.OWNER_INFO
      : DirectAppStep.ADDITIONAL_INFO
  }
  if (!metadata.debtInfoUpdated) {
    return currentStep !== DirectAppStep.ADDITIONAL_INFO
      ? DirectAppStep.ADDITIONAL_INFO
      : DirectAppStep.DOCUMENT
  }
  if (!metadata.documentsUpdated) {
    return currentStep !== DirectAppStep.DOCUMENT
      ? DirectAppStep.DOCUMENT
      : DirectAppStep.REVIEW_SUBMIT
  }
  return DirectAppStep.REVIEW_SUBMIT
}
