<div class="step-content">
  <div class="direct-app-page-title">Review & Submit</div>
  <div class="direct-app-page-info info-mobile">
    Review your information below to make sure we are getting the correct
    information.
  </div>

  <div class="review-submit-card">
    <div class="card-title-block">
      <div class="card-title">Business Information</div>
      <div class="expand-icon" (click)="toggleBusinessSection()">
        <div class="expand-icon-wrapper">
          <img
            class="expand-icon point-down"
            src="assets/imgs/chevron-down.svg"
            *ngIf="!openSections?.businessInfo"
          />
          <img
            class="expand-icon point-up"
            src="assets/imgs/chevron-up.svg"
            *ngIf="openSections?.businessInfo"
          />
        </div>
      </div>
    </div>
    <div class="card-contents" *ngIf="openSections?.businessInfo">
      <div class="card-section">
        <div class="card-section-header">
          <div class="card-section-title">BASIC INFO</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editBasicInfo()"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Company Name</div>
            <div class="data-content">{{ getCompanyName() }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Doing Business As</div>
            <div class="data-content">{{ getDoingBusinessAs() }}</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Corporate Structure</div>
            <div class="data-content">{{ getCorporateStructure() }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Business Tax ID</div>
            <div class="data-content">{{ getTaxId() }}</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">State of Incorporation</div>
            <div class="data-content">{{ getStateOfIncorporation() }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Business Start Date</div>
            <div class="data-content">{{ getBusinessStartDate() }}</div>
          </div>
        </div>
      </div>

      <div class="card-section-separator"></div>

      <div class="card-section">
        <div class="card-section-header">
          <div class="card-section-title">CONTACT INFO</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editContactInfo()"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Business Phone Number</div>
            <div class="data-content">{{ getBusinessPhoneNumber() }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Business Email</div>
            <div class="data-content">{{ getBusinessEmail() }}</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Business Website</div>
            <div class="data-content">{{ getBusinessWebsite() }}</div>
          </div>
        </div>
      </div>

      <div class="card-section-separator"></div>

      <div class="card-section">
        <div class="card-section-header">
          <div class="card-section-title">ADDRESS INFO</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editAddressInfo()"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Physical Address</div>
            <div class="data-content street">
              {{ getPhysicalAddressStreet() }}
            </div>
            <div class="data-content">
              {{ getPhysicalAddressCityStateZip() }}
            </div>
          </div>
          <div class="card-data">
            <div class="data-label">Mailing Address</div>
            <div class="data-content street">
              {{ getMailingAddressStreet() }}
            </div>
            <div class="data-content">
              {{ getMailingAddressCityStateZip() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="review-submit-card">
    <div class="card-title-block">
      <div class="card-title">Ownership Information</div>
      <div class="expand-icon" (click)="toggleOwnerInfoSection()">
        <div class="expand-icon-wrapper">
          <img
            class="expand-icon point-down"
            src="assets/imgs/chevron-down.svg"
            *ngIf="!openSections?.ownerInfo"
          />
          <img
            class="expand-icon point-up"
            src="assets/imgs/chevron-up.svg"
            *ngIf="openSections?.ownerInfo"
          />
        </div>
      </div>
    </div>
    <div class="card-contents" *ngIf="openSections?.ownerInfo">
      <div
        class="card-section"
        *ngFor="let owner of getOwnerList(); let i = index"
        [attr.data-index]="i"
      >
        <div class="card-section-separator" *ngIf="i > 0"></div>
        <div class="card-section-header">
          <div class="card-section-title">OWNER {{ i + 1 }}</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editOwnerInfo(i)"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>

        <div class="card-row">
          <div class="card-data">
            <div class="data-label">First Name</div>
            <div class="data-content">{{ owner.ownerFirstName }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Last Name</div>
            <div class="data-content">{{ owner.ownerLastName }}</div>
          </div>
        </div>

        <div
          class="card-row"
          *ngIf="i === 0 && !getApplyingOnBehalfOfPrimary()"
        >
          <div class="card-data">
            <div class="data-label">Date of Birth</div>
            <div class="data-content">{{ owner.ownerDOB }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Social Security Number</div>
            <div class="data-content">{{ getOwnerSSN(owner) }}</div>
          </div>
        </div>

        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Mobile Phone Number</div>
            <div class="data-content">{{ owner.ownerMobilePhone }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Email</div>
            <div class="data-content">{{ owner.ownerEmail }}</div>
          </div>
        </div>

        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Title</div>
            <div class="data-content">{{ owner.ownerTitle }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Ownership Percentage</div>
            <div class="data-content">{{ owner.ownershipPercentage }}</div>
          </div>
        </div>
        <div
          class="card-row"
          *ngIf="i === 0 && !getApplyingOnBehalfOfPrimary()"
        >
          <div class="card-data">
            <div class="data-label">Address</div>
            <div class="data-content street">
              {{ owner.ownerStreetAddress }}
            </div>
            <div class="data-content">
              {{ getOwnerAddressCityStateZip(owner) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="review-submit-card">
    <div class="card-title-block">
      <div class="card-title">Additional Information</div>
      <div class="expand-icon" (click)="toggleAdditionalInfoSection()">
        <div class="expand-icon-wrapper">
          <img
            class="expand-icon point-down"
            src="assets/imgs/chevron-down.svg"
            *ngIf="!openSections?.debtInfo"
          />
          <img
            class="expand-icon point-up"
            src="assets/imgs/chevron-up.svg"
            *ngIf="openSections?.debtInfo"
          />
        </div>
      </div>
    </div>
    <div class="card-contents" *ngIf="openSections?.debtInfo">
      <div class="card-section" *ngIf="applicationInfo.debtInfo.length === 0">
        <div class="card-section-header">
          <div class="card-section-title">No debt information</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editDebt(-1)"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>
      </div>
      <div
        class="card-section"
        *ngFor="let debt of getDebtList(); let i = index"
        [attr.data-index]="i"
      >
        <div class="card-section-separator" *ngIf="i > 0"></div>
        <div class="card-section-header">
          <div class="card-section-title">LENDER {{ i + 1 }}</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editDebt(i)"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Name of Lender</div>
            <div class="data-content">{{ debt.nameOfLender }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Loan Origination Date</div>
            <div class="data-content">{{ debt.loanOriginationDate }}</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Original Loan Amount</div>
            <div class="data-content">{{ debt.originalLoanAmount }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Remaining Balance</div>
            <div class="data-content">{{ debt.remainingBalance }}</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Length of Loan (Months)</div>
            <div class="data-content">{{ debt.lengthOfLoan }}</div>
          </div>
          <div class="card-data">
            <div class="data-label">Payment Amount</div>
            <div class="data-content">{{ debt.paymentAmount }}</div>
          </div>
        </div>
        <div class="card-row">
          <div class="card-data">
            <div class="data-label">Payment Frequency</div>
            <div class="data-content">{{ debt.paymentFrequency }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="review-submit-card">
    <div class="card-title-block">
      <div class="card-title">Documents</div>
      <div class="expand-icon" (click)="toggleDocumentsSection()">
        <div class="expand-icon-wrapper">
          <img
            class="expand-icon point-down"
            src="assets/imgs/chevron-down.svg"
            *ngIf="!openSections?.docInfo"
          />
          <img
            class="expand-icon point-up"
            src="assets/imgs/chevron-up.svg"
            *ngIf="openSections?.docInfo"
          />
        </div>
      </div>
    </div>
    <div class="card-contents" *ngIf="openSections?.docInfo">
      <div class="card-section">
        <div class="card-section-header" *ngIf="getConnectedBanks.length > 0">
          <div class="card-section-title">BANKS CONNECTED</div>
        </div>
        <div
          class="plaid-rows"
          *ngFor="let plaidBank of getConnectedBanks(); let i = index"
          [attr.data-index]="i"
        >
          <div class="card-row">
            <div class="card-data">
              <div class="data-label">Bank Name</div>
              <div class="data-content">{{ plaidBank.institutionName }}</div>
            </div>
            <div class="card-data">
              <div class="data-label">Status</div>
              <div class="data-content">{{ plaidBank.status }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-section-separator"></div>
      <div class="card-section">
        <div class="card-section-header">
          <div class="card-section-title">BANK STATEMENTS</div>
          <div
            class="card-section-edit-wrapper"
            *ngIf="!disableEdit"
            (click)="editBankStatements()"
          >
            <div class="edit-icon-wrapper">
              <img class="edit-icon" src="assets/imgs/icon-pencil.svg" />
            </div>
            <div class="edit-text">Edit</div>
          </div>
        </div>
        <div class="card-row wrap">
          <div
            class="card-data"
            *ngFor="let statement of getBankStatements(); let i = index"
            [attr.data-index]="i"
          >
            <div class="data-label">Bank Statement {{ i + 1 }}</div>
            <div class="data-content">{{ statement.filename }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="review-submit-card" *ngIf="!getVerificationStatus()">
    <div class="card-title-block">
      <div class="card-title">Email Verification</div>
      <div class="not-verified-block">
        <div class="not-verified-text">Not Verified</div>
        <div class="not-verified-icon-wrapper">
          <img
            class="not-verified-icon"
            src="assets/imgs/alert-error-icon.svg"
          />
        </div>
      </div>
    </div>
    <div class="card-contents">
      <div class="card-text">
        For your security, we need to verify your email before you submit your
        application. Please check that your email is correctly listed and click
        “Resend Verification Email” to send a verification link.
      </div>
      <div class="success-banner" *ngIf="showEmailSentSuccessfully">
        <div class="green-check-wrapper">
          <img
            class="green-check-icon"
            src="assets/imgs/check_circle_outline.svg"
          />
        </div>
        <div class="success-text">
          Success! We resent your verification link.
        </div>
        <div class="close-icon-wrapper" (click)="closeEmailSentSuccessfully()">
          <img class="close-x-icon" src="assets/imgs/close-icon.svg" />
        </div>
      </div>
      <div class="resend-verification-block">
        <div class="resend-verification-email">
          {{ getPrimaryEmailAddress() }}
        </div>
        <div
          class="resend-verification-link"
          (click)="resendVerificationEmail()"
        >
          (Resend Verification Email)
        </div>
      </div>
    </div>
  </div>

  <div class="disclosure-card" *ngIf="!disableEdit">
    <div class="disclosure-text">
      By clicking "Submit Application" below, you consent to receiving
      telemarketing calls and messages from Mulligan Funding and those acting on
      its behalf at the telephone number you have provided above (including your
      cellular phone number); agree that this consent applies even if the number
      you have provided is currently on any state, federal or corporate
      Do-Not-Call registry; and understand that you are not required to provide
      this consent as a condition of receiving any credit or services from
      Mulligan Funding and that you may apply for business credit by contacting
      us directly.
    </div>
  </div>

  <div class="btn-group" *ngIf="!disableEdit">
    <div class="btn-container end">
      <button (click)="submitApplication$()" #submit class="btn-background">
        SUBMIT APPLICATION
      </button>
    </div>
  </div>
</div>
