import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../../../environments/environment'
import { httpOptions } from '../../objects'
import { ConfigService } from '../config/config.service'

@Injectable({
  providedIn: 'root',
})
export class MicroAuthService {
  serverUrl: string | null
  useServer: boolean

  constructor(private configService: ConfigService, private http: HttpClient) {
    this.useServer = environment.useServer
    this.serverUrl = this.getUrl()
  }

  getUrl() {
    if (environment.serverUrl) {
      if (environment.environment === 'local-server') {
        return `${environment.serverUrl}`
      } else {
        return `${environment.serverUrl}/auth`
      }
    } else {
      return null
    }
  }

  tokenRefresh$(token: string) {
    const url = `${this.serverUrl}/token-refresh`
    const data = {
      token,
    }
    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {}),
      catchError(this.configService.handleError),
    )
  }

  exchangeOneTimeUseToken$(token: string) {
    const url = `${this.serverUrl}/external/lookup-token-exchange`
    const data = {
      token,
    }
    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {}),
      catchError(this.configService.handleError),
    )
  }
}
