import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'
import { DirectAppStep } from 'src/app/core'
import { DirectAppStepService } from 'src/app/core/services/direct-app-steps/direct-app-steps.service'
import { DirectAppService } from 'src/app/core/services/direct/direct-app.service'
import { ErrorService } from 'src/app/core/services/generic-error/error.service'
import { ModalService } from '../../../../core/services/modal/modal.service'
import { handleError } from '../../../../core'

@Component({
  selector: 'app-before-continue',
  templateUrl: './before-continue.component.html',
  styleUrls: ['./before-continue.component.scss'],
})
export class BeforeContinueComponent implements OnInit {
  packetId: string
  clicked = false
  constructor(
    private modalService: ModalService,
    private directAppService: DirectAppService,
    private directAppStepService: DirectAppStepService,
    private errorService: ErrorService,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this.directAppService.currentDirectAppPacketId.subscribe((packetId) => {
      this.packetId = packetId
    })
  }
  close() {
    this.modalService.updatedModalState(false)
    this.modalService.updatedModalType(null)
  }
  submitDocInfo$() {
    const nextStep = DirectAppStep.REVIEW_SUBMIT
    const data = { packetId: this.packetId, newStatus: nextStep }
    this.directAppService.setStatus$(data).subscribe(
      (res) => {
        this.clicked = true
        this.directAppStepService.updatedDirectAppStep(nextStep)
        this.close()
      },
      (err) => {
        console.log('HTTP Error', err)
        if (err.error && err.error.toString().toLowerCase() === 'forbidden') {
          this.router.navigate([`/auth/login`])
        } else {
          handleError(err, this.errorService, this.router)
        }
      },
    )
  }
}
