import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  Renderer2,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core'
import { Router } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'
import { Subscription } from 'rxjs'

import { ModalService } from '../core/services/modal/modal.service'
import { LoanService } from '../core/services/loan/loan.service'
import { environment } from '../../environments/environment'
import * as fakeData from '../../assets/fixtures/calcNums.json'
import { ErrorService } from '../core/services/generic-error/error.service'
import * as INTF from '../core/models/interfaces'
import { AuthService } from '../core/services/auth/auth.service'
import { handleError } from '../core'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked, OnDestroy {
  dashboardData: any = null
  public isAuthenticated: boolean
  getDashboardSubscription: Subscription
  useServer: boolean
  userClaims: any
  givenName: string
  fullname: string
  firstName: string
  showViewAllAccount = true
  showAccount = false
  auth: boolean

  @ViewChild('showAccounts', { static: false }) showAccounts: ElementRef

  constructor(
    private errorService: ErrorService,
    public oktaAuth: OktaAuthService,
    private loanService: LoanService,
    private router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private modalService: ModalService,
  ) {
    this.useServer = environment.useServer
    this.oktaAuth.$authenticationState.subscribe((isAuth) => {
      this.isAuthenticated = isAuth
      if (!isAuth) {
        // dont show any modal if unanthenitcated
        this.modalService.updatedModalType(null)
        this.modalService.updatedModalState(false)
        this.router.navigate([`/auth/login`])
      }
    })
  }

  async ngOnInit() {
    if (this.useServer) {
      this.isAuthenticated = await this.oktaAuth.isAuthenticated()
    } else {
      this.isAuthenticated = fakeData.auth.isAuthenticated
    }

    if (!this.isAuthenticated) {
      this.router.navigate([`/auth/login`])
    } else {
      this.userClaims = await this.oktaAuth.getUser()
      this.fullname = this.userClaims?.name
      const fullNameList = this.fullname?.split(' ')
      this.firstName = fullNameList[0]
    }

    if (this.useServer) {
      this.oktaAuth.$authenticationState.subscribe((isAuth) => {
        this.isAuthenticated = isAuth
        if (!this.isAuthenticated) {
          this.router.navigate([`/auth/login`])
        } // can idToken change within this component? Bug if so: should be reset here.
      })
    }
    this.getDashboard()

    this.showAllAccountDropdown()
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges()
  }

  // showAllAccounts() {
  //   const showAccountDropdown = document.getElementById("show-accounts");
  //   showAccountDropdown.style.display = 'block';
  // }

  showAccountDetail() {
    this.showAccount = true
    this.showViewAllAccount = false
  }

  getDashboard() {
    this.getDashboardSubscription = this.loanService
      .getUserDashboard$()
      .subscribe(
        (res) => {
          this.dashboardData = res
          // document.getElementById("show-accounts").style.display = 'none';
          if (
            res.accounts.length !== 0 &&
            this.dashboardData &&
            this.dashboardData.accounts[0].loans
          ) {
            const step = INTF.stepFromString(
              this.dashboardData?.accounts[0]?.loans[0].docCheckoutStatus,
            )

            if (
              this.dashboardData.accounts.length === 1 &&
              this.dashboardData.accounts &&
              this.dashboardData?.accounts[0].loans.length === 1 &&
              step >= 20 &&
              step <= 55
            ) {
              this.router.navigate([
                `doc-checkout/loan/${this.dashboardData.accounts[0].loans[0].uuid}`,
              ])
            }
            if (
              this.dashboardData.accounts.length === 1 &&
              this.dashboardData.accounts[0].loans.length === 1 &&
              step === 5
            ) {
              this.router.navigate([
                `doc-checkout/loan/${this.dashboardData.accounts[0].loans[0].uuid}/start`,
              ])
            }
          }
        },
        (err) => {
          console.log('HTTP Error', err)

          handleError(err, this.errorService, this.router)
        },
      )
  }

  showAllAccountDropdown() {
    document.body.addEventListener('click', (event) => {
      const showAccountDropdown = document.getElementById('show-accounts')
      const downArrowBtn = document.getElementById('down-arrow-btn')
      const showSignOutDropdown = document.getElementById(
        'show-signOut-dropdown',
      )
      const showSignOutDropdownBtn = document.getElementById(
        'signout-dropdown-btn',
      )

      if (event.target && downArrowBtn && showSignOutDropdownBtn) {
        // const isClickInsideAllAccount = downArrowBtn.contains(event.target as Element);
        // const isClickInsideSignout = showSignOutDropdownBtn.contains(event.target as Element);
        // if (!isClickInsideAllAccount) {
        //   if (downArrowBtn.contains(event.target as Element)) {
        //     showAccountDropdown.style.display = 'block';
        //   } else {
        //     if (showAccountDropdown.style.display === 'block') {
        //       showAccountDropdown.style.display = 'none';
        //     }
        //   }
        // }
        // if (!isClickInsideSignout) {
        //   if (showSignOutDropdownBtn.contains(event.target as Element)) {
        //     showSignOutDropdown.style.display = 'block';
        //     showSignOutDropdownBtn.style.backgroundImage = "url(/assets/imgs/chevron-up.svg)";
        //   } else {
        //     if (showSignOutDropdown.style.display === 'block') {
        //       showSignOutDropdown.style.display = 'none';
        //       showSignOutDropdownBtn.style.backgroundImage = "url(/assets/imgs/chevron-down.svg)";
        //     }
        //   }
        // }
      }
    })
  }

  showViewAll() {
    this.showViewAllAccount = true
    this.showAccount = false
  }

  ngOnDestroy() {
    if (this.getDashboardSubscription) {
      this.getDashboardSubscription.unsubscribe()
    }
  }
}
