<div class="modal-body">
  <div class="warning-wrapper">
    <img class="warning-icon" src="assets/imgs/delete-alert-illustration.svg" />
  </div>
  <div class="modal-title">Before you continue…</div>
  <div class="modal-text">
    Although you can provide documents later, your banking information will be
    required for a loan offer decision. As a result, a loan representative will
    contact you at a later time. Keep in mind that this option will slow down
    your application review.
  </div>
  <div class="btn-container save text-align-center">
    <button
      [disabled]="clicked"
      (click)="submitDocInfo$()"
      #submit
      type="submit"
      class="btn-background"
    >
      PROVIDE DOCUMENTS LATER
    </button>
  </div>

  <div class="ok cursor-pointer margin-auto" (click)="close()">Cancel</div>
</div>
