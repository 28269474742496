import { ActivatedRoute } from '@angular/router'
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  Input,
} from '@angular/core'

import * as INTF from '../../core/models/interfaces'
import { StepService } from '../../core/services/steps/step.service'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewChecked {
  @Input() loanStatus: INTF.DocCheckoutStep
  @Input() stepStatus: string

  doneStep = {
    doneIntroduction: false,
    doneVerification: false,
    doneLoanDetails: false,
    doneLoanAgreement: false,
    doneReviewSubmit: false,
  }

  disabledBusinessReference = false
  disabledIntroduction = false

  loanUuid: string
  loanStep = {
    loanIntroductionStep: false,
    identityVerificationIdentityStep: false,
    loanDetailsStep: false,
    loanAgreementStep: false,
    businessReferenceStep: false,
  }

  constructor(
    private stepService: StepService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
  }

  ngAfterViewChecked() {
    if (this.loanStatus === INTF.DocCheckoutStep.loanIntroduction) {
      this.cleanLoanStep()
      this.cleanDoneStep()
      this.loanStep.loanIntroductionStep = true
    }

    if (
      this.loanStatus === INTF.DocCheckoutStep.identityVerificationQuestions
    ) {
      this.cleanLoanStep()
      this.cleanDoneStep()
      this.loanStep.identityVerificationIdentityStep = true
      this.doneStep.doneIntroduction = true
    }

    if (this.loanStatus === INTF.DocCheckoutStep.loanDetails) {
      this.cleanLoanStep()
      this.cleanDoneStep()
      this.loanStep.loanDetailsStep = true
      this.doneStep.doneIntroduction = true
      this.doneStep.doneVerification = true
    }

    if (this.loanStatus === INTF.DocCheckoutStep.loanAgreement) {
      this.cleanLoanStep()
      this.cleanDoneStep()
      this.loanStep.loanAgreementStep = true
      this.doneStep.doneIntroduction = true
      this.doneStep.doneVerification = true
      this.doneStep.doneLoanDetails = true
    }

    if (this.loanStatus === INTF.DocCheckoutStep.businessReference) {
      this.cleanLoanStep()
      this.cleanDoneStep()
      this.loanStep.businessReferenceStep = true
      this.doneStep.doneIntroduction = true
      this.doneStep.doneVerification = true
      this.doneStep.doneLoanDetails = true
      this.doneStep.doneLoanAgreement = true
    }

    // Run change detection explicitly after the change
    this.cdr.detectChanges()
  }

  cleanLoanStep() {
    for (const step in this.loanStep) {
      if (this.loanStep.hasOwnProperty(step)) {
        this.loanStep[step] = false
      }
    }
  }

  cleanDoneStep() {
    for (const step in this.doneStep) {
      if (this.doneStep.hasOwnProperty(step)) {
        this.doneStep[step] = false
      }
    }
  }

  introduction() {
    this.stepService.updatedStep(
      INTF.DocCheckoutStep.loanIntroduction,
      this.loanUuid,
    )
  }

  verification() {
    this.stepService.updatedStep(
      INTF.DocCheckoutStep.identityVerificationQuestions,
      this.loanUuid,
    )
  }

  loanDetails() {
    this.stepService.updatedStep(
      INTF.DocCheckoutStep.loanDetails,
      this.loanUuid,
    )
  }

  loanAgreement() {
    this.stepService.updatedStep(
      INTF.DocCheckoutStep.loanAgreement,
      this.loanUuid,
    )
  }

  businessReference() {
    this.stepService.updatedStep(
      INTF.DocCheckoutStep.businessReference,
      this.loanUuid,
    )
  }
}
