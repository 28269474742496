<div class="margin-auto ucc">

  <div class="whatisit margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto">What is a UCC?</p>
  </div>

  <div class="rectangle margin-auto text-align-center">
    <div class="content-group">
      A UCC-1 statement is a document which serves as a lien on commercial property in a business loan. UCC-1 statements
      can be filed for all types of assets. The filing of a UCC-1 statement for securing collateral is required for
      business loans under the Uniform Commercial Code (UCC).
    </div>

    <div class="source">Source: Investopedia</div>
  </div>

  <div class="ok margin-auto cursor-pointer" (click)="close()">Close</div>

</div>