import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms'
import { Router } from '@angular/router'

import { distinctUntilChanged } from 'rxjs/operators'
import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import {
  DirectAppStep,
  EMAIL_FORM_REGEX,
  getDirectAppUrlFromStep,
  handleError,
  isValidDateKeydown,
  SSN_FORM_REGEX,
  SupportedDateFormat,
  SupportedTaxIdFormat,
  US_STATES,
  validateDateString,
  validateSSNString,
} from '../../core'
import { ValidatePhone } from '../../shared/validators'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import { InValidateDOB } from '../../shared/validators/DOB.validator'
import { InValidateAddress } from '../../shared/validators/address.validator'
import { ValidateUniqueEmail } from '../../shared/validators/uniqueEmail.validator'
import Utils from '../../shared/utils/utils'
import { ModalService } from '../../core/services/modal/modal.service'
import { DirectAppReviewSubmitService } from 'src/app/core/services/direct-app-review-submit/direct-app-review-submit.service'

@Component({
  selector: 'app-owner-info',
  templateUrl: './owner-info.component.html',
  styleUrls: ['./owner-info.component.scss'],
})
export class OwnerInfoComponent implements OnInit, OnDestroy {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  disableEdit = false

  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    const newAppInfo = value && !this.applicationInfo
    this.applicationInfo = value

    if (newAppInfo) {
      this.formInit()
      this.valueChange()
    }
  }
  isPrimaryOwner: boolean
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
  }

  @ViewChild('togglePrimary') togglePrimary: ElementRef
  ownerEmailGroup = []
  newphoneNo = []
  newOwnerZip = []
  collapseOwner = []
  comingFromReviewSubmit = false
  comingFromReviewSubmitSubscription: Subscription
  disableAddOwner = false
  backspace = false
  cleanedPercentage: string
  ownerInfo: INTF.OwnerInfo
  continue: boolean
  ownersForm: FormGroup
  showAddOwner: boolean
  ownersSubscription: Subscription
  ownersFormSubscription: Subscription
  ownersFormValueChangSubscription: Subscription
  clicked: boolean
  directAppStatus: DirectAppStep
  showError = false
  applyingOnBehalfOfPrimary: boolean
  states = Object.keys(US_STATES)
  newOwnerSSN = []

  notPrimaryOwnerControl = [
    'ownerSSN',
    'ownerDOB',
    'ownerStreetAddress',
    'ownerCity',
    'ownerState',
    'ownerZipcode',
  ]
  totalPercentage = 0
  newOwnerDOB: string
  updateOwner = false
  deleteID: number

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private directAppService: DirectAppService,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private errorService: ErrorService,
    private directAppStepService: DirectAppStepService,
  ) {}

  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/owner-info`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })
    this.directAppService.currentDeleteItem.subscribe((val: boolean) => {
      if (val) {
        this.deleteOwnerBtn()
      }
    })

    this.comingFromReviewSubmitSubscription =
      this.directAppReviewSubmitService.currentComingFromReviewSubmit.subscribe(
        (val) => {
          this.comingFromReviewSubmit = val
        },
      )
  }

  showDelete(i: number) {
    this.deleteID = i
    if (this.collapseOwner.every(this.check)) {
      this.modalService.updatedItemTypeState('owner')
      this.modalService.updatedModalState(true)
      this.modalService.updatedModalType(this.modalService.DELETE)
    }
  }

  formInit() {
    this.ownersForm = new FormGroup({
      owners: this.formBuilder.array([]),
    })

    this.isPrimaryOwner =
      this.applicationInfo &&
      this.applicationInfo?.ownerInfo?.applyingOnBehalfOfPrimary &&
      this.applicationInfo?.ownerInfo?.owners.length !== 0
        ? !this.applicationInfo?.ownerInfo?.applyingOnBehalfOfPrimary
        : true

    if (!this.applicationInfo?.ownerInfo) {
      this.ownerInfo = {
        owners: [],
        applyingOnBehalfOfPrimary: false,
      }
    } else {
      this.ownerInfo = this.applicationInfo.ownerInfo
    }
    this.addOwner()
    this.clicked = false
  }

  addMoreOwner() {
    this.disableAddOwner = this.collapseOwner.every(this.check) ? false : true

    if (this.owners.controls.length === 0) {
      // start fresh
      this.ownerInfo.applyingOnBehalfOfPrimary = false
      this.isPrimaryOwner = true

      this.checkPrimaryRadioBtn(this.isPrimaryOwner)
      this.addPrimaryOwnerFields(0, null, false) // no owners in memory, index is 0
      this.addNonPrimaryOwnerFields(null)
    }

    if (
      this.owners.controls.length !== 0 &&
      !this.disableAddOwner &&
      this.totalPercentage <= 100 &&
      this.ownersForm.valid &&
      this.ownersForm.value.owners.length < 3
    ) {
      const ownerIndex = this.owners.controls.length
      this.addPrimaryOwnerFields(ownerIndex, null, false)
    }

    this.updateOwner = false
    this.showError = false
  }

  getDateFormatPlaceholder() {
    return SupportedDateFormat.MM_DD_YYYY
  }
  getTotalPercentage() {
    const owners = this.ownersForm.value.owners
    this.totalPercentage = 0
    owners.forEach((element: INTF.OwnerDetail) => {
      this.totalPercentage += +element.ownershipPercentage
    })
  }

  handleDateInput(e: InputEvent) {
    const target = e.target as HTMLInputElement
    const value = target.value ? target.value.toString() : ''

    const newValue = validateDateString(
      value,
      SupportedDateFormat.MM_DD_YYYY,
      this.backspace,
    )
    this.newOwnerDOB = newValue

    if (newValue !== value) {
      target.value = newValue
    }
  }

  handleSSNInput(e: InputEvent, index: number) {
    const target = e.target as HTMLInputElement
    const value = target.value ? target.value.toString() : ''
    const newValue = validateSSNString(
      value,
      SupportedTaxIdFormat.SOCIAL_SECURITY_NUMBER,
      this.backspace,
    )
    this.newOwnerSSN[index] = newValue

    if (newValue !== value) {
      target.value = newValue
    }
  }

  handleNumberKeydown(e: KeyboardEvent) {
    if (!isValidDateKeydown(e)) {
      e.preventDefault()
    }
    if (e.key === 'Backspace') {
      this.backspace = true
    } else {
      this.backspace = false
    }
  }

  addPrimaryOwnerFields(index, owner, collapseOwnerValue: boolean) {
    const ownerFirstName = owner?.ownerFirstName || ''
    const ownerLastName = owner?.ownerLastName || ''
    const ownerMobilePhone = owner?.ownerMobilePhone || ''
    const ownerEmail = owner?.ownerEmail || ''
    const ownerTitle = owner?.ownerTitle || ''
    const ownershipPercentage = owner?.ownershipPercentage || ''
    if (this.isPrimaryOwner && index === 0) {
      this.owners.push(
        new FormGroup({
          ownerFirstName: new FormControl(
            this.applicationInfo?.accountInfo.firstName,
          ),
          ownerLastName: new FormControl(
            this.applicationInfo?.accountInfo.lastName,
          ),
          ownerMobilePhone: new FormControl(ownerMobilePhone, {
            validators: [Validators.required, ValidatePhone],
            updateOn: 'blur',
          }),
          ownerEmail: new FormControl(this.applicationInfo?.accountInfo.email, {
            validators: [],
          }),
          ownerTitle: new FormControl(ownerTitle),
          ownershipPercentage: new FormControl(ownershipPercentage, {
            validators: [
              Validators.min(1),
              Validators.max(100),
              Validators.required,
            ],
          }),
        }),
      )
    } else {
      this.owners.push(
        new FormGroup({
          ownerFirstName: new FormControl(ownerFirstName, Validators.required),
          ownerLastName: new FormControl(ownerLastName, Validators.required),
          ownerMobilePhone: new FormControl(ownerMobilePhone, {
            validators: [Validators.required, ValidatePhone],
            updateOn: 'blur',
          }),
          ownerEmail: new FormControl(ownerEmail, {
            validators: [
              Validators.required,
              Validators.email,
              Validators.pattern(EMAIL_FORM_REGEX),
              ValidateUniqueEmail(this.owners.value.map((el) => el.ownerEmail)),
            ],
            updateOn: 'blur',
          }),
          ownerTitle: new FormControl(ownerTitle),
          ownershipPercentage: new FormControl(ownershipPercentage, {
            validators: [
              Validators.min(1),
              Validators.max(100),
              Validators.required,
            ],
          }),
        }),
      )
    }

    this.collapseOwner.push(collapseOwnerValue)
    this.checkAddBtn()
    this.showError = false
  }

  addOwnerWithTwelveFields() {
    // add first 6 fields

    const ownerIndex = this.owners.controls.length
    this.addPrimaryOwnerFields(ownerIndex, null, false)
    const firstCacheOwner = this.ownerInfo?.owners[0]

    // if it has owners AND owners list is not empty from DB OR from memory
    const firstOwner = firstCacheOwner ? firstCacheOwner : null
    // add 6 MORE fields
    this.addNonPrimaryOwnerFields(firstOwner)
    this.ownerInfo.applyingOnBehalfOfPrimary = false
    this.isPrimaryOwner = true // for the toggle btn
    this.showError = false
  }

  addOwner() {
    this.getTotalPercentage()
    if (this.ownerInfo?.owners?.length === 0 || !this.ownerInfo) {
      this.checkPrimaryRadioBtn(true)

      this.addOwnerWithTwelveFields()
    } else {
      // if it has owners AND owners list is not empty from DB OR from memory
      const firstOwner = this.ownerInfo?.owners[0]

      for (const owner of this.ownerInfo?.owners) {
        // for all owners from from DB OR from memory, add all basic 6 fields first

        const ownerIndex = this.owners.controls.length

        this.addPrimaryOwnerFields(ownerIndex, owner, true)
        this.showError = false
        this.checkAddBtn()
      }
      this.checkPrimaryRadioBtn(this.isPrimaryOwner)

      // for applying NOT OnBehalfOfPrimary owner from DB OR from memory,
      // need to add just 6 fields for only the 1st owner
      if (this.ownerInfo.applyingOnBehalfOfPrimary === false) {
        this.isPrimaryOwner = true
        this.addNonPrimaryOwnerFields(firstOwner)
      }
    }
  }

  checkPrimaryRadioBtn(val: boolean) {
    setTimeout(() => {
      if (val) {
        const primaryOwner = document.getElementById(
          'primaryOwner',
        ) as HTMLInputElement
        const notPrimaryOwner = document.getElementById(
          'notPrimaryOwner',
        ) as HTMLInputElement
        if (primaryOwner) {
          primaryOwner.checked = true
          notPrimaryOwner.checked = false
        }
      } else {
        const primaryOwner = document.getElementById(
          'primaryOwner',
        ) as HTMLInputElement
        const notPrimaryOwner = document.getElementById(
          'notPrimaryOwner',
        ) as HTMLInputElement
        if (notPrimaryOwner) {
          notPrimaryOwner.checked = true
          primaryOwner.checked = false
        }
      }
    }, 0) // have to wait for the form to be built
  }

  addNonPrimaryOwnerFields(firstOwner: INTF.OwnerDetail) {
    const firstControl = this.ownersForm.controls.owners['controls'][0]
    // when no data defualt to empty string
    const ownerDOB = firstOwner?.ownerDOB || ''
    const ownerSSN = firstOwner?.ownerSSN || ''
    const ownerStreetAddress = firstOwner?.ownerStreetAddress || ''
    const ownerCity = firstOwner?.ownerCity || ''
    const ownerState = firstOwner?.ownerState || ''
    const ownerZipcode = firstOwner?.ownerZipcode || ''
    if (firstControl) {
      firstControl.addControl(
        'ownerDOB',
        new FormControl(ownerDOB, {
          validators: [InValidateDOB, Validators.required],
          updateOn: 'blur',
        }),
      )
      firstControl.addControl(
        'ownerSSN',
        new FormControl(ownerSSN, {
          validators: [
            Validators.required,
            Validators.pattern(SSN_FORM_REGEX),
            Validators.maxLength(11),
          ],
          updateOn: 'blur',
        }),
      )
      firstControl.addControl(
        'ownerStreetAddress',
        new FormControl(ownerStreetAddress, {
          validators: [Validators.required, InValidateAddress],
          updateOn: 'blur',
        }),
      )
      firstControl.addControl(
        'ownerCity',
        new FormControl(ownerCity, {
          validators: [Validators.required],
          updateOn: 'blur',
        }),
      )
      firstControl.addControl(
        'ownerState',
        new FormControl(ownerState, {
          validators: [Validators.required],
          updateOn: 'blur',
        }),
      )
      firstControl.addControl(
        'ownerZipcode',
        new FormControl(ownerZipcode, {
          validators: [
            Validators.maxLength(5),
            Validators.minLength(5),
            Validators.required,
          ],
          updateOn: 'blur',
        }),
      )
    }
    this.newOwnerDOB = ownerDOB
  }
  get owners() {
    if (this.ownersForm) {
      return this.ownersForm.get('owners') as FormArray
    }
  }

  amountKeydown(e) {
    Utils.AmountKeydown(e)
  }

  formatOwnerPhone(element, index: number) {
    this.newphoneNo[index] = Utils.formatPhoneNo(element.target.value)

    this.owners.at(index).patchValue(
      {
        ownerMobilePhone: this.newphoneNo[index],
      },
      { emitEvent: false },
    )
  }

  valueChange() {
    this.ownersFormValueChangSubscription = this.ownersForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((ownersForm) => {
        for (const [index, value] of ownersForm.owners.entries()) {
          this.getTotalPercentage()

          const percentage = document.getElementById(
            'owerPercentage' + index,
          ) as HTMLInputElement

          this.cleanedPercentage = Utils.cleanDigit(
            value.ownershipPercentage,
          ).substring(0, 3)

          if (percentage) {
            percentage.value = this.cleanedPercentage
          }

          if (value.ownerSSN) {
            const cleanedSSN = Utils.cleanDigit(value.ownerSSN).substring(0, 9)
            const ownerSsn = document.getElementById(
              'owner-ssn' + index,
            ) as HTMLInputElement

            if (ownerSsn) {
              ownerSsn.value = cleanedSSN
            }

            this.newOwnerSSN[index] = Utils.formatSSN(cleanedSSN)
            this.owners.at(index).patchValue(
              {
                ownerSSN: this.newOwnerSSN[index],
              },
              { emitEvent: false },
            )
          }

          if (value.ownerDOB) {
            this.owners.at(index).patchValue(
              {
                ownerDOB: this.newOwnerDOB,
              },
              { emitEvent: false },
            )
          }

          if (value.ownerZipcode) {
            this.newOwnerZip[index] = Utils.formatZipcode(value.ownerZipcode)
            this.owners.at(index).patchValue(
              {
                ownerZipcode: this.newOwnerZip[index],
              },
              { emitEvent: false },
            )
          }

          this.getTotalPercentage()

          this.checkContinue()
        }
      })
  }

  showPrimaryOwner(value) {
    this.isPrimaryOwner = value
    if (value === false) {
      this.owners.at(0).patchValue(
        {
          ownerFirstName: '',
          ownerLastName: '',
          ownerEmail: '',
          ownerMobilePhone: '',
          ownershipPercentage: '',
          ownerTitle: '',
        },
        { emitEvent: false },
      )
      const ownerVal = this.owners.at(0).value
      for (const val of this.notPrimaryOwnerControl) {
        if (val in ownerVal) {
          this.ownersForm.controls.owners['controls'][0].removeControl(val)
        }
      }
    } else {
      this.owners.at(0).patchValue(
        {
          ownerFirstName: this.applicationInfo.accountInfo.firstName,
          ownerLastName: this.applicationInfo.accountInfo.lastName,
          ownerEmail: this.applicationInfo.accountInfo.email,
          ownerMobilePhone: '',
          ownershipPercentage: '',
          ownerTitle: '',
        },
        { emitEvent: false },
      )
      this.addNonPrimaryOwnerFields(null)
      this.owners.at(0).get('ownerEmail').clearValidators()

      this.owners.at(0).get('ownerEmail').updateValueAndValidity()
    }
    this.ownersForm.markAsPristine()
    this.ownersForm.markAsUntouched()
    this.showError = false
    this.getTotalPercentage()
  }

  goBack() {
    this.directAppStepService.updatedDirectAppStep(
      DirectAppStep.BUSINESS_ADDRESS_INFO,
    )
  }

  toggleOwnerSSN(i) {
    const ownerSsn = document.getElementById(
      'owner-ssn' + i,
    ) as HTMLInputElement

    ownerSsn.type = ownerSsn.type === 'text' ? 'password' : 'text'
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  saveOwnerInfo(i: number) {
    this.showError = true
    if (this.ownersForm.valid && this.totalPercentage <= 100) {
      this.collapseOwner[i] = true
      this.ownerInfo.owners = this.ownersForm.value.owners
      this.ownerInfo.applyingOnBehalfOfPrimary = !this.isPrimaryOwner
    } else {
      this.collapseOwner[i] = false
    }

    this.checkAddBtn()
  }

  checkAddBtn() {
    this.getTotalPercentage()
    this.checkContinue()

    this.showAddOwner =
      this.ownersForm.get('owners')['controls'].length >= 3 ||
      this.totalPercentage >= 100
        ? false
        : true
  }

  checkTotalPercentage() {
    return this.totalPercentage >= 51
  }

  submitOwnerInfo() {
    if (this.ownersForm.valid && this.totalPercentage <= 100) {
      for (const owner of this.ownersForm.value.owners) {
        owner.ownershipPercentage = Number(owner.ownershipPercentage)
      }
      const nextStep = this.comingFromReviewSubmit
        ? DirectAppStep.REVIEW_SUBMIT
        : DirectAppStep.ADDITIONAL_INFO

      this.directAppReviewSubmitService.setComingFromReviewSubmit(false)
      const data = {
        packetId: this.packetId,
        ownerInfo: {
          owners: this.ownersForm.value.owners,
          applyingOnBehalfOfPrimary: this.ownerInfo.applyingOnBehalfOfPrimary,
        },
        nextStep,
      }
      this.ownersSubscription = this.directAppService
        .submitOwnerInfo$(data)
        .subscribe(
          (res) => {
            this.clicked = false
            if (nextStep === DirectAppStep.REVIEW_SUBMIT) {
              window.location.replace(`/ui/direct-app/review-submit`)
            } else {
              window.location.replace(`/ui/direct-app/debt-info`)
            }
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    }
  }

  checkContinue() {
    if (
      !this.checkTotalPercentage() ||
      this.ownersForm.invalid ||
      this.totalPercentage > 100 ||
      this.collapseOwner.length === 0 ||
      this.collapseOwner.length !== this.owners['controls'].length
    ) {
      this.continue = false
    } else {
      this.continue = this.collapseOwner.every(this.check) ? true : false
    }
  }
  check(val) {
    return val === true
  }

  checkIsCollaspe() {
    return this.collapseOwner.every(this.check)
  }

  editOwner(i: number) {
    this.checkIsCollaspe()
    if (this.isPrimaryOwner) {
      this.addNonPrimaryOwnerFields(this.ownerInfo?.owners[0])
    }

    this.checkPrimaryRadioBtn(!this.ownerInfo.applyingOnBehalfOfPrimary)
    if (this.collapseOwner.every(this.check)) {
      this.collapseOwner[i] = false
      this.updateOwner = true
      setTimeout(() => {
        const btn = document.getElementById(
          'save-owner' + i,
        ) as HTMLInputElement
        btn.textContent = 'UPDATE'
      }, 0) // have to wait for the form
    }

    this.checkAddBtn()
    this.continue = false
  }

  deleteOwnerBtn() {
    this.directAppService.deleteItemSource.next(false)

    if (this.collapseOwner.every(this.check)) {
      this.deleteOwner()
    }
  }

  deleteOwner() {
    const i = this.deleteID
    if (!this.deleteID && this.deleteID !== 0) {
      this.ownerInfo?.owners.slice(0, -1)
      this.owners?.removeAt(-1)
      this.collapseOwner.splice(-1)
    } else {
      this.owners?.removeAt(i)
      this.ownerInfo?.owners.splice(i, 1)
      this.collapseOwner.splice(i, 1)
    }

    if (i === null) {
      return
    }
    if (i === 0 && this.owners.controls.length !== 0) {
      this.ownerInfo.applyingOnBehalfOfPrimary = true
      this.isPrimaryOwner = false
    }
    if (i === 0 && this.owners.controls.length === 0) {
      this.ownerInfo.applyingOnBehalfOfPrimary = false
      this.isPrimaryOwner = true
    }

    this.checkPrimaryRadioBtn(this.isPrimaryOwner)
    this.checkAddBtn()
    this.modalService.updatedItemTypeState(null)
    this.modalService.updatedModalState(false)
    this.modalService.updatedModalIDState(null)
    this.modalService.updatedModalType(null)
  }

  cancelOwner(i: number) {
    if (!this.updateOwner) {
      this.deleteOwner()
    } else {
      const owner = this.ownerInfo?.owners[i]

      this.getTotalPercentage()

      if (owner) {
        this.isPrimaryOwner = !this.ownerInfo.applyingOnBehalfOfPrimary

        this.owners.at(i).patchValue(
          {
            ownerFirstName: owner.ownerFirstName,
            ownerLastName: owner.ownerLastName,
            ownerMobilePhone: owner.ownerMobilePhone,
            ownerEmail: owner.ownerEmail,
            ownerTitle: owner.ownerTitle,
            ownershipPercentage: owner.ownershipPercentage,
          },
          { emitEvent: false },
        )

        if (!this.ownerInfo.applyingOnBehalfOfPrimary && i === 0) {
          this.owners.at(0).patchValue(
            {
              ownerSSN: owner.ownerSSN,
              ownerDOB: owner.ownerDOB,
              ownerStreetAddress: owner.ownerStreetAddress,
              ownerCity: owner.ownerCity,
              ownerState: owner.ownerState,
              ownerZipcode: owner.ownerZipcode,
            },
            { emitEvent: false },
          )
        }
        if (this.ownerInfo.applyingOnBehalfOfPrimary && i === 0) {
          const ownerVal = this.owners.at(0).value
          for (const val of this.notPrimaryOwnerControl) {
            if (val in ownerVal) {
              this.ownersForm.controls.owners['controls'][0].removeControl(val)
            }
          }
        }
        this.collapseOwner[i] = true
      }
    }

    this.checkAddBtn()
  }

  ngOnDestroy() {
    if (this.comingFromReviewSubmitSubscription) {
      this.comingFromReviewSubmitSubscription.unsubscribe()
    }

    if (this.ownersFormValueChangSubscription) {
      this.ownersFormValueChangSubscription.unsubscribe()
    }

    if (this.ownersSubscription) {
      this.ownersSubscription.unsubscribe()
    }

    if (this.ownersFormSubscription) {
      this.ownersFormSubscription.unsubscribe()
    }
  }
}
