<div class="nuula-tile-container">
  <div class="tile-container">
    <div class="logo-container title-logo">
      <div>
        <img class="main-logo" src="assets/imgs/mf-main-logo.svg" />
      </div>
    </div>

    <div class="tile-text-container">
      <ng-content></ng-content>

      <img class="title-icon" *ngIf="tileIcon" src="{{ tileIcon }}" />

      <div class="tile-title" *ngIf="tileTitle">{{ tileTitle }}</div>
      <div class="tile-body" *ngIf="tileBody">
        {{ tileBody }}
      </div>
      <div *ngIf="tileButton">
        <button class="nuula-btn">{{ tileButton }}</button>
      </div>
    </div>
  </div>
</div>
