import { NgModule } from '@angular/core'
import { Routes, RouterModule, Router } from '@angular/router'
import { CommonModule } from '@angular/common'

import { DirectComponent } from './direct.component'
import { NuulaLoanApplicationExpiredComponent } from './nuula/nuula-loan-application-expired/nuula-loan-application-expired.component'
import { NuulaAppSubmittedComponent } from './nuula/nuula-app-submitted/nuula-app-submitted.component'
import { NuulaLandingComponent } from './nuula/nuula-landing/nuula-landing.component'
import { NuulaResumeApplicationComponent } from './nuula/nuula-resume-application/nuula-resume-application.component'
import { NuulaAppDetailsComponent } from './nuula/nuula-app-details/nuula-app-details.component'

// https://github.com/okta/okta-angular#using-a-custom-login-page
export function onAuthRequired(oktaAuth, injector) {
  const router = injector.get(Router)

  // Redirect the user to your custom login page
  router.navigate(['/auth/login'])
}
const directRoutes: Routes = [
  {
    path: '',
    component: DirectComponent,
    children: [
      { path: 'address-info', component: DirectComponent },
      { path: 'application-review', component: DirectComponent },
      { path: 'application-submitted', component: DirectComponent },
      { path: 'bank-upload-preference', component: DirectComponent },
      { path: 'basic-info', component: DirectComponent },
      { path: 'contact-info', component: DirectComponent },
      { path: 'create-from-lead/:token', component: DirectComponent },
      { path: 'credit-auth-success', component: DirectComponent },
      { path: 'debt-info', component: DirectComponent },
      { path: 'email-verification/:token', component: DirectComponent },
      { path: 'owner-credit-auth/:token', component: DirectComponent },
      { path: 'owner-info', component: DirectComponent },
      { path: 'start-application', component: DirectComponent },
    ],
  },
  {
    path: 'nuula-application-expired',
    component: NuulaLoanApplicationExpiredComponent,
  },
  {
    path: 'nuula-app-details/:token',
    component: NuulaAppDetailsComponent,
  },

  {
    path: 'nuula-landing/:token',
    component: NuulaLandingComponent,
  },
  {
    path: 'resume-application-details/:token',
    component: NuulaResumeApplicationComponent,
  },
  {
    path: 'app-submitted-nuula',
    component: NuulaAppSubmittedComponent,
  },
]

@NgModule({
  imports: [RouterModule.forChild(directRoutes), CommonModule],
  exports: [RouterModule],
})
export class DirectRoutingModule {}
