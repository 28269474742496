import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

import * as INTF from '../../../core/models/interfaces'

@Injectable({
  providedIn: 'root',
})
export class BannersStatesService {
  public readonly BEEN_VERIFIED = 'beenVerified'
  public readonly ACCOUNT_UPDATED = 'updated'
  public readonly EMAIL_RESENT = 'emailResent'
  public readonly ACCOUNT_CREATED = 'accountCreated'
  public readonly EXISTING_ACCOUNT = 'existingAccount'
  public readonly ALREADY_VERIFIED = 'alreadyVerified'
  public readonly SESSION_EXP = 'sessionExpired'
  public readonly RENEW_TOKEN_FAILED = 'renewTokenFailed'
  private readonly BANNER_STORAGE_KEY = 'bannerState'

  bannerStateSource = new Subject<INTF.BannerStateType>()
  currentBannerState = this.bannerStateSource.asObservable()

  constructor() {}

  updatedBannerState(data: INTF.BannerStateType) {
    this.bannerStateSource.next(data)
  }

  storeBannerState(data: INTF.BannerStateType) {
    localStorage.setItem(this.BANNER_STORAGE_KEY, data)
  }

  checkIfStoredBannerState(clearState: boolean = false) {
    if (localStorage.getItem(this.BANNER_STORAGE_KEY)) {
      const bannerState = localStorage.getItem(
        this.BANNER_STORAGE_KEY,
      ) as INTF.BannerStateType
      this.updatedBannerState(bannerState)
    }

    if (clearState) {
      localStorage.removeItem(this.BANNER_STORAGE_KEY)
    }
  }
}
