import { Component, OnInit } from '@angular/core'
import { ModalService } from '../../../../core/services/modal/modal.service'

@Component({
  selector: 'app-connection-error',
  templateUrl: './connection-error.component.html',
  styleUrls: ['./connection-error.component.scss'],
})
export class ConnectionErrorComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  close() {
    this.modalService.updatedModalState(false)
    this.modalService.updatedModalType(null)
  }
}
