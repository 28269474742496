export enum PlaidAction {
  CLOSE_OAUTH = 'CLOSE_OAUTH',
  ERROR = 'ERROR',
  EXIT = 'EXIT',
  HANDOFF = 'HANDOFF',
  OPEN = 'OPEN',
  OPEN_OAUTH = 'OPEN_OAUTH',
  SELECT_BRAND = 'SELECT_BRAND',
  SELECT_INSTITUTION = 'SELECT_INSTITUTION',
  SUBMIT_CREDENTIALS = 'SUBMIT_CREDENTIALS',
  TRANSITION_VIEW = 'TRANSITION_VIEW',
}
