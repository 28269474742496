import { Component, OnInit, Input } from '@angular/core'
import { AuthService } from '../../../core/services/auth/auth.service'

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
})
export class DashboardHeaderComponent implements OnInit {
  @Input() dashboardData: any
  @Input() firstName: any
  @Input() fullname: any
  @Input() auth: boolean

  showSignoutMobileDropdown = false
  showSignoutDropdown = false

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  async logout() {
    this.authService.logout()
  }

  toggleSignOut() {
    this.showSignoutDropdown = !this.showSignoutDropdown
  }

  toggleMobileSignOut() {
    this.showSignoutMobileDropdown = !this.showSignoutMobileDropdown
  }

  close() {
    this.showSignoutMobileDropdown = false
  }

  nothing(event) {
    event.stopPropagation()
    event.preventDefault()
  }
}
