import { Component, OnInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'

import { Subscription } from 'rxjs'
import { format } from 'date-fns'

import { OktaAuthService } from '@okta/okta-angular'
import { ModalService } from '../../../../core/services/modal/modal.service'
import { AuthService } from '../../../../core/services/auth/auth.service'
import {
  checkIsTokenNuula,
  invalidateAccessToken,
  MFAccessToken,
} from '../../../../core'

@Component({
  selector: 'app-expiration',
  templateUrl: './expiration.component.html',
  styleUrls: ['./expiration.component.scss'],
})
export class ExpirationComponent implements OnInit, OnDestroy {
  accessToken: MFAccessToken | null
  currentAccessTokenSubscription: Subscription
  currentTokenTimeLeftSubscription: Subscription

  timeLeftSec: any

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    public oktaAuth: OktaAuthService,
    private router: Router,
  ) {}

  loanUuid: string
  timeoutMin: any
  timeoutSec: any

  ngOnInit() {
    this.currentAccessTokenSubscription =
      this.authService.currentMfAccessToken.subscribe((val) => {
        this.accessToken = val
      })
    this.currentTokenTimeLeftSubscription =
      this.authService.currentTokenTimeLeft.subscribe((tokenTimeLeft) => {
        this.timeLeftSec = tokenTimeLeft
        if (tokenTimeLeft) {
          const minsec = format(this.timeLeftSec * 1000, 'mm:ss').split(':')

          this.timeoutMin = minsec[0]
          this.timeoutSec = minsec[1]
        } else {
          this.logout()
        }
      })
  }

  continueSession() {
    console.log('trying to continue session')
    if (this.accessToken && this.accessToken.isValid) {
      this.authService.renewToken()
      // dont show any modal
      this.modalService.updatedModalType(null)
      this.modalService.updatedModalState(false)
    }
  }

  async logout() {
    if (checkIsTokenNuula(this.accessToken)) {
      // nuula close window when token expired or logout
      window.close()
      // backup to window.close is a delayed redirect to token expired tile
      setTimeout(() => window.location.replace('/ui/tiles/token-expired'), 1000)
    } else {
      invalidateAccessToken()
      this.authService.logout()
      // redirect to login
      this.router.navigate([`/auth/login`])
    }
  }

  ngOnDestroy() {
    if (this.currentAccessTokenSubscription) {
      this.currentAccessTokenSubscription.unsubscribe()
    }
    if (this.currentTokenTimeLeftSubscription) {
      this.currentTokenTimeLeftSubscription.unsubscribe()
    }
  }
}
