<div class="iframe-modal-wrapper">
  <iframe
  *ngIf="isReady"
  id="plaid-widget"
  title="Plaid Widget"
  [src]="urlSafe"
  style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;"
  (load)="onIframeLoaded()"
  >
  </iframe>
</div>