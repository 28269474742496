<div class="margin-auto">

  <div class="calendar margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto"> Change Payment Date</p>
  </div>

  <div class="rectangle margin-auto">
    <div class="content-group">
      <div class="content">
        <form [formGroup]="firstPaymentDateForm" class="payment-day-group">
          <a *ngFor="let paymentDay of calcNums.paymentDayOptionList
          let i=index" [attr.data-index]="i">

            <label for="{{paymentDay.date}}" class="first-day-group" id="first-day-group{{i}}"
              (click)="checkPaymentDay(i)">
              <div id="first-day-box{{i}}" class="start-day-group text-align-center">
                <div class="check-icon" id="first-dayIcon{{i}}" #icon{{i}}></div>
                <input type="radio" id="{{paymentDay.date}}" name="firstPaymentDay" value="{{paymentDay.date}}"
                  formControlName="firstPaymentDay">
                <div class="every-payment-day" id="first-everyday{{i}}">
                  <span class="every">Every</span>
                  <span class="payment-day"> {{paymentDay["dayName"]}}</span>
                </div>
                <div class="start-date">
                  <span>
                    Starting
                  </span>
                  <span>
                    {{paymentDay.formatedDate}}
                  </span>
                </div>
              </div>
            </label>
          </a>
        </form>

      </div>
    </div>
  </div>

  <div class="btn-container save text-align-center">
    <button [disabled]="clicked" (click)="changeFirstPaymentDay()" #submit type="submit" class="btn-background">SAVE
      DATE
    </button>
  </div>

  <div class="ok cursor-pointer margin-auto" (click)="close()">Cancel</div>

</div>