import { Component, OnInit, OnDestroy } from '@angular/core'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { Subscription } from 'rxjs'
import { OwnerCreditAuthResponse } from 'src/app/core/models'

@Component({
  selector: 'app-owner-credit-auth-success',
  templateUrl: './owner-credit-auth-success.component.html',
  styleUrls: ['./owner-credit-auth-success.component.scss'],
})
export class DirectAppOwnerCreditAuthSuccessComponent
  implements OnInit, OnDestroy
{
  creditAuthResponse: undefined | OwnerCreditAuthResponse = undefined
  directAppOwnerDetailSubscription: Subscription
  returning = false
  constructor(private directAppService: DirectAppService) {}

  ngOnInit(): void {
    // The verify token response will set the creditResponse - we need to subscribe to it
    this.directAppOwnerDetailSubscription =
      this.directAppService.currentDirectAppOwnerCreditAuth.subscribe(
        async (creditResponse) => {
          // no op
          this.creditAuthResponse = creditResponse
          if (creditResponse?.creditAuthorized) {
            this.returning = true
          }
        },
      )
  }

  getEmail() {
    return this.creditAuthResponse
      ? this.creditAuthResponse.owner.ownerEmail
      : ''
  }

  getText() {
    return this.returning
      ? `Your authorization was already submitted.`
      : `Thank you for submitting your authorization.`
  }
  getTitle() {
    return this.returning
      ? `Authorization Already Submitted`
      : `Authorization Submitted`
  }
  ngOnDestroy() {
    if (this.directAppOwnerDetailSubscription) {
      this.directAppOwnerDetailSubscription.unsubscribe()
    }
  }
}
