<div class="">

	<form [formGroup]="signupForm" (keydown)="onSubmit($event)">
		<div class="rectangle">
			<div class="auth-title">Create an Account</div>
			<div class="auth-info text-align-center margin-auto">Before you continue, you must create an
				account. Once created, you will be able to sign in later and continue
				where you left off.
			</div>
			<div class="creds-wrap">
				<div></div>
				<div class="creds">
					<div class="input-text-disabled em">
						<div class="label-text">Email</div>
						<div class="email-change" *ngIf="!emailChange">
							<div id="old-email">{{userEmail}}</div>

							<div (click)="changeEmail()" class="change">(Change)</div>
						</div>

						<div class="email-change" id="change-email" *ngIf="emailChange">
							<input id="new-email" (focus)="focusEmail()" (blur)="blurEmail()" type="email" formControlName="email"
								#newemail class="container input-text-disabled input-text">

							<div *ngIf="showError || blurEmailErr" class="alert alert-danger err-text">

								<div *ngIf="f.email.errors?.required " class="red-text" class="alert alert-danger err-text">
									<span class="alert alert-danger red-text help-text">
										<img class="symbol">

										<span>Email is required.</span>
									</span>
								</div>

								<div *ngIf="(f.email.errors?.pattern || f.email.errors?.email) && blurEmailErr"
									class="red-text alert alert-danger err-text">
									<img class="symbol">
									<span>Enter a valid email.</span>
								</div>
							</div>
						</div>
					</div>

					<div class="input-text-default">
						<div class="label-text">Create Password</div>
						<input (focus)="focusPassword()" (blur)="blurPassword()" formControlName="password" autocomplete="off"
							type="password" #password id="password" class="container input-text-basic-default-input input-text">
						<button (click)="togglePassword()" id="toggle-password" class="password"></button>

						<span *ngIf="f.password.valid">
							<div class="green-text">
								<img class="check-symbol">
								<span class="green help-text">Password strength: Good</span>
							</div>
						</span>

						<div *ngIf="showError || blurPasswordErr || focusPasswordErr" class="alert alert-danger err-text">
							<div *ngIf="f.password.invalid" id="invalid-err" class="alert alert-danger err-text">
								<!-- if error -->
								<span class="alert alert-danger help-text">
									<div class="red-text" *ngIf="f.password.invalid && f.password.value && blurPasswordErr">Password
										requirements have not been met.</div>

									<div *ngIf="f.password.errors"
										[ngClass]="{'red-text': blurPasswordErr || showError,'grey-text': focusPasswordErr}">
										<img class="symbol">
										<span id="weak">Password strength: Weak</span>
									</div>

									<div [ngClass]="{
										'green-text': !f.password.errors?.minlength && f.password.value!=='',
										'red-text': blurPasswordErr || showError,  
											'grey-text': focusPasswordErr && !(!f.password.errors?.minlength && f.password.value!=='')
										}">

										<img class="symbol">
										<span id="toofewchar">Use at least 8 characters</span>
									</div>



									<div [ngClass]="{
										'green-text': !f.password.errors?.haveUpperLowerCase && f.password.value!=='',
										'red-text': blurPasswordErr || showError,  
											'grey-text': focusPasswordErr && !(!f.password.errors?.haveUpperLowerCase && f.password.value!=='')
										}">
										<img class="symbol">
										<span id="upperlower">Use upper and lower case letters</span>
									</div>

									<div [ngClass]="{
										'green-text': !f.password.errors?.hasNum && f.password.value!=='',
										'red-text': blurPasswordErr || showError,  
											'grey-text': focusPasswordErr && !(!f.password.errors?.hasNum && f.password.value!=='')
										}">

										<img class="symbol">
										<span id="numbersymbol">Use a number</span>
									</div>

								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="btn-container">
					<button (click)="onSignup()" #submit type="submit" class="btn-background">CREATE ACCOUNT</button>
				</div>

			</div>
		</div>
	</form>
</div>