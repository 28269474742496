import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

import { Subscription } from 'rxjs'
import { OktaAuthService } from '@okta/okta-angular'

import { AuthService } from '../../core/services/auth/auth.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import * as INTF from '../../core/models/interfaces'
import { LoanService } from '../../core/services/loan/loan.service'

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss'],
})
export class GenericErrorComponent implements OnInit {
  @Input() docusignUrl: string
  currentErrorSubscription: Subscription
  @Input() genError: any
  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
  }
  error: any
  showgoHome = false
  showdocUsign = false
  showgSignIn = false

  constructor(
    private loanService: LoanService,
    private router: Router,
    private errorService: ErrorService,
    public oktaAuth: OktaAuthService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.currentErrorSubscription = this.errorService.currentError.subscribe(
      (data) => {
        this.error = data
        console.log('currentError', data)

        if (this.error.click === this.errorService.DOC_U_SIGN) {
          this.showdocUsign = true
        }
        if (this.error.click === this.errorService.GO_HOME) {
          this.showgoHome = true
        }

        if (this.error.click === this.errorService.SIGNIN) {
          this.showgSignIn = true
        }
      },
    )

    this.errorService.checkIfStoredError(true)

    // if no error in error service, go to not found
    if (!this.error) {
      // this.showgSignIn = true
      // this.router.navigate([`doc-checkout/not-found`])
      this.errorService.storeError(this.errorService.NOT_FOUND)
    } else {
      // if no error img use not found icon
      this.error.img = this.error.img ? this.error.img : 'error-err'
    }
  }

  goHome() {
    this.router.navigate([``])
  }

  goSignIn() {
    this.router.navigate([`/auth/login`])
  }

  async logout() {
    this.authService.logout()
  }
}
