<div class="modal-body">
  <div class="warning-wrapper">
    <img
      class="warning-icon"
      src="assets/imgs/error-generic-illustration.svg"
    />
  </div>
  <div class="modal-title">Email Verification Needed</div>
  <div class="modal-text">
    In order to submit your application, your email needs to be verified. Go to
    the Email Verification Section and send a verification email.
  </div>
  <div class="btn-container">
    <button (click)="close()" #submit class="btn-background">GOT IT</button>
  </div>
</div>
