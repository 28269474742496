import {
  Component,
  Renderer2,
  Input,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms'

import { distinctUntilChanged } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'
import { OktaAuthService } from '@okta/okta-angular'
import { format, parseISO } from 'date-fns'

import * as INTF from '../../core/models/interfaces'
import { LoanService } from '../../core/services/loan/loan.service'
import { StepService } from '../../core/services/steps/step.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { ValidatePhone } from '../../shared/validators/phone.validator'
import { ModalService } from '../../core/services/modal/modal.service'
import Utils from '../../shared/utils/utils'
import { EMAIL_FORM_REGEX, handleError } from '../../core'

@Component({
  selector: 'app-review-submit',
  templateUrl: './review-submit.component.html',
  styleUrls: ['./review-submit.component.scss'],
})
export class ReviewSubmitComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild('refName') refName: ElementRef
  @ViewChild('refNum') refNum: ElementRef
  @ViewChild('refRelationship') refRelationship: ElementRef
  @ViewChild('refBiz') refBiz: ElementRef

  isAuthenticated: boolean
  error: INTF.BackendError | null

  constructor(
    public oktaAuth: OktaAuthService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private stepService: StepService,
    private errorService: ErrorService,
    private loanService: LoanService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
  ) {}

  private bizRefFormSubscription: Subscription | undefined
  private guarantorFormSubscription: Subscription | undefined
  private reviewAndSubmitSubscription: Subscription | undefined
  getloanSubscription: Subscription

  guarantorForm: FormGroup
  bizRefForm: FormGroup
  emailForm: FormGroup

  @Input() calcNums: INTF.Loan
  @Input() userClaims: any

  showWarning: boolean
  showContact: boolean
  newphone: string[] = []
  sendEmails: string[] = []
  emailStatus: string[] = []
  newphoneNo = ''
  loanUuid: string
  businessReferenceRequest: INTF.BusinessReferenceRequest
  userName: string

  loanStatus: INTF.DocCheckoutStep | null = null
  stepStatus: INTF.DocCheckoutStep | null = null
  clicked = false
  showError = false
  blurBizRefPhoneErr = false
  focusBizRefPhoneErr = false
  focusAddPhoneErr = false
  blurAddPhoneErr = false
  blurAddEmailErr = false
  focusAddEmailErr = false
  defaultPhoneGuarantor: any
  defaultEmailGuarantor: any
  selectedDate: string

  ngOnInit() {
    window.scrollTo(0, 0)
    this.showWarning = false
    this.showContact = false
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.getLoan()
    this.initForm()
    this.valueChange()
  }

  getLoan() {
    this.getloanSubscription = this.loanService
      .getLoan$(this.loanUuid)
      .subscribe(
        (res) => {
          const step = INTF.stepFromString(
            res.docCheckoutStatus,
          ) as INTF.DocCheckoutStep
          if (step !== INTF.DocCheckoutStep.preApprovalConfirmation) {
            this.calcNums = res
            this.calcNums.guarantors.forEach((element) => {
              element.user.fullName =
                element.user.firstName + ' ' + element.user.lastName
            })
          }
          this.loanService.currentFirstPaymentDay.subscribe((data) => {
            if (data) {
              this.selectedDate = format(parseISO(data), 'EEEE MMMM dd, yyyy')
            } else {
              this.selectedDate = format(
                parseISO(this.calcNums.requestedStartDate),
                'EEEE MMMM dd, yyyy',
              )
            }
          })
          this.loanService.loanDataSource.next(res)
          const route = this.stepService.stepToRoute(step, this.loanUuid)

          this.router.navigate([route])
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  showChangeFirstPaymentDay() {
    this.modalService.updatedModalState(true)
    this.modalService.updatedModalType(this.modalService.CHANGEPAYMENTDAY)
  }

  toggleContact() {
    this.showContact = !this.showContact
    if (!this.showContact) {
      this.guarantorForm = new FormGroup({
        contacts: this.fb.array([]),
      })

      this.emailForm = new FormGroup({
        emails: this.fb.array([]),
      })
      this.valueChange()
    }
  }

  formatBusinessPhone(element) {
    this.newphoneNo = Utils.formatPhoneNo(element.target.value)

    this.bizRefForm.patchValue(
      {
        refNum: this.newphoneNo,
      },
      { emitEvent: false },
    )
  }
  amountKeydown(e) {
    Utils.AmountKeydown(e)
  }

  initForm() {
    this.bizRefForm = this.fb.group({
      refName: ['', Validators.required],
      refNum: [
        '',
        { validators: [Validators.required, ValidatePhone], updateOn: 'blur' },
      ],
      refRelationship: ['', Validators.required],
      refBizEntity: ['', Validators.required],
    })

    this.guarantorForm = new FormGroup({
      contacts: this.fb.array([]),
    })

    this.emailForm = new FormGroup({
      emails: this.fb.array([]),
    })
  }

  get contacts() {
    return this.guarantorForm.get('contacts') as FormArray
  }

  get emailsControl() {
    return this.emailForm.get('emails') as FormArray
  }

  get bizRefFormControls() {
    return this.bizRefForm.controls
  }

  valueChange() {
    this.bizRefFormSubscription = this.bizRefForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((value) => {})

    this.guarantorFormSubscription = this.guarantorForm.valueChanges.subscribe(
      (value) => {
        value.contacts.forEach(
          (element: INTF.GuarantorPhone, index: number) => {
            this.newphone[index] = Utils.formatPhoneNo(element.phone)

            // important to have the emitEvent set to false
            this.contacts
              .at(index)
              .patchValue({ phone: this.newphone[index] }, { emitEvent: false })
          },
        )
      },
    )
  }

  // VERY IMPORTANT TO USE THIS HOOK
  ngAfterViewChecked() {
    this.cdr.detectChanges()
  }

  focusBizRefPhone() {
    this.blurBizRefPhoneErr = false
    this.focusBizRefPhoneErr = true
  }

  blurBizRefPhone() {
    this.blurBizRefPhoneErr = true
    this.focusBizRefPhoneErr = false
  }

  blurAddPhone() {
    this.focusAddPhoneErr = false
    this.blurAddPhoneErr = true
  }

  focusAddPhone() {
    this.focusAddPhoneErr = true
    this.blurAddPhoneErr = false
  }

  blurAddEmail() {
    this.blurAddEmailErr = true
    this.focusAddEmailErr = false
  }

  focusAddEmail() {
    this.focusAddEmailErr = true
    this.blurAddEmailErr = false
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.submitBizRref()
    }
  }

  submitBizRref() {
    this.calcNums.guarantors.forEach(
      (guarantor: INTF.Guarantor, index: number) => {
        const phones = this.guarantorForm.value.contacts
        const emails = this.emailForm.value.emails

        if (phones) {
          phones.forEach((element: INTF.GuarantorPhone) => {
            if (guarantor.user.fullName === element.phoneGuarantorName) {
              guarantor.additionalPhoneNumbers.push(element.phone)
            }
          })
        }

        if (emails) {
          emails.forEach((element: INTF.GuarantorEmail) => {
            if (guarantor.user.fullName === element.emailGuarantorName) {
              guarantor.additionalEmails.push(element.email)

              if (element.checkEmail) {
                guarantor.additionalStatementEmails.push(element.email)
              }
            }
          })
        }
      },
    )

    this.calcNums.businessReference.name = this.bizRefForm.value.refName
    this.calcNums.businessReference.phoneNumber = this.bizRefForm.value.refNum
    this.calcNums.businessReference.relationship =
      this.bizRefForm.value.refRelationship
    this.calcNums.businessReference.businessEntity =
      this.bizRefForm.value.refBizEntity

    this.businessReferenceRequest = {
      businessReference: this.calcNums.businessReference,
      guarantors: this.calcNums.guarantors,
    }

    this.showError = true

    if (
      this.bizRefForm.valid &&
      this.guarantorForm.valid &&
      this.emailForm.valid &&
      !this.clicked
    ) {
      this.clicked = true

      this.reviewAndSubmitSubscription = this.loanService
        .reviewAndSubmit$(this.loanUuid, this.businessReferenceRequest)
        .subscribe(
          (res) => {
            this.showError = false

            this.loanStatus = res.updatedStep
            this.stepService.updatedStep(
              this.stepService.nextStep(INTF.DocCheckoutStep.businessReference),
              this.loanUuid,
            )
            this.loanService.updatedStatus$(res.updatedStep)
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    }
  }

  onAddPhone() {
    this.contacts.push(
      new FormGroup({
        phone: new FormControl('', {
          validators: [Validators.required, ValidatePhone],
          updateOn: 'blur',
        }),
        phoneGuarantorName: new FormControl('', Validators.required),
      }),
    )
  }

  onAddEmail() {
    this.emailsControl.push(
      new FormGroup({
        email: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_FORM_REGEX),
        ]),
        emailGuarantorName: new FormControl('', Validators.required),
        checkEmail: new FormControl(false, [Validators.required]),
      }),
    )
  }

  onDeleteContacts(index: number) {
    this.contacts.removeAt(index)
  }

  onDeleteEmails(index: number) {
    this.emailsControl.removeAt(index)
  }

  onlyOneGuarantor() {
    return this.calcNums.guarantors.length === 1
  }

  thirdGuarantor(guarantor) {
    return (
      this.calcNums.guarantors.length === 3 &&
      this.calcNums.guarantors.indexOf(guarantor) === 2
    )
  }

  ngOnDestroy() {
    if (this.bizRefFormSubscription) {
      this.bizRefFormSubscription.unsubscribe()
      this.bizRefFormSubscription = null
    }

    if (this.guarantorFormSubscription) {
      this.guarantorFormSubscription.unsubscribe()
      this.guarantorFormSubscription = null
    }

    if (this.getloanSubscription) {
      this.getloanSubscription.unsubscribe()
      this.getloanSubscription = null
    }

    if (this.reviewAndSubmitSubscription) {
      this.reviewAndSubmitSubscription.unsubscribe()
      this.reviewAndSubmitSubscription = null
    }
  }
}
