import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../../../../core/services/modal/modal.service'
import { DirectAppService } from '../../../../core/services/direct/direct-app.service'
import * as INTF from '../../../../core/models/interfaces'

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class DeleteComponent implements OnInit {
  @Input() modalID: number
  @Input() modalItemType: INTF.DeleteItemType

  constructor(
    private modalService: ModalService,
    private directAppService: DirectAppService,
  ) {}

  ngOnInit(): void {}

  close() {
    this.modalService.updatedModalState(false)
  }

  deleteItem() {
    this.directAppService.deleteItemSource.next(true)
    this.modalService.updatedModalState(false)
    this.modalService.updatedModalType(null)
  }
}
