import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FooterComponent } from './components/footer/footer.component'
import { HeaderComponent } from '../doc-checkout/header/header.component'
import { SidebarComponent } from '../doc-checkout/sidebar/sidebar.component'
import { RouterModule } from '@angular/router'
import { LogoComponent } from './components/logo/logo.component'
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component'
import { ModalComponent } from './components/modal/modal.component'
import { UccComponent } from './components/modal/ucc/ucc.component'
import { DisbursementComponent } from './components/modal/disbursement/disbursement.component'
import { ExpirationComponent } from './components/modal/expiration/expiration.component'
import { NumOfPaymentComponent } from './components/modal/num-of-payment/num-of-payment.component'
import { ChangePaymentDueDayComponent } from './components/modal/change-payment-due-day/change-payment-due-day.component'
import { ConnectionErrorComponent } from './components/modal/connection-error/connection-error.component'
import { DeleteComponent } from './components/modal/delete/delete.component'
import { EmailVerificationNeededComponent } from './components/modal/email-verification-needed/email-verification-needed.component'
import { GenericErrorComponent } from '../doc-checkout/generic-error/generic-error.component'
import { ServerErrorComponent } from '../doc-checkout/server-error/server-error.component'
import { AccountAlreadyExistsComponent } from './components/modal/account-already-exists/account-already-exists.component'
import { BeforeContinueComponent } from './components/modal/before-continue/before-continue.component'

@NgModule({
  declarations: [
    GenericErrorComponent,
    ServerErrorComponent,
    ChangePaymentDueDayComponent,
    ConnectionErrorComponent,
    DisbursementComponent,
    EmailVerificationNeededComponent,
    ExpirationComponent,
    FooterComponent,
    HeaderComponent,
    LogoComponent,
    LoadingSpinnerComponent,
    ModalComponent,
    NumOfPaymentComponent,
    SidebarComponent,
    UccComponent,
    DeleteComponent,
    AccountAlreadyExistsComponent,
    BeforeContinueComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [
    BeforeContinueComponent,
    ChangePaymentDueDayComponent,
    CommonModule,
    DisbursementComponent,
    ConnectionErrorComponent,
    EmailVerificationNeededComponent,
    ExpirationComponent,
    FooterComponent,
    FormsModule,
    HeaderComponent,
    LoadingSpinnerComponent,
    LogoComponent,
    ModalComponent,
    NumOfPaymentComponent,
    ReactiveFormsModule,
    RouterModule,
    SidebarComponent,
    UccComponent,
    DeleteComponent,
    GenericErrorComponent,
    ServerErrorComponent,
  ],
})
export class SharedModule {}
