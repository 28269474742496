<div class="auth-page-container">
  <div class="info-page-wrap">
    <div></div>
    <div>
      <app-logo></app-logo>

      <div class="rectangle margin-auto">
        <div class="added-icon docusign-finish-later"></div>
        <div class="text-align-center">
          <p class="pre-title margin-auto">You’ve saved your agreement to finish later.</p>
        </div>
        <div class="rectangle margin-auto">
          <div class="review sign-info margin-auto text-align-center">
            To continue closing your loan, you must sign your loan
            agreement. Use the button below to go back to the agreement.
          </div>

          <div class="text-align-center margin-auto">
            <div>
              <button *ngIf="docusignUrl" (click)="docUsign()" class="margin-auto text-align-center btn-background">
                SIGN LOAN AGREEMENT
              </button>

              <div class="signout">
                <div class="ok text-align-center" (click)="logout()">Sign Out</div>
                <div class="link-arrow-icon"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>