import { BasicInfo } from './interfaces'
import { DirectAppStep } from '../enums'

export const directAppStepToStageList = [
  DirectAppStep.ADDITIONAL_INFO,
  DirectAppStep.BUSINESS_ADDRESS_INFO,
  DirectAppStep.BUSINESS_CONTACT_INFO,
  DirectAppStep.BUSINESS_BASIC_INFO,
  DirectAppStep.CREATE_ACCOUNT,
  DirectAppStep.DOCUMENT,
  DirectAppStep.OWNER_INFO,
  DirectAppStep.REVIEW_SUBMIT,
]
