export enum ModalType {
  BEFORE_CONTINUE = 'before-continue',
  CHANGEPAYMENTDAY = 'changepaymentday',
  CONNECTION_ERROR = 'connection-error',
  DISBURSEMENT = 'disbursement',
  PAYMENT = 'NumOfPayment',
  PLAID = 'plaid',
  TIMEOUT = 'timeout',
  UCC = 'ucc',
  DELETE = 'delete',
  EMAIL_VERIFICATION_NEEDED = 'email-verification-needed',
  ACCOUNT_ALREADY_EXISTS = 'account-already-exists',
}
