import { Component, OnInit } from '@angular/core'
import { ModalService } from '../../../../core/services/modal/modal.service'

@Component({
  selector: 'app-email-verification-needed',
  templateUrl: './email-verification-needed.component.html',
  styleUrls: ['./email-verification-needed.component.scss'],
})
export class EmailVerificationNeededComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  close() {
    this.modalService.updatedModalState(false)
  }
}
