import { Component, OnInit, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'

import { AuthService } from '../../core/services/auth/auth.service'
import * as INTF from '../../core/models/interfaces'

@Component({
  selector: 'app-funded',
  templateUrl: './funded.component.html',
  styleUrls: ['./funded.component.scss'],
})
export class FundedComponent implements OnInit {
  @Input() calcNums: any
  @Input() userClaims: any

  loanUuid: string
  isAuthenticated: boolean
  achRequestedDate: string

  constructor(
    private authService: AuthService,
    public oktaAuth: OktaAuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    // need to wait for backend to return loan obj
  }

  goDashboard() {
    this.router.navigate([`/`])
  }

  async logout() {
    this.authService.logout()
  }
}
