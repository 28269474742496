<div id="page-container">
  <div [ngClass]="'mulligan-content-wrap'">
    <app-modal
      [modalItemType]="modalItemType"
      [modalMsg]="modalMsg"
      *ngIf="modalMsg && showModal"
    ></app-modal>
    <router-outlet></router-outlet>
  </div>
</div>
