<div class="intro">
  <div class="note">
    Note: Running balance is contingent upon completing all payments by due date.</div>
  <div class="download">
    <img (click)="downloadSchedule()" src='/assets/imgs/download-icon.svg'>
    <span (click)="downloadSchedule()">Download</span>
  </div>
</div>

<div class="mobile-payment-schedule">
  <div>Payment Schedule</div>
  <div class="mobile-download">
    <img (click)="downloadSchedule()" src='/assets/imgs/download-icon.svg'>
    <span (click)="downloadSchedule()">Download</span>
  </div>
</div>

<div class="payment-table-desktop">
  <div class="header">Date</div>
  <div class="header">Payment Amount</div>
  <div class="header">Running Balance</div>

  <!-- Table Row -->
  <div *ngFor="let item of paymentScheduleList; index as i; first as isFirst" class="row">
    <div class="box" id="paymentDate{{i}}">
      {{     
        item.date
      }}
    </div>
    <div class="box" id="paymentAmt{{i}}">{{item.paymentAmt| currency}}
    </div>
    <div class="box" id="paymentBalance{{i}}">{{item.runningBalance| currency}}
    </div>
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
<div class="payment-table-mobile">
  <div class="payment-table-item" *ngFor="let item of paymentScheduleList; index as i; first as isFirst">
    <div class="payment-row">
      <div class="payment-date">Date</div>
      <div class="payment-date-value">{{item.date}}</div>

    </div>
    <div class="payment-row">
      <div class="payment-amount">Payment Amount</div>
      <div class="payment-amount-value">{{item.paymentAmt| currency}}</div>

    </div>
    <div class="payment-row">
      <div class="payment-balance">Running Balance</div>
      <div class="payment-balance-value">{{item.runningBalance| currency}}</div>

    </div>
  </div>
</div>


<div class="payment-table-btn-group">
  <div class="prev-payment">
    <span *ngIf="page > 0" class="prev" (click)="prevPage()">
      <img class="arrow-left" src="/assets/imgs/arrow-left-icon.svg">
      Previous 10
    </span>
    <span *ngIf="page <= 0"></span>
  </div>
  <div class="next-payment">
    <span class="next" (click)="nextPage()">Next 10
      <img class="arrow-right" src="/assets/imgs/arrow-right-icon.svg">
    </span>
  </div>
</div>