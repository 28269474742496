import { MFAccessToken } from '../models'
import { MF_ACCESS_TOKEN } from '../strings'
export const getAccessToken: () => null | MFAccessToken = () => {
  const token = window.localStorage.getItem(MF_ACCESS_TOKEN)
  if (token) {
    try {
      const mfToken = JSON.parse(token) as MFAccessToken
      return mfToken
    } catch (error) {
      return null
    }
  }
  return null
}
