import { Component, OnInit } from '@angular/core'
import { ErrorService } from '../../core/services/generic-error/error.service'

@Component({
  selector: 'app-ineligible',
  templateUrl: './ineligible.component.html',
  styleUrls: ['./ineligible.component.scss'],
})
export class IneligibleComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.storeError(this.errorService.LOAN_DISABLED)
  }
}
