import { Component, OnInit } from '@angular/core'
import { ModalService } from '../../../../core/services/modal/modal.service'

@Component({
  selector: 'app-account-already-exists',
  templateUrl: './account-already-exists.component.html',
  styleUrls: ['./account-already-exists.component.scss'],
})
export class AccountAlreadyExistsComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}
  close() {
    this.modalService.updatedModalState(false)
  }
}
