<div class="margin-auto">

  <div class="delete-icon margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto">
      Are you sure you want to delete this {{modalItemType}} from the application?
    </p>
  </div>

  <div class="btn-container save text-align-center">
    <button (click)="deleteItem()" #submit type="submit" class="btn-background">
      YES, DELETE {{modalItemType|uppercase}}
    </button>
  </div>

  <div class="ok cursor-pointer margin-auto" (click)="close()">No, Don’t Delete</div>

</div>