import { AbstractControl } from '@angular/forms'
import isValid from 'date-fns/isValid'
import getYear from 'date-fns/getYear'
import { parse } from 'date-fns'

export function InValidateDOB(control: AbstractControl) {
  if (control.value && control.value.length !== 0) {
    const dateStr = parse(control.value, 'MM/dd/yyyy', new Date())

    const birthYear = getYear(dateStr)
    const today: Date = new Date()
    const thisYear = getYear(today)
    const age: number = thisYear - birthYear

    // fun-fact: oldest living person (disputed) died in France
    // in 1997 at the age of 122 (an age which some people dispute!)
    if (!dateStr || !isValid(dateStr) || !age || age < 18 || age > 122) {
      // Erik wrote this!!!!!
      return { InValidateDOB: true }
    }
  }
  return null
}
