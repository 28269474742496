import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { ErrorService } from '../../core/services/generic-error/error.service'

import { environment } from '../../../environments/environment'
import * as INTF from '../../core/models/interfaces'
import { Env } from 'src/main'

@Component({
  selector: 'app-plaid-iframe',
  templateUrl: './plaid-iframe.component.html',
  styleUrls: ['./plaid-iframe.component.scss'],
})
export class PlaidIframeComponent implements OnInit {
  @Input() packetId: string
  @Input() applicationInfo
  @Input() metadata: INTF.Metadata
  phoneNumber: string
  companyName: string
  email: string
  firstName: string
  isReady = false
  lastName: string
  data: any
  formattedDesiredLoanAmt: any
  formattedMonthlyAverageSalesAmt: any
  url = ''
  urlSafe: SafeResourceUrl

  constructor(
    private directAppService: DirectAppService,
    private errorService: ErrorService,
    private router: Router,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.prepareForIframe()
  }

  onIframeLoaded() {
    // No op
  }

  prepareForIframe() {
    const accountId = this.metadata.sfAccountId
    const boxFolderId = this.metadata.boxFolderId
    const company = this.applicationInfo.accountInfo.companyName
    let domain = 'auth.mulliganfunding.com' // default is prod
    const email = this.metadata.username
    const firstName = this.applicationInfo.accountInfo.firstName
    const lastName = this.applicationInfo.accountInfo.lastName

    // plaid will point to dev for env: local, dev, or stg
    const devEnvsForPlaid = [
      Env.local as string,
      Env.dev as string,
      Env.stg as string,
    ]

    if (devEnvsForPlaid.includes(environment.environment)) {
      domain = 'dev.plaid.mulligancloud.com'
    }
    console.log('--- Plaid domain: ' + domain)

    this.url = `https://${domain}/direct-app?accountId=${accountId}&company=${company}&email=${email}&firstName=${firstName}&lastName=${lastName}&sfBoxFolderId=${boxFolderId}`

    // This is angular specific handling - otherwise the angular app refuses the iframe with a generated url
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)

    this.isReady = true
  }
}
