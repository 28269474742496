import {
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from '@angular/platform-browser'
import { throwError } from 'rxjs'
import { DirectAppStep } from '../enums'
import { BoxFileUpload } from './box-file-upload'
import { ConnectedBankAccount } from './connected-bank-account'

export enum ErrorCode {
  BUSINESS_VERIFICATION_MAX_ATTEMPTS_REACHED = 5,
  BUSINESS_VERIFICATION_INVALID_DATA = 10, // 2 more attempts
  BUSINESS_VERIFICATION_ONE_ATTEMPT_LEFT = 15,
  OKTA_EMAIL_VERIFIED = 25,
  OKTA_ERROR_USER_EXISTS = 30,
  OKTA_PASSWORD_REQUIREMENTS_ERROR = 31,
  EMAIL_VERIFICATION_FAILED = 35,
}

export const DocusignEvent = {
  decline: 'decline',
  cancel: 'cancel',
  unknown: 'unknown',
  sessionTimeout: 'session_timeout',
}

export interface Docusign {
  updatedStep: DocCheckoutStep
  docCheckoutStatus: stepStrings
  docusignUrl: string
}

export interface ErrType {
  title?: string
  message: string
  img?: string
  btn?: string
  click?: string
  errorCode?: number
  email?: string
}

export type StepName =
  | 'Introduction'
  | 'Verification'
  | 'Loan Details'
  | 'Loan Agreement'
  | 'Review & Submit'
export type DirectStepName =
  | 'Start Application'
  | 'Business Information'
  | 'Owner Information'
  | 'Additional Information'
  | 'Documents'
  | 'Review & Submit'

export type StepNum = 1 | 2 | 3 | 4 | 5
export type DirectStepNum = 1 | 2 | 3 | 4 | 5 | 6

export const TOTAL_PROGRESS_STEP = 5
export const TOTAL_DIRECT_PROGRESS_STEP = 6

export enum DocCheckoutStep {
  unknownStep = -1,
  disabled = 500,
  unableToVerifyBusiness = 501,
  businessVerificationAndCreateAcct = 5,
  declinedToSign = 502,
  verifyEmail = 15,
  loanIntroduction = 20,
  identityVerificationQuestions = 35,
  retryVerificationQuestions = 37,
  unableToVerifyIdentity = 40,
  loanDetails = 45,
  loanAgreement = 50,
  loanAgreementFinishLater = 53,
  businessReference = 55,
  preApprovalConfirmation = 60,
  funded = 65,
  ineligible = 503,
}

// KBA request body
export interface BlockscoreQuestionSet {
  blockscoreQuestionSetId: string
  answers: BlockscoreQuestionAnswer[]
}

export interface BlockscoreQuestionAnswer {
  question_id: number
  answer_id: number
}

export interface BackendError {
  status: string
  error: {
    message: string
    title: string
  }
}

export type stepStrings = keyof typeof DocCheckoutStep

export type DirectAppStepString = keyof typeof DirectAppStep

export const DOC_CHECKOUT_PROCESS_STEPS = [
  DocCheckoutStep.loanIntroduction,
  DocCheckoutStep.identityVerificationQuestions,
  DocCheckoutStep.loanDetails,
  DocCheckoutStep.loanAgreement,
  DocCheckoutStep.businessReference,
  DocCheckoutStep.unableToVerifyIdentity,
  DocCheckoutStep.disabled,
  DocCheckoutStep.unableToVerifyBusiness,
  DocCheckoutStep.declinedToSign,
  DocCheckoutStep.loanAgreementFinishLater,
  DocCheckoutStep.preApprovalConfirmation,
  DocCheckoutStep.funded,
  DocCheckoutStep.ineligible,
  DocCheckoutStep.retryVerificationQuestions,
]
export const DOC_CHECKOUT_SIDEBAR_VISIBLE_STEPS = [
  DocCheckoutStep.loanIntroduction,
  DocCheckoutStep.identityVerificationQuestions,
  DocCheckoutStep.loanDetails,
  DocCheckoutStep.loanAgreement,
  DocCheckoutStep.businessReference,
  DocCheckoutStep.retryVerificationQuestions,
]

export function stepToHumanReadableString(
  step: DocCheckoutStep,
): [StepName, StepNum] {
  switch (step) {
    case DocCheckoutStep.loanIntroduction:
      return ['Introduction', 1]
    case DocCheckoutStep.identityVerificationQuestions:
      return ['Verification', 2]
    case DocCheckoutStep.retryVerificationQuestions:
      return ['Verification', 2]
    case DocCheckoutStep.loanDetails:
      return ['Loan Details', 3]
    case DocCheckoutStep.loanAgreement:
      return ['Loan Agreement', 4]
    case DocCheckoutStep.businessReference:
      return ['Review & Submit', 5]
  }
}

export function directAppStepToHumanReadableString(
  step: DirectAppStep,
): [DirectStepName, DirectStepNum] {
  switch (step) {
    case DirectAppStep.CREATE_ACCOUNT:
      return ['Start Application', 1]
    case DirectAppStep.BUSINESS_CONTACT_INFO:
      return ['Business Information', 2]
    case DirectAppStep.BUSINESS_ADDRESS_INFO:
      return ['Business Information', 2]
    case DirectAppStep.BUSINESS_BASIC_INFO:
      return ['Business Information', 2]
    case DirectAppStep.OWNER_INFO:
      return ['Owner Information', 3]
    case DirectAppStep.ADDITIONAL_INFO:
      return ['Additional Information', 4]
    case DirectAppStep.DOCUMENT:
      return ['Documents', 5]
    case DirectAppStep.REVIEW_SUBMIT:
      return ['Review & Submit', 6]
  }
}

export function dashboardStatuses(step: DocCheckoutStep) {
  switch (step) {
    case DocCheckoutStep.unknownStep:
    case DocCheckoutStep.unableToVerifyBusiness:
    case DocCheckoutStep.businessVerificationAndCreateAcct:
    case DocCheckoutStep.verifyEmail:
      return ''
    case DocCheckoutStep.disabled:
      return 'Loan Voided'
    case DocCheckoutStep.declinedToSign:
      return 'Declined To Sign'
    case DocCheckoutStep.loanIntroduction:
    case DocCheckoutStep.identityVerificationQuestions:
    case DocCheckoutStep.loanDetails:
    case DocCheckoutStep.loanAgreement:
    case DocCheckoutStep.loanAgreementFinishLater:
    case DocCheckoutStep.businessReference:
      return 'Loan Documentation Pending'
    case DocCheckoutStep.unableToVerifyIdentity:
      return 'Unable To Verify Identity'
    case DocCheckoutStep.preApprovalConfirmation:
      return 'Under Final Review'
    case DocCheckoutStep.funded:
      return 'Funded'
    case DocCheckoutStep.ineligible:
      return 'Unable to Process'
  }
}

export function stepToString(step: DocCheckoutStep): string {
  return DocCheckoutStep[step]
}

export function stepFromString(step: stepStrings): DocCheckoutStep {
  if (!DocCheckoutStep[step]) {
    throwError('Unknown loan status ' + step)
  }
  return DocCheckoutStep[step]
}

export interface Guarantor {
  userId: number
  additionalStatementEmails: string[]
  id: number
  additionalEmails: string[]
  additionalPhoneNumbers: string[]
  user: User
  uuid: string
  primary: boolean
}

export interface MulliganReps {
  name: string
  email: string
  id: number
  title: string
  phoneNumber: string
  uuid: string
  staffMemberType: string
}

export interface Payoff {
  amount: number
  payoffRecipientName: string
}

export interface BusinessReference {
  relationship: string
  name: string
  businessEntity: string
  phoneNumber: string
}

export interface DocusigEvent {
  event: string
}

export interface LoanStatus {
  docCheckoutStatus: stepStrings
  updatedStep: DocCheckoutStep
  blockscoreFailedAttempts?: number
}
export interface ResendEmail {
  email: string
}

export interface AuthUser {
  email: string
  password: string
  tokens?: string
}

export interface ResetPassword {
  password: string
  token: string
}

export interface BizVeriInputs {
  dob: string
  taxId: string
  ssn: string
}

export interface PostBizVerif {
  email: string
  docCheckoutStatus: stepStrings
  userExists: boolean
  magicLinkLogin: boolean
}

export interface PrimaryUser {
  claimed: boolean
  firstName: string
  lastName: string
  email: string
  failedVerificationAttempts: number
  id: number
  phoneNumber: string
  uuid: string
  dob: string
  fullName?: string
}

export interface Dashboard {
  accounts: Account[]
  user: User
}
export interface User {
  fullName?: string
  addressCity: string
  addressCountryCode: string
  addressPostalCode: string
  addressState: string
  addressStreet1: string
  addressStreet2: string
  claimed: boolean
  email: string
  emailVerified: boolean
  firstName: string
  id: number
  lastName: string
  middleName: string
  phoneNumber: string
  uuid: string
  sfContactId: string
}

export interface Account {
  addressPostalCode: string
  addressStreet1: string
  addressStreet2: string
  addressCity: string
  addressState: string
  addressCountryCode: string
  nameOfBusiness: string
  id?: number
  uuid: string
  corporateStructure: string
}

export interface PaymentScheduleItem {
  date: string
  paymentAmt: number
  runningBalance: number
}

export interface PaymentScheduleWithPage {
  nextPage: number
  paymentScheduleList: PaymentScheduleItem[]
}

export interface PaymentDayOption {
  date: string
  dayName: string
  formatedDate?: string
}

export interface Token {
  token: string
}

export interface ForgotPasswordReq {
  email: string
}

export interface Loan {
  loanAgreementExists: string
  id: number
  payoffs: Payoff[]
  achRequestedDate: any
  disbursement: number
  paymentAmt: number
  originationFee: number
  loanAmount: number
  totalPaybackAmt: number
  sfid: string
  bankAccount: string
  uuid: string
  existingStartDate: string
  accountId: number
  numOfPayment: number
  paymentFreq: string
  docCheckoutStatus: stepStrings
  requestedStartDate: string
  businessReference: BusinessReference
  paymentScheduleList: PaymentScheduleItem[]
  paymentDayOptionList: PaymentDayOption[]
  account: Account
  primaryUser: PrimaryUser
  guarantors: Guarantor[]
  mulliganReps: MulliganReps[]
}

export interface BusinessReferenceRequest {
  guarantors: Guarantor[]
  businessReference: BusinessReference
}

export interface GuarantorEmail {
  email: string
  emailGuarantorName: string
  checkEmail: boolean
}

export interface GuarantorPhone {
  phone: string
  phoneGuarantorName: string
}

export interface PaymentDate {
  requestedStartDate: string
}

export type DeleteItemType = 'owner' | 'lender'

export type BannerStateType =
  | 'default banner state value'
  | 'notMatched'
  | 'beenVerified'
  | 'accountCreated'
  | 'emailResent'
  | 'existingAccount'
  | 'sessionExpired'
  | 'updated'
  | 'notSentEmail'
  | 'sentEmail'
  | 'alreadyVerified'
  | 'renewTokenFailed'

export type safeValue =
  | SafeHtml
  | SafeStyle
  | SafeScript
  | SafeUrl
  | SafeResourceUrl

export interface PutLoanStatus {
  step: DocCheckoutStep
}

export interface IdentityVerification {
  userId: number
  id: number
  uuid: string
  active: boolean
  scored: null
  blockscoreQuestionSetId: string
  docCheckoutStatus?: string
  questionSet?: {
    createdAt: number
    updatedAt: number
    object: 'question_set'
    id: string
    personId: string
    score: null
    expired: boolean
    timeLimit: number
    questions: Questions[]
  }
}

export interface Questions {
  id: number
  question: string
  answers: Answers[]
}

export interface Answers {
  id: number
  answer: string
}

// start direct app interfaces
export interface DirectAppInfo {
  respId: string
  applicationInfo?: ApplicationInfo
}

export interface DirectAppResp {
  status: string
  metadata: Metadata
}
export interface Packet {
  applicationInfo: ApplicationInfo
  id: string
  metadata: Metadata
  _attachments: string
  _etag: any
  _rid: string
  _self: string
  _ts: number
}

export interface DirectAppGetPacketResp {
  packet: Packet
  status: string
}
export interface ApplicationInfo {
  respId?: string
  accountInfo?: AccountInfo
  basicInfo?: BasicInfo
  contactInfo?: ContactInfo
  addressInfo?: AddressInfo
  ownerInfo?: OwnerInfo
  debtInfo?: DebtInfo[]
  bankStatementInfo?: ConnectedBankAccount[]
}
export interface AccountInfo {
  firstName: string
  lastName: string
  companyName: string
  phoneNumber: string
  desiredLoanAmount: number
  loanPurpose: string
  timeInBusiness: string
  monthlyAverageSales: number
  email: string
  password: string
  privacyPolicyAndeConsent: string
}

export interface BasicInfo {
  businessLegalName: string
  doingBusinessAs?: string
  corporateStructure: string
  taxID: string
  stateOfIncorporation: string
  businessStartDate: string
}
export interface ContactInfo {
  businessPhone: string
  businessEmail: string
  businessWebsite?: string
}

export interface AddressInfo {
  physicalAddressInfo: PhysicalAddressInfo
  mailingAddressInfo?: MailingAddressInfo
}
export interface PhysicalAddressInfo {
  street: string
  city: string
  state: string
  zip: string
}

export interface MailingAddressInfo {
  mailingStreet: string
  mailingCity: string
  mailingState: string
  mailingZip: string
}

export interface OwnerInfo {
  owners: OwnerDetail[]
  applyingOnBehalfOfPrimary: boolean
}
export interface OwnerDetail {
  ownerFirstName: string
  ownerLastName: string
  ownerMobilePhone: string
  ownerEmail: string
  ownerTitle: string
  ownershipPercentage: number
  ownerStreetAddress?: string
  ownerCity?: string
  ownerState?: string
  ownerZipcode?: number
  ownerDOB?: string
  ownerSSN?: string
}

export interface DebtInfo {
  lengthOfLoan: number
  loanOriginationDate: string
  nameOfLender: string
  originalLoanAmount: number
  paymentAmount: number
  remainingBalance: number
  paymentFrequency: string
}
export interface Metadata {
  accountInfoUpdated?: string
  addressInfoUpdated?: string
  basicInfoUpdated?: string
  boxFolderId: string
  contactInfoUpdated?: string
  created: string
  data_version: number
  debtInfoUpdated?: string
  directAppStep: string
  documentsUpdated?: string
  emailVerified?: boolean
  file_uploads: BoxFileUpload[]
  id: string
  maxStepReachedInt: number
  ownerInfoUpdated?: string
  packet_payload_version: string
  plaidInteractions?: boolean
  qparams?: Qparams
  respId: string
  sfAccountId?: string
  sfLeadId?: string
  sfLeadStage?: string
  sfOpportunityId?: string
  sfOpportunityStage?: null | string
  timestamps: any[]
  updated: string
  username: string
  version: string
}

export interface Qparams {
  email: string
  companyName: string
  firstName: string
  lastName: string
  phoneNumber: string
  username: string
}

export const DIRECT_APP_SIDEBAR_VISIBLE_STEPS = [
  DirectAppStep.BUSINESS_BASIC_INFO,
  DirectAppStep.BUSINESS_CONTACT_INFO,
  DirectAppStep.BUSINESS_ADDRESS_INFO,
  DirectAppStep.OWNER_INFO,
  DirectAppStep.ADDITIONAL_INFO,
  DirectAppStep.DOCUMENT,
  DirectAppStep.REVIEW_SUBMIT,
  DirectAppStep.APP_SUBMITTED,
]

export const DIRECT_APP_PROCESS_STEPS = [
  DirectAppStep.DISABLED,
  DirectAppStep.CREATE_ACCOUNT,
  DirectAppStep.BUSINESS_BASIC_INFO,
  DirectAppStep.BUSINESS_CONTACT_INFO,
  DirectAppStep.BUSINESS_ADDRESS_INFO,
  DirectAppStep.OWNER_INFO,
  DirectAppStep.ADDITIONAL_INFO,
  DirectAppStep.DOCUMENT,
  DirectAppStep.REVIEW_SUBMIT,
  DirectAppStep.APP_SUBMITTED,
]
