import { Component, OnInit } from '@angular/core'
import Utils from '../../utils/utils'

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  applyALoan() {
    Utils.RequestaQuote()
  }
}
