<div class="header">
  <div class="header-logo-wrapper">
    <img
      *ngIf="!isNuulaUser"
      class="header-icon"
      src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
    />
    <img
      *ngIf="isNuulaUser"
      class="header-logo"
      src="assets/imgs/mf-main-logo.svg"
    />
  </div>
  <div
    [ngClass]="{
      'header-phone': true,
      white: true
    }"
    *ngIf="!isNuulaUser"
  >
    <a class="phone-link" href="tel:(855) 326-3564">
      <img class="phone" src="assets/imgs/phone-icon-orange.svg" />
      <div class="phone-number">(855) 326-3564</div>
    </a>
  </div>
</div>
