export const isValidNumericKeydown: (
  event: KeyboardEvent,
  currentValue: string,
) => boolean = (event, currentValue) => {
  if (
    event.key &&
    event.key.length === 1 &&
    event.key.replace(/[^0-9\,\.\-]+/g, '').length !== 1
  ) {
    return false
  }
  if (event.key && event.key === '.' && currentValue.indexOf('.') !== -1) {
    return false
  }
  return true
}
