import { Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { BehaviorSubject } from 'rxjs'

import * as INTF from '../../models/interfaces'

@Injectable({
  providedIn: 'root',
})
export class StepService {
  stepSource = new BehaviorSubject<INTF.DocCheckoutStep>(
    INTF.DocCheckoutStep.unknownStep,
  )
  currentStep = this.stepSource.asObservable()

  constructor(private router: Router) {}

  updatedStep(step: INTF.DocCheckoutStep, loanUuid: string) {
    this.stepSource.next(step)
    const route = this.stepToRoute(step, loanUuid)
    this.router.navigate([route])
  }

  previousStep(step: INTF.DocCheckoutStep): INTF.DocCheckoutStep {
    switch (step) {
      case INTF.DocCheckoutStep.businessVerificationAndCreateAcct:
        return INTF.DocCheckoutStep.businessVerificationAndCreateAcct
      case INTF.DocCheckoutStep.verifyEmail:
        return INTF.DocCheckoutStep.businessVerificationAndCreateAcct
      case INTF.DocCheckoutStep.loanIntroduction:
        return INTF.DocCheckoutStep.verifyEmail
      case INTF.DocCheckoutStep.identityVerificationQuestions:
        return INTF.DocCheckoutStep.loanIntroduction
      case INTF.DocCheckoutStep.loanDetails:
        return INTF.DocCheckoutStep.identityVerificationQuestions
      case INTF.DocCheckoutStep.loanAgreement:
        return INTF.DocCheckoutStep.loanDetails
      case INTF.DocCheckoutStep.businessReference:
        return INTF.DocCheckoutStep.loanAgreement
      case INTF.DocCheckoutStep.preApprovalConfirmation:
        return INTF.DocCheckoutStep.businessReference
      case INTF.DocCheckoutStep.funded:
        return INTF.DocCheckoutStep.preApprovalConfirmation
    }
    return INTF.DocCheckoutStep.unknownStep
  }
  nextStep(step: INTF.DocCheckoutStep): INTF.DocCheckoutStep {
    switch (step) {
      case INTF.DocCheckoutStep.businessVerificationAndCreateAcct:
        return INTF.DocCheckoutStep.verifyEmail
      case INTF.DocCheckoutStep.verifyEmail:
        return INTF.DocCheckoutStep.loanIntroduction
      case INTF.DocCheckoutStep.loanIntroduction:
        return INTF.DocCheckoutStep.identityVerificationQuestions
      case INTF.DocCheckoutStep.identityVerificationQuestions:
        return INTF.DocCheckoutStep.loanDetails
      case INTF.DocCheckoutStep.loanDetails:
        return INTF.DocCheckoutStep.loanAgreement
      case INTF.DocCheckoutStep.loanAgreement:
        return INTF.DocCheckoutStep.businessReference
      case INTF.DocCheckoutStep.businessReference:
        return INTF.DocCheckoutStep.preApprovalConfirmation
      case INTF.DocCheckoutStep.preApprovalConfirmation:
        return INTF.DocCheckoutStep.funded
    }
    return INTF.DocCheckoutStep.unknownStep
  }

  stepToRoute(step: INTF.DocCheckoutStep, loanUuid: string): string {
    // if (INTF.DOC_CHECKOUT_PROCESS_STEPS.indexOf(step) >= 0) {
    //   return `/doc-checkout/loan/${loanUuid}`;
    // }
    switch (step) {
      case INTF.DocCheckoutStep.loanIntroduction:
        return `/doc-checkout/loan/${loanUuid}/loan-introduction`

      case INTF.DocCheckoutStep.identityVerificationQuestions:
        return `/doc-checkout/loan/${loanUuid}/identity-verification`

      case INTF.DocCheckoutStep.loanDetails:
        return `/doc-checkout/loan/${loanUuid}/loan-details`

      case INTF.DocCheckoutStep.loanAgreement:
        return `/doc-checkout/loan/${loanUuid}/loan-agreement`

      case INTF.DocCheckoutStep.businessReference:
        return `/doc-checkout/loan/${loanUuid}/review-submit`

      // non nested component page

      case INTF.DocCheckoutStep.businessVerificationAndCreateAcct:
        return `/doc-checkout/loan/${loanUuid}/start`
      case INTF.DocCheckoutStep.verifyEmail:
        return `/doc-checkout/loan/${loanUuid}/verify-email`
      case INTF.DocCheckoutStep.preApprovalConfirmation:
        return `/doc-checkout/loan/${loanUuid}/pre-approval`
      case INTF.DocCheckoutStep.loanAgreementFinishLater:
        return `doc-checkout/loan/${loanUuid}/loan-agreement-finish-later`
      case INTF.DocCheckoutStep.disabled:
        return `doc-checkout/loan/${loanUuid}/disabled`
      case INTF.DocCheckoutStep.funded:
        return `doc-checkout/loan/${loanUuid}/funded`
      case INTF.DocCheckoutStep.preApprovalConfirmation:
        return `doc-checkout/loan/${loanUuid}/pre-approval`
      case INTF.DocCheckoutStep.unableToVerifyBusiness:
        return `doc-checkout/loan/${loanUuid}/unable-to-verify-business`
      case INTF.DocCheckoutStep.unableToVerifyIdentity:
        return `doc-checkout/loan/${loanUuid}/unable-to-verify-identity`
      case INTF.DocCheckoutStep.declinedToSign:
        return `doc-checkout/loan/${loanUuid}/declined-to-sign`
      case INTF.DocCheckoutStep.ineligible:
        return `doc-checkout/loan/${loanUuid}/ineligible`
      case INTF.DocCheckoutStep.retryVerificationQuestions:
        return `doc-checkout/loan/${loanUuid}/identity-verification`

      // ERROR STATES
      case INTF.DocCheckoutStep.unknownStep:
        return '/PageNotFoundComponent'
    }
  }

  stepFromString(step: INTF.stepStrings): INTF.DocCheckoutStep {
    return INTF.stepFromString(step)
  }
}
