<div class="auth-page-container">
  <div class="info-page-wrap">
    <div></div>
    <div>
      <app-logo></app-logo>

      <div class="margin-auto rectangle">
        <div class="loan-funded-illustration info-icon added-icon margin-auto"></div>
        <div class="text-align-center">
          <p class="pre-title margin-auto">Your loan has been funded.</p>
        </div>

        <div class="rectangle">
          <div class="review margin-auto">
            Your disbursement amount of <strong>{{ calcNums?.disbursement | currency }}</strong> was sent on
            <strong>{{ calcNums?.achRequestedDate }}</strong> to
            <strong>{{ calcNums?.account.nameOfBusiness }}</strong>. An email
            with your loan details was sent to <strong class="user-email">{{ userClaims?.email }}</strong>.
          </div>
        </div>

        <div class="btn-container text-align-center">
          <button (click)="goDashboard()" #submit type="submit" class="btn-background">GO TO DASHBOARD
          </button>
        </div>
      </div>

      <div class="contact-group margin-auto">
        <div class="question margin-auto">Have Questions?</div>
        <div class="contact margin-auto">
          <p>
            Call us at <strong>(888) 705-3765</strong> or send an email to </p>
          <p> <strong class="user-email">docs@mulliganfunding.com</strong> for assistance. </p>
        </div>
      </div>
    </div>
  </div>
</div>