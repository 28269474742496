import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewChecked,
  OnDestroy,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'

import { ValidatePassword } from '../../../shared/validators/password.validator'
import { hasNum } from '../../../shared/validators/hasNum.validator'
import { haveUpperLowerCase } from '../../../shared/validators/haveUpperLowerCase.validator'
import * as INTF from '../../../core/models/interfaces'
import { BannersStatesService } from '../../../core/services/banners/banners-states.service'
import { AuthService } from '../../../core/services/auth/auth.service'
import { ErrorService } from '../../../core/services/generic-error/error.service'
import { CurrentUserService } from '../../../core/services/current-user/current-user.service'
import { LoanService } from '../../../core/services/loan/loan.service'
import { EMAIL_FORM_REGEX, getTokenObjectFromValue } from '../../../core'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, AfterViewChecked, OnDestroy {
  signupForm: FormGroup
  onSignupSubscription: Subscription

  @Input() bizVerifyFormInputs: INTF.BizVeriInputs
  @Input() userEmail: null | string
  @Input() loanStatus: INTF.DocCheckoutStep
  @ViewChild('email') email: ElementRef
  @ViewChild('newemail') newemail: ElementRef
  @ViewChild('password') password: ElementRef

  loanUuid: string
  error: INTF.BackendError | null
  emailChange: boolean
  clicked: boolean
  showError = false
  blurPasswordErr = false
  focusPasswordErr = false
  blurEmailErr = false
  focusEmailErr = false

  constructor(
    private loanService: LoanService,
    private authService: AuthService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private bannersStatesService: BannersStatesService,
    private currentUserService: CurrentUserService,
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.initForm()
  }

  blurPassword() {
    this.blurPasswordErr = true
    this.focusPasswordErr = false
  }

  focusPassword() {
    this.focusPasswordErr = true
    this.blurPasswordErr = false
  }

  focusEmail() {
    this.focusEmailErr = true
    this.blurEmailErr = false
  }

  blurEmail() {
    this.blurEmailErr = true
    this.focusEmailErr = false
  }

  initForm(): FormGroup {
    this.clicked = false

    this.signupForm = this.formBuilder.group({
      email: [
        this.userEmail,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_FORM_REGEX),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(72),
          ValidatePassword,
          hasNum,
          haveUpperLowerCase,
        ],
      ],
    })
    return this.signupForm
  }

  valueEmpty(control: any) {
    return control.value.length === 0
  }

  // VERY IMPORTNAT TO USE THIS HOOK
  ngAfterViewChecked() {
    // Run change detection explicitly after the change
    if (this.emailChange) {
      const changeEmail = document.getElementById('change-email')
      changeEmail.style.display = 'block'
    }
    this.cdr.detectChanges()
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signupForm.controls
  }

  togglePassword() {
    this.password.nativeElement.type =
      this.password.nativeElement.type === 'text' ? 'password' : 'text'
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.onSignup()
    }
  }

  onSignup() {
    this.showError = true
    if (this.signupForm.valid && !this.clicked) {
      this.clicked = true

      const data: INTF.AuthUser = this.signupForm.getRawValue()
      this.currentUserService.updatedCurrentUser(data.email)

      this.onSignupSubscription = this.loanService
        .signupUser(this.loanUuid, { ...data, ...this.bizVerifyFormInputs })
        .subscribe(
          (response) => {
            if (response) {
              console.log('signupUser', response)
              this.showError = false

              const rawToken = response['access_token']
              if (rawToken) {
                const newToken = getTokenObjectFromValue(rawToken)
                this.authService.loadToken(newToken)
              }

              localStorage.setItem('email', data.email)
              this.loanStatus = INTF.stepFromString(
                response.docCheckoutStatus as INTF.stepStrings,
              )
              this.loanService.updatedStatus$(this.loanStatus)

              if (this.loanStatus === INTF.DocCheckoutStep.verifyEmail) {
                this.router.navigate([
                  `/doc-checkout/loan/${this.loanUuid}/verify-email`,
                ])
              } else {
                this.router.navigate([`/doc-checkout/loan/${this.loanUuid}`])

                this.bannersStatesService.storeBannerState(
                  this.bannersStatesService.ACCOUNT_CREATED,
                )
              }
            }
          },
          (err) => {
            if (
              err?.error?.error?.errorCode ===
              INTF.ErrorCode.OKTA_ERROR_USER_EXISTS
            ) {
              this.bannersStatesService.storeBannerState(
                this.bannersStatesService.EXISTING_ACCOUNT,
              )
              this.router.navigate([`/auth/login`])
            } else {
              this.errorService.storeError(
                this.errorService.NOT_SIGNUP as INTF.ErrType,
              )
              this.router.navigate([`doc-checkout/error`])
            }
          },
        )
    }
  }

  changeEmail() {
    this.emailChange = true
    // this.resetForm();
  }

  ngOnDestroy() {
    if (this.onSignupSubscription) {
      this.onSignupSubscription.unsubscribe()
      this.onSignupSubscription = null
    }
  }
}
