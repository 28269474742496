<!-- The sidebar -->
<div class="sidebar">
  <div>

  </div>

  <div>
    <div class="checked createacc">Create Account</div>
    <a (click)="bizInfo()" id="business" [ngClass]="{'current-on':getBusinessInfoCurrent(),
      'checked':getBusinessInfoChecked(),
      'uncheck':!getBusinessInfoChecked()}">Business
      Info
    </a>

    <a (click)="ownerInfo()" id="owner" [ngClass]="{
    'current-on':directAppStep === 'owner-info',
    'checked':getOwnerInfoChecked(),
    'uncheck':!getOwnerInfoChecked()}">Owner Info
    </a>

    <a (click)="additionalInfo()" id="additional" [ngClass]="{
    'current-on':directAppStep === 'additional-info',
    'checked':getAdditionalInfoChecked(),
    'uncheck':!getAdditionalInfoChecked()}">Additional Info
    </a>

    <a (click)="documentInfo()" id="documents" [ngClass]="{
    'current-on':directAppStep === 'document',
    'checked':getDocumentsChecked(),
    'uncheck':!getDocumentsChecked()}">Documents
    </a>

    <a (click)="reviewSubmitInfo()" id="review" [ngClass]="{
    'current-on':directAppStep === 'review-submit',
    'checked':getReviewSubmitChecked(),
    'uncheck':!getReviewSubmitChecked()}">Review & Submit
    </a>
  </div>
  <div></div>
</div>