import { Component, Input, OnInit } from '@angular/core'

import { ErrorService } from '../../core/services/generic-error/error.service'

@Component({
  selector: 'app-server-error',
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorComponent implements OnInit {
  constructor(private errorService: ErrorService) {}
  @Input() genError: any
  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
  }
  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.errorService.storeError(this.errorService.SERVER_ERROR)
  }
}
