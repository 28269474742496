import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import * as INTF from '../../../core/models/interfaces'

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  currentUserSource = new BehaviorSubject<string>('')
  currentUser = this.currentUserSource.asObservable()

  constructor() {}

  updatedCurrentUser(data: string) {
    this.currentUserSource.next(data)
  }
}
