<div class="view-all">
  <div></div>
  <div class="rectangle">
    <div class="page-container bgw">
      <div class="welcome">Welcome, {{firstName}}!</div>
      <div class="intro">
        Below are all the accounts associated to your user account.
        <!-- You can choose your default business account for future sign ins. -->
      </div>

      <!-- <div class="more-financing">
        <div class="need-more">Need more Financing?</div>
        <div class="get-started">Get Started<span class="right"></span></div>
      </div> -->

      <div>
        <div>

          <div *ngFor="let account of dashboardData?.accounts">
            <div>

            </div>

            <div class="account">
              <div class="account-name">
                <span class="shop-icon"></span>
                {{account.nameOfBusiness}}
              </div>

              <a [ngClass]="{
                'not-active': !showLoanArrow(loan.docCheckoutStatus),
                'active': showLoanArrow(loan.docCheckoutStatus)
                }" [routerLink]="['doc-checkout/loan',loan.uuid]" class="loan" *ngFor="let loan of account.loans">
                <div class="loan-container">

                  <div class="term-loan">
                    <div>Term Loan</div>
                    <div>Status:
                      <span class="font-600 loan-status">{{stepFromdDashboardStatuses(loan.docCheckoutStatus)}}
                      </span>

                    </div>
                  </div>
                  <div class="loan-detail">
                    ID: {{loan.uuid}}
                    | Loan Amount:
                    <!-- <br> Outstanding Balance:  -->
                    <span class="font-600 ">{{loan.loanAmount|currency: "USD":"symbol":"1.0-0"}}</span>
                  </div>
                  <div class="loan-detail-mobile">
                    ID: {{loan.uuid}} <br>
                    Loan Amount:
                    <!-- Balance:  -->
                    <span class="font-600 ">{{loan.loanAmount|currency: "USD":"symbol":"1.0-0"}}</span>
                  </div>
                </div>

                <div *ngIf="showLoanArrow(loan.docCheckoutStatus)" class="icon-circlearrow">
                </div>

              </a>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>