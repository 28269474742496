import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core'

import * as INTF from '../../core/models/interfaces'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  @Input() calcNums: INTF.Loan
  @Input() loanStatus: INTF.DocCheckoutStep | null = null

  showItem: boolean
  stepName: INTF.StepName
  stepNum: INTF.StepNum
  progress: any
  stepPair: [string, number]
  loanSpecialistNo: string
  staffMemberNo: string
  totalSteps: number

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.totalSteps = INTF.TOTAL_PROGRESS_STEP
    this.showItem = false

    this.progress = document.getElementById('progress')

    const StaffMemberType = {
      LOAN_SPECIALIST: 'loan_specialist',
      SALES_REP: 'sales_rep',
    }

    if (this.calcNums) {
      this.calcNums.mulliganReps.forEach((el) => {
        if (el.staffMemberType === StaffMemberType.LOAN_SPECIALIST) {
          this.loanSpecialistNo = el.phoneNumber
        }
        if (el.staffMemberType === StaffMemberType.SALES_REP) {
          this.staffMemberNo = el.phoneNumber
        }
      })
    }
  }
  // VERY IMPORTANT TO USE THIS HOOK
  ngAfterViewChecked() {
    ;[this.stepName, this.stepNum] = INTF.stepToHumanReadableString(
      this.loanStatus,
    )

    this.progress.value = this.stepNum / this.totalSteps

    // Run change detection explicitly after the change
    this.cdr.detectChanges()
  }

  toggleShowItem() {
    this.showItem = !this.showItem
  }
}
