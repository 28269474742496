<div class="margin-auto ucc">
  <div class="whatisit margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto">An Account Already Exists</p>
  </div>

  <div class="rectangle account margin-auto text-align-center">
    <div class="content-group">
      An account is already associated with the email provided. Please use a
      different email or if you own this account, sign in.
    </div>
  </div>

  <div class="ok margin-auto cursor-pointer" (click)="close()">Close</div>
</div>
