import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { LoanService } from '../../core/services/loan/loan.service'
import { Subscription } from 'rxjs'

import { ErrorService } from '../../core/services/generic-error/error.service'
import * as INTF from '../../core/models/interfaces'
import { handleError } from '../../core'

@Component({
  selector: 'app-docu-sign-call-back',
  templateUrl: './docu-sign-call-back.component.html',
  styleUrls: ['./docu-sign-call-back.component.scss'],
})
export class DocuSignCallBackComponent implements OnInit {
  event: string
  loanUuid: string
  docusignEnvelopeUuid: string
  docusignCallBackSubscription: Subscription
  error: INTF.BackendError | null
  loanStatus: INTF.DocCheckoutStep | null = null

  constructor(
    private errorService: ErrorService,
    private router: Router,
    private route: ActivatedRoute,
    private loanService: LoanService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.event = params['event']
    })
  }

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.docusignEnvelopeUuid = this.route.snapshot.paramMap.get(
      'docusignEnvelopeUuid',
    )

    this.docusignCallBack()
  }

  // Docusign Event Responses
  // Success: 200, { 'docCheckoutStatus': 'businessReference' }
  // Declined Signature: 200, { 'docCheckoutStatus': 'loanAgreement', 'docusignEvent': 'decline' }
  // Finish Later: 200, { 'docCheckoutStatus': 'loanAgreement', 'docusignEvent': 'cancel' }
  // Unknown Event Response: 400, { "message": msg, 'title': 'Error', 'docCheckoutStatus': 'loanAgreement', 'docusignEvent': 'unknown' }
  // Session Timeout: 400, { "message": msg, 'title': 'Error', 'docCheckoutStatus': 'loanAgreement', 'docusignEvent': 'session_timeout' }

  docusignCallBack() {
    const eventData = { event: this.event }
    this.docusignCallBackSubscription = this.loanService
      .docusignCallBack$(this.loanUuid, this.docusignEnvelopeUuid, eventData)
      .subscribe(
        (res) => {
          console.log('docusignCallBack', res)
          this.loanStatus = res.updatedStep
          this.loanService.updatedStatus$(res.updatedStep)
          this.router.navigate([`doc-checkout/loan/${this.loanUuid}`])
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }
}
