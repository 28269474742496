import { AbstractControl } from '@angular/forms'
// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number:
// If the password is missing a number it returns { hasNum: true } which is an error object
export function hasNum(control: AbstractControl) {
  if (
    control.value &&
    control.value.length !== 0 &&
    control.value.match(/[0-9]/)
  ) {
    return null // no error
  }
  return { hasNum: true } // hasNum error
}
