import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'
import { OktaAuthService } from '@okta/okta-angular'
import { Observable, from } from 'rxjs'
import { getAccessToken } from '../../utils'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authReq: HttpRequest<any>

  constructor(private oktaAuth: OktaAuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next))
  }

  private async handleAccess(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Promise<HttpEvent<any>> {
    const mfAccessToken = getAccessToken()

    if (mfAccessToken && mfAccessToken.isValid) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${mfAccessToken.value}`,
        },
      })
    }
    return next.handle(request).toPromise()
  }
}
