import {
  Component,
  HostListener,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import {
  DirectAppFileUploadResponse,
  DirectAppStep,
  UPLOADED_DOC_MAX,
} from '../../core'
import {
  ConnectedBankAccount,
  FileUploadStatus,
  GenericMetadataResponse,
} from '../../core/models'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { ModalService } from '../../core/services/modal/modal.service'
import {
  KB,
  MB,
  ModalType,
  PDF,
  PLAID_SOURCE_LIST,
  PlaidAction,
  PlaidEvent,
  TWENTY_MBS,
  UploadErrorType,
  UploadStatus,
  handleError,
} from '../../core'

import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  disableEdit = false

  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    this.applicationInfo = value
  }
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
    if (value?.file_uploads) {
      this.fileUploadList = []
      for (const file of value.file_uploads) {
        const fileUpload: FileUploadStatus = {
          file: {
            name: file.filename,
            size: file.size,
          } as File,
          status: UploadStatus.SUCCESS,
        }
        this.fileUploadList.push(fileUpload)
      }
    }
    this.fileUploadCount = this.getFileUploadCount()
    this.setIsDocumentsUploaded()
  }

  activeTimeout: null | number
  bankAccountList: ConnectedBankAccount[] = []
  currentPlaidBankAccountInfoSubscription: Subscription
  currentModalSubscription: Subscription
  fileOverflowList: FileUploadStatus[] = []
  fileUploadCount = 0
  fileUploadList: FileUploadStatus[] = []
  form: FormGroup
  isActivelyDeleting = false
  isActivelyUploading = false
  isDocumentsUploaded = false
  pendingFileUploadList: FileUploadStatus[] = []
  showAddAdditionalArea = false
  showBankStatementUploads = false
  showPlaidModal = false

  constructor(
    private directAppService: DirectAppService,
    private directAppStepService: DirectAppStepService,
    private errorService: ErrorService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private router: Router,
  ) {
    this.form = this.formBuilder.group({
      filename: [null],
    })
  }

  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/document-summary`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })
    this.currentModalSubscription =
      this.modalService.currentShowPlaidModal.subscribe((val) => {
        this.showPlaidModal = val
      })
    this.currentPlaidBankAccountInfoSubscription =
      this.directAppService.currentPlaidBankInfo.subscribe((plaidInfo) => {
        this.bankAccountList = plaidInfo
      })
  }

  @HostListener('window:message', ['$event'])
  WindowMessage(event: MessageEvent) {
    let closePlaidIframe = false
    if (PLAID_SOURCE_LIST.includes(event.origin)) {
      const data: PlaidEvent = JSON.parse(event.data) as PlaidEvent
      if (data.statusCode === 200) {
        const connectedBank: ConnectedBankAccount = {
          institutionName: data.body.institution_name,
          status: 'success',
        }

        this.directAppService.addToPlaidBankInfo$(connectedBank)
        if (!this.metadata.plaidInteractions) {
          this.directAppService
            .submitPlaidInteractions$(this.metadata.id)
            .subscribe((res) => {
              console.log('submitPlaidInteractions response:', res)
            })
        }
        closePlaidIframe = true
      } else if (data.statusCode === 0) {
        // 0 is used for various internal transitions - the EXIT is the one we need to listen for
        const bodyEvent = data.body.event
        if (bodyEvent === PlaidAction.EXIT) {
          closePlaidIframe = true
        }
      }
    } else {
      console.warn(
        `window message received from unregistered origin: ${event.origin}`,
      )
    }
    this.showAddAdditionalArea = false
    if (closePlaidIframe) {
      this.modalService.updatedPlaidModalState(false)
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(evt: DragEvent) {
    evt.preventDefault()
    evt.stopPropagation()
    // no op
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    // no op
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    const fileList = evt.dataTransfer.files
    console.log('uploading file List:', fileList)
    this.handleFileUpload(fileList)
  }

  openBeforeContinueModal() {
    this.modalService.updatedModalState(true)
    this.modalService.updatedModalType(this.modalService.BEFORE_CONTINUE)
  }

  goBack() {
    if (this.showAddAdditionalArea) {
      this.showAddAdditionalArea = false
    } else {
      this.directAppStepService.updatedDirectAppStep(
        DirectAppStep.ADDITIONAL_INFO,
      )
    }
  }

  addAnotherBank() {
    this.showAddAdditionalArea = true
  }

  browseForFileUpload(event) {
    const fileList = event.target.files
    this.handleFileUpload(fileList)
    event.target.value = ''
  }

  clearFileOverflowList() {
    this.fileOverflowList = []
  }
  closeAddAdditionalArea() {
    this.showAddAdditionalArea = false
  }

  launchPlaid() {
    this.modalService.updatedPlaidModalState(true)
    this.modalService.updatedModalType(ModalType.PLAID)
  }

  connectAccount() {
    if (this.metadata.sfAccountId) {
      this.launchPlaid()
    } else {
      this.directAppService
        .getRequestSfData(this.metadata.id)
        .subscribe((res) => {
          const response = res as GenericMetadataResponse
          if (response.metadata.sfAccountId) {
            this.launchPlaid()
          } else {
            this.modalService.updatedModalState(true)
            this.modalService.updatedModalType(ModalType.CONNECTION_ERROR)
          }
        })
    }
  }

  fileUploadBack() {
    this.showBankStatementUploads = false
  }

  fileUploadContinue() {
    if (!this.isActivelyDeleting && !this.isActivelyUploading) {
      this.showBankStatementUploads = false
    }
  }

  getFileUploadErrorText(error: UploadErrorType) {
    switch (error) {
      case UploadErrorType.DUPLICATE:
        return `A file with the same name already exists. We are unable to upload this file.`
      case UploadErrorType.NOT_PDF:
        return `Issue uploading file. File must be in PDF format.`
        break
      case UploadErrorType.TOO_LARGE:
        return `Issue uploading file. File must be less than 20 MB.`
        break
      case UploadErrorType.TRANSMISSION_ERROR:
      default:
        return `Issue uploading file. Error during file transmission.`
        break
    }
  }

  getFileSizeText(size: number) {
    if (size < KB) {
      return `${size} B`
    }
    if (size < MB) {
      return `${(size / KB).toFixed(1)} KB`
    }
    return `${(size / MB).toFixed(1)} MB`
  }

  getFileUploadCount() {
    return this.fileUploadList.filter((fileStatus) => {
      return (
        fileStatus.status === UploadStatus.PENDING ||
        fileStatus.status === UploadStatus.SUCCESS
      )
    }).length
  }

  async handleFileUpload(fileList: File[]) {
    const handleAsyncReturn = (id: string) => {
      const [first, ...rest] = [...this.pendingFileUploadList]
      console.log('Finished uploading ', first.file.name)
      this.pendingFileUploadList = rest
      this.isActivelyUploading = false
      this.fileUploadCount = this.getFileUploadCount()
      this.setIsDocumentsUploaded()
      this.activeTimeout = window.setTimeout(() => {
        actuallyUploadFile(id)
      }, 100)
    }
    const actuallyUploadFile = async (id) => {
      if (!this.isActivelyUploading && this.pendingFileUploadList.length > 0) {
        this.isActivelyUploading = true
        const file = this.pendingFileUploadList[0]
        this.fileUploadList.push(file)
        this.fileUploadCount = this.getFileUploadCount()
        this.setIsDocumentsUploaded()
        console.log('actually uploading file:', file.file.name)
        // actually upload the document
        this.form.patchValue({
          filename: file.file,
        })
        this.form.get('filename').updateValueAndValidity()
        const formData = new FormData()
        formData.append('filename', this.form.get('filename').value)
        this.directAppService.postUploadDocument$(id, formData).subscribe(
          (res) => {
            const response = res as DirectAppFileUploadResponse
            const uploadStatus = this.fileUploadList.find(
              (fileUpload) => fileUpload.file.name === file.file.name,
            )
            if (uploadStatus) {
              if (response.status === 'submitted') {
                uploadStatus.status = UploadStatus.SUCCESS
              } else if (
                response.status === 'not_found' ||
                response.status === 'missing upload folder'
              ) {
                uploadStatus.status = UploadStatus.ERROR
                uploadStatus.error = UploadErrorType.TRANSMISSION_ERROR
              }
            }
            handleAsyncReturn(id)
          },
          (err) => {
            console.warn('document upload error: ', err)
            handleAsyncReturn(id)
          },
        )
      }
    }
    const pendingFileList: FileUploadStatus[] = []
    for (const file of fileList) {
      console.log(`Verifying file upload ${file.name}. . .`)
      if (this.fileUploadList.find((f) => f.file.name === file.name)) {
        console.warn(`Duplicate detected - skipping file upload.`)
        const errorUpload: FileUploadStatus = {
          file,
          status: UploadStatus.ERROR,
          error: UploadErrorType.DUPLICATE,
        }
        this.fileUploadList.push(errorUpload)
        continue
      }
      if (
        this.fileUploadList.filter((f) => f.status !== UploadStatus.ERROR)
          .length +
          pendingFileList.length >=
        UPLOADED_DOC_MAX
      ) {
        console.warn(`File upload max reached - skipping file upload.`)
        const errorUpload: FileUploadStatus = {
          file,
          status: UploadStatus.ERROR,
          error: UploadErrorType.TOO_MANY_FILES,
        }
        this.fileOverflowList.push(errorUpload)
        continue
      }
      const uploadStatus: FileUploadStatus = {
        file,
        status: UploadStatus.PENDING,
      }
      if (file.size > TWENTY_MBS) {
        uploadStatus.error = UploadErrorType.TOO_LARGE
        uploadStatus.status = UploadStatus.ERROR
      } else if (file.type !== PDF) {
        uploadStatus.error = UploadErrorType.NOT_PDF
        uploadStatus.status = UploadStatus.ERROR
      }
      if (uploadStatus.status === UploadStatus.PENDING) {
        pendingFileList.push(uploadStatus)
      } else {
        this.fileUploadList.push(uploadStatus)
      }
    }
    if (pendingFileList.length > 0) {
      this.pendingFileUploadList = pendingFileList
      this.activeTimeout = window.setTimeout(
        () => actuallyUploadFile(this.metadata.id),
        100,
      )
    }
  }

  getMaxDocsString() {
    return `Max files allowed: ${UPLOADED_DOC_MAX}`
  }

  getShowFileOverflowError() {
    return this.fileOverflowList.length > 0
  }
  getIsDocUploadEnabled() {
    return this.fileUploadCount < UPLOADED_DOC_MAX
  }
  removeFile(i: number) {
    console.log(this.fileUploadList)
    if (this.fileUploadList.length > i) {
      const file = this.fileUploadList[i]

      const removeFileFromList = () => {
        const replacementList = this.fileUploadList.slice()
        replacementList.splice(i, 1)
        this.fileUploadList = replacementList
        this.fileUploadCount = this.getFileUploadCount()
        this.setIsDocumentsUploaded()
      }

      if (file.status !== UploadStatus.ERROR) {
        if (!this.isActivelyDeleting) {
          this.isActivelyDeleting = true
          this.directAppService
            .postDeleteDocuments$(this.metadata.id, [file.file.name])
            .subscribe((res) => {
              this.isActivelyDeleting = false
              console.log(res)
              removeFileFromList()
            })
        }
      } else {
        removeFileFromList()
      }
    }
  }

  setIsDocumentsUploaded() {
    this.isDocumentsUploaded = this.fileUploadList.some(
      (fileStatus) => fileStatus.status === UploadStatus.SUCCESS,
    )
  }

  showSkip() {
    return this.fileUploadCount === 0 && this.bankAccountList.length === 0
  }
  submitDocInfo$() {
    const nextStep = DirectAppStep.REVIEW_SUBMIT
    const data = { packetId: this.packetId, newStatus: nextStep }
    this.directAppService.setStatus$(data).subscribe(
      (res) => {
        console.log('submitDocInfo', res)
        this.directAppStepService.updatedDirectAppStep(nextStep)
      },
      (err) => {
        console.log('HTTP Error', err)
        if (err.error && err.error.toString().toLowerCase() === 'forbidden') {
          this.router.navigate([`/auth/login`])
        } else {
          handleError(err, this.errorService, this.router)
        }
      },
    )
  }

  uploadStatements() {
    this.showAddAdditionalArea = false
    this.showBankStatementUploads = true
  }

  ngOnDestroy() {
    if (this.activeTimeout) {
      window.clearTimeout(this.activeTimeout)
    }
    if (this.currentPlaidBankAccountInfoSubscription) {
      this.currentPlaidBankAccountInfoSubscription.unsubscribe()
    }
    if (this.currentModalSubscription) {
      this.currentModalSubscription.unsubscribe()
    }
  }
}
