import { Component, OnInit } from '@angular/core'
import { ErrorService } from '../../core/services/generic-error/error.service'

@Component({
  selector: 'app-declined-to-sign',
  templateUrl: './declined-to-sign.component.html',
  styleUrls: ['./declined-to-sign.component.scss'],
})
export class DeclinedToSignComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.storeError(this.errorService.DOCUSIGN_DECLINE)
  }
}
