<div class="step-content">
  <div class="direct-app-page-title">Documents</div>
  <div class="direct-app-page-info">
    In order to review your application, we need some business documents to have
    an accurate look into your company's finances.
  </div>

  <div class="direct-app-page-sub-title">Bank Statements</div>

  <div class="documents-form">
    <div
      class="document-management-area"
      *ngIf="
        !showBankStatementUploads &&
        fileUploadList.length === 0 &&
        bankAccountList.length === 0
      "
    >
      <div class="bank-verification-icon">
        <img src="assets/imgs/bank-verification-illustration.svg" />
      </div>
      <div class="document-management-heading">
        Get a decision faster, link your bank account.
      </div>
      <div class="document-management-text">
        Link your business bank account to give us read-only access and
        instantly review your financial information. This banking information is
        required for a loan offer decision.
      </div>

      <div class="connect-account-wrapper">
        <button
          (click)="connectAccount()"
          class="btn-background add connect bold"
          id="link-account"
        >
          LINK ACCOUNT
        </button>
      </div>

      <div class="or-line">
        <div class="or-line-segment"></div>
        <div class="or-text">OR</div>
        <div class="or-line-segment"></div>
      </div>
      <div class="connect-account-wrapper">
        <button
          (click)="uploadStatements()"
          class="btn-background add connect"
          id="upload-statements"
        >
          UPLOAD STATEMENTS
        </button>
      </div>
    </div>
    <div
      class="connected-bank-list"
      *ngIf="
        (bankAccountList.length > 0 || fileUploadList.length > 0) &&
        !showBankStatementUploads
      "
    >
      <div
        class="connected-bank-row"
        *ngFor="let connectedBank of bankAccountList; let i = index"
      >
        <div class="connected-bank-banner">
          <div class="connected-bank-icon">
            <img
              class="document-icon-image"
              src="assets/imgs/bank-verified-illustration.svg"
            />
          </div>
          <div class="bank-text">
            <div class="bank-header">BANK</div>
            <div class="bank-name">{{ connectedBank.institutionName }}</div>
          </div>
          <div class="connected-text-wrapper success">
            <div class="connected-text">Connected</div>
            <div class="connected-checkmark">
              <img src="assets/imgs/check_circle_outline.svg" />
            </div>
          </div>
          <div class="bank-name responsive">
            {{ connectedBank.institutionName }}
          </div>
        </div>
      </div>
      <div
        class="connected-bank-row"
        *ngIf="fileUploadList.length > 0 && !showBankStatementUploads"
      >
        <div class="connected-bank-banner">
          <div class="connected-bank-icon">
            <img
              class="document-icon-image"
              src="assets/imgs/files-uploaded-illustration.svg"
            />
          </div>
          <div class="bank-text">
            <div class="bank-header">STATEMENTS</div>
            <div class="bank-name">
              {{ getFileUploadCount() }} File{{
                fileUploadList.length > 1 ? 's' : ''
              }}
              Uploaded
            </div>
          </div>
          <div
            class="connected-text-wrapper pointer edit"
            *ngIf="!disableEdit"
            (click)="uploadStatements()"
          >
            <div class="edit-text">Edit</div>
            <div class="connected-checkmark pencil">
              <img src="assets/imgs/icon-pencil.svg" />
            </div>
          </div>
          <div class="bank-name responsive">
            {{ getFileUploadCount() }} File{{
              fileUploadList.length > 1 ? 's' : ''
            }}
            Uploaded
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="add-another-area" *ngIf="showAddAdditionalArea && !disableEdit">
    <div class="add-another-area-close-icon" (click)="closeAddAdditionalArea()">
      <img class="add-another-area-close-x" src="assets/imgs/close-icon.svg" />
    </div>
    <div class="add-another-area-title">Add Another Bank Account</div>
    <div class="add-another-area-text">
      Connect a business bank account to allows us to instantly review your
      financial information and speed up your application. Easy, simple and
      secure!
    </div>
    <div class="add-another-area-button-row">
      <div class="connect-account-wrapper">
        <button
          (click)="connectAccount()"
          class="btn-background add connect bold"
          id="link-account"
        >
          LINK ACCOUNT
        </button>
      </div>
      <div class="or-line">
        <div class="or-line-segment"></div>
        <div class="or-text">OR</div>
        <div class="or-line-segment"></div>
      </div>
      <div class="connect-account-wrapper">
        <button
          (click)="uploadStatements()"
          class="btn-background add connect"
          id="upload-statements"
        >
          UPLOAD STATEMENTS
        </button>
      </div>
    </div>
  </div>

  <div class="upload-statements-form" *ngIf="showBankStatementUploads">
    <div class="direct-app-page-info bank-statement-upload">
      Upload your bank statements from the last 3 months (including current
      month-to-date transactions). Files must be in PDF format and cannot exceed
      20 MB each.
    </div>
    <div class="bank-statement-block">
      <div class="direct-app-page-info bank-statement-upload info-mobile">
        Upload your bank statements from the last 3 months (including current
        month-to-date transactions). Files must be in PDF format and cannot
        exceed 20 MB each.
      </div>
      <div class="file-overflow-error" *ngIf="getShowFileOverflowError()">
        <div class="file-status-icon">
          <img src="assets/imgs/alert-error-icon.svg" />
        </div>
        <div class="file-overflow-error-body">
          <div class="file-overflow-text">
            A maximum number of 12 files are allowed to be uploaded. The
            following files could not be uploaded:
          </div>
          <div
            class="file-overflow-item"
            *ngFor="let overflowFile of fileOverflowList"
          >
            - {{ overflowFile.file.name }}
          </div>
        </div>
        <div
          (click)="clearFileOverflowList()"
          [ngClass]="{
            'file-delete': true
          }"
        >
          <img src="assets/imgs/close-icon.svg" />
        </div>
      </div>
      <div class="bank-statement-max-files">
        {{ getMaxDocsString() }}
      </div>
      <div
        [ngClass]="{
          'bank-statement-drop-area': true,
          disabled: isActivelyUploading
        }"
        (click)="!isActivelyUploading ? fileUpload.click() : null"
        (ondrop)="onDrop($event)"
        *ngIf="getIsDocUploadEnabled()"
      >
        <div class="cloud-upload-icon">
          <img class="cloud-upload" src="/assets/imgs/cloud-upload-icon.svg" />
        </div>
        <div class="cloud-upload-icon-blue">
          <img
            class="cloud-upload"
            src="/assets/imgs/cloud-upload-blue-icon.svg"
          />
        </div>
        <div class="upload-text-block">
          <input
            id="file-select"
            type="file"
            style="display: none"
            (change)="browseForFileUpload($event)"
            #fileUpload
          />
          <div class="upload-text">Drag and drop file or</div>
          <div class="browse-link">browse</div>
          <div class="upload-text">to upload file.</div>
          <div class="browse-files">Browse Files</div>
        </div>
      </div>
      <div
        class="file-upload-row"
        *ngFor="let fileStatus of fileUploadList; let i = index"
      >
        <div class="file-banner" [class.error]="fileStatus.status === 'error'">
          <div class="file-status-icon">
            <img
              src="assets/imgs/alert-error-icon.svg"
              *ngIf="fileStatus.status === 'error'"
            />
            <img
              class="spinning-icon"
              src="assets/imgs/uploading-icon.svg"
              *ngIf="fileStatus.status === 'pending'"
            />
            <img
              src="assets/imgs/file-upload-icon.svg"
              *ngIf="fileStatus.status === 'success'"
            />
          </div>
          <div
            class="file-status-name"
            [class.file-status-name-error]="fileStatus.status === 'error'"
          >
            {{ fileStatus.file.name }}
          </div>
          <div class="file-seperator">|</div>
          <div class="file-status-size">
            {{ getFileSizeText(fileStatus.file.size) }}
          </div>
          <div
            (click)="removeFile(i)"
            *ngIf="fileStatus.status !== 'pending'"
            [ngClass]="{
              'file-delete': true,
              wait: isActivelyDeleting
            }"
          >
            <img src="assets/imgs/close-icon.svg" />
          </div>
        </div>
        <div class="file-error-message" *ngIf="fileStatus.status === 'error'">
          {{ getFileUploadErrorText(fileStatus.error) }}
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      !disableEdit &&
      !showAddAdditionalArea &&
      !showBankStatementUploads &&
      (bankAccountList.length > 0 || fileUploadList.length > 0)
    "
  >
    <button (click)="addAnotherBank()" class="add-item">
      <img class="add-icon" src="/assets/imgs/add-icon.svg" />
      <div class="add-another-text">Add Another Bank</div>
    </button>
  </div>

  <div class="btn-group">
    <div class="goback-group">
      <div
        class="goback"
        (click)="!showBankStatementUploads ? goBack() : fileUploadBack()"
      >
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="goback" #back>Back </a>
      </div>
    </div>
    <div class="goback-group">
      <div class="gobackmobile" (click)="goBack()">
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="gobackmobile" #back>Back </a>
      </div>
    </div>
    <div class="goback-group btn-container end">
      <div
        class="arrow-right"
        (click)="openBeforeContinueModal()"
        *ngIf="showSkip()"
      >
        <img src="assets/imgs/link-arrow-left-icon.svg" />
        <a class="skip" #back>Provide Documents Later </a>
      </div>
      <button
        (click)="
          !showBankStatementUploads ? submitDocInfo$() : fileUploadContinue()
        "
        #submit
        [ngClass]="{
          'btn-background': true,
          wait: isActivelyUploading || isActivelyDeleting
        }"
        *ngIf="!showSkip()"
      >
        CONTINUE
      </button>
    </div>
  </div>
  <app-plaid-iframe
    *ngIf="showPlaidModal"
    [applicationInfo]="applicationInfo"
    [metadata]="metadata"
    [packetId]="packetId"
  ></app-plaid-iframe>
</div>
