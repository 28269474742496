import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
// polyfill TextEncoder for IE Edge
import { TextEncoder } from 'text-encoding'
if (typeof (window as any).TextEncoder === 'undefined') {
  ;(window as any).TextEncoder = TextEncoder
}

export enum Env {
  local = 'local',
  dev = 'dev',
  stg = 'stg',
  prod = 'prod',
  unknown = 'unknown',
}

export const getEnv = (): Env => {
  // hostname looks like: "localhost", "customer.dev.mulligancloud.com"
  const { hostname } = window.location

  if (
    hostname.includes('customer.prod.mulligancloud.com') ||
    hostname.includes('account.mulliganfunding.com')
  ) {
    return Env.prod
  }

  if (hostname.startsWith('customer.stg.')) {
    return Env.stg
  }

  if (hostname.startsWith('customer.dev.') || hostname.startsWith('account.dev.')) {
    return Env.dev
  }

  if (['localhost', 'local-dev', 'local.dev.mulligancloud.com'].includes(hostname)) {
    return Env.local
  }

  // error if hostname does not match a known environment
  console.error('getEnv() env unknown for hostname: ' + hostname)
  return Env.unknown
} // END getEnv()

const env = getEnv()

environment.environment = env

export const isEnvProd = () => [Env.prod].includes(env)

if (isEnvProd()) {
  console.log('--- enabling prod mode')
  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
