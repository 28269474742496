import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'

import { ErrorService } from '../../services/generic-error/error.service'
import { AuthService } from '../../services/auth/auth.service'
import * as INTF from '../../../core/models/interfaces'
import { handleError } from '../../'
import { EMAIL_FORM_REGEX } from '../../strings'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  forgotForm: FormGroup
  forgotFormSubscription: Subscription
  error: INTF.BackendError | null

  loanUuid: string

  @ViewChild('email') email: ElementRef
  clicked: boolean

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.forgotForm = this.formBuilder.group({
      email: [
        '',
        {
          validators: [
            Validators.required,
            Validators.email,
            Validators.pattern(EMAIL_FORM_REGEX),
          ],
          updateOn: 'blur',
        },
      ],
    })
    this.clicked = false
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.email.nativeElement.focus()
    }, 0)
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.forgotForm.controls
  }

  signin() {
    this.router.navigate([`/auth/login`])
  }

  onSubmit(event: KeyboardEvent) {
    if (event.key === 'Enter' && !this.clicked) {
      this.reSet()
    }
  }

  reSet() {
    if (this.forgotForm.valid && !this.clicked) {
      this.clicked = true

      const data = this.forgotForm.getRawValue()

      if (data && data.email) {
        sessionStorage.setItem('resetEmail', data.email)
      }

      this.forgotFormSubscription = this.authService
        .forgotPassword(data)
        .subscribe(
          () => {
            this.router.navigate([`auth/link-sent`])
          },
          (err) => {
            // error, still send to link-sent page  
            this.router.navigate([`auth/link-sent`])
          },
        )
    }
  }

  ngOnDestroy() {
    if (this.forgotFormSubscription) {
      this.forgotFormSubscription.unsubscribe()
      this.forgotFormSubscription = null
    }
  }
}
