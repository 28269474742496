import { ActivatedRoute } from '@angular/router'
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewChecked,
  Input,
} from '@angular/core'

import { DirectAppStep } from '../../core'
import * as INTF from '../../core/models/interfaces'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'

@Component({
  selector: 'app-direct-sidebar',
  templateUrl: './direct-sidebar.component.html',
  styleUrls: ['./direct-sidebar.component.scss'],
})
export class DirectSidebarComponent {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  metadata: INTF.Metadata | undefined
  @Input() set setMetadata(value: INTF.Metadata) {
    this.metadata = value
  }
  directAppStep: DirectAppStep | null
  @Input() set setDirectAppStep(value: DirectAppStep) {
    this.directAppStep = value
  }

  constructor(
    private directAppStepService: DirectAppStepService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {}

  getAdditionalInfoChecked() {
    return (
      this.metadata &&
      this.metadata.debtInfoUpdated &&
      this.directAppStep !== DirectAppStep.ADDITIONAL_INFO
    )
  }

  getBusinessInfoCurrent() {
    return (
      this.directAppStep === DirectAppStep.BUSINESS_BASIC_INFO ||
      this.directAppStep === DirectAppStep.BUSINESS_CONTACT_INFO ||
      this.directAppStep === DirectAppStep.BUSINESS_ADDRESS_INFO
    )
  }
  getBusinessInfoChecked() {
    return (
      this.metadata &&
      this.metadata.basicInfoUpdated &&
      this.metadata.contactInfoUpdated &&
      this.metadata.addressInfoUpdated &&
      this.directAppStep !== DirectAppStep.BUSINESS_BASIC_INFO &&
      this.directAppStep !== DirectAppStep.BUSINESS_CONTACT_INFO &&
      this.directAppStep !== DirectAppStep.BUSINESS_ADDRESS_INFO
    )
  }

  getOwnerInfoChecked() {
    return (
      this.metadata &&
      this.metadata.ownerInfoUpdated &&
      this.directAppStep !== DirectAppStep.OWNER_INFO
    )
  }

  getDocumentsChecked() {
    return (
      this.metadata &&
      (this.metadata.file_uploads.length > 0 ||
        this.metadata.plaidInteractions) &&
      this.directAppStep !== DirectAppStep.DOCUMENT
    )
  }

  getReviewSubmitChecked() {
    return (
      this.metadata &&
      this.metadata.directAppStep === DirectAppStep.APP_SUBMITTED
    )
  }

  bizInfo() {
    this.directAppStepService.updatedDirectAppStep(
      DirectAppStep.BUSINESS_BASIC_INFO,
    )
  }

  ownerInfo() {
    this.directAppStepService.updatedDirectAppStep(DirectAppStep.OWNER_INFO)
  }

  additionalInfo() {
    this.directAppStepService.updatedDirectAppStep(
      DirectAppStep.ADDITIONAL_INFO,
    )
  }

  documentInfo() {
    this.directAppStepService.updatedDirectAppStep(DirectAppStep.DOCUMENT)
  }

  reviewSubmitInfo() {
    this.directAppStepService.updatedDirectAppStep(DirectAppStep.REVIEW_SUBMIT)
  }
}
