import { AbstractControl } from '@angular/forms'
// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
export function ValidatePassword(control: AbstractControl) {
  if (
    control.value &&
    !control.value.match('(?=.*[a-z])(?=.*[A-Z])(?=.*[$@#$!%*?&])') &&
    !control.value.match('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')
  ) {
    return { ValidatePassword: true }
  }
  return null
}
