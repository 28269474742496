import { AbstractControl } from '@angular/forms'
import { PO_BOX_FORM_REGEX } from '../../core'

export function InValidateAddress(control: AbstractControl) {
  if (control.value && control.value.length !== 0) {
    if (PO_BOX_FORM_REGEX.test(control.value)) {
      return { InValidateAddress: true }
    }
  }
  return null
}
