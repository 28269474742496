import { Component, OnInit, Input } from '@angular/core'

@Component({
  selector: 'app-dashboard-account',
  templateUrl: './dashboard-account.component.html',
  styleUrls: ['./dashboard-account.component.scss'],
})
export class DashboardAccountDetailComponent implements OnInit {
  @Input() dashboardData: any
  @Input() firstName: any

  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0)
  }
}
