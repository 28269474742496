import { Component, OnInit } from '@angular/core'
import { ModalService } from '../../../../core/services/modal/modal.service'

@Component({
  selector: 'app-ucc',
  templateUrl: './ucc.component.html',
  styleUrls: ['./ucc.component.scss'],
})
export class UccComponent implements OnInit {
  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  close() {
    this.modalService.updatedModalState(false)
  }
}
