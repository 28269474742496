<div class="auth-page-container">
  <div class="info-page-wrap">
    <div></div>
    <div>
      <app-logo></app-logo>

      <div class="rectangle margin-auto">
        <div class="cannot-verify added-icon"></div>
        <div class="text-align-center">
          <p class="pre-title margin-auto">We could not verify your <br>information.</p>
        </div>

        <div class="err-rectangle">

          <div class="review margin-auto text-align-center">

            You will have one more attempt to go through this automated verification process when you click the button
            below.
          </div>
        </div>

        <div class="text-align-center margin-auto">


          <button (click)="goRetryID()" class="btn-background">
            RETRY VERIFICATION
          </button>
        </div>
      </div>
    </div>
  </div>
</div>