import { AbstractControl } from '@angular/forms'
export function ValidateUniqueEmail(list: string[]) {
  return (control: AbstractControl) => {
    if (control.value && list.includes(control.value)) {
      return {
        ValidateUniqueEmail: true,
      }
    }
    return null
  }
}
