export enum DirectAppUrl {
  ADDITIONAL_INFO = '/ui/direct-app/debt-info',
  APP_SUBMITTED = '/ui/direct-app/app-submitted',
  BUSINESS_ADDRESS_INFO = '/ui/direct-app/address-info',
  BUSINESS_BASIC_INFO = '/ui/direct-app/basic-info',
  BUSINESS_CONTACT_INFO = '/ui/direct-app/contact-info',
  CREATE_ACCOUNT = '/ui/direct-app/start-application',
  CREATE_FROM_LEAD = '/ui/direct-app/create-from-lead/:packetId',
  CREDIT_AUTH_SUCCESS = '/ui/direct-app/owner-credit-auth-success',
  DOCUMENT = '/ui/direct-app/document-summary',
  EMAIL_VERIFICATION = '/ui/direct-app/email-verification/:token',
  OWNER_CREDIT_AUTH = '/ui/direct-app/owner-credit-auth',
  OWNER_INFO = '/ui/direct-app/owner-info',
  REVIEW_SUBMIT = '/ui/direct-app/review-submit',
  NUULA_LANDING = '/ui/direct-app/nuula-landing/:token',
  NUULA_REVIEW_SUBMIT = '/ui/direct-app/app-submitted',
}
