<div class="content-container">
  <div class="main-container">
    <div class="logo-container">
      <img
        class="header-logo cursor-pointer"
        (click)="applyALoan()"
        src="assets/imgs/MF_HorizontalStacked_RGB.png"
      />
    </div>
    <div id="okta-signin-container"></div>
    <div class="not-a-customer">
      <p>
        Not a customer?
        <a class="info" (click)="applyALoan()">
          Apply for a Loan <img src="assets/imgs/link-arrow-icon-new.svg" />
        </a>
      </p>
      <p>
        <a class="info" (click)="goToMagicLinkLogin()">
          Email Only Sign In <img src="assets/imgs/link-arrow-icon-new.svg" />
        </a>
      </p>
    </div>
    <footer class="login-footer">
      <div class="footer-text">
        &copy; {{ currentYear }} Mulligan Funding. All Rights Reserved.
      </div>
    </footer>

    <div class="signin-messages">
      <div *ngIf="showAccountCreated" class="signin-info-container success">
        <div class="icon green-checked-icon"></div>
        <div class="signin-info fr">
          Success! Your account has been created. Sign in to continue.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <div *ngIf="showAccountUpdated" class="signin-info-container success">
        <div class="icon green-checked-icon"></div>
        <div class="signin-info fr">
          Success! Your password has been updated.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <div *ngIf="showBeenVerified" class="signin-info-container success">
        <div class="icon green-checked-icon"></div>
        <div class="signin-info fr">
          Success! Your email has been verified. Sign in to continue.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <div *ngIf="showEmailResent" class="signin-info-container success">
        <div class="icon green-checked-icon"></div>
        <div class="signin-info fr">
          Success! We resent your verification email.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <!-- need backend return okta error messages in response  -->
      <!-- https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/src/properties/login.properties#L607 -->
      <div *ngIf="showExistingAccount" class="signin-info-container warning">
        <div class="icon warning-icon"></div>
        <div class="signin-info fr">
          An account is already associated with this business. Sign in to
          continue.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <div *ngIf="showRenewTokenFailed" class="signin-info-container warning">
        <div class="icon warning-icon"></div>
        <div class="signin-info fr">
          Sorry, something went wrong. Please sign in again.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <div *ngIf="showSessionExpired" class="signin-info-container warning">
        <div class="icon warning-icon"></div>
        <div class="signin-info fr">
          Your session has expired. Please sign in below.
        </div>
        <button (click)="close()" class="close"></button>
      </div>

      <div *ngIf="showAlreadyVerified" class="signin-info-container warning">
        <div class="icon warning-icon"></div>
        <div class="signin-info fr">
          Your email is already verified. Sign in to continue.
        </div>
        <button (click)="close()" class="close"></button>
      </div>
    </div>

    <div class="container-right"></div>

    <div class="signin-right-info">
      <div class="demonstrates">
        Mulligan Funding Demonstrates Commitment to Deal Transparency
      </div>

      <p class="long-info">
        Mulligan Funding is proud to be a member of the Innovative Lending
        Platform Association. The ILPA is dedicated to advancing the industry's
        best practices, establishing standards that empower small business
        owners.
      </p>
      <p class="long-info cursor-pointer">
        <a (click)="readStory()" class="info full"
          >Read Full Story<img src="assets/imgs/link-arrow-white-icon.svg" />
        </a>
      </p>
    </div>
  </div>
</div>
