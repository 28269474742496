<div class="home" *ngIf="dashboardData">
  <app-dashboard-header
    [auth]="true"
    [fullname]="fullname"
    [firstName]="firstName"
    [dashboardData]="dashboardData"
  >
  </app-dashboard-header>

  <div class="all-accounts">
    <div>
      All Accounts
      <!-- <span (click)="showAllAccounts()" id="down-arrow-btn" class="down"></span> -->
    </div>
  </div>

  <!-- <div #showAccounts id="show-accounts">
        <ul class="dropdown">
            <span *ngFor="let account of dashboardData?.accounts">
                 this link address has to be change when design is ready 
                <li><a (click)="showAccountDetail()">{{account.nameOfBusiness}}</a></li>
            </span>
            <div class="line"></div>
            <li class="view-all"><span id="checkmark"></span><a (click)="showViewAll()"><span class="viewAll">View
                        All</span></a></li>
        </ul>
    </div> -->

  <app-dashboard-account
    *ngIf="showAccount"
    [firstName]="firstName"
    [dashboardData]="dashboardData"
  >
  </app-dashboard-account>

  <app-dashboard-all-accounts
    *ngIf="showViewAllAccount"
    [firstName]="firstName"
    [dashboardData]="dashboardData"
  >
  </app-dashboard-all-accounts>

  <app-footer></app-footer>
</div>
