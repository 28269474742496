<div class="margin-auto ucc">

  <div class="whatisit margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto">Number of Payments</p>
  </div>

  <div class="payments-rect margin-auto text-align-center">
    <div class="content-group">
      <p>
        The number of payments is the Total Payback Amount divided by the Payment Amount. </p>
      <p>
        In many cases, this number will not be a whole number. In this scenario, the number after the decimal is the
        prorated percentage of the last payment due. For example, if your payments are $1,000 per week and the number of
        payments is 50.2, you will be paying $1,000 for 50 weeks and $200 (prorated at 20% of $1,000) on week 51.
      </p>
    </div>

  </div>

  <div class="ok margin-auto cursor-pointer" (click)="close()">Close</div>

</div>