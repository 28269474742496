import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'
import { format, parseISO } from 'date-fns'

import * as INTF from '../../../core/models/interfaces'
import { LoanService } from '../../../core/services/loan/loan.service'
import { ErrorService } from '../../../core/services/generic-error/error.service'
import { handleError } from '../../../core'

@Component({
  selector: 'app-payment-schedule',
  templateUrl: './payment-schedule.component.html',
  styleUrls: ['./payment-schedule.component.scss'],
})
export class PaymentScheduleComponent implements OnInit, OnDestroy {
  @Input() calcNums: INTF.Loan
  @Input() userClaims: any
  @Input() paymentScheduleList: INTF.PaymentScheduleItem[]
  @Input() requestedStartDate: any
  error: INTF.BackendError | null
  downloadedSchedule: any
  page: number
  paymentScheduleSubscription: Subscription | undefined
  downloadScheduleSubscription: Subscription | undefined
  loanUuid: string

  constructor(
    private loanService: LoanService,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    this.page = 0
    this.paymentSchedule()
  }

  downloadSchedule() {
    this.downloadScheduleSubscription = this.loanService
      .downloadSchedule$(this.loanUuid, this.requestedStartDate)
      .subscribe(
        (res) => {
          this.downloadedSchedule = res
          this.downloadFile(res)
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  downloadFile(data) {
    const a = document.createElement('a')
    const blob = new Blob([data], { type: 'text/csv' })
    a.href = window.URL.createObjectURL(blob)
    a.setAttribute('download', 'payment-schedule.csv')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  prevPage() {
    this.page--
    this.paymentSchedule()
  }

  nextPage() {
    this.page++
    this.paymentSchedule()
  }

  formatDate(date) {
    return format(parseISO(date), 'MM/dd/yyyy')
  }

  paymentSchedule() {
    this.paymentScheduleSubscription = this.loanService
      .paymentSchedule(this.loanUuid, this.requestedStartDate, this.page)
      .subscribe(
        (res) => {
          this.paymentScheduleList = res.paymentScheduleList

          for (const el of this.paymentScheduleList) {
            el.date = this.formatDate(el.date)
          }
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }
  ngOnDestroy() {
    if (this.downloadScheduleSubscription) {
      this.downloadScheduleSubscription.unsubscribe()
      this.downloadScheduleSubscription = null
    }
  }
}
