import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { Observable, BehaviorSubject, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

import * as INTF from '../../../core/models/interfaces'
import { environment } from '../../../../environments/environment'
import { ConfigService } from '../config/config.service'
import * as fakeData from '../../../../assets/fixtures/calcNums.json'
import { StepService } from '../../../core/services/steps/step.service'

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }
  statusSource = new BehaviorSubject<INTF.DocCheckoutStep>(
    INTF.DocCheckoutStep.unknownStep,
  )
  currentStatus = this.statusSource.asObservable()

  // need to change the default

  loanDataSource = new BehaviorSubject<INTF.Loan>(null)
  currentLoanData = this.loanDataSource.asObservable()

  FirstPaymentDaySource = new BehaviorSubject<string>(null)
  currentFirstPaymentDay = this.FirstPaymentDaySource.asObservable()

  loanUuidSource = new BehaviorSubject<string>(null)
  currentLoanUuid = this.loanUuidSource.asObservable()

  useServer: boolean
  serverUrl: string | null
  statusUrl: string | null

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private stepService: StepService,
  ) {
    this.useServer = environment.useServer
    this.serverUrl = this.getUrl()
  }

  getUrl() {
    if (environment.serverUrl) {
      if (environment.environment === 'local-server') {
        return `${environment.serverUrl}`
      } else {
        return `${environment.serverUrl}/doc-checkout`
      }
    } else {
      return null
    }
  }

  verifyEmail(
    loanUuid: string,
    authToken: string,
  ): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.postVerifyEmail(loanUuid, authToken)
    } else {
      return this.verifyEmailStatic$()
    }
  }

  verifyEmailStatic$(): Observable<INTF.LoanStatus> {
    const fakeVerifyEmailSuccess =
      fakeData.verifyEmailSuccess as INTF.LoanStatus
    const obs$ = of(fakeVerifyEmailSuccess)

    return obs$
  }

  postVerifyEmail(
    loanUuid: string,
    authToken: string,
  ): Observable<INTF.LoanStatus> {
    const url = `${this.serverUrl}/loan/${loanUuid}/email-verification`

    return this.http
      .post<INTF.LoanStatus>(url, { token: authToken }, this.httpOptions)
      .pipe(
        tap((val) =>
          console.log(`email verification ${val.docCheckoutStatus}`),
        ),
        catchError(this.configService.handleError),
      )
  }

  resendEmail(data: INTF.ResendEmail) {
    if (this.useServer) {
      return this.postResendEmail$(data)
    } else {
      return this.resendEmailStatic$()
    }
  }

  resendEmailStatic$(): Observable<{}> {
    const obs$ = of(null)
    // go to login
    fakeData.auth.isAuthenticated = false
    return obs$
  }

  postResendEmail$(data: INTF.ResendEmail): Observable<{}> {
    const url = `${this.serverUrl}/resend-email-verification`

    return this.http.post<{}>(url, data, this.httpOptions).pipe(
      tap((val) => console.log(`resent email verification: ${val}`)),
      catchError(this.configService.handleError),
    )
  }

  docusignUrl$(loanUuid: string): Observable<INTF.Docusign> {
    if (this.useServer) {
      return this.getDocusignUrl$(loanUuid)
    } else {
      return this.docusignUrlStatic$()
    }
  }

  signupUser(
    loanUuid: string,
    user: INTF.AuthUser,
  ): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.postSignupUser(loanUuid, user)
    } else {
      return this.signupUserStatic$()
    }
  }

  signupUserStatic$(): Observable<INTF.LoanStatus> {
    const fakeSignupSuccess = fakeData.signupSuccess as INTF.LoanStatus
    const obs$ = of(fakeSignupSuccess)

    return obs$
  }

  postSignupUser(
    loanUuid: string,
    user: INTF.AuthUser,
  ): Observable<INTF.LoanStatus> {
    const url = `${this.serverUrl}/loan/${loanUuid}/account`

    return this.http
      .post<INTF.LoanStatus>(url, user)
      .pipe(catchError(this.configService.handleError))
  }

  docusignUrlStatic$() {
    const fakeDocusignStatic = fakeData.docusignStatic as INTF.Docusign
    const obs$ = of(fakeDocusignStatic)
    return obs$
  }

  getDocusignUrl$(loanUuid: string): Observable<INTF.Docusign> {
    return this.http
      .post<INTF.Docusign>(
        `${this.serverUrl}/loan/${loanUuid}/docusign-url`,
        {},
      )
      .pipe(
        tap((val) => {
          val.updatedStep = INTF.stepFromString(val.docCheckoutStatus)

          this.updatedStatus$(val.updatedStep)
        }),
        catchError(this.configService.handleError),
      )
  }

  docusignCallBack$(
    loanUuid: string,
    docusignEnvelopeUuid: string,
    event: INTF.DocusigEvent,
  ) {
    if (this.useServer) {
      return this.postDocusignCallBack$(loanUuid, docusignEnvelopeUuid, event)
    } else {
      return this.docusignCallBackStatic$()
    }
  }

  postDocusignCallBack$(
    loanUuid: string,
    docusignEnvelopeUuid: string,
    event: INTF.DocusigEvent,
  ): Observable<INTF.LoanStatus> {
    return this.http
      .post<INTF.LoanStatus>(
        `${this.serverUrl}/loan/${loanUuid}/docusign/${docusignEnvelopeUuid}`,
        event,
      )
      .pipe(
        tap((val) => {
          val.updatedStep = INTF.stepFromString(val.docCheckoutStatus)
          this.updatedStatus$(val.updatedStep)
        }),
        catchError(this.configService.handleError),
      )
  }

  docusignCallBackStatic$(): Observable<INTF.LoanStatus> {
    const fakeDocusignCallBackSuccess =
      fakeData.docusignCallBackSuccess as INTF.LoanStatus
    const obs$ = of(fakeDocusignCallBackSuccess)
    return obs$
  }

  getLoanStatus$(loanUuid: string): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.fetchLoanStatus$(loanUuid)
    } else {
      return this.getLoanStatusStatic$()
    }
  }

  getLoanStatusStatic$(): Observable<INTF.LoanStatus> {
    const fakeStep = fakeData.loan.docCheckoutStatus
    const fakeLoanStatus = {
      docCheckoutStatus: fakeStep,
      updatedStep: this.stepService.stepFromString(
        fakeStep as INTF.stepStrings,
      ),
    } as INTF.LoanStatus

    const obs$ = of(fakeLoanStatus)
    this.updatedStatus$(fakeLoanStatus.updatedStep)

    return obs$
  }

  fetchLoanStatus$(loanUuid: string): Observable<INTF.LoanStatus> {
    return this.http
      .get<INTF.LoanStatus>(`${this.serverUrl}/loan/${loanUuid}/status`, {})
      .pipe(
        tap((val) => {
          val.updatedStep = INTF.stepFromString(val.docCheckoutStatus)
          this.updatedStatus$(val.updatedStep)
        }),
        catchError(this.configService.handleError),
      )
  }

  getLoan$(loanUuid: string): Observable<INTF.Loan> {
    if (this.useServer) {
      return this.fetchLoan$(loanUuid)
    } else {
      return this.getLoanStatic$()
    }
  }

  getLoanStatic$(): Observable<INTF.Loan> {
    const fakeLoan = fakeData.loan as INTF.Loan
    const obs$ = of(fakeLoan)
    return obs$
  }

  fetchLoan$(loanUuid: string): Observable<INTF.Loan> {
    const url = `${this.serverUrl}/loan/${loanUuid}`
    return this.http
      .get<INTF.Loan>(url)
      .pipe(
        tap((res) =>
          this.updatedStatus$(INTF.stepFromString(res.docCheckoutStatus)),
        ),
      )
  }

  updateLoanStatus$(
    loanUuid: string,
    step: INTF.DocCheckoutStep,
  ): Observable<INTF.DocCheckoutStep> {
    if (this.useServer) {
      return this.putLoanStatus$(loanUuid, step)
    } else {
      return this.updateLoanStatusStatic$(step)
    }
  }

  updateLoanStatusStatic$(
    step: INTF.DocCheckoutStep,
  ): Observable<INTF.DocCheckoutStep> {
    const obs$ = of(INTF.DocCheckoutStep.identityVerificationQuestions)
    return obs$
  }

  putLoanStatus$(
    loanUuid: string,
    step: INTF.DocCheckoutStep,
  ): Observable<INTF.DocCheckoutStep> {
    const status = INTF.stepToString(step)
    const url = `${this.serverUrl}/loan/${loanUuid}/status`
    const result: Observable<INTF.DocCheckoutStep> = this.http
      .put<INTF.LoanStatus>(url, { doc_checkout_status: status })
      .pipe(
        map((val) => INTF.stepFromString(val.docCheckoutStatus)),
        tap((updatedStep) => this.updatedStatus$(updatedStep)),
        catchError(this.configService.handleError),
      )

    return result
  }

  getUserDashboard$(): Observable<INTF.Dashboard> {
    if (this.useServer) {
      return this.fetchUserDashboard$()
    } else {
      return this.getUserDashboardStatic$()
    }
  }

  fetchUserDashboard$(): Observable<INTF.Dashboard> {
    const url = `${this.serverUrl}/user/dashboard`
    return this.http.get<INTF.Dashboard>(url)
  }

  getUserDashboardStatic$(): Observable<INTF.Dashboard> {
    const fakeDashboard = fakeData.dashboard as INTF.Dashboard
    const obs$ = of(fakeDashboard)
    this.updatedStatus$(
      INTF.stepFromString(
        fakeData.dashboard.accounts[0].loans[0]
          .docCheckoutStatus as INTF.stepStrings,
      ),
    )

    return obs$
  }

  reviewAndSubmit$(
    loanUuid: string,
    data: INTF.BusinessReferenceRequest,
  ): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.fetchReviewAndSubmit$(loanUuid, data)
    } else {
      return this.reviewAndSubmitStatic$()
    }
  }

  reviewAndSubmitStatic$(): Observable<INTF.LoanStatus> {
    const fakeLoanStatus = fakeData.reviewAndSubmitSuccess as INTF.LoanStatus
    const obs$ = of(fakeLoanStatus)

    return obs$
  }

  fetchReviewAndSubmit$(
    loanUuid: string,
    data: INTF.BusinessReferenceRequest,
  ): Observable<INTF.LoanStatus> {
    // currently we dont konw what backend return
    const result = this.http
      .post<INTF.LoanStatus>(`${this.serverUrl}/loan/${loanUuid}/submit`, data)
      .pipe(
        tap((val) => {
          val.updatedStep = INTF.stepFromString(val.docCheckoutStatus)
          this.updatedStatus$(val.updatedStep)
        }),
        catchError(this.configService.handleError),
      )

    return result
  }

  // KBA
  getQuestionSet$(loanUuid: string): Observable<INTF.IdentityVerification> {
    if (this.useServer) {
      return this.fetchQuestionSet$(loanUuid)
    } else {
      return this.getQuestionSetStatic$()
    }
  }

  getQuestionSetStatic$(): Observable<INTF.IdentityVerification> {
    const fakeKBAQuestionSet =
      fakeData.KBAQuestionSet as INTF.IdentityVerification
    const obs$ = of(fakeKBAQuestionSet)

    return obs$
  }

  fetchQuestionSet$(loanUuid: string): Observable<INTF.IdentityVerification> {
    return this.http
      .get<INTF.IdentityVerification>(
        `${this.serverUrl}/loan/${loanUuid}/identity-verification-questions`,
      )
      .pipe(
        tap((res) => {}),
        catchError(this.configService.handleError),
      )
  }

  scoreQuestionSet$(
    loanUuid: string,
    data: INTF.BlockscoreQuestionSet,
  ): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.postQuestionSet$(loanUuid, data)
    } else {
      return this.scoreQuestionSetStatic$()
    }
  }

  postQuestionSet$(
    loanUuid: string,
    data: INTF.BlockscoreQuestionSet,
  ): Observable<INTF.LoanStatus> {
    return this.http
      .post<INTF.LoanStatus>(
        `${this.serverUrl}/loan/${loanUuid}/identity-verification-questions`,
        data,
      )
      .pipe(
        tap((val) => {
          val.updatedStep = INTF.stepFromString(val.docCheckoutStatus)
          this.updatedStatus$(val.updatedStep)
        }),
        catchError(this.configService.handleError),
      )
  }

  scoreQuestionSetStatic$(): Observable<INTF.LoanStatus> {
    const fakeKBASuccess = fakeData.KBASuccess as INTF.LoanStatus
    const obs$ = of(fakeKBASuccess)
    this.updatedStatus$(fakeKBASuccess.updatedStep)

    return obs$
  }

  // businessVerificationAndCreateAcct
  onSubmitBizVerify(
    loanUuid: string,
    data: INTF.BizVeriInputs,
  ): Observable<INTF.PostBizVerif> {
    if (this.useServer) {
      return this.postSubmitBizVerify$(loanUuid, data)
    } else {
      return this.submitBizVerifyStatic$()
    }
  }

  postSubmitBizVerify$(
    loanUuid: string,
    data: INTF.BizVeriInputs,
  ): Observable<INTF.PostBizVerif> {
    return this.http
      .post<INTF.PostBizVerif>(
        `${this.serverUrl}/loan/${loanUuid}/business-verification`,
        data,
      )
      .pipe(tap(), catchError(this.configService.handleError))
  }

  submitBizVerifyStatic$(): Observable<INTF.PostBizVerif> {
    const fakeBizVerifySuccess = fakeData.bizVerifySuccess as INTF.PostBizVerif
    const obs$ = of(fakeBizVerifySuccess)
    return obs$
  }

  paymentSchedule(
    loanUuid: string,
    existingStartDate: string,
    page: number,
  ): Observable<INTF.PaymentScheduleWithPage> {
    if (this.useServer) {
      return this.fetchPaymentSchedule$(loanUuid, existingStartDate, page)
    } else {
      return this.paymentScheduleStatic$()
    }
  }

  paymentScheduleStatic$() {
    const fakePaymentSchedule =
      fakeData.paymentScheduleWithPage as INTF.PaymentScheduleWithPage
    const obs$ = of(fakePaymentSchedule)

    return obs$
  }

  fetchPaymentSchedule$(
    loanUuid: string,
    existingStartDate: string,
    page: number,
  ) {
    // Query Params
    // - requestedStartDate - optional, first day in the result. Defaults to loan's existing_start_date
    // - page - optional, which page you want data from, 0 indexed. Defaults to 0

    // Request Query Param Example - this will retrieve the 2nd page of date (ie, page=1)
    // GET /loan/<loan_uuid>/payment-schedule?requestedStartDate=2020-01-01&page=1
    const url = `${this.serverUrl}/loan/${loanUuid}/payment-schedule?requestedStartDate=${existingStartDate}&page=${page}`

    return this.http.get<INTF.PaymentScheduleWithPage>(url).pipe(
      tap((val) => {}),
      catchError(this.configService.handleError),
    )
  }

  submitLoanDetails$(
    loanUuid: string,
    data: INTF.PaymentDate,
  ): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.postLoanDetails$(loanUuid, data)
    } else {
      return this.submitLoanDetailsStatic$()
    }
  }

  submitLoanDetailsStatic$() {
    const fakeSubmitLoanDetails =
      fakeData.submitLoanDetailsStatic as INTF.LoanStatus
    const obs$ = of(fakeSubmitLoanDetails)

    return obs$
  }

  updateFirstPaymentDayStatic$() {
    const fakeUpdateFirstPaymentDay =
      fakeData.updateFirstPaymentDayStatic as INTF.LoanStatus
    const obs$ = of(fakeUpdateFirstPaymentDay)

    return obs$
  }

  postUpdateFirstPaymentDay$(loanUuid: string, data: INTF.PaymentDate) {
    const url = `${this.serverUrl}/loan/${loanUuid}/first-payment-due-date`

    return this.http.post<INTF.LoanStatus>(url, data).pipe(
      tap((res) => {
        this.updatedStatus$(INTF.stepFromString(res.docCheckoutStatus))
        res.updatedStep = this.stepService.stepFromString(
          res.docCheckoutStatus as INTF.stepStrings,
        )
      }),

      catchError(this.configService.handleError),
    )
  }

  postLoanDetails$(loanUuid: string, data: INTF.PaymentDate) {
    const url = `${this.serverUrl}/loan/${loanUuid}/loan-details`

    return this.http.post<INTF.LoanStatus>(url, data).pipe(
      tap((res) => {
        this.updatedStatus$(INTF.stepFromString(res.docCheckoutStatus))
        res.updatedStep = this.stepService.stepFromString(
          res.docCheckoutStatus as INTF.stepStrings,
        )
      }),

      catchError(this.configService.handleError),
    )
  }

  updateFirstPaymentDay$(
    loanUuid: string,
    data: INTF.PaymentDate,
  ): Observable<INTF.LoanStatus> {
    if (this.useServer) {
      return this.postUpdateFirstPaymentDay$(loanUuid, data)
    } else {
      return this.updateFirstPaymentDayStatic$()
    }
  }

  downloadSchedule$(loanUuid: string, data): Observable<string> {
    if (this.useServer) {
      return this.postDownloadSchedule$(loanUuid, data)
    } else {
      return this.downloadScheduleStatic$()
    }
  }

  downloadScheduleStatic$() {
    const fakeSubmitLoanDetails = fakeData.downloadSchedule
    const obs$ = of(fakeSubmitLoanDetails)

    return obs$
  }

  postDownloadSchedule$(
    loanUuid: string,
    requestedStartDate,
  ): Observable<string> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8',
    )

    const url = `${this.serverUrl}/loan/${loanUuid}/payment-schedule-download?requestedStartDate=${requestedStartDate}`
    return this.http
      .get<string>(url, { headers, responseType: 'text' as 'json' })
      .pipe(catchError(this.configService.handleError))
  }

  updatedStatus$(step: INTF.DocCheckoutStep) {
    this.statusSource.next(step)
  }
}
