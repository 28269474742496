import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { BehaviorSubject } from 'rxjs'
import * as INTF from '../../models/interfaces'
import { DirectAppStep, DirectAppUrl, getDirectAppUrlFromStep } from '../../'

@Injectable({
  providedIn: 'root',
})
export class DirectAppStepService {
  directAppStepSource = new BehaviorSubject<DirectAppStep>(
    DirectAppStep.UNKNOWN,
  )
  directAppCurrentStep = this.directAppStepSource.asObservable()

  constructor(private router: Router, private route: ActivatedRoute) {}

  directAppStepToRoute(step: DirectAppStep): string {
    return getDirectAppUrlFromStep(step)
  }

  directAppRouteToStep(url: string) {
    switch (url) {
      case DirectAppUrl.ADDITIONAL_INFO:
        window.location.replace(`/ui/direct-app/debt-info`)
        break
      case DirectAppUrl.APP_SUBMITTED:
        window.location.replace(`/ui/direct-app/app-submitted`)
        break
      case DirectAppUrl.BUSINESS_ADDRESS_INFO:
        window.location.replace(`/ui/direct-app/address-info`)
        break
      case DirectAppUrl.BUSINESS_BASIC_INFO:
        window.location.replace(`/ui/direct-app/basic-info`)
        break
      case DirectAppUrl.BUSINESS_CONTACT_INFO:
        window.location.replace(`/ui/direct-app/contact-info`)
        break
      case DirectAppUrl.CREATE_ACCOUNT:
        this.updatedDirectAppStep(DirectAppStep.CREATE_ACCOUNT)
        break
      case DirectAppUrl.DOCUMENT:
        window.location.replace(`/ui/direct-app/document-summary`)
        break
      case DirectAppUrl.OWNER_INFO:
        window.location.replace(`/ui/direct-app/owner-info`)
        break
      case DirectAppUrl.REVIEW_SUBMIT:
        window.location.replace(`/ui/direct-app/review-submit`)
        break
      default:
        if (url.indexOf('/app/create-from-lead') >= 0) {
          this.updatedDirectAppStep(DirectAppStep.CREATE_FROM_LEAD)
        } else if (url.indexOf('/app/owner-credit-auth-success') >= 0) {
          this.updatedDirectAppStep(DirectAppStep.CREDIT_AUTH_SUCCESS)
        } else if (url.indexOf('/app/email-verification/') >= 0) {
          this.updatedDirectAppStep(DirectAppStep.EMAIL_VERIFICATION)
        } else if (url.indexOf('/app/nuula_landing/') >= 0) {
          this.updatedDirectAppStep(DirectAppStep.NUULA_LANDING)
        } else if (url.indexOf('/app/owner-credit-auth') >= 0) {
          this.updatedDirectAppStep(DirectAppStep.OWNER_CREDIT_AUTH)
        }
        break
    }
  }

  updatedDirectAppStep(step: DirectAppStep) {
    this.directAppStepSource.next(step)

    if (
      step !== DirectAppStep.CREATE_FROM_LEAD &&
      step !== DirectAppStep.CREDIT_AUTH_SUCCESS &&
      step !== DirectAppStep.EMAIL_VERIFICATION &&
      step !== DirectAppStep.NUULA_LANDING &&
      step !== DirectAppStep.OWNER_CREDIT_AUTH
    ) {
      const route = this.directAppStepToRoute(step)
      window.location.replace(route)
    }
  }
}
