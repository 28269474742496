<div class="app-submitted-container">
  <div class="spacer"></div>
  <div class="app-submitted-card">
    <div class="spinner-wrapper" >
      <img class="spinner-icon" src="assets/imgs/uploading-icon.svg" />
    </div>
    <div class="spinner-wrapper" *ngIf="emailVerified && !verifiedError">
      <img
        class="static-icon"
        src="assets/imgs/email-verified-illustration.svg"
      />
    </div>
    <div class="spinner-wrapper" *ngIf="verifiedError">
      <img
        class="static-icon"
        src="assets/imgs/error-generic-illustration.svg"
      />
    </div>
    <div class="card-title" >Verifying Email</div>
    <div class="card-title" *ngIf="emailVerified && !verifiedError">
      Email Verified
    </div>
    <div class="card-title" *ngIf="verifiedError">Unable to Verify</div>
    <div class="card-text" *ngIf="emailVerified && !verifiedError">
      Your email has been verified. Please return to your application.
    </div>
    <div class="card-text" *ngIf="verifiedError">
      The verification link is either invalid or expired. Please review the
      verification email and try again. If this issue persists, please contact
      us at <span class="strong">(855) 326-3564</span>.
    </div>
  </div>
  <div class="spacer"></div>
</div>