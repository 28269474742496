import { HttpHeaders } from '@angular/common/http'
export const httpOptionsWithCredentials = {
  withCredentials: true,
}

export const httpOptionsWithHeaders = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}

export const httpOptions = {
  ...httpOptionsWithCredentials,
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
}
