import { Component, OnDestroy, Input, OnInit } from '@angular/core'
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription, timer } from 'rxjs'
import { scan, takeWhile, map } from 'rxjs/operators'
import { format, addSeconds } from 'date-fns'

import * as INTF from '../../core/models/interfaces'
import { LoanService } from '../../core/services/loan/loan.service'
import { StepService } from '../../core/services/steps/step.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { handleError } from '../../core'

@Component({
  selector: 'app-identity-verification',
  templateUrl: './identity-verification.component.html',
  styleUrls: ['./identity-verification.component.scss'],
})
export class IdentityVerificationComponent implements OnInit, OnDestroy {
  @Input() calcNums: INTF.Loan
  @Input() userClaims: any

  getQuestionSubscription: Subscription
  scoreQuestionSubscription: Subscription
  error: INTF.BackendError | null
  showMsg = false
  blockscoreQuestionSetId: string
  loanUuid: string
  loanStatus: INTF.DocCheckoutStep
  step: any
  questions: any
  questionForm: FormGroup
  incompletedKBA: boolean
  completedKBA: boolean

  ExpTimer: any
  timeout: any

  constructor(
    private errorService: ErrorService,
    private loanService: LoanService,
    private stepService: StepService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {
    this.loanService.currentStatus.subscribe((data) => {
      this.loanStatus = data
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
    if (
      this.loanStatus === INTF.DocCheckoutStep.identityVerificationQuestions ||
      this.loanStatus === INTF.DocCheckoutStep.retryVerificationQuestions
    ) {
      this.completedKBA = false
      this.incompletedKBA = true

      // This order is very imrpotant!
      this.initForm()

      this.getQuestion()
    } else {
      this.completedKBA = true
      this.incompletedKBA = false
    }
  }

  initForm() {
    this.questionForm = new FormGroup({
      questionAnswer: this.fb.array([]),
    })
  }

  get questionAnswer() {
    return this.questionForm.get('questionAnswer') as FormArray
  }

  formattedTime(date) {
    return format(addSeconds(new Date(0), date), 'm:ss')
  }

  startExpTimer() {
    this.ExpTimer = timer(0, 1000).pipe(
      scan((acc) => --acc, Math.round(this.timeout - Date.now() / 1000)),
      takeWhile((x) => x >= 0),
      map((val) => {
        if (val === 0) {
          this.errorService.storeError(this.errorService.ID_NOT_VERIFIED)
          this.router.navigate([`doc-checkout/error`])
        } else {
          return this.formattedTime(val)
        }
      }),
    )
  }

  getQuestion() {
    this.getQuestionSubscription = this.loanService
      .getQuestionSet$(this.loanUuid)
      .subscribe(
        (res) => {
          // 1st time seeing the questions
          if (res.docCheckoutStatus) {
            this.step = INTF.stepFromString(
              res.docCheckoutStatus as INTF.stepStrings,
            ) as INTF.DocCheckoutStep
          }

          if (res.questionSet) {
            this.questions = res.questionSet.questions
            this.blockscoreQuestionSetId = res.blockscoreQuestionSetId
            this.timeout = res.questionSet.createdAt + res.questionSet.timeLimit

            this.startExpTimer()

            this.questions.forEach(() => {
              this.questionAnswer.push(
                new FormGroup({
                  answer: new FormControl('', [Validators.required]),
                }),
              )
            })
          } else if (this.step === INTF.DocCheckoutStep.loanDetails) {
            this.router.navigate([`doc-checkout/loan/${this.loanUuid}`])
          } else {
            // if merchant has already reached their max number of blockscore question_sets
            this.errorService.storeError(this.errorService.ID_NOT_VERIFIED)
            this.router.navigate([`doc-checkout/error`])
          }
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  scoreQuestion() {
    const data = {
      blockscoreQuestionSetId: this.blockscoreQuestionSetId,
      answers: [],
    }

    for (let i = 0; i < this.questionForm.value.questionAnswer.length; i++) {
      data.answers.push({
        question_id: i + 1,
        answer_id: +this.questionForm.value.questionAnswer[i].answer,
      })
    }

    if (this.questionAnswer.valid) {
      this.scoreQuestionSubscription = this.loanService
        .scoreQuestionSet$(this.loanUuid, data)
        .subscribe(
          (res) => {
            console.log(res)

            if (
              res.updatedStep ===
              INTF.DocCheckoutStep.retryVerificationQuestions
            ) {
              console.log(
                'redirecting---to id retry-verification-questionspage',
              )
              this.router.navigate([
                `doc-checkout/loan/${this.loanUuid}/retry-verification-questions`,
              ])
            } else {
              this.loanStatus = res.updatedStep
              this.loanService.updatedStatus$(res.updatedStep)

              this.stepService.updatedStep(res.updatedStep, this.loanUuid)
            }
          },
          (err) => {
            handleError(err, this.errorService, this.router)
          },
        )
    } else {
      this.showMsg = true
    }
  }

  continue() {
    location.reload()
  }

  ngOnDestroy() {
    if (this.scoreQuestionSubscription) {
      this.scoreQuestionSubscription.unsubscribe()
      this.scoreQuestionSubscription = null
    }
  }
}
