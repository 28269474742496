import { Component, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { DirectAppService } from '../../../core/services/direct/direct-app.service'
import {
  DirectAppStep,
  getDirectAppUrlFromStep,
  getNextStepFromMetadata,
  GetPacketResponse,
  handleError,
} from '../../../core'
import { ErrorService } from '../../../core/services/generic-error/error.service'
import * as INTF from '../../../core/models/interfaces'
import { ApplicationInfo } from 'src/app/core/models'
import { AuthService } from 'src/app/core/services/auth/auth.service'
@Component({
  selector: 'app-nuula-resume-application',
  templateUrl: './nuula-resume-application.component.html',
  styleUrls: ['./nuula-resume-application.component.scss'],
})
export class NuulaResumeApplicationComponent implements OnInit {
  packetId: string
  metadata: INTF.Metadata | undefined
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private directAppService: DirectAppService,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {}
  tokenError = false
  tokenVerified = false
  getDirectAppStatusSubscription: Subscription
  directAppPacketData: ApplicationInfo

  ngOnInit(): void {
    const urlParts = this.router.url.split('/')
    const token = urlParts[urlParts.length - 1]
    window.location.replace(`/ui/direct-app/nuula-landing/${token}`)
    setTimeout(() => {
      this.authService.exchangeOneTimeUseTokenFromUrl()

      this.authService.currentTokenVerified.subscribe((val) => {
        this.tokenVerified = val
        if (val) {
          this.getDirectAppPacket()
        }
      })
      this.authService.currentTokenError.subscribe((val) => {
        this.tokenError = val
      })
    }, 0)
  }
  getDirectAppPacket() {
    this.getDirectAppStatusSubscription = this.directAppService
      .getDirectAppPacket$()
      .subscribe(
        (res) => {
          const response = res as GetPacketResponse
          this.metadata = response.packet.metadata

          const step = this.metadata.directAppStep as DirectAppStep

          this.directAppService.updatedDirectAppStatus$(
            this.metadata.directAppStep as DirectAppStep,
          )
          window.location.replace(getDirectAppUrlFromStep(step))
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }
}
