import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

import * as INTF from '../../../core/models/interfaces'

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  GO_HOME = 'goHome'
  DOC_U_SIGN = 'docUsign'
  SIGNIN = 'goSignIn'
  RETRYID = 'goRetryID'

  public readonly NOT_FOUND: INTF.ErrType = {
    title: 'Page Not Found',
    message: `The URL may have been entered incorrectly or the page
     you are looking for has been moved and no longer exists.`,
    img: 'not-found',
    btn: 'GO TO SIGN IN PAGE',
    click: 'goSignIn',
  }

  public readonly BIZ_NOT_VERIFIED: INTF.ErrType = {
    title: 'We could not verify your information.',
    message: `Unfortunately, we were not able to verify the information provided. Call
    <strong>(888) 705-3765</strong> or email us as <strong class="user-email"
      >docs@mulliganfunding.com</strong> for
    assistance.`,
    img: 'cannot-verify',
  }

  public readonly NOT_SIGNUP: INTF.ErrType = {
    title: 'We could not create the account',
    message: `Call <strong>(888) 705-3765</strong> or email us as
  <strong class="user-email">docs@mulliganfunding.com</strong> for
  assistance.`,
    img: 'cannot-verify',
  }

  public readonly ID_NOT_VERIFIED: INTF.ErrType = {
    title: 'We could not verify your <br>identity.',
    message: `Unfortunately, we were not able to verify your identity.
   <br>Call <strong>(888) 705-3765</strong> or email us at
   <strong class="user-email">docs@mulliganfunding.com</strong> for assistance.`,
    img: 'cannot-verify',
  }

  public readonly DOCUSIGN_DECLINE: INTF.ErrType = {
    title: 'You have declined to sign.',
    message: `We have been notified that you declined to sign. If you
   have any questions, call us at <strong>(888) 705-3765</strong> or email
    us at <strong class="user-email">docs@mulliganfunding.com</strong>.`,
    img: 'docusign-declined',
  }

  public readonly DOCUSIGN_CANCEL: INTF.ErrType = {
    title: 'You’ve saved your agreement to finish later.',
    message: `To continue closing your loan, you must sign your loan
   agreement. Use the button below to go back to the agreement.`,
    img: 'docusign-finish-later',
    btn: 'SIGN LOAN AGREEMENT',
    click: 'docUsign',
  }

  public readonly DOCUSIGN_VOIDED: INTF.ErrType = {
    title: 'Your loan agreement has been voided.',
    message: `Your loan agreement has been voided because it has
     [voiding reason]. If you have any questions, call us at
    <strong>(888) 705-3765</strong> or email us at
     <strong class="user-email">docs@mulliganfunding.com.</strong>`,
    img: 'docusign-voided',
  }

  public readonly LOAN_VOIDED: INTF.ErrType = {
    title: 'Your loan has been voided.',
    message: `Your loan has been voided. If you have any questions, call us at
    <strong>(888) 705-3765</strong> or email us at
     <strong class="user-email">docs@mulliganfunding.com.</strong>`,
    img: 'docusign-voided',
  }

  public readonly LOAN_DISABLED: INTF.ErrType = {
    title: 'Your loan deserves special attention.',
    message: `To continue closing your loan, a documentation
     specialist will contact you in 1 to 3 business days. If you
      have any questions, call us at <strong>(888) 705-3765</strong>
       or email us at <strong class="user-email">docs@mulliganfunding.com.</strong>`,
    img: 'loan-disabled',
  }

  public readonly SERVER_ERROR: INTF.ErrType = {
    title: 'Error',
    message: `An error has occurred. Please reach out if you need additional
    assistance.`,
    img: 'server-err',
    btn: 'GO TO HOMEPAGE',
    click: 'goHome',
  }

  private readonly ERROR_STORAGE_KEY = 'error'

  ErrorState: any

  errorSource = new Subject()
  currentError = this.errorSource.asObservable()

  constructor() {}

  reachMaxAttampt(errorCode: number) {
    return errorCode === 5
  }

  notMatchRecords(errorCode: number) {
    return errorCode === 10
  }

  updatedError(data: INTF.ErrType) {
    this.errorSource.next(data)
  }

  storeError(data: INTF.ErrType) {
    localStorage.setItem(this.ERROR_STORAGE_KEY, JSON.stringify(data))
  }

  checkIfStoredError(clearState: boolean = false) {
    if (
      localStorage.getItem(this.ERROR_STORAGE_KEY) &&
      localStorage.getItem(this.ERROR_STORAGE_KEY) !== 'undefined'
    ) {
      const err = JSON.parse(localStorage.getItem(this.ERROR_STORAGE_KEY))
      this.updatedError(err)
    }

    if (clearState) {
      localStorage.removeItem(this.ERROR_STORAGE_KEY)
    }
  }
}
