import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-nuula-loan-application-expired',
  templateUrl: './nuula-loan-application-expired.component.html',
  styleUrls: ['./nuula-loan-application-expired.component.scss'],
})
export class NuulaLoanApplicationExpiredComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      window.location.replace('/ui/direct-app/nuula-application-expired')
    }, 0)
  }
}
