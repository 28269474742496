<div class="margin-auto">

  <div class="money-bag margin-auto"></div>
  <div class="text-align-center">
    <p class="modal-title margin-auto">Disbursement Amount</p>
  </div>

  <div class="rectangle margin-auto">
    <div class="content-group">
      <div class="content">
        <div class="grid1fr">
          <div class="disbursement-title">Loan Amount</div>
          <div class="number">{{ calcNums.loanAmount | currency }}</div>
        </div>
        <div class="grid1fr">
          <div class="disbursement-title">Origination Fee</div>
          <div class="number">- {{ calcNums.originationFee | currency }}</div>
        </div>

        <div class="grid1fr" *ngIf="calcNums.payoffs && calcNums.payoffs.length!==0">
          <div class="loan-payoff">
            <div class="disbursement-title">Loan Payoff</div>
            <div class="company">
              ({{ calcNums.account.nameOfBusiness }})
            </div>
          </div>

          <div class="number">- {{ calcNums.payoffs[0].amount | currency }}</div>
        </div>

        <div class="line"></div>
        <div class="grid1fr">
          <div class="disbursement-title">Disbursement Amount</div>
          <div class="number">{{ calcNums.disbursement| currency }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="ok cursor-pointer margin-auto" (click)="close()">Close</div>

</div>