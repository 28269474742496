<!-- inside of right-box class -->
<div class="step-content">
  <div class="direct-app-page-title">
    About {{ applicationInfo?.accountInfo?.companyName }}
  </div>
  <div class="direct-app-page-info">
    Tell us where your business is located.
  </div>

  <div class="direct-app-page-sub-title">Address Information</div>
  <div class="direct-app-page-info info-mobile">
    Tell us where your business is located.
  </div>
  <div class="infoForm">
    <form
      *ngIf="physicalAddressInfoForm"
      [formGroup]="physicalAddressInfoForm"
      (keydown)="onSubmit($event)"
    >
      <div class="direct-app-form">
        <div class="input-text em">
          <div class="label-text">Street Address</div>
          <div class="content-input">
            <input
              formControlName="street"
              #street
              class="input-text container"
              type="text"
            />
          </div>
          <div *ngIf="showError || pf.street.dirty || pf.street.touched">
            <div
              *ngIf="pf.street.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Street address is required.</span
              >
            </div>
            <div
              *ngIf="pf.street.errors?.InValidateAddress"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Address cannot be a P.O. Box.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">City</div>
          <div class="content-input">
            <input
              formControlName="city"
              #city
              class="input-text container"
              type="text"
            />
          </div>
          <div *ngIf="showError || pf.city.dirty || pf.city.touched">
            <div
              *ngIf="pf.city.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >City is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">State</div>
          <div class="content-input">
            <select
              formControlName="state"
              #state
              id="stateOfIncorporation"
              class="input-text container"
              type="text"
            >
              <option hidden [ngValue]="null" selected>Select a State</option>
              <option value="{{ state }}" *ngFor="let state of states">
                {{ state }}
              </option>
            </select>
          </div>
          <div *ngIf="showError || pf.state.dirty || pf.state.touched">
            <div
              *ngIf="pf.state.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >State is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Zip Code</div>
          <div class="content-input">
            <input
              maxlength="5"
              formControlName="zip"
              #zip
              class="input-text container"
              type="text"
              value="{{ cleanedPhysicalZip }}"
            />
          </div>
          <div *ngIf="showError || pf.zip.dirty || pf.zip.touched">
            <div class="red-text alert alert-danger err-text">
              <span
                *ngIf="pf.zip.errors?.required"
                class="alert alert-danger help-text"
                >Zip code is required.</span
              >

              <span
                *ngIf="pf.zip.errors?.maxlength || pf.zip.errors?.minlength"
                class="alert alert-danger"
              >
                Enter a valid zip code.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="checkbox">
        <input
          #mailingAddress
          (click)="toggleMailingAddress()"
          id="mailingAddress"
          class="mailing"
          type="checkbox"
          name="mailingAddress"
          checked="checked"
        />
        <label class="" for="mailingAddress">
          Mailing address is different from business address
        </label>
      </div>
    </form>
  </div>

  <div class="infoForm">
    <form
      *ngIf="mailingAddressInfoForm"
      id="mailingAddressInfoForm"
      [formGroup]="mailingAddressInfoForm"
      (keydown)="onSubmit($event)"
    >
      <div class="direct-app-form">
        <div class="input-text em">
          <div class="label-text">Mailing Street Address</div>
          <div class="content-input">
            <input
              formControlName="mailingStreet"
              #street
              class="input-text container"
              type="text"
            />
          </div>
          <div
            *ngIf="
              showError || mf.mailingStreet.dirty || mf.mailingStreet.touched
            "
          >
            <div
              *ngIf="mf.mailingStreet.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >Mailing Street address is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">City</div>
          <div class="content-input">
            <input
              formControlName="mailingCity"
              #mailingCity
              class="input-text container"
              type="text"
            />
          </div>
          <div
            *ngIf="showError || mf.mailingCity.dirty || mf.mailingCity.touched"
          >
            <div
              *ngIf="mf.mailingCity.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >City is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">State</div>
          <div class="content-input">
            <select
              formControlName="mailingState"
              #mailingState
              id="mailingState"
              class="input-text container"
              type="text"
            >
              <option hidden [ngValue]="null" selected>Select a State</option>
              <option value="{{ state }}" *ngFor="let state of states">
                {{ state }}
              </option>
            </select>
          </div>
          <div
            *ngIf="
              showError || mf.mailingState.dirty || mf.mailingState.touched
            "
          >
            <div
              *ngIf="mf.mailingState.errors?.required"
              class="red-text"
              class="alert alert-danger err-text"
            >
              <span class="alert alert-danger help-text"
                >State is required.</span
              >
            </div>
          </div>
        </div>

        <div class="input-text em">
          <div class="label-text">Zip Code</div>
          <div class="content-input">
            <input
              maxlength="5"
              formControlName="mailingZip"
              #mailingZip
              class="input-text container"
              type="text"
              value="{{ cleanedMailingZip }}"
            />
          </div>
          <div
            *ngIf="showError || mf.mailingZip.dirty || mf.mailingZip.touched"
          >
            <div class="red-text" class="alert alert-danger err-text">
              <span
                class="alert alert-danger help-text"
                *ngIf="mf.mailingZip.errors?.required"
                >Zip code is required.</span
              >

              <span
                class="alert alert-danger help-text"
                *ngIf="
                  mf.mailingZip.errors?.maxlength ||
                  mf.mailingZip.errors?.minlength
                "
              >
                Enter a valid zip code.
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="btn-group">
      <div class="goback-group tablet-back">
        <div class="goback" (click)="goBack()">
          <img src="assets/imgs/link-arrow-left-icon.svg" />
          <a class="goback" #back>Back </a>
        </div>
      </div>

      <div class="btn-container end" *ngIf="!disableEdit">
        <button (click)="submitAddressInfo()" #submit class="btn-background">
          CONTINUE
        </button>
      </div>

      <div class="goback-group">
        <div class="gobackmobile" (click)="goBack()">
          <img src="assets/imgs/link-arrow-left-icon.svg" />
          <a class="gobackmobile" #back>Back </a>
        </div>
      </div>
    </div>
  </div>
</div>
