<div class="content-container">
  <div class="auth-page-container">
    <div class="info-page-wrap">
      <div></div>
      <div>
        <app-logo></app-logo>

        <div class="rectangle margin-auto">
          <div class="added-icon email-icon margin-auto"></div>

          <div *ngIf="error">
            <div class="text-align-center">
              <p class="pre-title margin-auto">{{ error.error.title }}</p>
            </div>
            <div class="err-rectangle margin-auto">
              <div class="review margin-auto text-align-center">
                {{ error.error.message }}
              </div>
            </div>
          </div>

          <div *ngIf="!error">
            <div class="text-align-center">
              <p class="pre-title margin-auto">Verify your Email</p>
            </div>
            <div class="err-rectangle margin-auto">
              <div class="review margin-auto text-align-center">
                We sent a verification link to
                <strong class="user-email">{{ currentUserEmail }}</strong
                >. If you do not receive an email within 10 minutes, check your
                spam folder first, then try again.
              </div>

              <div
                *ngIf="resent"
                class="resent-container password-updated margin-auto"
              >
                <div class="updated green-checked-icon"></div>
                <div class="signin-info fr">
                  Success! We resent your verification email.
                </div>
                <button (click)="close()" class="close"></button>
              </div>

              <div class="info text-align-center">
                <img class="blue-mail" src="assets/imgs/link-email-icon.svg" />
                <span class="bb resend" (click)="resendEmail()"
                  >Resend Email</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
