import { Component, OnInit, Input, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'
import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import { LoanService } from '../../core/services/loan/loan.service'
import { StepService } from '../../core/services/steps/step.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { handleError } from '../../core'

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss'],
})
export class IntroductionComponent implements OnInit, OnDestroy {
  @Input() calcNums: INTF.Loan
  @Input() userClaims: any

  updateLoanStatusSubscription: Subscription

  loanUuid: string
  loanStatus: INTF.DocCheckoutStep
  error: INTF.BackendError | null

  constructor(
    private loanService: LoanService,
    private stepService: StepService,
    private router: Router,
    private route: ActivatedRoute,
    public oktaAuth: OktaAuthService,
    private errorService: ErrorService,
  ) {
    this.loanService.currentStatus.subscribe((data) => {
      this.loanStatus = data
    })
  }

  async ngOnInit() {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
  }

  onSubmit(event: KeyboardEvent) {
    this.updateLoanStatus()
  }

  updateLoanStatus() {
    const nextStep: INTF.DocCheckoutStep = this.stepService.nextStep(
      INTF.DocCheckoutStep.loanIntroduction,
    )

    this.updateLoanStatusSubscription = this.loanService
      .updateLoanStatus$(this.loanUuid, nextStep)
      .subscribe(
        (res) => {
          this.loanStatus = res
          this.stepService.updatedStep(nextStep, this.loanUuid)
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  ngOnDestroy() {
    if (this.updateLoanStatusSubscription) {
      this.updateLoanStatusSubscription.unsubscribe()
      this.updateLoanStatusSubscription = null
    }
  }
}
