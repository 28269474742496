import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { BehaviorSubject, Observable, of } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import {
  httpOptions,
  httpOptionsWithCredentials,
  httpOptionsWithHeaders,
  QueryParams,
} from '../../../core'
import * as INTF from '../../models/interfaces'
import {
  DirectAppStep,
  getDirectAppStepFromString,
  GetPacketResponse,
} from '../../'
import { environment } from '../../../../environments/environment'
import { ConfigService } from '../config/config.service'
import * as fakeDirectAppData from '../../../../assets/fixtures/direct-application.json'
import { DirectAppStepService } from '../direct-app-steps/direct-app-steps.service'
import {
  ConnectedBankAccount,
  CreateAccountResponse,
  GenericMetadataResponse,
  GetPlaidInfoResponse,
  OwnerCreditAuthResponse,
  PostBankInfoResponse,
  StreetAddress,
} from '../../models'
import { fakeSendRid } from './../../../../assets/fixtures/fakeSendRid'
import { fakeBasicInfo } from './../../../../assets/fixtures/fakeBasicInfo'
import { fakeContactInfo } from './../../../../assets/fixtures/fakeContactInfo'
import { fakeAddressInfo } from './../../../../assets/fixtures/fakeAddressInfo'

@Injectable({
  providedIn: 'root',
})
export class DirectAppService {
  useServer: boolean
  serverUrl: string | null
  statusUrl: string | null

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private directAppStepService: DirectAppStepService,
  ) {
    this.useServer = environment.useServer
    this.serverUrl = this.getUrl()
  }

  createFromLeadSource = new BehaviorSubject<boolean>(false)
  currentCreateFromLead = this.createFromLeadSource.asObservable()
  directAppPacketIdSource = new BehaviorSubject<string>(null)
  currentDirectAppPacketId = this.directAppPacketIdSource.asObservable()

  directAppRespIdSource = new BehaviorSubject<string>(null)
  currentDirectAppRespId = this.directAppRespIdSource.asObservable()

  directAppStatusSource = new BehaviorSubject<DirectAppStep>(
    DirectAppStep.UNKNOWN,
  )
  currentDirectAppStatus = this.directAppStatusSource.asObservable()

  directAppQParamsSource = new BehaviorSubject<QueryParams>(null)
  currentdirectAppQParams = this.directAppQParamsSource.asObservable()

  directAppInfoSource = new BehaviorSubject<INTF.ApplicationInfo>(null)
  currentDirectAppInfo = this.directAppInfoSource.asObservable()

  directAppMetadataSource = new BehaviorSubject<INTF.Metadata | undefined>(
    undefined,
  )
  currentDirectAppMetadata = this.directAppMetadataSource.asObservable()

  directAppOwnerCreditAuthSource = new BehaviorSubject<
    OwnerCreditAuthResponse | undefined
  >(undefined)
  currentDirectAppOwnerCreditAuth =
    this.directAppOwnerCreditAuthSource.asObservable()

  directAppPlaidBankInfoSource = new BehaviorSubject<ConnectedBankAccount[]>([])
  currentPlaidBankInfo = this.directAppPlaidBankInfoSource.asObservable()

  deleteItemSource = new BehaviorSubject<boolean>(false)
  currentDeleteItem = this.deleteItemSource.asObservable()

  updatedDirectAppStatus$(step: DirectAppStep) {
    this.directAppStatusSource.next(step)
  }

  getUrl() {
    if (environment.serverUrl) {
      if (environment.environment === 'local-server') {
        return `${environment.serverUrl}`
      } else {
        return `${environment.serverUrl}/direct-app`
      }
    } else {
      return null
    }
  }

  setStatus$(status): Observable<GetPacketResponse> {
    if (this.useServer) {
      return this.postSetStatus$(status)
    } else {
      return this.setStatusStatic$()
    }
  }

  postSetStatus$(status: INTF.DirectAppResp): Observable<GetPacketResponse> {
    this.getUrl()
    const url = `${this.serverUrl}/set-status`

    return this.http.post<GetPacketResponse>(url, status, httpOptions).pipe(
      tap((res) => {
        const response = res as GetPacketResponse

        this.directAppInfoSource.next(response.packet.applicationInfo)
        this.directAppMetadataSource.next(response.packet.metadata)
      }),

      catchError(this.configService.handleError),
    )
  }

  setStatusStatic$(): Observable<GetPacketResponse> {
    return null
  }

  postDeleteDocuments$(packetId: string, filenameList: string[]) {
    this.getUrl()
    const url = `${this.serverUrl}/document-delete`

    return this.http
      .post(
        url,
        {
          filenameList,
          packetId,
        },
        httpOptions,
      )
      .pipe(
        tap((res) => {
          console.log('postDeleteDocuments response:', res)
        }),

        catchError(this.configService.handleError),
      )
  }

  postResendVerificationEmail$() {
    this.getUrl()
    const url = `${this.serverUrl}/resend-email-verification`

    return this.http.post(url, undefined, httpOptions).pipe(
      tap((res) => {
        console.log('postResendVerificationEmail response:', res)
      }),
      catchError((err, caught) => {
        console.log('resendVerificationError:', err)
        if (err?.error?.msg === 'Email has already been verified') {
          const updatedMetadata = {
            ...this.directAppMetadataSource.getValue(),
            emailVerified: true,
          }
          this.directAppMetadataSource.next(updatedMetadata)
        } else {
          return this.configService.handleError(err)
        }
      }),
    )
  }

  postUploadDocument$(packetId: string, formData: FormData) {
    this.getUrl()
    const url = `${this.serverUrl}/document-upload/${packetId}`

    // Use the httpOptions without a Content-type so that it is dynamically generated
    return this.http.post(url, formData, httpOptionsWithCredentials).pipe(
      tap((res) => {
        console.log('postUploadDocument response:', res)
      }),

      catchError(this.configService.handleError),
    )
  }

  createAccount$(data, isNuulaUser = false) {
    if (this.useServer) {
      return this.postCreateAccount$(data, isNuulaUser)
    } else {
      return this.createAccountStatic$()
    }
  }

  handleCreateAccountResponse = (
    data: any,
    response: CreateAccountResponse,
  ) => {
    this.directAppMetadataSource.next(response.metadata)
    const updatedAppInfo: INTF.ApplicationInfo = {
      ...this.directAppInfoSource.value,
      accountInfo: data.accountInfo,
    }
    this.directAppInfoSource.next(updatedAppInfo)
    this.directAppPacketIdSource.next(response.metadata.id)
    this.updatedDirectAppStatus$(
      getDirectAppStepFromString(response.metadata.directAppStep),
    )
  }

  postCreateAccount$(data, isNuulaUser) {
    const url = isNuulaUser
      ? `${this.serverUrl}/create-account-nuula`
      : `${this.serverUrl}/create-account`
    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {
        this.handleCreateAccountResponse(data, res as CreateAccountResponse)
      }),

      catchError(this.configService.handleError),
    )
  }

  createAccountStatic$() {
    const fakeDirectAppStepString = fakeDirectAppData['createAccountStatic']
      .metadata.directAppStep as INTF.DirectAppStepString
    const obs$ = of(fakeDirectAppStepString)
    const updatedDirectAppStep = getDirectAppStepFromString(
      fakeDirectAppStepString,
    )
    this.updatedDirectAppStatus$(updatedDirectAppStep)
    return obs$
  }

  postCreateAccountFromLead$(data) {
    const url = `${this.serverUrl}/create-account-from-lead`
    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {
        this.handleCreateAccountResponse(data, res as CreateAccountResponse)
      }),

      catchError(this.configService.handleError),
    )
  }
  getDirectAppPacket$(): Observable<INTF.DirectAppGetPacketResp> {
    if (this.useServer) {
      return this.fetchDirectAppPacket$()
    } else {
      return this.getDirectAppPacketStatic$()
    }
  }

  fetchDirectAppPacket$(): Observable<INTF.DirectAppGetPacketResp> {
    const url = `${this.serverUrl}/get-packet`

    return this.http
      .post<INTF.DirectAppGetPacketResp>(url, undefined, httpOptions)
      .pipe(
        tap((res) => {
          console.log('fetchDirectAppPacket', res)
          const response = res as GetPacketResponse
          this.directAppPacketIdSource.next(response.packet.id)
          this.directAppMetadataSource.next(response.packet.metadata)
          this.directAppInfoSource.next(response.packet.applicationInfo)
          const step = getDirectAppStepFromString(
            res['packet'].metadata.directAppStep,
          )
          this.updatedDirectAppStatus$(step)
        }),
        catchError(this.configService.handleError),
      )
  }

  getDirectAppPacketStatic$(): Observable<INTF.DirectAppGetPacketResp> {
    const obs$ = of(fakeSendRid)
    this.updatedDirectAppStatus$(
      getDirectAppStepFromString(fakeSendRid['packet'].metadata.directAppStep),
    )
    return obs$
  }

  submitBasicInfo$(data): Observable<GenericMetadataResponse> {
    if (this.useServer) {
      return this.postBasicInfo$(data)
    } else {
      return this.submitBasicInfoStatic$()
    }
  }

  postBasicInfo$(data): Observable<GenericMetadataResponse> {
    const url = `${this.serverUrl}/basic-info`

    return this.http.post<GenericMetadataResponse>(url, data, httpOptions).pipe(
      tap((res) => {
        const response = res as GenericMetadataResponse
        this.directAppMetadataSource.next(response.metadata)
        const updatedApplicationInfo = {
          ...this.directAppInfoSource.value,
          basicInfo: data.basicInfo,
        }

        this.directAppInfoSource.next(updatedApplicationInfo)
        this.updatedDirectAppStatus$(
          getDirectAppStepFromString(response.metadata.directAppStep),
        )
      }),
      catchError(this.configService.handleError),
    )
  }

  submitBasicInfoStatic$(): Observable<GenericMetadataResponse> {
    const obs$ = of(fakeBasicInfo)

    this.updatedDirectAppStatus$(
      getDirectAppStepFromString(fakeBasicInfo.metadata.directAppStep),
    )
    return obs$
  }

  submitContactInfo$(data): Observable<GenericMetadataResponse> {
    if (this.useServer) {
      return this.postContactInfo$(data)
    } else {
      return this.submitContactInfoStatic$()
    }
  }

  postContactInfo$(data): Observable<GenericMetadataResponse> {
    const url = `${this.serverUrl}/contact-info`

    return this.http.post<GenericMetadataResponse>(url, data, httpOptions).pipe(
      tap((res) => {
        const response = res as GenericMetadataResponse
        this.directAppMetadataSource.next(response.metadata)
        const updatedApplicationInfo = {
          ...this.directAppInfoSource.value,
          contactInfo: data.contactInfo,
        }

        this.directAppInfoSource.next(updatedApplicationInfo)
        this.updatedDirectAppStatus$(
          getDirectAppStepFromString(response.metadata.directAppStep),
        )
      }),

      catchError(this.configService.handleError),
    )
  }

  submitContactInfoStatic$(): Observable<GenericMetadataResponse> {
    const obs$ = of(fakeContactInfo)
    this.updatedDirectAppStatus$(
      getDirectAppStepFromString(fakeContactInfo.metadata.directAppStep),
    )
    return obs$
  }

  submitAddressinfo$(data): Observable<GenericMetadataResponse> {
    if (this.useServer) {
      return this.postAddressinfo$(data)
    } else {
      return this.submitAddressInfoStatic$()
    }
  }

  postAddressinfo$(data): Observable<GenericMetadataResponse> {
    const url = `${this.serverUrl}/address-info`

    return this.http.post<GenericMetadataResponse>(url, data, httpOptions).pipe(
      tap((res) => {
        const response = res as GenericMetadataResponse
        this.directAppMetadataSource.next(response.metadata)
        const updatedApplicationInfo = {
          ...this.directAppInfoSource.value,
          addressInfo: data.addressInfo,
        }

        this.directAppInfoSource.next(updatedApplicationInfo)
        this.updatedDirectAppStatus$(
          getDirectAppStepFromString(response.metadata.directAppStep),
        )
      }),

      catchError(this.configService.handleError),
    )
  }

  submitAddressInfoStatic$(): Observable<GenericMetadataResponse> {
    const obs$ = of(fakeAddressInfo)

    this.updatedDirectAppStatus$(
      getDirectAppStepFromString(fakeAddressInfo.metadata.directAppStep),
    )
    return obs$
  }

  submitOwnerInfo$(data) {
    if (this.useServer) {
      return this.postOwnerinfo$(data)
    } else {
      return this.submitOwnerInfoStatic$()
    }
  }

  postOwnerinfo$(data) {
    const url = `${this.serverUrl}/owner-info`

    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {
        const response = res as GenericMetadataResponse
        const updatedApplicationInfo = {
          ...this.directAppInfoSource.value,
          ownerInfo: data.ownerInfo,
        }

        this.directAppInfoSource.next(updatedApplicationInfo)
        this.directAppMetadataSource.next(response.metadata)
        this.updatedDirectAppStatus$(
          getDirectAppStepFromString(response.metadata.directAppStep),
        )
      }),

      catchError(this.configService.handleError),
    )
  }

  submitOwnerInfoStatic$() {
    const fakeDirectAppStepString = fakeDirectAppData['submitOwnerInfoStatic'][
      'metadata'
    ]['directAppStep'] as INTF.DirectAppStepString
    const obs$ = of(fakeDirectAppStepString)
    const updatedDirectAppStep = getDirectAppStepFromString(
      fakeDirectAppStepString,
    )
    this.updatedDirectAppStatus$(updatedDirectAppStep)
    return obs$
  }

  submitDebtInfo$(data) {
    if (this.useServer) {
      return this.postDebtInfo$(data)
    } else {
      return this.submitDebtInfoStatic$()
    }
  }

  postDebtInfo$(data) {
    const url = `${this.serverUrl}/debt-info`

    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {
        const response = res as GenericMetadataResponse
        const updatedApplicationInfo = {
          ...this.directAppInfoSource.value,
          debtInfo: data.debtInfo,
        }

        this.directAppInfoSource.next(updatedApplicationInfo)
        this.directAppMetadataSource.next(response.metadata)
        this.updatedDirectAppStatus$(
          getDirectAppStepFromString(response.metadata.directAppStep),
        )
      }),

      catchError(this.configService.handleError),
    )
  }

  submitDebtInfoStatic$() {
    const fakeDirectAppStepString = fakeDirectAppData['submitOwnerInfoStatic'][
      'metadata'
    ]['directAppStep'] as INTF.DirectAppStepString
    const obs$ = of(fakeDirectAppStepString)
    const updatedDirectAppStep = getDirectAppStepFromString(
      fakeDirectAppStepString,
    )
    this.updatedDirectAppStatus$(updatedDirectAppStep)
    return obs$
  }

  submitPlaidInteractions$(packetId: string) {
    const url = `${this.serverUrl}/update-plaid-info`

    const data = {
      packetId,
      plaidInteractions: true,
    }
    return this.http.post(url, data, httpOptions).pipe(
      tap((res) => {
        const response = res as PostBankInfoResponse
        console.log('submitPlaidInteractiosn response:', response)
        this.directAppMetadataSource.next(response.metadata)
      }),
      catchError(this.configService.handleError),
    )
  }

  submitGetPlaidInfo$(packetId: string) {
    const url = `${this.serverUrl}/get-plaid-info`
    const body = {
      packetId,
    }
    return this.http.post(url, body, httpOptions).pipe(
      tap((res) => {
        const response = res as GetPlaidInfoResponse
        this.directAppPlaidBankInfoSource.next(response.plaidConnections)
      }),
      catchError(this.configService.handleError),
    )
  }

  addToPlaidBankInfo$(info: ConnectedBankAccount) {
    const newPlaidList = this.directAppPlaidBankInfoSource.value.slice()
    newPlaidList.push(info)
    this.directAppPlaidBankInfoSource.next(newPlaidList)
  }

  postVerifyEmail$(token: string) {
    const url = `${this.serverUrl}/verify-email`
    const body = {
      token,
    }
    return this.http.post(url, body, httpOptionsWithHeaders).pipe(
      tap((res) => {
        console.log('postVerifyEmail response:', res)
        const response = res as GetPacketResponse
        this.directAppPacketIdSource.next(response.packet.metadata.id)
        this.directAppMetadataSource.next(response.packet.metadata)

        this.directAppInfoSource.next(response.packet.applicationInfo)
      }),
    )
  }

  postRetrieveOwnerDetail$(token: string) {
    const url = `${this.serverUrl}/retrieve-owner-credit-auth-record`
    const body = {
      token,
    }

    return this.http.post(url, body, httpOptionsWithHeaders).pipe(
      tap((res) => {
        const response = res as OwnerCreditAuthResponse
        this.directAppOwnerCreditAuthSource.next(response)
      }),
    )
  }

  postAuthorizeOwnerCredit$(
    token: string,
    dateOfBirth: string,
    socialSecurityNumber: string,
    address: StreetAddress,
  ) {
    const url = `${this.serverUrl}/auth-owner-credit`
    const body = {
      address,
      dateOfBirth,
      ssn: socialSecurityNumber,
      token,
    }
    return this.http.post(url, body, httpOptionsWithHeaders).pipe(
      tap((res) => {
        console.log('postAuthorizeOwnerCredit response:', res)
      }),
    )
  }

  getRequestSfData(packetId: string) {
    this.getUrl()
    const body = {
      packetId,
    }
    const url = `${this.serverUrl}/request-sf-data`

    return this.http.post<INTF.DirectAppResp>(url, body, httpOptions).pipe(
      tap((res) => {
        const response = res as GenericMetadataResponse
        this.directAppMetadataSource.next(response.metadata)
      }),

      catchError(this.configService.handleError),
    )
  }

  getPacketForLead(token: string) {
    this.getUrl()
    const url = `${this.serverUrl}/get-packet-for-lead`

    const body = {
      token,
    }

    return this.http.post<GetPacketResponse>(url, body, httpOptions).pipe(
      tap((res) => {
        const response = res as GetPacketResponse
        this.createFromLeadSource.next(true)
        this.directAppMetadataSource.next(res.packet.metadata)
        this.directAppInfoSource.next(response.packet.applicationInfo)
        this.directAppPacketIdSource.next(token)
        console.log('direct-app-service response:', response)
      }),
      // catchError(this.configService.handleError),
    )
  }
}
