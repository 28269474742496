import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-nuula-app-submitted',
  templateUrl: './nuula-app-submitted.component.html',
  styleUrls: ['./nuula-app-submitted.component.scss'],
})
export class NuulaAppSubmittedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      window.location.replace('/ui/tiles/app-submitted')
    }, 0)
  }
}
