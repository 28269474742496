import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { OktaAuthService } from '@okta/okta-angular'
import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import { AuthService } from '../../core/services/auth/auth.service'

@Component({
  selector: 'app-pre-approval',
  templateUrl: './pre-approval.component.html',
  styleUrls: ['./pre-approval.component.scss'],
})
export class PreApprovalComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public oktaAuth: OktaAuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  loanUuid: string
  id: number
  signOutSubscription: Subscription

  @Input() calcNums: INTF.Loan
  @Input() userClaims: any

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')
  }

  async logout() {
    this.authService.logout()
  }

  goDashboard() {
    this.router.navigate([`/`])
  }
}
