export enum SfOpportunityStageName {
  PAPERWORK_PENDING = 'Paperwork Pending',
  E_PREP = 'e-Prep',
  UNDERWRITING = 'Underwriting',
  OFFER = 'Offer',
  ONGOING_DISCUSSION = 'Ongoing Discussion',
  SOLUTION_DEFINITION = 'Solution Definition',
  PROPOSAL = 'Proposal',
  DOCUMENTATION = 'Documentation',
  STIPS = 'Stips',
  WELCOME_CALL = 'Welcome Call',
  VERIFY_FUNDS = 'Verify Funds',
  ACH_REQUESTED = 'ACH Requested',
  FUNDING = 'Funding',
  ACTIVE = 'Active',
  CLOSED_LOST = 'Closed Lost',
}
