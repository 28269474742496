import { Component, OnInit, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Subscription } from 'rxjs'

import { ErrorService } from '../../core/services/generic-error/error.service'
import { LoanService } from '../../core/services/loan/loan.service'
import { AuthService } from '../../core/services/auth/auth.service'
import { handleError } from '../../core'

@Component({
  selector: 'app-finish-later',
  templateUrl: './finish-later.component.html',
  styleUrls: ['./finish-later.component.scss'],
})
export class FinishLaterComponent implements OnInit {
  @Input() calcNums: any
  docusignUrlSubscription: Subscription
  loanUuid: string
  docusignUrl: string

  constructor(
    private errorService: ErrorService,
    private loanService: LoanService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')

    this.errorService.storeError(this.errorService.DOCUSIGN_CANCEL)
    this.getDocusignUrl()
  }

  docUsign() {
    window.location.href = this.docusignUrl
  }

  async logout() {
    this.authService.logout()
  }

  getDocusignUrl() {
    this.docusignUrlSubscription = this.loanService
      .docusignUrl$(this.loanUuid)
      .subscribe(
        (res) => {
          if (res) {
            this.docusignUrl = res.docusignUrl
          }
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }
}
