import { getAccessToken } from './get-access-token'
import { updateAccessToken } from './update-access-token'

export const invalidateAccessToken: () => void = () => {
  const mfToken = getAccessToken()
  updateAccessToken({
    ...mfToken,
    isValid: false,
  })
}
