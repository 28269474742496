import * as INTF from '../../app/core/models/interfaces'

export const fakeAddressInfo: INTF.DirectAppResp = {
  status: 'success',
  metadata: {
    accountInfoUpdated: '2021-09-08T21:24:06.729999+00:00',
    addressInfoUpdated: '2021-09-08T21:30:34.757883+00:00',
    basicInfoUpdated: '2021-09-08T22:08:02.551075+00:00',
    contactInfoUpdated: '2021-09-08T22:21:55.195636+00:00',
    id: '001cb771cbd08f310ad0876',
    respId: 'fdd48258cf794302',
    boxFolderId: '144888688372',
    username: 'abby@fdsa.com',
    version: 'pr3606-107f19ca9366fa7dbe5b3a4a50f8e02fa7fcf265',
    packet_payload_version: '3',
    qparams: {
      firstName: null,
      lastName: null,
      companyName: null,
      phoneNumber: null,
      email: null,
      username: null,
    },
    timestamps: [
      { '2021-09-02T18:26:55.534964+00:00': 'accountInfo' },
      { '2021-09-08T18:20:00.452044+00:00': 'basicInfo' },
      { '2021-09-08T21:25:56.768568+00:00': 'contactInfo' },
      { '2021-09-08T21:30:34.757883+00:00': 'addressInfo' },
    ],
    file_uploads: [],
    maxStepReachedInt: 20,
    plaidInteractions: false,
    emailVerified: false,
    directAppStep: 'owner-info',
    created: '2021-09-02T18:24:01.122463+00:00',
    updated: '2021-09-02T18:26:55.535015+00:00',
    data_version: 5,
  },
}
