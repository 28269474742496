import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { OktaAuthService } from '@okta/okta-angular'
import * as INTF from '../../models/interfaces'
import { environment } from '../../../../environments/environment'
import { ConfigService } from '../config/config.service'
import * as fakeData from '../../../../assets/fixtures/calcNums.json'
import { MicroAuthService } from '../../services/micro-auth'
import { ErrorService } from '../../services/generic-error/error.service'
import { BannersStatesService } from '../../services/banners/banners-states.service'
import {
  checkIsTokenNuula,
  getTokenObjectFromValue,
  handleError,
  invalidateAccessToken,
  storeAccessToken,
} from '../../utils'
import { ModalService } from '../modal/modal.service'
import { MFAccessToken, TokenExchangeResult } from '../../models'
import { NUULA } from '../../strings'
// https://developer.okta.com/code/angular/okta_angular_sign-in_widget/
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  }
  useServer: boolean
  serverUrl: string | null
  interval: null | number
  public idToken: string
  public isAuthenticated: boolean

  accessTokenExpiresAt: number | null
  tokenTimeLeft = new Subject<number>()
  currentTokenTimeLeft = this.tokenTimeLeft.asObservable()

  packetId = new Subject<string>()
  currentPacketId = this.packetId.asObservable()
  renewTokenSub: Subscription
  emitTokenRenewedSub = new Subject<boolean>()
  emitTokenRenewedObs = this.emitTokenRenewedSub.asObservable()
  mfAccessToken = new BehaviorSubject<MFAccessToken>(null)
  currentMfAccessToken = this.mfAccessToken.asObservable()

  isNuulaUser = new BehaviorSubject<boolean>(false)
  currentIsNuulaUser = this.isNuulaUser.asObservable()

  tokenError = new BehaviorSubject<boolean>(false)
  currentTokenError = this.tokenError.asObservable()

  tokenVerified = new BehaviorSubject<boolean>(false)
  currentTokenVerified = this.tokenVerified.asObservable()

  constructor(
    private errorService: ErrorService,
    private microAuthService: MicroAuthService,
    private modalService: ModalService,
    public oktaAuth: OktaAuthService,
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router,
    private bannersStatesService: BannersStatesService,
  ) {
    this.useServer = environment.useServer
    this.serverUrl = this.getUrl()

    // Subscribe to authentication state changes, fires when login/logout
    this.oktaAuth.$authenticationState.subscribe((isAuthenticated: boolean) => {
      this.isAuthenticated = isAuthenticated
    })
  }

  private updateTokenTimeLeft() {
    // when we have the mfAccessToken, emit the remaining time left
    const tokenValue = this.mfAccessToken.getValue()
    if (tokenValue && tokenValue.isValid) {
      const currentTime = Date.now() / 1000
      const timeLeft = tokenValue.exp - currentTime
      if (timeLeft > 0) {
        this.tokenTimeLeft.next(timeLeft)
      } else {
        this.tokenTimeLeft.next(null)
        this.mfAccessToken.next({
          ...tokenValue,
          isValid: false,
        })
        invalidateAccessToken()
      }
    } else {
      // Token isn't present or is no longer valid
      this.tokenTimeLeft.next(null)
      this.clearInterval()
    }
  }

  loadToken(token: MFAccessToken) {
    this.clearInterval()
    if (token && token.value) {
      storeAccessToken(token.value)
      this.mfAccessToken.next(token)
      this.isNuulaUser.next(checkIsTokenNuula(token))

      // Uncomment this to test Nuula users
      // this.isNuulaUser.next(true)
      this.updateTokenTimeLeft()
      this.interval = window.setInterval(
        () => this.updateTokenTimeLeft(),
        1000,
      ) as number
    }
  }

  clearInterval() {
    if (this.interval) {
      window.clearInterval(this.interval)
    }
  }

  getUrl() {
    if (environment.serverUrl) {
      if (environment.environment === 'local-server') {
        return `${environment.serverUrl}`
      } else {
        return `${environment.serverUrl}/auth`
      }
    } else {
      return null
    }
  }

  renewToken() {
    const tokenValue = this.mfAccessToken.getValue()
    if (tokenValue && tokenValue.isValid) {
      this.renewTokenSub = this.microAuthService
        .tokenRefresh$(tokenValue.value)
        .subscribe(
          (res) => {
            const response = res as TokenExchangeResult
            console.log('renewToken--200', res)
            const newToken = getTokenObjectFromValue(response.access_token)
            this.loadToken(newToken)
          },
          (err) => {
            console.log('renewToken---400', err)
            this.bannersStatesService.storeBannerState(
              this.bannersStatesService.SESSION_EXP,
            )

            this.mfAccessToken.next({
              ...tokenValue,
              isValid: false,
            })
            invalidateAccessToken()
            localStorage.removeItem('okta-token-storage')
            handleError(err, this.errorService, this.router)
            this.router.navigate([`/auth/login`])
          },
        )
    }
  }

  emitTokenRenewed(val: boolean) {
    this.emitTokenRenewedSub.next(val)
  }

  async logout() {
    if (this.useServer) {
      // Terminates the session with Okta and removes current tokens.
      await this.oktaAuth.signOut()
      const oldToken = this.mfAccessToken.getValue()
      this.mfAccessToken.next({
        ...oldToken,
        isValid: false,
      })
      invalidateAccessToken()
    } else {
      fakeData.auth.isAuthenticated = false
    }
  }

  forgotPassword(data: INTF.ForgotPasswordReq): Observable<{}> {
    if (this.useServer) {
      return this.postForgotPassword(data)
    } else {
      return this.forgotPasswordStatic$()
    }
  }

  forgotPasswordStatic$(): Observable<{}> {
    const obs$ = of(null)
    return obs$
  }

  postForgotPassword(data: INTF.ForgotPasswordReq) {
    const url = `${this.serverUrl}/password-reset`

    return this.http
      .post<{}>(url, data, this.httpOptions)
      .pipe(catchError(this.configService.handleError))
  }

  resetPassword(data: INTF.ResetPassword): Observable<{}> {
    if (this.useServer) {
      return this.postResetPassword(data)
    } else {
      return this.resetPasswordStatic$()
    }
  }

  resetPasswordStatic$(): Observable<{}> {
    const obs$ = of(null)
    // go to login
    fakeData.auth.isAuthenticated = false
    return obs$
  }

  postResetPassword(data: INTF.ResetPassword): Observable<{}> {
    const url = `${this.serverUrl}/password-reset-finish`

    return this.http.post<{}>(url, data, this.httpOptions).pipe(
      tap((val) => console.log(`resetPassword: ${val}`)),
      catchError(this.configService.handleError),
    )
  }

  exchangeOneTimeUseTokenFromUrl(incomingToken?: string) {
    let token
    if (incomingToken) {
      token = incomingToken
    } else {
      const urlParts = this.router.url.split('/')
      token = urlParts[urlParts.length - 1]
    }
    this.microAuthService.exchangeOneTimeUseToken$(token).subscribe(
      (res) => {
        const response = res as TokenExchangeResult
        console.log('OneTimeUse token exchange result:', response)
        const newToken = getTokenObjectFromValue(response.access_token)
        this.loadToken(newToken)
        this.tokenVerified.next(true)
      },
      (error) => {
        console.warn('error with one time use token exchange: ', error)
        this.tokenError.next(true)
      },
    )
  }
}
