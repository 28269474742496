import { Component, Input, OnInit } from '@angular/core'
import { DirectAppStep } from 'src/app/core'

@Component({
  selector: 'app-direct-header',
  templateUrl: './direct-header.component.html',
  styleUrls: ['./direct-header.component.scss'],
})
export class DirectHeaderComponent implements OnInit {
  directAppStep: DirectAppStep | null
  @Input() set setDirectAppStep(value: DirectAppStep) {
    this.directAppStep = value
  }
  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
  }
  constructor() {}

  ngOnInit(): void {}
}
