// PLACEHOLDER Text
export const DOLLAR_INPUT_PLACEHOLDER = '0.00'
export const SSN_INPUT_PLACEHOLDER = '### - ## - ####'
export const TAX_ID_INPUT_PLACEHOLDER = '## - #######'

// REGEX Formulas
export const EMAIL_FORM_REGEX = /\S+@\S+\.\S+/
export const SSN_FORM_REGEX = /^\d{3}-?\d{2}-?\d{4}$/
export const SSN_OR_TAX_ID_REGEX =
  /^(\d{3}(?:-)\d{2}(?:-)\d{4}$)|(\d{2}(?:-)\d{7})$/
export const PO_BOX_FORM_REGEX =
  /\bP(ost|ostal)?([ \.]*O(ffice)?)?([ \.]*Box)?\b/i

// OTHER
export const BYPASS_ONE_TIME_USE_TOKEN = 'load-access-token'
export const NUULA = 'nuula'
export const MF_ACCESS_TOKEN = 'mf_access_token'
export const PDF = 'application/pdf'
export const PLAID_SOURCE_LIST = [
  'https://auth.mulliganfunding.com',
  'https://prod.plaid.mulligancloud.com',
  'https://dev.plaid.mulligancloud.com',
]
