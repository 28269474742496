import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { scan, takeWhile } from 'rxjs/operators'
import { Subscription, timer } from 'rxjs'
import { tap } from 'rxjs/operators'

import * as INTF from '../../core/models/interfaces'
import { LoanService } from '../../core/services/loan/loan.service'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { StepService } from '../../core/services/steps/step.service'
import Utils from '../../shared/utils/utils'
import { handleError } from '../../core'

@Component({
  selector: 'app-loan-agreement',
  templateUrl: './loan-agreement.component.html',
  styleUrls: ['./loan-agreement.component.scss'],
})
export class LoanAgreementComponent implements OnInit, OnDestroy {
  @Input() calcNums: INTF.Loan
  @Input() userClaims: any

  questions: any
  loanUuid: string
  loanStatus: INTF.DocCheckoutStep
  error: INTF.BackendError | null
  docusignUrl: string
  docusignUrlSubscription: Subscription

  showSpinnerTimer: any
  getNewUrlTimer: any
  incompleteDocusign = false
  completedDocusign = false
  showSpinner = false

  constructor(
    private stepService: StepService,
    private loanService: LoanService,
    private route: ActivatedRoute,
    private router: Router,
    private errorService: ErrorService,
  ) {
    this.loanService.currentStatus.subscribe((data) => {
      this.loanStatus = data
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.loanUuid = this.route.snapshot.paramMap.get('loanUuid')

    if (this.loanStatus === INTF.DocCheckoutStep.businessReference) {
      this.completedDocusign = true
      this.incompleteDocusign = false
    }
    if (this.loanStatus === INTF.DocCheckoutStep.loanAgreement) {
      this.completedDocusign = false
      this.incompleteDocusign = true

      this.getDocusignUrl()

      if (!this.docusignUrl) {
        this.showSpinner = true
        // hide spinner and redirect to errror page when spinning for 2 min
        this.showSpinnerTimer = timer(0, 1000).pipe(
          scan((acc) => --acc, 130),
          takeWhile((x) => x >= 0),
          tap((val) => {
            if (val === 0) {
              this.showSpinner = false
              this.errorService.storeError(
                this.errorService.SERVER_ERROR as INTF.ErrType,
              )
              this.router.navigate([`doc-checkout/unknownerror`])
            }
          }),
        )
      }
    }
  }

  continue() {
    location.reload()
  }

  startExpLinkTimer() {
    this.getNewUrlTimer = timer(0, 1000).pipe(
      scan((acc) => --acc, 130),
      takeWhile((x) => x >= 0),
      tap((val) => {
        if (val === 0) {
          this.getDocusignUrl()
        }
      }),
    )
  }

  getDocusignUrl() {
    this.loanStatus = this.stepService.stepFromString(
      this.calcNums.docCheckoutStatus as INTF.stepStrings,
    )
    this.docusignUrlSubscription = this.loanService
      .docusignUrl$(this.loanUuid)
      .subscribe(
        (res) => {
          if (res) {
            this.docusignUrl = res.docusignUrl
            this.showSpinner = false
            this.startExpLinkTimer()
          }
        },
        (err) => {
          handleError(err, this.errorService, this.router)
        },
      )
  }

  docUsign() {
    Utils.Redirect(this.docusignUrl)
  }

  ngOnDestroy() {
    if (this.docusignUrlSubscription) {
      this.docusignUrlSubscription.unsubscribe()
      this.docusignUrlSubscription = null
    }
  }
}
