import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core'
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router'
import { OktaAuthService } from '@okta/okta-angular'
import { Subscription } from 'rxjs'
import * as fakeData from '../../assets/fixtures/calcNums.json'
import { DirectAppStepService } from '../core/services/direct-app-steps/direct-app-steps.service'
import { DirectAppService } from '../core/services/direct/direct-app.service'
import { DirectAppReviewSubmitService } from '../core/services/direct-app-review-submit/direct-app-review-submit.service'
import * as INTF from '../core/models/interfaces'
import {
  ConnectedBankAccount,
  DirectAppStep,
  getDirectAppUrlFromStep,
  getNextStepFromMetadata,
  GetPacketResponse,
  ReviewSubmitOpenSections,
  handleError,
  MFAccessToken,
  DirectAppUrl,
  clearAccessToken,
} from '../core'
import { AuthService } from '../core/services/auth/auth.service'
import { ErrorService } from '../core/services/generic-error/error.service'
import { environment } from '../../environments/environment'

@Component({
  selector: 'app-direct',
  templateUrl: './direct.component.html',
  styleUrls: ['./direct.component.scss'],
})
export class DirectComponent implements OnInit, AfterViewChecked, OnDestroy {
  progress: any
  stepName: INTF.DirectStepName
  stepNum: INTF.DirectStepNum

  @ViewChild('directAppProgress') directAppProgress: ElementRef

  constructor(
    private cdr: ChangeDetectorRef,
    public oktaAuth: OktaAuthService,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private directAppService: DirectAppService,
    private directAppStepService: DirectAppStepService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private errorService: ErrorService,
  ) {
    this.useServer = environment.useServer
  }

  accessToken: MFAccessToken | null
  applicationInfo: INTF.ApplicationInfo | undefined
  createFromLead = false
  directAppStatus: DirectAppStep | null = null
  directAppStep: DirectAppStep | null = null
  directStepNum: INTF.DirectStepNum
  error: INTF.BackendError | null
  isShowSidebar: boolean
  isAuthenticated: boolean
  metadata: INTF.Metadata | undefined
  openSections: ReviewSubmitOpenSections
  plaidBankInfo: ConnectedBankAccount[] = []
  packetId: string
  totalSteps = INTF.TOTAL_DIRECT_PROGRESS_STEP
  useServer: boolean
  userClaims: any
  genError: INTF.ErrType
  showSeverErr = false
  showGenErr = false
  disableEdit = false
  isAppSubmittedPage = false
  isNuulaUser: boolean

  // subs
  currentAccessTokenSubscription: Subscription
  currentErrorSubscription: Subscription
  directAppAppInfoSubscription: Subscription
  directAppCurrentStepSubscription: Subscription
  directAppCreateFromLeadSubscription: Subscription
  directAppMetadataSubscription: Subscription
  directAppPlaidConnectionInfoSubscription: Subscription
  directAppPacketIdSubscription: Subscription
  directAppReviewSubmitSubscription: Subscription
  directAppStatusSubscription: Subscription
  getDirectAppStatusSubscription: Subscription
  isNuulaUserSubscription: Subscription

  async ngOnInit() {
    window.scrollTo(0, 0)

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes(DirectAppUrl.APP_SUBMITTED)) {
          this.isAppSubmittedPage = true
        } else {
          this.isAppSubmittedPage = false
        }
      }
    })

    // step update coming from sidebar links
    this.directAppCurrentStepSubscription =
      this.directAppStepService.directAppCurrentStep.subscribe((data) => {
        this.directAppStep = data

        // when direct app is submitted
        if (this.disableEdit && data !== DirectAppStep.CREDIT_AUTH_SUCCESS) {
          this.directAppStep = DirectAppStep.APP_SUBMITTED
          window.location.replace(`/ui/direct-app/review-submit`)
        }
      })

    this.currentAccessTokenSubscription =
      this.authService.currentMfAccessToken.subscribe((val) => {
        this.accessToken = val
      })

    this.currentErrorSubscription = this.errorService.currentError.subscribe(
      (data: any) => {
        // todo when we handle expired token
        // if (
        //   data?.error?.status?.toString().toLowerCase() === 'forbidden' ||
        //   data?.statusCode === 401
        // ) {
        //   this.router.navigate([`/auth/login`])
        // }
        this.genError = data as INTF.ErrType

        if (this.genError?.title === 'Error') {
          this.showSeverErr = true
        } else {
          this.showGenErr = true
        }
      },
    )
    this.errorService.checkIfStoredError(true)
    if (this.useServer) {
      this.isAuthenticated = await this.oktaAuth.isAuthenticated()
    } else {
      this.isAuthenticated = fakeData.auth.isAuthenticated
    }

    this.directAppPacketIdSubscription =
      this.directAppService.currentDirectAppPacketId.subscribe(
        async (packetId) => {
          this.packetId = packetId
        },
      )

    this.directAppCreateFromLeadSubscription =
      this.directAppService.currentCreateFromLead.subscribe((status) => {
        this.createFromLead = status
      })

    this.directAppMetadataSubscription =
      this.directAppService.currentDirectAppMetadata.subscribe(
        async (metadata) => {
          this.metadata = metadata
          if (
            metadata &&
            metadata.plaidInteractions &&
            this.plaidBankInfo.length === 0
          ) {
            if (this.accessToken && this.accessToken.isValid) {
              this.directAppService
                .submitGetPlaidInfo$(this.metadata.id)
                .subscribe((res) => {
                  console.log('getPlaidInfo response:', res)
                })
            }
          }
          if (metadata?.directAppStep === DirectAppStep.APP_SUBMITTED) {
            this.disableEdit = true
          }
        },
      )

    this.directAppPlaidConnectionInfoSubscription =
      this.directAppService.currentPlaidBankInfo.subscribe(async (bankInfo) => {
        this.plaidBankInfo = bankInfo
      })

    this.directAppAppInfoSubscription =
      this.directAppService.currentDirectAppInfo.subscribe(async (appInfo) => {
        this.applicationInfo = appInfo
      })

    this.directAppReviewSubmitSubscription =
      this.directAppReviewSubmitService.currentOpenSections.subscribe(
        async (sections) => {
          this.openSections = sections
        },
      )

    this.isNuulaUserSubscription =
      this.authService.currentIsNuulaUser.subscribe((val) => {
        this.isNuulaUser = val
      })

    if (!this.packetId && this.accessToken && this.accessToken.isValid) {
      this.getDirectAppPacket()
    } else if (this.accessToken && !this.accessToken.isValid) {
      switch (this.directAppStep) {
        case DirectAppStep.CREATE_FROM_LEAD:
          console.log('create-from-lead - clearing any existing token')
          clearAccessToken()
          break
        case DirectAppStep.CREATE_ACCOUNT:
        case DirectAppStep.CREDIT_AUTH_SUCCESS:
        case DirectAppStep.EMAIL_VERIFICATION:
        case DirectAppStep.OWNER_CREDIT_AUTH:
          // proceed as normal
          break
        default:
          console.log(
            'direct.component - redirect to auth/login from:',
            this.directAppStep,
          )
          this.router.navigate([`/auth/login`])
      }
    }
  }

  signin() {
    this.router.navigate([`login`])
  }

  async logout() {
    this.authService.logout()
  }

  getDirectAppPacket() {
    this.getDirectAppStatusSubscription = this.directAppService
      .getDirectAppPacket$()
      .subscribe(
        (res) => {
          const response = res as GetPacketResponse
          this.packetId = response.packet.id
          this.metadata = response.packet.metadata
          if (
            this.metadata.directAppStep === DirectAppStep.APP_SUBMITTED &&
            this.directAppStep !== DirectAppStep.OWNER_CREDIT_AUTH &&
            this.directAppStep !== DirectAppStep.CREDIT_AUTH_SUCCESS
          ) {
            this.disableEdit = true
            this.directAppService.updatedDirectAppStatus$(
              DirectAppStep.APP_SUBMITTED,
            )
            this.directAppStep = DirectAppStep.APP_SUBMITTED
          }

          this.applicationInfo = response.packet.applicationInfo
          // no step in the url it will look up at metadata
          if (
            !this.directAppStep ||
            this.directAppStep === DirectAppStep.UNKNOWN
          ) {
            const nextStep = getNextStepFromMetadata(
              DirectAppStep.UNKNOWN,
              this.metadata,
            )
            window.location.replace(getDirectAppUrlFromStep(nextStep))
          } else if (this.directAppStep === DirectAppStep.CREATE_ACCOUNT) {
            if (!this.applicationInfo.accountInfo) {
              window.location.replace(`ui/direct-app/start-application`)
            }
          }
        },
        (err) => {
          // If they are a Nuula user they can have a token without there being a packet to lookup
          if (
            !this.isNuulaUser &&
            this.directAppStep !== DirectAppStep.CREATE_ACCOUNT
          ) {
            handleError(err, this.errorService, this.router)
          }
        },
      )
  }

  showReviewSubmitPage() {
    return (
      this.directAppStep === DirectAppStep.REVIEW_SUBMIT ||
      (this.directAppStep === DirectAppStep.APP_SUBMITTED &&
        !this.isAppSubmittedPage)
    )
  }

  showAppSubmittedPage() {
    return (
      this.directAppStep === DirectAppStep.APP_SUBMITTED &&
      this.isAppSubmittedPage
    )
  }

  showCreateAccountPage() {
    return this.directAppStep === DirectAppStep.CREATE_ACCOUNT
  }

  showCreateFromLeadPage() {
    return this.directAppStep === DirectAppStep.CREATE_FROM_LEAD
  }

  showCreditAuthSuccessPage() {
    return this.directAppStep === DirectAppStep.CREDIT_AUTH_SUCCESS
  }

  showEmailVerificationPage() {
    return this.directAppStep === DirectAppStep.EMAIL_VERIFICATION
  }

  showFullWidthHeader() {
    return (
      (this.directAppStep === DirectAppStep.APP_SUBMITTED &&
        this.isAppSubmittedPage) ||
      this.directAppStep === DirectAppStep.CREATE_FROM_LEAD ||
      this.directAppStep === DirectAppStep.CREDIT_AUTH_SUCCESS ||
      this.directAppStep === DirectAppStep.EMAIL_VERIFICATION
    )
  }

  showOwnerCreditAuthPage() {
    return this.directAppStep === DirectAppStep.OWNER_CREDIT_AUTH
  }
  showSidebar() {
    this.isShowSidebar = INTF.DIRECT_APP_SIDEBAR_VISIBLE_STEPS.includes(
      this.directAppStatus,
    )
      ? true
      : false
  }

  // VERY IMPORTANT TO USE THIS HOOK
  ngAfterViewChecked() {
    this.errorService.checkIfStoredError(true)

    if (INTF.directAppStepToHumanReadableString(this.directAppStep)) {
      this.progress = document.getElementById('directAppProgress')
      ;[this.stepName, this.stepNum] = INTF.directAppStepToHumanReadableString(
        this.directAppStep,
      )
    }
    if (this.progress) {
      this.progress.value = this.stepNum / this.totalSteps
    }

    // Run change detection explicitly after the change
    this.cdr.detectChanges()
  }

  ngOnDestroy() {
    if (this.currentAccessTokenSubscription) {
      this.currentAccessTokenSubscription.unsubscribe()
    }
    if (this.currentErrorSubscription) {
      this.currentErrorSubscription.unsubscribe()
    }
    if (this.directAppCreateFromLeadSubscription) {
      this.directAppCreateFromLeadSubscription.unsubscribe()
    }
    if (this.directAppAppInfoSubscription) {
      this.directAppAppInfoSubscription.unsubscribe()
    }
    if (this.directAppCurrentStepSubscription) {
      this.directAppCurrentStepSubscription.unsubscribe()
    }
    if (this.directAppMetadataSubscription) {
      this.directAppMetadataSubscription.unsubscribe()
    }
    if (this.directAppPlaidConnectionInfoSubscription) {
      this.directAppPlaidConnectionInfoSubscription.unsubscribe()
    }
    if (this.directAppPacketIdSubscription) {
      this.directAppPacketIdSubscription.unsubscribe()
    }
    if (this.directAppReviewSubmitSubscription) {
      this.directAppReviewSubmitSubscription.unsubscribe()
    }
    if (this.directAppStatusSubscription) {
      this.directAppStatusSubscription.unsubscribe()
    }
    if (this.getDirectAppStatusSubscription) {
      this.getDirectAppStatusSubscription.unsubscribe()
    }
    if (this.isNuulaUserSubscription) {
      this.isNuulaUserSubscription.unsubscribe()
    }
  }
}
