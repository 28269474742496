<div>
  <div class="header-container">
    <div class="header-logo-container">
      <img
        class="header-logo"
        src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
      />
    </div>

    <div class="need-help" (click)="toggleShowItem()">
      <span class="need-help-text"> Need Help? </span>
      <img #arrow [ngClass]="showItem ? 'arrow-up' : 'arrow-down'" />
    </div>

    <div class="help">
      <div class="sales">
        <div class="illustration sales-rep"></div>
        <div>
          <div class="help-title">Sales Rep</div>
          <div class="fw600">
            {{ staffMemberNo }}
          </div>
        </div>
      </div>
    </div>

    <div class="help" id="loan-specialist">
      <div class="sales">
        <div class="illustration specialist"></div>
        <div>
          <div class="help-title">Loan Specialist</div>
          <div class="fw600">
            {{ loanSpecialistNo }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="reps-dropdown" *ngIf="showItem">
    <div class="rep-wrap">
      <div class="rep-wrap-title">
        <img src="/assets/imgs/sales-rep-icon.svg" class="illustration" />
        <span class="help-title"> Sales Rep </span>
      </div>

      <span class="staff-phone fw600">
        <a href="tel:{{ staffMemberNo }}"> {{ staffMemberNo }}</a>
      </span>
    </div>

    <div class="rep-wrap">
      <div class="rep-wrap-title">
        <img src="/assets/imgs/loan-specialist-icon.svg" class="illustration" />
        <span class="help-title"> Loan Specialist </span>
      </div>

      <span class="loan-specialist-phone fw600">
        <a href="tel:{{ loanSpecialistNo }}">{{ loanSpecialistNo }}</a>
      </span>
    </div>
  </div>

  <div class="content progress-bar">
    <div class="progress-info">
      <div class="step-name">{{ stepName }}</div>
      <div class="step-num">{{ stepNum }} of {{ totalSteps }}</div>
    </div>
    <div><progress id="progress" max="1"></progress></div>
  </div>

  <div></div>
</div>
