import { formatCurrency } from '@angular/common'
import { DirectAppPacket } from 'src/app/core'
import { isValidNumericKeydown } from '../../shared/validators'
import { SfOpportunityStageName } from '../../core/'
import { directAppStepToStageList } from '../../core/models/direct-app-step-to-stage-list'

export default class Utils {
  static RequestaQuote() {
    window.location.href = 'https://www.mulliganfunding.com/request-quote/'
  }

  static ReadStory() {
    window.location.href =
      'https://www.mulliganfunding.com/blog/press/mulligan-funding-joins-the-innovative-lending-platform-association/'
  }

  static Redirect(link: string) {
    window.location.href = link
  }

  static AmountKeydown(event) {
    if (!isValidNumericKeydown(event, event.target.value)) {
      event.preventDefault()
    }
  }
  static formatNumberToCurrency(value) {
    const cleanValue = Number(String(value).replace(/[^0-9\.]+/g, ''))
    let formattedValue
    if (isNaN(value)) {
      formattedValue = formatCurrency(0, 'en', '')
    } else {
      formattedValue = formatCurrency(cleanValue, 'en', '')
    }

    return formattedValue
  }
  static formatCurrencyField(event) {
    const targetValue = event.target.value

    const cleanValue = targetValue.replace(/[^0-9\.]+/g, '')
    let formattedValue
    if (isNaN(cleanValue)) {
      formattedValue = '0.00'
    } else {
      formattedValue = formatCurrency(cleanValue, 'en', '')
    }

    event.target.value = formattedValue
  }

  static formatCurrencyValue(element) {
    const targetValue = element.value

    const cleanValue = targetValue.replace(/[^0-9\.]+/g, '')

    let formattedValue
    if (isNaN(cleanValue)) {
      formattedValue = '0.00'
    } else {
      formattedValue = formatCurrency(cleanValue, 'en', '')
    }

    element.value = formattedValue
  }
  static handleFormCurrencyInput(form, formControl) {
    let cleanedAmount = Number(Utils.format2Decimals(form.value[formControl]))
    if (cleanedAmount === 0 || isNaN(cleanedAmount)) {
      cleanedAmount = null
    }
    form.get(formControl).patchValue(cleanedAmount, { emitEvent: false })
    form.controls[formControl].updateValueAndValidity()
  }
  static format2Decimals(element) {
    const cleaned = String(element).replace(/[^0-9\.]+/g, '')
    return Number(cleaned).toFixed(2)
  }

  static formatDate8Digit(element) {
    const cleanedDate = element
      ? String(element).replace(/\D/g, '').substring(0, 8)
      : ''
    const newDate = cleanedDate.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3')
    return newDate
  }

  static formatDate6Digit(element) {
    const cleanedDate = element
      ? String(element).replace(/\D/g, '').substring(0, 6)
      : ''
    const newDate = cleanedDate.replace(/(\d{2})(\d{4})/, '$1/$2')
    return newDate
  }

  static formatZipcode(element) {
    const cleanedZip = String(element).replace(/\D/g, '').substring(0, 5)
    return String(cleanedZip)
  }

  static cleanDigit(element) {
    const cleaned = ('' + element).replace(/\D/g, '')
    return cleaned
  }

  static formatSSN(element) {
    const cleaned = element
      ? String(element).replace(/\D/g, '').substring(0, 9)
      : ''
    const cleanedSSN = cleaned.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
    return cleanedSSN
  }

  static formatTaxID(element) {
    const cleaned = element
      .split('-')
      .join('')
      .replace(/(\d{2})(\d{7})/, '$1-$2')
    return cleaned
  }

  static formatPhoneNo(element) {
    const cleaned = ('' + element).replace(/\D/g, '').substring(0, 10)
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    // for example  match = ["3453454333", undefined, "345", "345", "4333", index: 0, input: "3453454333", groups: undefined]

    if (match) {
      const intlCode = match[1] ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    } else {
      return cleaned
    }
  }

  static directAppStepToStage: (directAppStep) => boolean = (stage) => {
    const matches = directAppStepToStageList.some((element) => {
      return element.toLowerCase() === stage.toLowerCase()
    })
    return matches
  }

  static getDirectAppStage = (packet: DirectAppPacket) => {
    if (Utils.directAppStepToStage(packet.metadata.directAppStep)) {
      return 'In Progress'
    }
    if (!packet?.metadata?.sfOpportunityStage) {
      return 'Under Review'
    }
    switch (packet.metadata.sfOpportunityStage) {
      case SfOpportunityStageName.ACTIVE:
        return 'Active'
      case SfOpportunityStageName.WELCOME_CALL:
      case SfOpportunityStageName.VERIFY_FUNDS:
      case SfOpportunityStageName.ACH_REQUESTED:
        return 'Approved'
      case SfOpportunityStageName.CLOSED_LOST:
        return 'Declined / Expired'
      case SfOpportunityStageName.DOCUMENTATION:
        return 'Documentation Pending'
      case SfOpportunityStageName.FUNDING:
        return 'Funded'
      case SfOpportunityStageName.OFFER:
      case SfOpportunityStageName.STIPS:
        return 'Offer'
      default:
        return 'Under Review'
    }
  }
}
