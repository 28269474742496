<div class="loan-details-container">
  <div class="details-container">
    <div class="m-orange"></div>
    <div class="m-yellow"></div>
    <div class="left">
      <div class="left-box">
        <div class="header-logo-container">
          <img
            class="header-logo"
            src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
          />
        </div>

        <div class="lets-help">
          Let us help your <br />business. Apply for <br />funding through
          Mulligan Funding.
        </div>
        <div class="line"></div>
        <div class="sub-txt">
          <div class="left-group">
            <div class="calendar-icon"></div>
            <div class="help-txt">Fast approval in as soon as a few hours*</div>
          </div>
          <div class="left-group">
            <div class="get-approved-icon"></div>
            <div class="help-txt">
              Funding as soon as the business day after approval*
            </div>
          </div>
          <div class="left-group">
            <div class="consultant-icon"></div>
            <div class="help-txt">Get dedicated access to a loan advisor</div>
          </div>
          <div class="left-group">
            <div class="money-bag"></div>
            <div class="help-txt">Loan amounts of up to $2,000,000</div>
          </div>
        </div>
        <div class="trust-pilot"></div>
      </div>
    </div>

    <div class="nuula-mobile-header" *ngIf="isNuulaUser">
      <div class="nuula-header-logo-container">
        <div>
          <img
            *ngIf="isNuulaUser"
            class="nuula-header-logo"
            src="assets/imgs/mf-main-logo.svg"
          />
        </div>
      </div>
      <div class="nuula-line"></div>
    </div>
    <div class="tablet-header" *ngIf="!isNuulaUser">
      <div class="header-logo-container">
        <div>
          <img
            class="header-logo"
            src="assets/imgs/MF_HorizontalStacked_RGB_whitetext.png"
          />
        </div>
        <div *ngIf="!isNuulaUser">
          <a href="tel:(855) 326-3564">
            <img class="phone" src="assets/imgs/phone-icon-orange.svg" /><span
              >(855) 326-3564</span
            ></a
          >
        </div>
      </div>

      <div class="tablet-title">
        <div>
          <div class="lets-help">
            Let us help your business. Apply for funding through Mulligan
            Funding.
          </div>
          <div class="line"></div>
        </div>
        <div class="trustpilot-tablet"></div>
      </div>
    </div>

    <div class="right">
      <div class="right-box">
        <div
          class="header-logo-container fullname desktop"
          *ngIf="!isNuulaUser"
        >
          <a href="tel:(855) 326-3564">
            <img class="phone" src="assets/imgs/phone-icon-orange.svg" />(855)
            326-3564</a
          >
        </div>
        <div class="answer">
          Answer the questions below to start your application. Your credit
          score will not be affected by applying.
        </div>
        <form
          *ngIf="createAccountForm"
          [formGroup]="createAccountForm"
          (keydown)="onSubmit($event)"
        >
          <div class="direct-app-form">
            <div class="input-text em">
              <div class="label-text">First Name</div>
              <div class="content-input">
                <input
                  autocomplete="given-name"
                  formControlName="firstName"
                  #firstName
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  [readonly]="getIsFormDisabled()"
                />
              </div>
              <div
                *ngIf="showError || f.firstName.dirty || f.firstName.touched"
              >
                <div
                  *ngIf="f.firstName.errors?.required"
                  class="red-text"
                  class="alert alert-danger err-text"
                >
                  <span class="alert alert-danger help-text">
                    First name is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="input-text em">
              <div class="label-text">Last Name</div>
              <div class="content-input">
                <input
                  autocomplete="family-name"
                  formControlName="lastName"
                  #lastName
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  [readonly]="getIsFormDisabled()"
                />
              </div>

              <div *ngIf="showError || f.lastName.dirty || f.lastName.touched">
                <div
                  *ngIf="f.lastName.errors?.required"
                  class="red-text"
                  class="alert alert-danger err-text"
                >
                  <span class="alert alert-danger help-text">
                    Last name is required.
                  </span>
                </div>
              </div>
            </div>

            <div class="input-text em">
              <div class="label-text">Company Name</div>
              <div class="content-input">
                <input
                  autocomplete="organization"
                  formControlName="companyName"
                  #companyName
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  [readonly]="getIsFormDisabled()"
                />
              </div>
              <div
                *ngIf="
                  showError || f.companyName.dirty || f.companyName.touched
                "
              >
                <div
                  *ngIf="f.companyName.errors?.required"
                  class="red-text"
                  class="alert alert-danger err-text"
                >
                  <span class="alert alert-danger help-text">
                    Company name is required.
                  </span>
                </div>
              </div>
            </div>
            <div class="input-text em">
              <div class="label-text">Phone Number</div>

              <div class="content-input">
                <input
                  autocomplete="tel"
                  formControlName="phoneNumber"
                  type="text"
                  name="phoneNumber"
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  placeholder="(###) ###-####"
                  value="{{ newphoneNo }}"
                  [readonly]="getIsFormDisabled()"
                  (keydown)="amountKeydown($event)"
                  (keyup)="formatBusinessPhone($event)"
                  maxlength="14"
                />

                <div
                  *ngIf="
                    showError || f.phoneNumber.dirty || f.phoneNumber.touched
                  "
                >
                  <div class="alert alert-danger err-text">
                    <span *ngIf="f.phoneNumber.errors?.required"
                      >Phone number is required.</span
                    >

                    <span *ngIf="f.phoneNumber.errors?.ValidatePhone"
                      >Enter a valid phone number.</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="input-text em" *ngIf="!getHideLoanAmount()">
              <div class="label-text">Desired Loan Amount</div>

              <div class="input-icon">
                <input
                  (blur)="blurDesiredAmt($event)"
                  (keydown)="amountKeydown($event)"
                  formControlName="desiredLoanAmount"
                  #desiredLoanAmt
                  id="desired-loan"
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  value="{{ formattedDesiredLoanAmt }}"
                  [readonly]="getIsFormDisabled()"
                  placeholder="{{ amountPlaceholderText }}"
                />
                <i>$</i>
              </div>
              <div
                *ngIf="
                  showError ||
                  f.desiredLoanAmount.dirty ||
                  f.desiredLoanAmount.touched
                "
                class="alert alert-danger err-text"
              >
                <div
                  *ngIf="f.desiredLoanAmount.errors || showError"
                  class="red-text"
                >
                  <span
                    class="alert alert-danger help-text"
                    *ngIf="f.desiredLoanAmount.errors?.required"
                    >Desired loan amount is required.</span
                  >

                  <span
                    class="alert alert-danger"
                    *ngIf="
                      f.desiredLoanAmount.errors?.pattern ||
                      f.desiredLoanAmount.errors?.ValidateDesiredAmt
                    "
                  >
                    Amount must be between $5,000 and $2,000,000.
                  </span>
                </div>
              </div>
            </div>

            <div class="input-text em" *ngIf="!getHideLoanPurpose()">
              <div class="label-text">Loan Purpose</div>
              <div class="content-input">
                <input
                  formControlName="loanPurpose"
                  #loanPurpose
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  [readonly]="getIsFormDisabled()"
                />
              </div>
              <div
                *ngIf="
                  showError || f.loanPurpose.dirty || f.loanPurpose.touched
                "
                class="alert alert-danger err-text"
              >
                <div *ngIf="f.loanPurpose.errors?.required" class="red-text">
                  <span class="alert alert-danger help-text"
                    >Loan purpose is required.</span
                  >
                </div>
              </div>
            </div>

            <div class="input-text em" *ngIf="!getHideTimeInBusiness()">
              <div class="label-text">Time In Business</div>
              <div class="content-input">
                <select
                  formControlName="timeInBusiness"
                  #timeinbiz
                  id="timeinbiz"
                  class="input-text container"
                  type="text"
                  *ngIf="!getIsFormDisabled()"
                >
                  <option hidden [ngValue]="null" selected>
                    Select an Option
                  </option>
                  <option
                    value="{{ time }}"
                    *ngFor="let time of timeInBusinessList"
                  >
                    {{ time }}
                  </option>
                </select>
                <input
                  *ngIf="getIsFormDisabled()"
                  formControlName="timeInBusiness"
                  #timeInBusiness
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  [readonly]="true"
                />
              </div>
              <div
                *ngIf="
                  showError ||
                  f.timeInBusiness.dirty ||
                  f.timeInBusiness.touched
                "
                class="alert alert-danger err-text"
              >
                <div *ngIf="f.timeInBusiness.errors?.required" class="red-text">
                  <span class="alert alert-danger help-text">
                    Time in business is required.</span
                  >
                </div>
              </div>
            </div>
            <div class="input-text em" *ngIf="!getHideAverageSales()">
              <div class="label-text">Monthly Average Sales</div>
              <div class="input-icon">
                <input
                  formControlName="monthlyAverageSales"
                  (blur)="blurMonthlyAverageSales($event)"
                  (keydown)="amountKeydown($event)"
                  value="{{ formattedMonthlyAverageSalesAmt }}"
                  #monthlySales
                  id="monthly-average-sales"
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="text"
                  [readonly]="getIsFormDisabled()"
                  placeholder="{{ amountPlaceholderText }}"
                />
                <i>$</i>
              </div>
              <div
                *ngIf="
                  showError ||
                  f.monthlyAverageSales.dirty ||
                  f.monthlyAverageSales.touched
                "
                class="alert alert-danger err-text"
              >
                <div
                  *ngIf="f.monthlyAverageSales.errors || showError"
                  class="red-text"
                >
                  <span
                    class="alert alert-danger help-text"
                    *ngIf="f.monthlyAverageSales.errors?.required"
                    >Monthly average sales is required.</span
                  >

                  <span
                    class="alert alert-danger"
                    *ngIf="
                      f.monthlyAverageSales.errors?.pattern ||
                      f.monthlyAverageSales.errors?.ValidateMonthlySalesAmt
                    "
                  >
                    Enter a valid monthly average sales.
                  </span>
                </div>
              </div>
            </div>
            <div class="input-text em">
              <div class="label-text">Email</div>
              <div class="content-input">
                <input
                  autocomplete="email"
                  formControlName="email"
                  #email
                  *ngIf="!isNuulaUser"
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': getIsFormDisabled()
                  }"
                  type="email"
                  [readonly]="getIsFormDisabled()"
                />
                <input
                  *ngIf="isNuulaUser"
                  [ngClass]="{
                    'input-text': true,
                    container: true,
                    'read-only': true
                  }"
                  type="email"
                  [value]="getEmailAddress()"
                  [readonly]="true"
                />
              </div>

              <div
                *ngIf="showError || f.email.dirty || f.email.touched"
                class="alert alert-danger err-text"
              >
                <div *ngIf="f.email.errors?.required" class="red-text">
                  <span class="alert alert-danger help-text">
                    Email is required.
                  </span>
                </div>
                <div
                  *ngIf="
                    f.email.errors?.email ||
                    f.email.errors?.pattern ||
                    f.email.errors?.minLength ||
                    f.email.errors?.maxLength
                  "
                  class="red-text"
                  class="alert alert-danger err-text"
                >
                  <span class="alert alert-danger help-text"
                    ><span>Enter a valid email.</span></span
                  >
                </div>
              </div>
            </div>
            <div
              class="input-text"
              id="create-password"
              *ngIf="!getIsFormDisabled() && !isNuulaUser"
            >
              <div class="label-text">Create Password</div>
              <input
                formControlName="password"
                autocomplete="off"
                type="password"
                #password
                id="password"
                [ngClass]="{
                  'input-text': true,
                  container: true,
                  'input-text-basic-default-input': true,
                  'read-only': getIsFormDisabled()
                }"
                [readonly]="getIsFormDisabled()"
              /><button
                (click)="togglePassword()"
                id="toggle-password"
                class="password"
              ></button
              ><span *ngIf="f.password.valid">
                <div class="green-text">
                  <img class="check-symbol" /><span class="green help-text"
                    >Password strength: Good</span
                  >
                </div>
              </span>

              <div
                *ngIf="showError || f.password.dirty || f.password.touched"
                class="alert alert-danger err-text"
              >
                <div
                  *ngIf="f.password.invalid"
                  id="invalid-err"
                  class="alert alert-danger err-text"
                >
                  <div *ngIf="f.password.errors?.required" class="red-text">
                    <span class="alert alert-danger help-text">
                      Password is required.
                    </span>
                  </div>
                  <div
                    *ngIf="
                      f.password.value &&
                      f.password.value?.length !== 0 &&
                      (showError || f.password.dirty || f.password.touched)
                    "
                  >
                    <div
                      class="red-text"
                      *ngIf="f.password.invalid && f.password.value"
                    >
                      Password requirements have not been met.
                    </div>
                    <div
                      *ngIf="f.password.errors"
                      [ngClass]="{
                        'red-text': showError,
                        'grey-text': !showError
                      }"
                    >
                      <img class="symbol" /><span id="weak"
                        >Password strength: Weak</span
                      >
                    </div>
                    <div
                      [ngClass]="{
                        'green-text':
                          !f.password.errors?.minlength &&
                          f.password.value !== '',
                        'red-text': f.password.errors?.minlength,
                        'grey-text': !(
                          !f.password.errors?.minlength &&
                          f.password.value !== ''
                        )
                      }"
                    >
                      <img class="symbol" /><span id="toofewchar"
                        >Use at least 8 characters</span
                      >
                    </div>
                    <div
                      [ngClass]="{
                        'green-text':
                          !f.password.errors?.haveUpperLowerCase &&
                          f.password.value !== '',
                        'red-text': showError,
                        'grey-text': !(
                          !f.password.errors?.haveUpperLowerCase &&
                          f.password.value !== ''
                        )
                      }"
                    >
                      <img class="symbol" /><span id="upperlower"
                        >Use upper and lower case letters</span
                      >
                    </div>
                    <div
                      [ngClass]="{
                        'green-text': !f.password.errors?.hasNum && f.password.value,
                        'red-text': f.password.errors?.hasNum && f.password.value && showError,
                        'grey-text': f.password.errors?.hasNum && f.password.value && !showError
                      }"
                    >
                      <img class="symbol" /><span id="numbersymbol"
                        >Use a number</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="checkbox acknowledgement" *ngIf="!getIsFormDisabled()">
            <input
              (change)="
                $event.target.checked
                  ? f.privacyPolicyAndeConsent.patchValue(true)
                  : f.privacyPolicyAndeConsent.patchValue(false)
              "
              formControlName="privacyPolicyAndeConsent"
              #privacyPolicyAndeConsent
              id="privacyPolicyAndeConsent"
              #acknowledgedConsent
              type="checkbox"
              name="privacyPolicyAndeConsent"
              [ngClass]="
                showError ||
                f.privacyPolicyAndeConsent.dirty ||
                f.privacyPolicyAndeConsent.touched
                  ? 'alert-danger'
                  : 'acknowledged'
              "
            />
            <label
              class="acknowledged-disclosures"
              for="privacyPolicyAndeConsent"
              >I agree to Mulligan Funding’s
              <a
                target="_blank"
                href="https://www.mulliganfunding.com/application-terms-conditions/"
                >Application Agreement</a
              >,
              <a
                target="_blank"
                href="https://www.mulliganfunding.com/privacy-policy/"
                >Privacy Policy</a
              >, and
              <a
                target="_blank"
                href="https://www.mulliganfunding.com/application-terms-conditions/#section5"
                >E-Sign Consent.</a
              ></label
            >
            <div></div>

            <div
              *ngIf="
                showError ||
                f.privacyPolicyAndeConsent.dirty ||
                f.privacyPolicyAndeConsent.touched
              "
              class="agreement alert alert-danger err-text"
            >
              <div
                *ngIf="f.privacyPolicyAndeConsent.value === false"
                class="red-text"
              >
                <span class="alert alert-danger help-text">
                  You must agree to the above to continue.
                </span>
              </div>
            </div>
          </div>

          <div class="btn-container start">
            <button
              (click)="handleSubmit()"
              type="button"
              #submit
              class="btn-background"
            >
              {{ getSubmitButtonText() }}
            </button>
          </div>
        </form>
        <div class="have btn-container" *ngIf="!isFormDisabled && !isNuulaUser">
          <div class="have-acc">
            <span class="have">Have an account? </span
            ><span class="ok" (click)="signin()">Sign In</span
            ><img
              (click)="signin()"
              src="/assets/imgs/link-arrow-icon-new.svg"
              class="link-arrow-icon"
            />
          </div>
        </div>
        <div class="disclosures-list">
          <div class="disclosure-item">
            *Approval and funding speed refer to application submitted during
            normal business hours (Monday-Friday 9 AM - 5 PM PT). Most approvals
            happen within a few hours of completed applications. Delays could
            occur due to unforeseen circumstances or if additional information
            or verification is required.
          </div>
          <div class="disclosure-item">
            **Your business is applying generally for credit and not for a
            specific credit product or amount. Any product/amount offered will
            depend on the credit and underwriting standards of the lender
          </div>
          <div class="disclosure-item">
            ¹ Final loan decision may be subject to underwriting by FinWise
            Bank.
          </div>
          <div class="mobile-only">
            Final loan decision may be subject to underwriting by FinWise Bank.
          </div>
          <div class="disclosure-item">
            ² Reviews are accurate as of 01/01/2022
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer [setIsNuulaUser]="isNuulaUser"></app-footer>
</div>
