import { Injectable, Injector } from '@angular/core'
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationStart,
} from '@angular/router'
import { OktaAuthService } from '@okta/okta-angular'

import { filter } from 'rxjs/operators'

import { AuthState } from '@okta/okta-auth-js'
import { AuthRequiredFunction } from '@okta/okta-angular/src/okta/models/okta.config'

import * as fakeData from '../../../../assets/fixtures/calcNums.json'
import { environment } from '../../../../environments/environment'
import { getAccessToken } from '../../utils'

@Injectable()
export class OktaAuthGuard implements CanActivate {
  private route: ActivatedRouteSnapshot
  private state: RouterStateSnapshot

  useServer: boolean
  isAuthenticated: boolean

  // https://github.com/okta/okta-angular/blob/master/src/okta/okta.guard.ts
  constructor(private oktaAuth: OktaAuthService, private injector: Injector) {
    // Unsubscribe updateAuthStateListener when route change
    const router = injector.get(Router)

    router.events
      .pipe
      // filter((e: Event) => e instanceof NavigationStart && this.state && this.state.url !== e.url)
      ()
      .subscribe(() => {
        this.oktaAuth.authStateManager.unsubscribe(this.updateAuthStateListener)
      })
    this.useServer = environment.useServer
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    // Track states for current route
    this.route = route
    this.state = state
    if (this.useServer) {
      // using mfAccessToken
      const mfAccessToken = getAccessToken()
      if (mfAccessToken && mfAccessToken.isValid) {
        this.isAuthenticated = true

        return true
      } else {
        // Protect the route after accessing
        this.oktaAuth.authStateManager.subscribe(this.updateAuthStateListener)
        const isAuthenticated = await this.oktaAuth.isAuthenticated()
        if (isAuthenticated) {
          return true
        }

        await this.handleLogin(state.url)
        return false
      }
    } else {
      this.isAuthenticated = fakeData.auth.isAuthenticated
    }
  }

  private async handleLogin(fromUri: string): Promise<void> {
    // Get the operation to perform on failed authentication from
    // either the global config or route data injection.
    const onAuthRequired: AuthRequiredFunction =
      this.route.data['onAuthRequired'] ||
      this.oktaAuth.getOktaConfig().onAuthRequired

    // Store the current path
    this.oktaAuth.setOriginalUri(fromUri)

    if (onAuthRequired) {
      onAuthRequired(this.oktaAuth, this.injector)
    } else {
      await this.oktaAuth.signInWithRedirect()
    }
  }

  private updateAuthStateListener = (authState: AuthState) => {
    if (!authState.isAuthenticated) {
      this.handleLogin(this.state.url)
    }
  }
}
