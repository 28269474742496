import { Component, OnInit, Input } from '@angular/core'

import { Router } from '@angular/router'

import * as INTF from '../../core/models/interfaces'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { BYPASS_ONE_TIME_USE_TOKEN } from '../../core'

@Component({
  selector: 'app-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss'],
})
export class SubmittedComponent implements OnInit {
  packetId: string
  @Input() set setPacketId(value: string) {
    this.packetId = value
  }
  applicationInfo: INTF.ApplicationInfo
  @Input() set setApplicationInfo(value: INTF.ApplicationInfo) {
    this.applicationInfo = value
  }

  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
  }
  constructor(
    private router: Router,
    private directAppService: DirectAppService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    if (this.isNuulaUser) {
      this.router.navigate([
        `app/nuula-app-details/${BYPASS_ONE_TIME_USE_TOKEN}`,
      ])
    } else {
      window.location.replace(`/ui/direct-app/app-submitted`)
    }
  }
}
