<div class="rectangle">
  <div class="page-title" *ngIf="userClaims.given_name">
    Hello {{ userClaims?.given_name }}!
  </div>
  <div class="page-title" *ngIf="!userClaims.given_name">Hello!</div>
  <div class="intro">
    As part of the closing process for your business loan for
    <strong>{{ calcNums.account.nameOfBusiness }}</strong
    >, we need you to complete the following steps:
  </div>

  <div class="steps-container">
    <div></div>
    <div class="steps-group">
      <div class="illustration kba"></div>
      <div class="feature verify">
        <div class="feature-title">Verify Your Identity</div>
        <div class="feature-info">
          You will verify your identity by answering personal questions that
          only you have knowledge of.
        </div>
      </div>

      <div class="illustration money-bag"></div>
      <div class="feature review">
        <div *ngIf="calcNums.loanAgreementExists !== 'N'" class="feature-title">
          Review Loan Details
        </div>
        <div *ngIf="calcNums.loanAgreementExists !== 'N'" class="feature-info">
          You will review your loan details.
        </div>
        <div *ngIf="calcNums.loanAgreementExists === 'N'" class="feature-title">
          Review Loan Details & Sign Loan Agreement
        </div>
        <div *ngIf="calcNums.loanAgreementExists === 'N'" class="feature-info">
          You will review your loan details and sign your loan agreement.
        </div>
      </div>

      <div class="illustration briefcase"></div>
      <div class="feature provide">
        <div class="feature-title">Provide Business Reference</div>
        <div class="feature-info">
          You will need to provide a professional business reference to keep on
          file for emergency purposes.
        </div>
      </div>
    </div>
    <div></div>
  </div>

  <div class="btn-container text-align-center">
    <button (click)="onSubmit($event)" #submit class="btn-background">
      START PROCESS
    </button>
  </div>
</div>
