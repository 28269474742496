import { NgModule } from '@angular/core'

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { CommonModule } from '@angular/common'

import { SharedModule } from '../shared/shared.module'
import { DirectRoutingModule } from './direct-routing.module'
import { DirectComponent } from './direct.component'
import { DirectHeaderComponent } from './direct-header/direct-header.component'
import { DirectLoanDetailsComponent } from './direct-loan-details/direct-loan-details.component'
import { OwnerInfoComponent } from './owner-info/owner-info.component'
import { AdditionalInfoComponent } from './additional-info/additional-info.component'
import { DocumentsComponent } from './documents/documents.component'
import { DirectReviewSubmitComponent } from './direct-review-submit/direct-review-submit.component'
import { BusinessBasicInfoComponent } from './business-basic-info/business-basic-info.component'
import { BusinessContactInfoComponent } from './business-contact-info/business-contact-info.component'
import { BusinessAddressInfoComponent } from './business-address-info/business-address-info.component'
import { DirectSidebarComponent } from './direct-sidebar/direct-sidebar.component'
import { DirectAppCreateFromLeadComponent } from './create-from-lead/create-from-lead.component'
import { DirectAppEmailVerificationComponent } from './email-verification/email-verification.component'
import { DirectAppOwnerCreditAuthComponent } from './owner-credit-auth/owner-credit-auth.component'
import { DirectAppOwnerCreditAuthSidebarComponent } from './owner-credit-auth-sidebar/owner-credit-auth-sidebar.component'
import { DirectAppOwnerCreditAuthSuccessComponent } from './owner-credit-auth-success/owner-credit-auth-success.component'
import { PlaidIframeComponent } from './plaid-iframe/plaid-iframe.component'
import { SubmittedComponent } from './submitted/submitted.component'
import { NuulaLoanApplicationExpiredComponent } from './nuula/nuula-loan-application-expired/nuula-loan-application-expired.component'
import { NuulaTileComponent } from './nuula/nuula-tile/nuula-tile.component'
import { NuulaAppSubmittedComponent } from './nuula/nuula-app-submitted/nuula-app-submitted.component'
import { NuulaLandingComponent } from './nuula/nuula-landing/nuula-landing.component'
import { NuulaResumeApplicationComponent } from './nuula/nuula-resume-application/nuula-resume-application.component'
import { NuulaAppDetailsComponent } from './nuula/nuula-app-details/nuula-app-details.component'

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    DirectRoutingModule,
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    AdditionalInfoComponent,
    BusinessAddressInfoComponent,
    BusinessBasicInfoComponent,
    BusinessContactInfoComponent,
    DirectAppCreateFromLeadComponent,
    DirectAppEmailVerificationComponent,
    DirectAppOwnerCreditAuthComponent,
    DirectAppOwnerCreditAuthSidebarComponent,
    DirectAppOwnerCreditAuthSuccessComponent,
    DirectComponent,
    DirectHeaderComponent,
    DirectLoanDetailsComponent,
    DirectReviewSubmitComponent,
    DirectSidebarComponent,
    DocumentsComponent,
    OwnerInfoComponent,
    PlaidIframeComponent,
    SubmittedComponent,
    NuulaLoanApplicationExpiredComponent,
    NuulaTileComponent,
    NuulaAppSubmittedComponent,
    NuulaLandingComponent,
    NuulaResumeApplicationComponent,
    NuulaAppDetailsComponent,
  ],
  exports: [],
})
export class DirectModule {}
