<div class="app-submitted-container">
  <div class="spacer"></div>
  <div class="app-submitted-card">
    <div class="submitted-icon-wrapper">
      <img
        class="submitted-success-icon"
        src="assets/imgs/link-sent-illustration.svg"
      />
    </div>
    <div class="app-submitted-title">{{ getTitle() }}</div>
    <div class="app-submitted-text">
      <span class="normal-text">{{ getText() }} </span>
    </div>
    <div class="btn-container">
      <a target="_blank" href="https://mulliganfunding.com">
        <button #submit class="btn-background">GO TO HOMEPAGE</button>
      </a>
    </div>
  </div>
  <div class="spacer"></div>
</div>
