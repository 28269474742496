import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'

import { Subscription } from 'rxjs'

import * as INTF from '../../core/models/interfaces'
import {
  ConnectedBankAccount,
  DirectAppStep,
  DirectAppUrl,
  GetPacketResponse,
  ModalType,
  ReviewSubmitOpenSections,
  starOutSSN,
} from '../../core'
import { ErrorService } from '../../core/services/generic-error/error.service'
import { DirectAppReviewSubmitService } from 'src/app/core/services/direct-app-review-submit/direct-app-review-submit.service'
import { DirectAppService } from '../../core/services/direct/direct-app.service'
import { DirectAppStepService } from '../../core/services/direct-app-steps/direct-app-steps.service'
import { ModalService } from 'src/app/core/services/modal/modal.service'
import { handleError } from '../../core'

@Component({
  selector: 'app-direct-review-submit',
  templateUrl: './direct-review-submit.component.html',
  styleUrls: ['./direct-review-submit.component.scss'],
})
export class DirectReviewSubmitComponent implements OnInit, OnDestroy {
  @Input() packetId: string
  @Input() applicationInfo: INTF.ApplicationInfo | undefined
  @Input() metadata: INTF.Metadata
  @Input() openSections: ReviewSubmitOpenSections
  isNuulaUser: boolean | null
  @Input() set setIsNuulaUser(value: boolean) {
    this.isNuulaUser = value
  }
  getDirectAppStatusSubscription: Subscription

  currentPlaidBankAccountInfoSubscription: Subscription

  bankAccountList: ConnectedBankAccount[] = []
  clicked: boolean
  directAppStatus: DirectAppStep
  showError = false
  showEmailSentSuccessfully = false
  disableEdit = false

  constructor(
    private directAppStepService: DirectAppStepService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private directAppReviewSubmitService: DirectAppReviewSubmitService,
    private directAppService: DirectAppService,
    private errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    window.location.replace(`/ui/direct-app/review-submit`)
    this.directAppService.currentDirectAppStatus.subscribe((val) => {
      if (val === DirectAppStep.APP_SUBMITTED) {
        this.disableEdit = true
      }
    })

    this.currentPlaidBankAccountInfoSubscription =
      this.directAppService.currentPlaidBankInfo.subscribe((plaidInfo) => {
        this.bankAccountList = plaidInfo
      })
  }

  closeEmailSentSuccessfully() {
    this.showEmailSentSuccessfully = false
  }
  editAddressInfo() {
    const nextStep = DirectAppStep.BUSINESS_ADDRESS_INFO
    this.goToPage(nextStep)
  }

  editBankStatements() {
    const nextStep = DirectAppStep.DOCUMENT
    this.goToPage(nextStep)
  }

  editBasicInfo() {
    const nextStep = DirectAppStep.BUSINESS_BASIC_INFO

    this.goToPage(nextStep)
  }

  editContactInfo() {
    const nextStep = DirectAppStep.BUSINESS_CONTACT_INFO
    this.goToPage(nextStep)
  }

  editDebt(index: number) {
    const nextStep = DirectAppStep.ADDITIONAL_INFO
    this.goToPage(nextStep)
  }

  editOwnerInfo(index: number) {
    const nextStep = DirectAppStep.OWNER_INFO
    this.goToPage(nextStep)
  }

  getAccountInfo() {
    if (!this.applicationInfo || !this.applicationInfo.accountInfo) {
      return undefined
    }
    const accountInfo: INTF.AccountInfo = this.applicationInfo.accountInfo
    return accountInfo
  }

  getAddressInfo() {
    if (!this.applicationInfo || !this.applicationInfo.addressInfo) {
      return undefined
    }
    const addressInfo: INTF.AddressInfo = this.applicationInfo.addressInfo
    return addressInfo
  }

  getApplyingOnBehalfOfPrimary() {
    const ownerInfo = this.getOwnerInfo()
    return ownerInfo.applyingOnBehalfOfPrimary
  }

  getBankStatements() {
    return this.metadata ? this.metadata.file_uploads : []
  }

  getBasicInfo() {
    if (!this.applicationInfo || !this.applicationInfo.basicInfo) {
      return undefined
    }
    const basicInfo: INTF.BasicInfo = this.applicationInfo.basicInfo
    return basicInfo
  }

  getBusinessEmail() {
    const contactInfo = this.getContactInfo()
    return contactInfo ? contactInfo.businessEmail : ''
  }

  getBusinessPhoneNumber() {
    const contactInfo = this.getContactInfo()
    return contactInfo ? contactInfo.businessPhone : ''
  }

  getBusinessStartDate() {
    const basicInfo = this.getBasicInfo()
    return basicInfo ? basicInfo.businessStartDate : ''
  }

  getBusinessWebsite() {
    const contactInfo = this.getContactInfo()
    return contactInfo ? contactInfo.businessWebsite : ''
  }

  getCompanyName() {
    const basicInfo = this.getBasicInfo()
    return basicInfo ? basicInfo.businessLegalName : ''
  }

  getConnectedBanks() {
    return this.bankAccountList
  }

  getContactInfo() {
    if (!this.applicationInfo || !this.applicationInfo.contactInfo) {
      return undefined
    }
    const contactInfo: INTF.ContactInfo = this.applicationInfo.contactInfo
    return contactInfo
  }

  getCorporateStructure() {
    const basicInfo = this.getBasicInfo()
    if (basicInfo) {
      return basicInfo.corporateStructure
    }
    return ''
  }

  getDebtList() {
    if (!this.applicationInfo || !this.applicationInfo.debtInfo) {
      return undefined
    }
    const debtList: INTF.DebtInfo[] = this.applicationInfo.debtInfo
    return debtList
  }

  getDoingBusinessAs() {
    const basicInfo = this.getBasicInfo()
    return basicInfo ? basicInfo.doingBusinessAs : ''
  }

  getMailingAddressStreet() {
    const addressInfo = this.getAddressInfo()
    if (addressInfo.mailingAddressInfo) {
      return addressInfo.mailingAddressInfo.mailingStreet
    }
    return this.getPhysicalAddressStreet()
  }

  getMailingAddressCityStateZip() {
    const addressInfo = this.getAddressInfo()
    if (addressInfo.mailingAddressInfo) {
      const info = addressInfo.mailingAddressInfo
      return `${info.mailingCity}, ${info.mailingState} ${info.mailingZip}`
    }
    return this.getPhysicalAddressCityStateZip()
  }

  getOwnerAddressCityStateZip(owner: INTF.OwnerDetail) {
    return `${owner.ownerCity}, ${owner.ownerState} ${owner.ownerZipcode}`
  }

  getOwnerInfo() {
    if (!this.applicationInfo || !this.applicationInfo.ownerInfo) {
      return undefined
    }
    const ownerInfo: INTF.OwnerInfo = this.applicationInfo.ownerInfo
    return ownerInfo
  }

  getOwnerList(): INTF.OwnerDetail[] {
    const ownerInfo = this.getOwnerInfo()
    return ownerInfo ? ownerInfo.owners : []
  }

  getOwnerSSN(owner: INTF.OwnerDetail) {
    const ssn = owner.ownerSSN
    if (ssn) {
      try {
        return starOutSSN(ssn)
      } catch (error) {
        console.warn(error)
        return 'MALFORMED SSN'
      }
    }
    return ''
  }

  getPhysicalAddressStreet() {
    const addressInfo = this.getAddressInfo()
    if (addressInfo.physicalAddressInfo) {
      return addressInfo.physicalAddressInfo.street
    }
    return ''
  }

  getPhysicalAddressCityStateZip() {
    const addressInfo = this.getAddressInfo()
    if (addressInfo.physicalAddressInfo) {
      const info = addressInfo.physicalAddressInfo
      return `${info.city}, ${info.state} ${info.zip}`
    }
    return ''
  }

  getPrimaryEmailAddress() {
    if (
      this.applicationInfo &&
      this.applicationInfo.accountInfo &&
      this.applicationInfo.accountInfo.email
    ) {
      return this.applicationInfo.accountInfo.email
    }
    return ''
  }
  getStateOfIncorporation() {
    const basicInfo = this.getBasicInfo()
    return basicInfo ? basicInfo.stateOfIncorporation : ''
  }

  getTaxId() {
    const basicInfo = this.getBasicInfo()
    return basicInfo ? basicInfo.taxID : ''
  }

  getVerificationStatus() {
    return this.metadata ? this.metadata.emailVerified : false
  }

  goToPage(nextStep: DirectAppStep) {
    this.directAppReviewSubmitService.setComingFromReviewSubmit(true)

    const data = { packetId: this.packetId, newStatus: nextStep }
    this.directAppService.setStatus$(data).subscribe(
      (res) => {
        this.directAppStepService.updatedDirectAppStep(nextStep)
      },
      (err) => {
        if (err.error && err.error.toString().toLowerCase() === 'forbidden') {
          this.router.navigate([`/auth/login`])
        } else {
          handleError(err, this.errorService, this.router)
        }
      },
    )
  }

  resendVerificationEmail() {
    this.directAppService.postResendVerificationEmail$().subscribe((res) => {
      console.log('Resend response: ', res)
      this.showEmailSentSuccessfully = true
    })
  }

  proceedToAppSubmittedPage() {
    const nextStep = DirectAppStep.APP_SUBMITTED

    this.goToPage(nextStep)
  }

  submitApplication$() {
    if (this.metadata.emailVerified) {
      this.directAppService.updatedDirectAppStatus$(DirectAppStep.APP_SUBMITTED)
      this.disableEdit = true

      this.proceedToAppSubmittedPage()
    } else {
      this.directAppService.getDirectAppPacket$().subscribe((res) => {
        const response = res as GetPacketResponse
        if (response.packet.metadata.emailVerified) {
          this.directAppService.updatedDirectAppStatus$(
            DirectAppStep.APP_SUBMITTED,
          )
          this.disableEdit = true
          this.proceedToAppSubmittedPage()
        } else {
          // Display Email Verification Needed Modal
          this.modalService.updatedModalType(
            ModalType.EMAIL_VERIFICATION_NEEDED,
          )
          this.modalService.updatedModalState(true)
        }
      })
    }
  }

  toggleAdditionalInfoSection() {
    this.directAppReviewSubmitService.toggleDebtInfo$()
  }

  toggleBusinessSection() {
    this.directAppReviewSubmitService.toggleBusinessInfo$()
  }

  toggleDocumentsSection() {
    this.directAppReviewSubmitService.toggleDocInfo$()
  }

  toggleOwnerInfoSection() {
    this.directAppReviewSubmitService.toggleOwnerInfo$()
  }

  ngOnDestroy() {
    if (this.currentPlaidBankAccountInfoSubscription) {
      this.currentPlaidBankAccountInfoSubscription.unsubscribe()
    }
    if (this.getDirectAppStatusSubscription) {
      this.getDirectAppStatusSubscription.unsubscribe()
    }
  }
}
