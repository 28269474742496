import { Component, OnInit, Input } from '@angular/core'
import * as INTF from '../../../core/models/interfaces'

@Component({
  selector: 'app-dashboard-all-accounts',
  templateUrl: './dashboard-all-accounts.component.html',
  styleUrls: ['./dashboard-all-accounts.component.scss'],
})
export class DashboardAllAccountsComponent implements OnInit {
  @Input() dashboardData: any
  @Input() firstName: any

  notSubmit: any

  constructor() {}

  ngOnInit(): void {
    window.scrollTo(0, 0)
  }

  stepFromdDashboardStatuses(docCheckoutStatus) {
    return INTF.dashboardStatuses(INTF.stepFromString(docCheckoutStatus))
  }

  showLoanArrow(docCheckoutStatus) {
    if (INTF.DOC_CHECKOUT_PROCESS_STEPS.includes(docCheckoutStatus)) {
      return true
    }

    return (
      INTF.stepFromString(docCheckoutStatus) !== INTF.DocCheckoutStep.funded &&
      INTF.stepFromString(docCheckoutStatus) !==
        INTF.DocCheckoutStep.preApprovalConfirmation
    )
  }
}
