import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LoginComponent } from './login/login.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { LinkSentComponent } from './link-sent/link-sent.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'
import { SharedModule } from '../../shared/shared.module'
import { AuthRoutingModule } from './auth-routing.module'
import { LoginCallbackComponent } from './login-callback/login-callback.component'

@NgModule({
  declarations: [
    ForgotPasswordComponent,
    LinkSentComponent,
    ResetPasswordComponent,
    LoginComponent,
    LoginCallbackComponent,
  ],
  imports: [CommonModule, SharedModule, AuthRoutingModule],
  exports: [],
})
export class AuthModule {}
