import { Component, OnInit } from '@angular/core'

import { ErrorService } from '../../core/services/generic-error/error.service'

@Component({
  selector: 'app-unable-to-verify-business',
  templateUrl: './unable-to-verify-business.component.html',
  styleUrls: ['./unable-to-verify-business.component.scss'],
})
export class UnableToVerifyBusinessComponent implements OnInit {
  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0)

    this.errorService.storeError(this.errorService.BIZ_NOT_VERIFIED)
  }
}
