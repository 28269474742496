import { Input, Component, OnInit } from '@angular/core'
@Component({
  selector: 'app-nuula-tile',
  templateUrl: './nuula-tile.component.html',
  styleUrls: ['./nuula-tile.component.scss'],
})
export class NuulaTileComponent implements OnInit {
  @Input() tileTitle: string
  @Input() tileBody: string
  @Input() tileButton: string
  @Input() tileIcon: string

  navigating = false
  openSections = false
  token = ''
  tokenError = false
  tokenVerified = false
  constructor() {}
  ngOnInit(): void {}
}
