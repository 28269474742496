<div class="rectangle">
  <div class="page-container ref">
    <div class="page-title">Almost Done!</div>
    <div class="intro">
      Review your business and personal information below to ensure that we have
      all the correct information. You also have the option to add additional
      phone numbers or emails.
    </div>

    <div class="biz-guarantor">
      <div class="title">BUSINESS</div>

      <div class="biz-group">
        <div class="name-of-business">
          <div id="biz-title" class="biz-title">
            {{ calcNums?.account.nameOfBusiness }}
          </div>
          <div class="biz-cor-structure">
            {{ calcNums?.account.corporateStructure }}
          </div>
        </div>

        <div class="guarantor-address">
          <div>{{ calcNums?.account.addressStreet1 }}</div>
          <div>{{ calcNums?.account.addressStreet2 }}</div>
          <div>
            {{ calcNums?.account.addressCity }},
            {{ calcNums?.account.addressState }}
            {{ calcNums?.account.addressPostalCode }}
          </div>
        </div>

        <div class=""></div>
      </div>

      <div class="title">GUARANTORS</div>

      <div class="biz-group" id="guarantor-group">
        <div *ngFor="let guarantor of calcNums?.guarantors">
          <div class="guarantor">
            <div class="biz-title">
              {{ guarantor?.user.fullName }}
            </div>
            <div class="biz-detail">
              <div class="mobile-phone-icon"></div>
              <div class="num-email">
                {{ guarantor?.user.phoneNumber }}
              </div>
            </div>
            <div class="biz-detail">
              <div class="email-icon"></div>
              <div
                class="num-email"
                [ngClass]="
                  onlyOneGuarantor() || thirdGuarantor(guarantor)
                    ? ''
                    : 'break-word'
                "
              >
                {{ guarantor?.user.email }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="checkbox">
        <input
          (click)="toggleContact()"
          id="add-phone-email"
          value="checked"
          class="acknowledged"
          #acknowledged
          type="checkbox"
          name="add-phone-email"
        />
        <label for="add-phone-email">
          I would like to add additional phone numbers and/or emails.
        </label>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showContact" class="additional-contact-rectangle">
  <div class="additional">
    <form [formGroup]="guarantorForm">
      <div class="phone">
        <div class="add-title" id="additional-phones">
          ADDITIONAL PHONE NUMBERS
        </div>

        <div formArrayName="contacts">
          <div *ngFor="let item of contacts.controls; let i = index">
            <div [formGroupName]="i" class="contacts-group">
              <div>
                <div id="phone-num-title" class="phone-num">
                  Phone Number #{{ i + 1 }}
                </div>
                <input
                  (focus)="focusAddPhone()"
                  (blur)="blurAddPhone()"
                  formControlName="phone"
                  type="text"
                  name="phone"
                  class="add-contact"
                  id="phone-input{{ i }}"
                  placeholder="(###) ###-####"
                  value="{{ newphone[i] }}"
                  maxlength="14"
                  (keydown)="amountKeydown($event)"
                  maxlength="14"
                />

                <div class="alert alert-danger err-text">
                  <span
                    *ngIf="
                      (showError || blurAddPhoneErr) &&
                      item['controls'].phone.errors?.required
                    "
                    >Phone number is required.</span
                  >

                  <span
                    *ngIf="
                      (showError || blurAddPhoneErr) &&
                      item['controls'].phone.errors?.ValidatePhone
                    "
                    >Enter a valid phone number.</span
                  >
                </div>
              </div>

              <div>
                <div id="phone-num-for-title" class="phone-num">
                  Phone Number For
                </div>
                <select
                  formControlName="phoneGuarantorName"
                  name="name"
                  class="select-guarantor"
                >
                  <option id="default-phone-guarantor-{{ i }}" value="" hidden>
                    Select Guarantor
                  </option>
                  <option
                    value="{{ guarantor.user.fullName }}"
                    *ngFor="let guarantor of calcNums.guarantors"
                    [value]="guarantor.user.fullName"
                  >
                    {{ guarantor.user.fullName }}
                  </option>
                </select>

                <div
                  *ngIf="
                    showError ||
                    item['controls'].phoneGuarantorName.dirty ||
                    item['controls'].phoneGuarantorName.touched
                  "
                  class="alert alert-danger err-text"
                >
                  <span *ngIf="item['controls'].phoneGuarantorName.invalid">
                    Guarantor Name is required.</span
                  >
                </div>
              </div>

              <div>
                <div class="phone-num"></div>
                <div
                  class="delete-group cursor-pointer"
                  (click)="onDeleteContacts(i)"
                >
                  <div class="delete">
                    <img
                      class="trashcan-icon"
                      src="/assets/imgs/trashcan-icon.svg"
                    />Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-num">
          <div class="add-phone" id="add-num" (click)="onAddPhone()">
            <img class="add-icon" src="/assets/imgs/add-icon.svg" />Add Phone
            Number
          </div>
        </div>
      </div>
    </form>

    <form [formGroup]="emailForm">
      <div class="email">
        <div id="additional-emails" class="add-title">ADDITIONAL EMAILS</div>

        <div formArrayName="emails">
          <div
            class="email-container"
            *ngFor="let item of emailsControl.controls; let i = index"
          >
            <div [formGroupName]="i" class="contacts-group">
              <div>
                <div class="phone-num">Email #{{ i + 1 }}</div>

                <input
                  (focus)="focusAddEmail()"
                  class="add-contact"
                  (blur)="blurAddEmail()"
                  type="email"
                  formControlName="email"
                  #email{{i}}
                  id="email{{ i }}"
                />
                <div class="">
                  <input
                    value="checked"
                    id="send-email-statement{{ i }}"
                    class="send-statement"
                    #{{item}}
                    type="checkbox"
                    name="send-email-statement{{ i }}"
                    formControlName="checkEmail"
                  />
                  <label class="statement" for="send-email-statement{{ i }}">
                    Send statements to this email.
                  </label>
                </div>
                <div class="alert alert-danger err-text">
                  <span
                    *ngIf="
                      (showError || blurAddEmailErr) &&
                      item['controls'].email.errors?.required
                    "
                    >Email is required.</span
                  >
                  <span
                    *ngIf="
                      item['controls'].email.errors?.pattern && blurAddEmailErr
                    "
                    >Enter a valid email.</span
                  >
                </div>
              </div>

              <div>
                <div class="phone-num">Email For</div>

                <select
                  formControlName="emailGuarantorName"
                  name="name"
                  class="select-guarantor"
                >
                  <option id="default-email-guarantor-{{ i }}" value="" hidden>
                    Select Guarantor
                  </option>
                  <option
                    value="{{ guarantor.user.fullName }}"
                    *ngFor="let guarantor of calcNums.guarantors"
                    [value]="guarantor.user.fullName"
                  >
                    {{ guarantor.user.fullName }}
                  </option>
                </select>
                <div
                  *ngIf="
                    showError ||
                    item['controls'].emailGuarantorName.dirty ||
                    item['controls'].emailGuarantorName.touched
                  "
                  class="alert alert-danger err-text"
                >
                  <span *ngIf="item['controls'].emailGuarantorName.invalid"
                    >Guarantor Name is required.</span
                  >
                </div>
              </div>

              <div>
                <div class="phone-num"></div>
                <div
                  class="delete-group cursor-pointer"
                  (click)="onDeleteEmails(i)"
                >
                  <div class="delete">
                    <img
                      class="trashcan-icon"
                      src="/assets/imgs/trashcan-icon.svg"
                    />Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-num">
          <div class="add-email" (click)="onAddEmail()">
            <img class="add-icon" src="/assets/imgs/add-icon.svg" />Add Email
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="biz-guarantor first-payment-due biz-ref-rectangle">
  <div>
    <div class="title">DISBURSEMENT AMOUNT</div>
    <div class="dis-amt">{{ calcNums?.disbursement | currency }}</div>
    <div class="amt-info">To {{ calcNums?.bankAccount }}</div>
  </div>

  <div>
    <div class="title">FIRST PAYMENT DUE</div>
    <div class="first-payment-amt">{{ calcNums?.paymentAmt | currency }}</div>
    <div class="amt-info">on {{ selectedDate }}</div>
    <div>
      <a class="info" (click)="showChangeFirstPaymentDay()">
        <img id="pencil" src="assets/imgs/icon-pencil.svg" />Change Date
      </a>
    </div>
  </div>
</div>

<div class="biz-ref-rectangle reference-container">
  <div class="">
    <div class="biz-reference">
      <div class="page-sub-title">Business Reference</div>

      <div class="intro">
        Lastly, please provide a professional business reference to keep on file
        in case we cannot reach you. This should be a business that your company
        conducts business with. Examples include a
        <strong
          >vendor/supplier, a business customer, accountant, attorney,
          sub-contractor, etc</strong
        >.
      </div>
    </div>

    <form
      class="offer-details-grid"
      [formGroup]="bizRefForm"
      (keydown)="onSubmit($event)"
    >
      <div class="grid1fr ref">
        <div class="biz-ref-field">
          <div class="ref-title">Business Name</div>
          <input
            type="text"
            formControlName="refBizEntity"
            #refBizEntity
            class="biz-input"
          />
          <div class="ref-title information">
            Cannot be a businesss you own.
          </div>
          <div
            *ngIf="
              showError ||
              bizRefFormControls.refBizEntity.dirty ||
              bizRefFormControls.refBizEntity.touched
            "
            class="alert alert-danger err-text"
          >
            <div
              *ngIf="bizRefFormControls.refBizEntity.invalid"
              class="alert alert-danger err-text"
            >
              <!-- if error -->
              <span class="alert alert-danger help-text">
                <div
                  *ngIf="bizRefFormControls.refBizEntity.errors?.required"
                  [ngClass]="
                    bizRefFormControls.refBizEntity.invalid
                      ? 'red-text'
                      : 'green-text'
                  "
                >
                  <span>Business name is required.</span>
                </div>
              </span>
            </div>
          </div>
        </div>

        <div class="biz-ref-field">
          <div class="ref-title">Contact Name</div>
          <input
            type="text"
            formControlName="refName"
            #refName
            class="biz-input"
          />
          <div class="ref-title information">
            Must be an employee or owner of the business.
          </div>
          <!-- if dirty or touched -->
          <div
            *ngIf="
              showError ||
              bizRefFormControls.refName.dirty ||
              bizRefFormControls.refName.touched
            "
            class="alert alert-danger err-text"
          >
            <div
              *ngIf="bizRefFormControls.refName.invalid"
              class="alert alert-danger err-text"
            >
              <!-- if error -->
              <span class="alert alert-danger help-text">
                <div
                  *ngIf="bizRefFormControls.refName.errors?.required"
                  [ngClass]="
                    bizRefFormControls.refName.invalid
                      ? 'red-text'
                      : 'green-text'
                  "
                >
                  <span>Contact name is required.</span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="biz-ref-field">
          <div class="ref-title">Contact Title/Position</div>
          <input
            type="text"
            formControlName="refRelationship"
            #refRelationship
            class="biz-input"
          />
          <div class="ref-title information">
            Ex.: CEO, Head of Sales, Owner, etc.
          </div>
          <!-- if dirty or touched -->
          <div
            *ngIf="
              showError ||
              bizRefFormControls.refRelationship.dirty ||
              bizRefFormControls.refRelationship.touched
            "
            class="alert alert-danger err-text"
          >
            <div
              *ngIf="bizRefFormControls.refRelationship.invalid"
              class="alert alert-danger err-text"
            >
              <!-- if error -->
              <span class="alert alert-danger help-text">
                <div
                  *ngIf="bizRefFormControls.refRelationship.errors?.required"
                  [ngClass]="
                    bizRefFormControls.refRelationship.invalid
                      ? 'red-text'
                      : 'green-text'
                  "
                >
                  <span>Contact Title/Position is required.</span>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div class="biz-ref-field">
          <div class="ref-title">Contact Phone Number</div>
          <input
            (keydown)="amountKeydown($event)"
            (keyup)="formatBusinessPhone($event)"
            maxlength="14"
            type="text"
            (focus)="focusBizRefPhone()"
            (blur)="blurBizRefPhone()"
            formControlName="refNum"
            id="refNum"
            #refNum
            class="biz-input"
            value="{{ newphoneNo }}"
            placeholder="(###) ### - ####"
          />
          <!-- if dirty or touched -->
          <div
            *ngIf="showError || blurBizRefPhoneErr"
            class="alert alert-danger err-text"
          >
            <div
              *ngIf="bizRefFormControls.refNum.invalid"
              class="alert alert-danger err-text"
            >
              <!-- if error -->
              <span class="alert alert-danger help-text">
                <div
                  *ngIf="bizRefFormControls.refNum.errors?.required"
                  [ngClass]="
                    bizRefFormControls.refNum.invalid
                      ? 'red-text'
                      : 'green-text'
                  "
                >
                  <span>Contact phone number is required.</span>
                </div>

                <div
                  *ngIf="bizRefFormControls.refNum.errors?.ValidatePhone"
                  [ngClass]="
                    bizRefFormControls.refNum.invalid
                      ? 'red-text'
                      : 'green-text'
                  "
                >
                  <span>Enter a valid contact phone number. </span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="btn-container text-align-center">
      <button
        (click)="submitBizRref()"
        #submit
        type="submit"
        id="submitFinalReview"
        class="btn-background"
      >
        SUBMIT FOR FINAL REVIEW
      </button>
    </div>
  </div>
</div>
